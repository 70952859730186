/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 16/01/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

type IFormatter = (a: number) => number;

const FORMATTERS: { [key: string]: IFormatter } = {
    //Default (e.g. NZD,AUD,USD,EUR,GBP): cents to dollars
    DEFAULT: (a: number) => a / 100,
    //China: no conversion
    CNY: (a: number) => a,
};

const fromLowestCurrencyFormatter = (amount: number, code: string): number => {
    return (FORMATTERS[code] ?? FORMATTERS.DEFAULT)(amount);
};

export default fromLowestCurrencyFormatter;
