import { Dimensions, ScaledSize, StyleSheet } from "react-native";

type NamedStyles<T> = StyleSheet.NamedStyles<T>;

type MediaQuery<T extends NamedStyles<T>> = (
    target: number,
    ...args: ClassValue<T>[]
) => ClassValue<T>[];

type Object<T extends NamedStyles<T>> = {
    [P in keyof T]?: boolean | null | undefined;
};

export type ClassValue<T extends NamedStyles<T>> =
    | ClassValue<T>[]
    | keyof T
    | Object<T>
    | undefined
    | null;

export default function classnames<T extends NamedStyles<T>>(
    styles: T | NamedStyles<T>
) {
    function handleArgs(...args: ClassValue<T>[]) {
        const concatedStyles: StyleSheet.NamedStyles<T>[any][] = [];

        for (let i = 0, len = args.length; i < len; i++) {
            const arg = args[i];

            if (!arg) {
                continue;
            }

            const argType = typeof arg;

            if (argType === "string") {
                const str = arg as keyof T;
                const style = styles[str];
                concatedStyles.push(style);
            } else if (Array.isArray(arg) && arg.length) {
                const array = arg as ClassValue<T>[];
                const arrayStyles = handleArgs(...array);
                if (arrayStyles && arrayStyles.length) {
                    concatedStyles.push(...arrayStyles);
                }
            } else if (argType === "object") {
                const obj = arg as Object<T>;
                const keys = Object.keys(obj) as (keyof T)[];
                if (keys && keys.length) {
                    keys.forEach((prop) => {
                        const value = obj[prop];
                        if (!value) {
                            return;
                        }

                        const style = styles[prop];
                        concatedStyles.push(style);
                    });
                }
            }
        }

        return concatedStyles;
    }

    const createMediaQuery = (
        testFunc: (dimensions: ScaledSize, target: number) => boolean
    ) => {
        return (target: number, ...args: ClassValue<T>[]) => {
            const testResult = testFunc(Dimensions.get("window"), target);
            if (testResult) {
                return args;
            }
            return [];
        };
    };

    return {
        styles: handleArgs,
        minWidth: createMediaQuery(({ width }, target) => target <= width),
        maxWidth: createMediaQuery(({ width }, target) => target >= width),
        minHeight: createMediaQuery(({ height }, target) => target <= height),
        maxHeight: createMediaQuery(({ height }, target) => target >= height),
    };
}
