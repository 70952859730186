import {DispatchFunc, MapPreferences, ParkingType} from "../../model/Types";
import {Region} from "react-native-maps";

export const SET_MAP_PREFERENCES = 'SET_MAP_PREFERENCES';
export const RECEIVED_PARKING_TYPE = 'RECEIVED_PARKING_TYPE';
export const SET_LAST_MAP_LOCATION = 'SET_LAST_MAP_LOCATION'
export const TOGGLE_FAVOURITES_VIEW_STATE_MINIMISED = "TOGGLE_FAVOURITES_VIEW_STATE_MINIMISED";
export const ENABLE_MAP_SCROLL = "ENABLE_MAP_SCROLL";

export const saveMapPreferences = (prefs: MapPreferences) => (dispatch: DispatchFunc) =>
    dispatch(setMapPreferences(prefs));

const setMapPreferences = (preferences: MapPreferences) => ({
    type: SET_MAP_PREFERENCES,
    preferences,
});

export function setParkingType(parkingType: ParkingType) {
    return {
        type: RECEIVED_PARKING_TYPE,
        parkingType
    }
}

export function lastMapLocationReceived(lastMapLocation: Region) {
    return {
        type: SET_LAST_MAP_LOCATION,
        lastMapLocation
    };
}

export function toggleFavouritesViewStateMinimised() {
    return {
        type: TOGGLE_FAVOURITES_VIEW_STATE_MINIMISED
    };
}

export function enableMapScroll(enable: boolean) {
    return {
        type: ENABLE_MAP_SCROLL,
        enableMapScroll: enable
    };
}

export function setLastMapLocation(location: Region) {
    return (dispatch: DispatchFunc) => {
        dispatch(lastMapLocationReceived(location));
    };
}
