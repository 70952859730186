import {
    setFirebaseTokenAndUser,
    setIsAuthenticating
} from "../../redux/actions/auth";
import {setUser} from "../../redux/actions/user";
import {setUserId} from "../../redux/actions/data";
import { useAppDispatch } from "../../redux/redux";

export const useLogout = () => {
    const dispatch = useAppDispatch();

    return () => {
        console.log("clearing user values");
        dispatch(setIsAuthenticating(false));
        dispatch(setFirebaseTokenAndUser(undefined));
        dispatch(setUser(null));
        dispatch(setUserId(null));
    }

};
