export enum Status  {
    Operational,
    OutOfOrder,
    Deleted
}

export enum Type  {
    IoTStream,
    GSM
}

export enum Orientation {
    Entrance = `Entrance`,
    Exit = `Exit`,
    EntranceExit = `EntranceExit`,
}

export enum AccessGateProximity {
    GPS = 'GPS',
    Bluetooth = 'Bluetooth',
    AlwaysAllowed = 'AlwaysAllowed',
    AccessGateOverride = 'AccessGateOverride',
    Visitor = 'Visitor',
}

export type AccessGate = {
    /** for legacy gates, gate id = gate.beaconId
     * for newer gates, gate id is a random uuid
     * */
    id: string,

    bluetoothId: string,

    beaconId: string | null,

    park: string,

    status: Status,

    description: string,

    created: string
    lastUpdated: string,

    name: string,
    major: number | null,
    minor: number | null,

    lastOpened: string,
    cooldownPeriodSeconds: number,

    type: Type,

    gsmNumber: string,

    password: string,

    orientation: Orientation | null,
    latitude: number | null,
    longitude: number | null,
    limitDistanceToOpen: number | null,
    showGateInMobileApp: boolean,
}

export type AccessGateEx = AccessGate & {
    hash: string /* for BT gates, getAccessGateHash. Otherwise the gate id */
    allowedProximityTypes: AccessGateProximity[], /* local only, for convenience */
}
