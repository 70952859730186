import Strings from "../../../util/localization/localization";
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import { IconName } from "react/legacy/parkable-components/icon/Icons";
import Spinner from "react/legacy/parkable-components/spinner/Spinner";
import React, {useCallback, useEffect, useState} from "react";
import moment from "moment/moment";
import {Term} from "../../../model/Term";
import {getNextPaymentDate} from "./getNextPaymentDate";

type AllProps = {
    anchorDay: number|null,
    weekAnchorDay: string|null
    termStartedAt: string | null,
    term: Term | undefined
}

export default function subscriptionEndingTableRow(props: AllProps){

    const [contentLeft, setContentLeft] = useState<JSX.Element | undefined>(<Spinner/>);
    const [label, setLabel] = useState("");
    const [icon, setIcon] = useState<IconName|undefined>(undefined);
    const [dateString, setDateString] = useState(Strings("loading"));

    useEffect(() => {
        if((!!props.anchorDay || !!props.weekAnchorDay) && !!props.term){
            setDateString(calculateNextPaymentDate().format("Do MMMM YYYY"));

            setLabel(Strings("subscription_ends"));
            setContentLeft(undefined);
            setIcon("calendar");
        }
    }, [props.anchorDay, props.weekAnchorDay, props.term]);

    const calculateNextPaymentDate = useCallback(() => {
        if((!!props.anchorDay || !!props.weekAnchorDay) && !!props.term) {
            return getNextPaymentDate(props.term, props.anchorDay, props.weekAnchorDay, props.termStartedAt);
        }
        return moment();//never used
    }, [props.anchorDay, props.weekAnchorDay, props.term]);

    return (<TableRow contentLeft={contentLeft} iconLeft={icon}
                      label={label}>
        {dateString}
    </TableRow>)
}

