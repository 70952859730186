import { Term } from "../model/Term";
import { BayGroupType } from "react/model/BayGroupType";

export enum EmployeeSubscriptionStatus {
	Invited = 'Invited',
	Active = 'Active',
	HostEnding = 'HostEnding',
	LeaserEnding = 'LeaserEnding',
	PastDue = 'PastDue',
	Deleted = 'Deleted',
	Ended = 'Ended',
	Pending = 'Pending',
	Processing = 'Processing',
	Unpaid = 'Unpaid'
}

export interface AvailableToDTO {
	group: number;
	date: string;
	createdAt: string | null;
}

export interface AvailabilityRulesDTO {
	availableTo: AvailableToDTO[];
}

export interface EmployeeSubscriptionDTO {
	id: number;
	organisation: number;
	group: number;
	park: number;
	bay: number;
	owner: number;
	createdAt: string;
	endedAt: string | null;
	start: string | undefined;
	end: string | undefined;
	availabilityRules: AvailabilityRulesDTO;
	previousStatus: EmployeeSubscriptionStatus;
	status: EmployeeSubscriptionStatus;
	pricePerTerm: number;
	lastStripeChargeId: string;
	term: Term;
	termStartedAt: string | null;
	paymentFailed: string | null;
	nextPaymentAttempt: string | null;
	stripePlanId: string;
	stripeSubscriptionId: string;
	primaryStripeSubscriptionId: string;
	handledEventIds: Array<string>;
	groupType: BayGroupType;
	previousStripePlanId: string;
	previousStripeSubscriptionId: string;
	deposits: Array<number>;
	anchorDay: number;
	weekAnchorDay: string;
	nextAnchorDay: number | null;
	nextWeekAnchorDay: string | null;
	vehicles: number[] | null;
	processingFee: number;
}
