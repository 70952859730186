import {PricePeriod, PricePeriodType} from "react/model/parkingPrice/PricePeriod";
import {getCurrentDayOfWeek, getDayOfWeek, getYesterdayDayOfWeek, isFreeSessionVoucher} from "react/util/Util";
import moment, {Moment} from "moment";
import {ParkDTOWithTerritory} from "react/redux/actions/parks";
import {Park} from "react/model/Park";
import {ParkDTO} from "react/model/ParkDTO";
import {Voucher} from "react/model/Voucher";

export function getDailyPricePeriod(pricePeriods: PricePeriod[], time: string | undefined, currentDayOfWeek: string, yesterdayDayOfWeek: string) {
    const now = moment();

    const timeFilter = time ? `${time.split(":")[0].padStart(2, "0")}${time.split(":")[1]}00000`
        : parseInt(now.format("HHmmssSSS")) //10:12:45.234 hour, minute, seconds, milliseconds

    return pricePeriods
        .filter(p => p.type !== "Hourly")
        .filter(p => p.start <= +timeFilter || (p.end < p.start && p.end >= +timeFilter))
        .filter(p => p.end >= +timeFilter || (p.end < p.start && p.start <= +timeFilter))
        .filter(p => p.day === (p.end < p.start && +timeFilter <= p.end ? yesterdayDayOfWeek : currentDayOfWeek) || p.day === "Default")
        [0];
}

export function getCurrentDailyPricePeriod(pricePeriods: PricePeriod[], time?: string, date?: Moment) {
    const dayOfWeek = date ? getDayOfWeek(date.day()) : getCurrentDayOfWeek();
    const beforeDayOfWeek = date ? getDayOfWeek(date.add({day: -1}).day()) : getYesterdayDayOfWeek();

    return getDailyPricePeriod(pricePeriods, time, dayOfWeek, beforeDayOfWeek);
}

export function getCurrentHourlyPricePeriod(pricePeriods: PricePeriod[]) {
    const currentDayOfWeek = getCurrentDayOfWeek();
    return pricePeriods
        .filter(p => p.type === "Hourly")
        .filter(p => p.day === currentDayOfWeek || p.day === "Default")
        [0];
}

export function getPricePeriodByTypeAndDay(pricePeriods?: PricePeriod[], type?: PricePeriodType, day?: string) {
    return pricePeriods?.filter(p => p.type === type && (p.day === day || p.day === "Default"))[0] ?? undefined;
}

export function isCreditCardRequired(park: ParkDTOWithTerritory|Park|ParkDTO, pricePeriods: PricePeriod[], hasCard:boolean, voucher?: Voucher, isEv?: boolean){
    if(hasCard){
        return false;
    }

    const currentHourlyPricePeriod = !!pricePeriods ? getCurrentHourlyPricePeriod(pricePeriods) : null;
    const currentDailyPricePeriod = !!pricePeriods ? getCurrentDailyPricePeriod(pricePeriods) : null;

    const pricePerDay = currentDailyPricePeriod?.price??0;
    const pricePerHour = currentHourlyPricePeriod?.price??0;
    const pricePerKwh = park.pricePerKwh??0;
    const parkCostsMoney = pricePerDay > 0 || pricePerHour > 0 || (!!isEv && pricePerKwh > 0);

    if(!parkCostsMoney){
        return false;
    }

    return !voucher || !isFreeSessionVoucher(voucher, pricePerDay, pricePerHour);
}
