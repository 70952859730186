import {get, Token} from "../rest";
import {CampusesDataResponse} from "./dto/CampusesDataResponse";
import {useMySWR} from "../http/use-swr";
import {CampusDataResponse} from "./dto/CampusDataResponse";
import {Nully} from "../../util/nully";
import {Campus} from "../../model/Campus";

// REDUX, to display campus pins on the map
export const getCampuses = (api: string, token: Token,
                      latitude: number,
                      longitude: number,
                      distanceMetres: number,
                      limit: number,
                      cursor: string | null,
                      organisation?: number) => {
    return (
        get(token, `${api}v1/organisation/campus`, {
            latitude,
            longitude,
            distanceMetres,
            limit,
            cursor,
            organisation
        }) as Promise<CampusesDataResponse>
    )
};

export const useCampusesInOrganisation = (orgId: Nully<number>) => {
    const r = useMySWR<{data: Campus[]}>(orgId ? `v1/organisation/${orgId}/campus` : null);

    return {
        ...r,
        campuses: r.data
    }
}

//SWR, to fetch data for a Campus
export const useCampus = (organisationId: Nully<number>, campusId: Nully<number>) => {
    const url = organisationId && campusId ? `v1/organisation/${organisationId}/campus/${campusId}` : null;
    const {data, ...rest} = useMySWR<CampusDataResponse>(url);

    return {campus: data, ...rest};
};


