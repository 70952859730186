import React, { useRef } from "react";
import { Routes } from "../../navigation/root/root.paths";
import { FlatList, TouchableOpacity, View } from "react-native";
import { createRoute, useNavigation } from "../../navigation/constants";
import ParkableBaseView from "react/components/common/ParkableBaseView";
import Icons from "react/legacy/parkable-components/icon/Icons";
import Spinner from "react/legacy/parkable-components/spinner/Spinner";
import Text from "react/legacy/parkable-components/text/Text";
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import Strings from "../../util/localization/localization";
import { useSupportCases } from "react/api/support/support.api";
import { SupportCase } from "react/api/support/dto/SupportCase.dto";
import moment from "moment";
import {useCalendars} from "expo-localization";

const SupportView = () => {
    const flatListRef = useRef(null);
    const { cases, isLoading } = useSupportCases();
    const messageDateFormat = "D/M/YY";
    const navigation = useNavigation();
    const userMobile = useCalendars();
    const userAppTimezone = userMobile[0].timeZone??'UTC';

    const renderMessageRow = ({ item: supportCase }: { item: SupportCase }) => {
        return (
            <TouchableOpacity
                key={supportCase.id}
                onPress={() => {
                    navigation.push(Routes.CaseDetailsView, {
                        caseId: supportCase.id,
                    });
                }}
            >
                <TableRow
                    label={supportCase.subject ? Strings("case_number")(supportCase.caseNumber) : ""}
                    chevronText={moment.utc(supportCase.createdDate).tz(userAppTimezone).format(messageDateFormat)}
                    textProps={{ numberOfLines: 1, ellipsizeMode: "tail" }}
                    chevron
                >
                    {supportCase.subject || Strings("case_number")(supportCase.caseNumber)}
                </TableRow>
            </TouchableOpacity>
        );
    };

    const onSupportPress = () => {
        navigation.navigate(Routes.AddNewCaseView, {});
    };

    return (
        <ParkableBaseView toolbarIcon={Icons.add} onIconClick={onSupportPress}>
            <Text h1 bold>
                {Strings("support")}
            </Text>
            <View>
                {isLoading ? (
                    <Spinner />
                ) : (cases?.length ?? 0) <= 0 ? (
                    <Text>{Strings("no_messages_found")}</Text>
                ) : (
                    <FlatList
                        ref={flatListRef}
                        data={cases}
                        renderItem={renderMessageRow}
                        keyExtractor={(item, index) => `${item?.id}_${index}`}
                    />
                )}
            </View>
        </ParkableBaseView>
    );
};

export default SupportView;

export const SupportRoute = createRoute({
    path: Routes.SupportView,
});
