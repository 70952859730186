import { Bay } from "../../model/Bay";
import Text from "react/legacy/parkable-components/text/Text";
import { View } from "react-native";
import React from "react";
import { useTandemPod } from "../../api/tandem/tandem.api";
import { BayGroup } from "../../model/BayGroup";
import { getBayNumberHeaderSignage } from "react/util/baySignage";

type BayNumberHeaderProps = {
    bay?: Bay | null;
    bayGroup?: BayGroup;
};

export default function BayNumberHeader({ bay, bayGroup }: BayNumberHeaderProps) {
    const { tandemPod } = useTandemPod(bay?.tandemPod);

    const { rowLabel: header, label: text } = getBayNumberHeaderSignage(bay, bayGroup, tandemPod);

    const length = text?.length ?? 0;
    const reduct = Math.min(Math.max(0, (length - 3) * 7), 80);
    const size = length < 3 ? 100 : 100 - reduct;

    return (
        <View style={{ flexDirection: "column", alignItems: "center", marginTop: 25, marginBottom: 29 }}>
            <Text h2>{header}</Text>
            <Text bold style={{ fontSize: size, lineHeight: size, padding: 0, margin: 0 }}>
                {text}
            </Text>
        </View>
    );
}
