import React, { useCallback, useEffect, useState } from "react";
import Dialog from "react/components/dialog/Dialog";
import Strings from "../../../util/localization/localization";
import useSafeArea from "react/util/hooks/useSafeArea";
import * as Updates from "expo-updates";
import { AppState, AppStateStatus, Platform } from "react-native";
import { AppStateChangeThrottleMillis } from "../../../util/constants";
import { throttle } from "lodash";

export default function UpdateModal() {

    const { top } = useSafeArea();

    const [show, setShow] = useState(false);

    useEffect(() => {
        const subscription = AppState.addEventListener('change', onAppStateChange);
        return subscription.remove;
    },[]);

    function onAppStateChange(status: AppStateStatus) {
        if (status === "active") {
            throttledAppStateChange();
        }
    }

    const throttledAppStateChange = useCallback(throttle(() => {
        check().catch(console.error);
    }, AppStateChangeThrottleMillis) as () => void, []);

    async function check() {
        if (__DEV__ || Platform.OS === "web") {
            return;
        }
        console.log("Checking for OTA updates...");
        const { isAvailable } = await Updates.checkForUpdateAsync();
        if (!isAvailable) {
            return;
        }
        console.log("Update found. Fetching...");
        const { isNew } = await Updates.fetchUpdateAsync();
        console.log(`Update is ${isNew ? "" : "not"} new.`);
        setShow(isNew);
    }

    function onHide() {
        Updates.reloadAsync().catch(console.error);
    }

    return <Dialog style={{ position: "absolute", top, width: "100%" }}
                   disableCloseOnBackdropPress
                   onModalHide={onHide}
                   isVisible={show}
                   label={Strings("important_update")}
                   title={Strings("important_update_desc")}
                   positiveText={Strings("update")}
                   onPositivePress={onHide}
    />;
}
