import { Image, StyleSheet } from "react-native";

const splash = require("assets/meta/splash.png");

export default function SplashScreen() {
    return (
        <Image style={styles.image} source={splash} />
    );
}

const styles = StyleSheet.create({
    image: {
        height: "100%",
        width: "100%",
        resizeMode: "contain"
    }
});
