export enum ParkingRequestStatus {
    Accepted = "Accepted",
    Confirmed = "Confirmed",
    Expired = "Expired",
    CancelledUser = "CancelledUser",
    CancelledAdmin = "CancelledAdmin",
    Spent = "Spent",
    WaitList = "WaitList",
}
export interface ParkingRequest {
    id: number;
    createdAt: string;
    park: number | null;
    campus: number | null;
    campusOrganisation: number | null;
    user: number;
    organisation: number, //legacy requests have this null but all new requests have it
    status: ParkingRequestStatus;
    dayIndex: number;
    date: string; // joda LocalDate in format YYYY-MM-DD
    bay: number | null;
    inVehicleId: number | null;
    session: number | null;
    priority: ParkingRequestPriority | null;
    weekStarting: string | null;
    allocation: number | null;
    isSharingPool: boolean;
}

export enum ParkingRequestPriority {
    Normal = "Normal",
    High = "High",
}

export const activeParkingRequestStatus = [
    ParkingRequestStatus.Accepted,
    ParkingRequestStatus.Confirmed,
    ParkingRequestStatus.WaitList,
];

export type ActiveParkingRequestStatus =
    | ParkingRequestStatus.Confirmed
    | ParkingRequestStatus.Accepted
    | ParkingRequestStatus.WaitList;
