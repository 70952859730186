import Strings from "../../../util/localization/localization";
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import { IconName } from "react/legacy/parkable-components/icon/Icons";
import Spinner from "react/legacy/parkable-components/spinner/Spinner";
import React, {useCallback, useEffect, useState} from "react";
import {Term} from "../../../model/Term";
import {getNextPaymentDate, getNextPaymentStartDate} from "./getNextPaymentDate";
import Constants from "../../../util/constants";

type AllProps = {
    anchorDay?: number|null,
    weekAnchorDay?: string|null,
    term: Term,
    price?: number | undefined,
    status?: string|null,
    startDate?: string | null
}

export default function subscriptionPaymentTableRow(props: AllProps){

    const [label, setLabel] = useState("");
    const [dateString, setDateString] = useState<string>(Strings("loading"));
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(!!props.anchorDay || !!props.weekAnchorDay){
            (props.price??0) === 0 ? setLabel(Strings("term_renews")) : (props.status === Constants.Subscription_Status_Pending ?
                setLabel(Strings("date_of_first_payment")) : setLabel(Strings("next_payment_will_be_on")));
            setDateString(calculateNextPaymentDate(props.term, props.anchorDay||null, props.weekAnchorDay||null).format("Do MMMM YYYY"));
        }else if(!!props.startDate && props.status === Constants.Subscription_Status_Pending){
            setLabel(Strings("date_of_first_payment"));
            setDateString(calculateNextPaymentStartDate(props.startDate, props.term).format("Do MMMM YYYY"));
        }
        setLoading(false);
    }, [props.anchorDay, props.weekAnchorDay, props.price, props.startDate]);

    const calculateNextPaymentDate = useCallback((term: Term, anchorDay: number|null, weekAnchorDay: string|null) => {
        return getNextPaymentDate(term, anchorDay, weekAnchorDay);
    }, []);

    const calculateNextPaymentStartDate = useCallback((termStartDate: string, term: Term) => {
        return getNextPaymentStartDate(termStartDate, term);
    }, []);

    if (loading) {
        return <Spinner />
    }

    return (<TableRow iconLeft={"calendarDollar"}
                      label={label}>
        {dateString}
    </TableRow>)
}
