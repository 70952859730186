import React from "react";
import {
    StyleSheet,
    Text,
    TextProps,
    TextStyle,
} from "react-native";
import Colours from "../styles/Colours";
import Touchable, { TouchableProps } from "../touchable/Touchable";
import classnames from "../util/classnames";
import Icons, { IconName } from "./Icons";

const styles = StyleSheet.create({
    base: {
        width: 45,
        height: 45,
        alignItems: "center",
        justifyContent: "center",
    },
    small: {
        width: 36,
        height: 36,
    },
    large: {
        width: 54,
        height: 54,
    },
    icon: {
        fontFamily: "parkableicons",
        fontSize: 27,
        alignSelf: "center",
    },
    iconSmall: {
        fontSize: 15,
    },
    iconLarge: {
        fontSize: 36,
    },
});

const cn = classnames(styles);

export type IconProps = {
    name: IconName;
    color?: string;
    white?: boolean;
    small?: boolean;
    large?: boolean;
    iconStyle?: TextStyle;
    iconProps?: TextProps;
    disabledColor?: string;
} & TouchableProps;

export default function Icon(props: IconProps) {
    const { name, color, white, small, large, style, iconProps } = props;

    let defaultColor = Colours.NEUTRALS_BLACK;
    if (white) {
        defaultColor = Colours.NEUTRALS_WHITE;
    }

    const baseStyle = [
        cn.styles("base", {
            small: small,
            large: large,
        }),
        style,
    ];

    const iconStyle = [
        cn.styles("icon", {
            iconSmall: small,
            iconLarge: large,
        }),
        {
            color: color || defaultColor,
        },
        props.iconStyle,
    ];

    let content;
    if (name in Icons) {
        const nameKey = name as keyof typeof Icons;
        content = Icons[nameKey];
    } else {
        content = name;
    }

    return (
        <Touchable
            disabled={!props.onPress}
            disabledColor={props.disabledColor || "transparent"}
            {...props}
            style={baseStyle}
        >
            <Text {...(iconProps || {})} style={iconStyle}>
                {content}
            </Text>
        </Touchable>
    );
}
