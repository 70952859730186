import {formatTimeNumberToString} from "react/util/Util";
import {PricePeriod} from "react/model/parkingPrice/PricePeriod";

export const getPeriodsFormatted = (pricePeriods?: PricePeriod[]) => {
	const periods = {} as { [key: string]: { type: string, start: string, end: string, cap: number} };
	const caps = new Set() as Set<number>;
	if(!pricePeriods){
		return { periods: undefined, caps: caps};
	}
	for(const period of pricePeriods.filter(p => p.type !== "Hourly")){
		const start = formatTimeNumberToString(period.start);
		const end = formatTimeNumberToString(period.end);
		periods[period.type] = {type: period.type, start, end, cap: period.cap}
		caps.add(period.cap);
	}
	return {
		caps,
		periods: Object.values(periods)
			.sort((p1, p2) => p1.type === "EarlyBird" ? -1 :
				p1.type === "Day" ? 0 : 1)
	}
}
