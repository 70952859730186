import * as Location from "expo-location";
import {ILocation} from "../../util/Util";
import {DispatchFunc} from "../../model/Types";
import {Token} from "../../api/rest";
import {LocationAccuracy} from "expo-location";

export const CURRENT_POSITION_RECEIVED = 'CURRENT_POSITION_RECEIVED';
export const LOCATION_PROVIDER_STATUS_RECEIVED = 'LOCATION_PROVIDER_STATUS_RECEIVED';
export const SET_ERROR_GET_GPS_LOCATION = 'SET_ERROR_GET_GPS_LOCATION';

export function setErrorGetGPSLocation(value: boolean) {
    return (dispatch: DispatchFunc) => {
        dispatch(errorGetGPSLocationReceived(value));
    };
}

export function getCurrentPosition(
    onSuccess?: (response: ILocation) => void,
    noLocation?: (err: any) => void
) {
    return async (dispatch: DispatchFunc) => {
        await Location.requestForegroundPermissionsAsync();
        try {
            const { coords } = await Location.getCurrentPositionAsync({
                accuracy: LocationAccuracy.Balanced
            });
            const position = {
                latitude: coords.latitude,
                longitude: coords.longitude
            };
            // console.log("Geolocation.getCurrentPosition", position);
            dispatch(currentPositionReceived(position));
            if (onSuccess) {
                onSuccess({
                    latitude: coords.latitude,
                    longitude: coords.longitude
                });
            }
            return position;
        } catch (err) {
            console.log("errorGetGPSLocationReceived");
            dispatch(errorGetGPSLocationReceived(true)); //notification
            if (noLocation) {
                noLocation(err);
            }
        }
    };
}

export function setLocationProviderStatus(status:boolean) {
    return (dispatch: DispatchFunc) => {
        dispatch(locationProviderStatusReceived(status));
    }
}

export function locationProviderStatusReceived(status:boolean) {
    return {
        type: LOCATION_PROVIDER_STATUS_RECEIVED,
        status
    };
}

export function currentPositionReceived(gpsLocation: ILocation) {
    return {
        type: CURRENT_POSITION_RECEIVED,
        gpsLocation
    };
}

export function errorGetGPSLocationReceived(errorGetGPSLocation: boolean) {
    return {
        type: SET_ERROR_GET_GPS_LOCATION,
        errorGetGPSLocation
    };
}
