import React, {useEffect, useState} from 'react';
import Text from "react/legacy/parkable-components/text/Text";
import Strings from "../../util/localization/localization";
import Colours from "react/legacy/parkable-components/styles/Colours";
import moment from "moment";
import {useCurrentParkingSession} from "../../api/parking";
import {showHideReservationTimeOutModal} from "../../redux/actions/modal";
import {useAppDispatch} from "../../redux/redux";
import { useNavigation } from 'react/navigation/constants';
import { Routes } from 'react/navigation/root/root.paths';
import { parkReservationTime } from 'react/util/Util';
import { TableRow } from 'react/legacy/parkable-components';

type Props = {
    reservationMinutes?: number | null,
}

export default function ReservationTableRow(props: Props) {
    const dispatch = useAppDispatch();
    const timeoutReservationInMinutes = 5;
    const [showTimeOutReservationModal, setShowTimeOutReservationModal] = useState(false);
    const { reservationMinutes } = props;
    const navigation = useNavigation();
    const [remainingText, setRemainingText] = useState("");
    const { parkSession, mutate: updateActiveSession } = useCurrentParkingSession();

    useEffect(() => {
        if(parkSession?.reservationStartedAt !== null) {
            const timerId = setInterval(async () => {
                const now = moment();
                const minutesRemaining = parkReservationTime(reservationMinutes) - moment.duration(now.diff(parkSession!.reservationStartedAt)).minutes();
                const minutesToShow = Math.max(0, minutesRemaining);

                setRemainingText(Strings("minutesRemaining")(minutesToShow))

                if (minutesToShow === 0) {
                    updateActiveSession();
                    setShowTimeOutReservationModal(false);
                    dispatch(showHideReservationTimeOutModal(false));
                    navigation.reset({
                        routes: [{
                            name: Routes.ActiveSession
                        }]
                    })
                } else if (minutesToShow <= timeoutReservationInMinutes && !showTimeOutReservationModal) {
                    setShowTimeOutReservationModal(true);
                    dispatch(showHideReservationTimeOutModal(true));
                }
            }, 1000);
            return () => clearInterval(timerId);
        }
    }, [parkSession, reservationMinutes, showTimeOutReservationModal]);

    return (
        <TableRow
            condensed
            iconLeft="lock"
            backgroundColor={Colours.ORANGE_100}
            contentRight={
                <Text small style={{ marginRight: 18 }}>
                    {remainingText}
                </Text>
            }
        >
            {Strings("reserved")}
        </TableRow>
    );
}
