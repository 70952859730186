import React, { useEffect, useState } from "react";
import { Image, StyleSheet, View } from "react-native";
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "react/legacy/parkable-components/spinner/Spinner";
import Strings from "react/util/localization/localization";
import Text from "react/legacy/parkable-components/text/Text";

const parkableStack = require("../../../resources/parkableStack.png");

export default () => {

    const { loginWithRedirect } = useAuth0();
    const [displayError, setDisplayError] = useState(false);

    useEffect(() => {

        const checkSession = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            console.log("mdtest: ext param: ", urlParams.get("ext-integration-id"));
            try {
                await loginWithRedirect({
                    authorizationParams: {
                        "ext-integration-id": urlParams.get("ext-integration-id"),
                    },
                });
            } catch (error: any) {
                if (
                    error.error === "login_required" ||
                    error.error === "interaction_required"
                ) {
                    //restart logging flow
                    await loginWithRedirect();
                } else {
                    setDisplayError(true);
                    console.error("An unexpected error occurred:", error);
                }
            }
        };
        void checkSession();
    }, [loginWithRedirect]);

    return <View style={styles.web}>
        <Image style={styles.logo}
               source={parkableStack}
        />
        {!displayError && <View>
            <Spinner />
        </View>}
        {displayError && <Text style={styles.text}>{Strings("provisioning_error")}</Text>}
    </View>;
};

const styles = StyleSheet.create({
    web: {
        justifyContent: "center",
        alignItems: "center",
        height: "80%",
    },
    logo: {
        width: "50%",
        aspectRatio: 512/404,
    },
    text: {
        margin: 20,
    },
});
