import { IconName } from "react/legacy/parkable-components/icon/Icons";
import Strings from "react/util/localization/localization";
import localizeCurrency from "react/util/localization/localizeCurrency";
import { CasualDisplayType, MapPreferences, ParkingType } from "react/model/Types";

export type ShowCasualPinProps = {
	hasShortTerm?: boolean;
	isParkFull?: boolean;
	isParkOpen?: boolean;
	hideCasualBays?: boolean | null;
	isParkOwner?: boolean;
	isOrgMember?: boolean;
	isFavouritePark?: boolean | null;
	preferences?: Partial<Pick<MapPreferences, "hideClosedParks" | "hideFullParks" | "parkingType">>
};
export const getShowCasualPin = (
	{ preferences, isParkFull, isParkOpen, hasShortTerm, hideCasualBays, isParkOwner, isOrgMember, isFavouritePark }: ShowCasualPinProps
) => {
	if (hideCasualBays) {
		return isParkOwner;
	}
	if (isOrgMember || isFavouritePark) {
		// if bayType is Leasable. hasShortTerm return false.
		return true;
	}
	if (preferences?.parkingType !== ParkingType.CASUAL) {
		return false
	}
	if (!hasShortTerm) {
		return false;
	}
	if (preferences?.hideFullParks && isParkFull) {
		return false;
	}
	if (preferences?.hideClosedParks && !isParkOpen) {
		return false;
	}
	return true;
};


export type GenerateCasualTextArgs = {
	displayType: CasualDisplayType;
	isParkFull?: boolean;
	isFavouritePark?: boolean;
	currencyCode?: string;
	pricePerDay?: number;
	pricePerHour?: number;
	numSpotsLeft?: number;
	isOrgMember?: boolean;
};
export const generateCasualText = (args: GenerateCasualTextArgs) => {
	if (args.isOrgMember) {
		return ""
	}

	if (args.isParkFull) {
		return args.isFavouritePark ? "0" : Strings("full")
	}

	switch (args.displayType) {
		case CasualDisplayType.SPOTS_LEFT:
			return `${args.numSpotsLeft ?? "0"}`;
		default:
			return localizeCurrency(
				args.pricePerHour ?? 0,
				args.currencyCode,
				false,
				true
			);
	}
};

export const generateCasualIcon = (args: {
	isFavouritePark?: boolean
	isOrgMember?: boolean
	isMotorbikeDefault?: boolean
}): IconName => {
	if (args.isOrgMember) {
		return "buildingwork"
	}

	if (args.isFavouritePark) {
		return "star"
	}

	return args.isMotorbikeDefault ? "motorbike" : "car"

}
