import Strings from "../../../util/localization/localization";
import React from "react";
import {StyleSheet, View} from "react-native";
import { Text, Colours, Button, Icon } from "react/legacy/parkable-components";
import { getTimeElapsed } from "../SessionElapsedFunctions";
import { useUnshareBayContext } from "./context";
import Dialog from "react/components/dialog/Dialog";

export const BayInUseDialog = () => {
    const context = useUnshareBayContext()

    if(!context?.activeSession) {
        return null
    }

    const reservationMinutes = () => {
        if(context?.currentPark) {
            return context.currentPark.reservationMinutes
        } return null
    }

    return (
        <Dialog
            isVisible={context?.bayInUseDialog}
            label={Strings("your_bay_being_used")}
            labelProps={{style: {color: Colours.NEUTRALS_BLACK, textAlign: 'left', marginBottom: 0}}}>
            <View>
                <View style={styles.timeContainer}>
                    <Icon style={styles.iconStyle} iconStyle={{fontSize: 25, marginBottom: 0}} name={"stopwatch"} />
                    <View>
                        <Text grey h5 style={{marginBottom: 3}}>{Strings("session_details")}</Text>
                        <Text h4>{getTimeElapsed(context.activeSession, {reservationMinutes: reservationMinutes()})}</Text>
                    </View>
                </View>
                <View style={styles.messageContainer}>
                    <Text p>{Strings("bay_shared_in_use_description")}</Text>
                    <Button
                        center
                        plain
                        onPress={() => context?.setBayInUseDialog(false)}
                        textProps={{white: false}}
                        style={styles.button}
                    >
                        {Strings("okay")}
                    </Button>
                </View>
            </View>
        </Dialog>
    )
}

const styles = StyleSheet.create({
    timeContainer: {
        flexDirection: "row",

    },
    messageContainer: {
        marginTop: "auto",
        paddingTop: "auto",
    },
    button: {
        width: "auto",
        marginTop: 10,
        marginBottom: 15,
        borderRadius: 3,
        borderWidth: 1,
        borderColor: Colours.GREY_BORDER,
        backgroundColor: Colours.NEUTRALS_WHITE
    },
    iconStyle: {
        marginRight: 10
    }
});
