import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import InputWrapper, { InputWrapperProps } from "../inputWrapper/InputWrapper";
import Colours from "../styles/Colours";
import Text from "../text/Text";
import Touchable from "../touchable/Touchable";
import Nullable from "../types/Nullable";
import classnames from "../util/classnames";

const styles = StyleSheet.create({
    base: {},
    open: {
        borderBottomWidth: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    options: {
        borderWidth: 1,
        borderTopWidth: 0,
        borderColor: Colours.BLUE_300,
        borderBottomRightRadius: 3,
        borderBottomLeftRadius: 3,
        overflow: "hidden",
    },
    warning: {
        borderColor: Colours.ORANGE,
    },
    error: {
        borderColor: Colours.RED,
    },
    option: {
        paddingVertical: 9,
        paddingHorizontal: 18,
        backgroundColor: Colours.NEUTRALS_WHITE,
        borderRadius: 3,
    },
    optionPressIn: {
        backgroundColor: Colours.GREY_10,
    },
});

const cn = classnames(styles);

export type DropdownOption = {
    label: string;
    value: string;
};

export type DropdownProps = {
    options: DropdownOption[];
} & Omit<InputWrapperProps, "focused" | "value">;

type DropdownItemProps = {
    option: DropdownOption;
    onPress: (option: DropdownOption) => void;
};

function DowndownItem(props: DropdownItemProps) {
    const { option } = props;
    const { label, value } = option;
    const onPress = () => {
        props.onPress(option);
    };

    return (
        <Touchable style={styles.option} onPress={onPress}>
            <Text small>{label}</Text>
        </Touchable>
    );
}

export default function Dropdown(props: DropdownProps) {
    const { options, warning, error, ...otherProps } = props;

    const [stateValue, setStateValue] =
        useState<Nullable<DropdownOption>>(null);
    const [open, setOpen] = useState(false);

    const onPress = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const onDropdownItemPress = (option: DropdownOption) => {
        setOpen(false);
        setStateValue(option);
    };

    const renderedOptions = options.map((option) => (
        <DowndownItem
            key={option.value}
            option={option}
            onPress={onDropdownItemPress}
        />
    ));

    const optionsStyle = cn.styles("options", {
        warning: !!warning,
        error: !!error,
    });

    const content = <View style={optionsStyle}>{renderedOptions}</View>;

    const style = cn.styles("base", {
        open: open,
    });

    return (
        <InputWrapper
            {...(otherProps || {})}
            warning={warning}
            error={error}
            style={style}
            content={open && content}
            value={(stateValue || {}).label}
            focused={open}
            iconRight={open ? "cheveronup" : "cheverondown"}
            iconRightProps={{ small: true }}
            selectable
            onPress={onPress}
        />
    );
}
