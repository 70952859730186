import * as Type from '../actions/data'
import * as TypeUser from '../actions/user'

import { findConfig, WebConfig } from "react/util/hooks/useConfig";
import { Platform } from "react-native";

const { api: defaultApi } = Platform.OS === "web" ? WebConfig : findConfig("production");

const initialState = {
    userId: null as number | null,
    api: defaultApi.url,
    apiName: defaultApi.name,
    service: null as string | null,
    token: null as string | null, //old-style token
    keyLocations: null,
    masterStripeApiKey: null as string | null,
    identityProvider: null as string | null,
};

export type IDataReducer = typeof initialState;

export default function dataReducer(state = initialState, action: any): IDataReducer {
    switch (action.type) {
        case Type.SET_USER_ID:
            return {
                ...state,
                userId: action.userId,
            };
        case Type.SET_API_URL:
            return {
                ...state,
                api: action.api,
                apiName: action.name,
                service: action.service,
            };
        case Type.SET_KEY_LOCATIONS:
            return {
                ...state,
                keyLocations: action.locations
            };
        case TypeUser.SET_TOKEN:
            return {
                ...state,
                token: action.token
            };
        case TypeUser.REMOVE_TOKEN:
            return {
                ...state,
                token: null
            };

        case Type.SET_SAML_IDENTITY_PROVIDER:
            return {
                ...state,
                identityProvider: action.identityProvider
            }
        default:
            return state
    }
}
