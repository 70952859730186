/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 23/03/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


export default function convertHourlyAvailabilityToString(availability: any) {

    let hourlyAvailability = availability.hourlyAvailability;
    if (Array.isArray(hourlyAvailability)) {
        hourlyAvailability = hourlyAvailability.map((bool) => bool ? 't' : 'f').join('');
    }

    return {
        ...availability,
        hourlyAvailability,
    };
}