import { initializeApp, getApps, deleteApp } from "firebase/app";
import {
    getAuth,
    signInWithCustomToken,
    signInWithEmailAndPassword,
    setPersistence,
    signInWithPopup,
    isSignInWithEmailLink,
    signInWithEmailLink,
    reauthenticateWithCredential,
    updatePassword,
    signOut,
    UserInfo,
    EmailAuthProvider,
    SAMLAuthProvider,
    browserSessionPersistence,
} from "firebase/auth";
import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import FirebaseError = firebase.FirebaseError;

class _FirebaseService {

    initializeApp(config: Object, sessionOnlyStorage: boolean) {
        const apps = getApps();
        apps.forEach(deleteApp);
        initializeApp(config);

        if(sessionOnlyStorage) {
            setPersistence(getAuth(), browserSessionPersistence);
        }
    }

    signInWithCustomToken(token: string) {
        return signInWithCustomToken(this.getAuth(), token);
    }

    signInWithEmailAndPassword(email: string, password: string) {
        return signInWithEmailAndPassword(this.getAuth(), email, password);
    }

    signInWithPopup(provider: string) {
        return signInWithPopup(this.getAuth(), new SAMLAuthProvider(provider));
    }

    isSignInWithEmailLink(link: string){
        return isSignInWithEmailLink(this.getAuth(), link);
    }

    signInWithEmailLink(email: string, emailLink: string) {
        return signInWithEmailLink(this.getAuth(), email, emailLink);
    }

    reauthenticate(password: string) {
        const user = this.getCurrentUser();
        if(!user) {
            throw new Error("Not logged in!");
        }
        if(!user.email) {
            throw new Error("Cannot reauthenticate user without email");
        }
        const credential = EmailAuthProvider.credential(
            user.email,
            password
        );
        return reauthenticateWithCredential(user, credential)
    }

    updatePassword(newPassword: string) {
        const user = this.getCurrentUser();
        if(!user) {
            throw new Error("Not logged in!");
        }
        return updatePassword(user, newPassword);
    }

    getCurrentUser() {
        return this.getAuth().currentUser;
    }

    async reloadUser() {
        await FirebaseService.getCurrentUser()?.reload();
        return FirebaseService.getCurrentUser();
    }

    getAuth() {
        return getAuth();
    }

    logout() {
        return signOut(getAuth());
    }
}

export function isFirebaseError(object: any): object is FirebaseError {
    return object?.name === "FirebaseError";
}

export type FirebaseUser = UserInfo & { emailVerified: boolean };

export const FirebaseService = new _FirebaseService();

export const useFirebaseToken = () => {
    const [token, setToken] = useState<string | undefined>()

    useEffect(() => {
        const unsubscribe = getAuth().onIdTokenChanged(async (user) => {
            const _token = await user?.getIdToken()
            setToken(_token)
        })
        return unsubscribe
    }, [])

    return token
}

export function stripUnserializableValues(user: FirebaseUser) : FirebaseUser {
    return {
        email: user.email,
        displayName: user.displayName,
        emailVerified: user.emailVerified,
        uid: user.uid,
        phoneNumber: user.phoneNumber,
        photoURL: user.photoURL,
        providerId: user.providerId,
    };
}
