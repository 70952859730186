import React, { useEffect, useRef, useState } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { DialogRef } from "react/legacy/parkable-components/dialog/Dialog";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Dialog from "react/components/dialog/Dialog";
import { Routes } from "react/navigation/root/root.paths";
import Strings from "react/util/localization/localization";
import { IRootReducer } from "../../../redux/reducers/main";
import { Token } from "../../../api/rest";
import { connect } from "react-redux";
import { FirebaseService } from "react/util/services/firebase.service";
import { createRoute, NavigationProps } from "../../../navigation/constants";
import useSafeArea from "react/util/hooks/useSafeArea";
import { useAppDispatch } from "../../../redux/redux";
import useConfig from "react/util/hooks/useConfig";
import { Auth0Provider } from '@auth0/auth0-react';
import StartAuth from "../../../components/landing/vts/StartAuth";
import FinishAuth from "../../../components/landing/vts/FinishAuth";
import { useVtsClientId } from "../../../api/sso/vts";
import Spinner from "react/legacy/parkable-components/spinner/Spinner";

class VtsLoginParams {
    email: string;
}

type VtsLoginViewProps = ReturnType<typeof getReduxProps> & NavigationProps<Routes.VtsLoginView>;

/*
This is a web only view used for logging into VTS using Auth0 library OIDC PKCE flow.
The Auth0 library may be able to be used for any generic OIDC flow and if this turns out to be the case then this view could be used more genericly.
 */
const VtsLoginView = (props: VtsLoginViewProps) => {

    const urlParams = new URLSearchParams(window.location.search);
    const {data: vtsClientId} = useVtsClientId();
    const { top, bottom } = useSafeArea();
    const { navigation, apiName, api, route } = props;

    const dispatch = useAppDispatch();
    const dialogRef = useRef<DialogRef>(null);
    const config = useConfig();

    const [error, setError] = useState<string|undefined>(undefined);

    useEffect(() => {
        if (FirebaseService.getCurrentUser()) {
            navigation.reset({
                routes: [{
                    name: Routes.ParkableMapView,
                }],
            });
            return;
        }
        if (Platform.OS !== "web") {
            return;
        }

    }, []);

    const onAcknowledgeError = () => {
        navigation.pop();
    }

    useEffect(() => {
        if(!!error){
            dialogRef.current?.show();
        }
        else{
            dialogRef.current?.hide();
        }
    }, [error]);

    const loading = !vtsClientId;

    if(loading){
        return <View style={styles.spinnerView}>
            <View style={styles.web}>
            <Spinner />
        </View>
            </View>
    }

    return <View style={[styles.authView, {bottom}]}>
        <Auth0Provider
            domain="auth.vts.com"
            clientId={vtsClientId}
            authorizationParams={{
                redirect_uri: window.location.origin + "/vts/login",
            }}
            cacheLocation="localstorage"
        >
            {urlParams.get("ext-integration-id") && <StartAuth/>}
            {urlParams.get("code") && <FinishAuth/>}
        </Auth0Provider>
        <Dialog ref={dialogRef} label={Strings("error")} labelProps={{red: true}} title={error} positiveText={Strings("ok")} onPositivePress={onAcknowledgeError}/>
    </View>
}

const styles = StyleSheet.create({
    spinnerView: {
        flex: 1,
        backgroundColor: Colours.NEUTRALS_WHITE,
        alignItems: "center",
    },
    web: {
        flexDirection: "row",
        justifyContent: "center",
    },
    authView: {
        flex: 1,
        backgroundColor: Colours.NEUTRALS_WHITE
    }
});

const getReduxProps = (state: IRootReducer) => {

    const tokenObject = {
        firebaseToken: state.auth.fireBaseToken
    } as Token;

    return {
        api: state.data.api,
        apiName: state.data.apiName,
        token: tokenObject,
    };
}

export const VtsLoginRoute = createRoute({
    path: Routes.VtsLoginView,
    params: {
        type: VtsLoginParams,
        deserialize: d => ({
            email: d.String()
        })
    },
})

export default connect(getReduxProps)(VtsLoginView);
