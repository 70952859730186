import { Bay } from "../../model/Bay";
import { createRoute, NavigationProps } from "../../navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import React from "react";
import { Platform } from "react-native";
import ParkableMapView from "./ParkableMapView";
import ParkableMapWebView from "./ParkableMapWebView";
import { EmployeeSubscriptionDTO } from "../../dto/EmployeeSubscriptionDTO";

type Props = NavigationProps<Routes.ParkableMapView>

export default function ParkableMapRootView(props: Props) {
    return Platform.OS === "web"
        ? <ParkableMapWebView {...props}/>
        : <ParkableMapView {...props}/>;
}

export class MapScreenParams {
    latitude?: number;
    longitude?: number;
    latitudeDelta?: number;
    longitudeDelta?: number;
    zoom?: number;
    animatedToSearchLocation?: boolean;
    employeeSubscription?: EmployeeSubscriptionDTO;
    bay?: Bay;
    showSubscription?: boolean;
    bySearch?: boolean;
}

export const ParkableMapRoute = createRoute({
    path: Routes.ParkableMapView,
    params: {
        type: MapScreenParams,
        deserialize: (d) => ({
            latitude: d.Number(),
            longitude: d.Number(),
            latitudeDelta: d.Number(),
            longitudeDelta: d.Number(),
            zoom: d.Number(),
            animatedToSearchLocation: d.Boolean(),
            employeeSubscription: d.Json<EmployeeSubscriptionDTO>(),
            bay: d.Json<Bay>(),
            showSubscription: d.Boolean(),
            bySearch: d.Boolean(),
        })
    }
})
