import { useMySWR } from "../http/use-swr";
import { OrganisationDtoResponse } from "./dto/organisation.response";

export const useOrganisation = (organisationId?: string | number | null) => {
  const res = useMySWR<OrganisationDtoResponse>(
    organisationId ? `v2/organisations/${organisationId}/client` : null
  );

  return {
    ...res,
    organisation: res.data?.organisation,
  };
};
