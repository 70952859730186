import React, { useState } from "react";
import { Alert, StyleSheet, View } from "react-native";
import Button from "react/legacy/parkable-components/button/Button";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Text from "react/legacy/parkable-components/text/Text";
import { StripeCardElement } from "@stripe/stripe-js";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Strings from "../../../util/localization/localization";
import LoadingOverlay_new from "../../../components/common/LoadingOverlay_new";
import { Token } from "../../../api/rest";
import { logEvent } from "react/util/analytics";
import { Routes } from "../../../navigation/root/root.paths";
import { useNavigation } from "../../../navigation/constants";
import { useAppDispatch, useSelector } from "../../../redux/redux";
import { userCardsReceived } from "../../../redux/actions/user";
import { addUserActionAPI, addUserCardAPI } from "../../../api/user";
import StripeElementsContainer from "../../../components/common/StripeElementsContainer";
import { AddNewCardProps } from "./AddNewCardRootView";
import ParkableBaseView from "../../../components/common/ParkableBaseView";

const CreateCardTokenSuccess = "CreateCardTokenSuccess";
const CreateCardTokenFailed = "CreateCardTokenFailed";

export default function AddNewCardWebView(props: AddNewCardProps) {
    const [stripeLoading, setStripeLoading] = useState(true);

    return <StripeElementsContainer setStripeLoading={setStripeLoading}>
        {stripeLoading ?
            <LoadingOverlay_new loading={true} /> :
            <AddNewCardForm {...props} />}
    </StripeElementsContainer>
}

function AddNewCardForm(props: AddNewCardProps) {

    const { destination } = props.route.params;

    const stripe = useStripe();
    const elements = useElements();

    const { currentCardId } = useSelector(state => state.user);
    const { api } = useSelector(state => state.data);

    const token = useSelector(state => ({
        firebaseToken: state.auth.fireBaseToken
    } as Token));

    const dispatch = useAppDispatch();

    async function setDefaultCard(paymentMethodId: string) {
        if (currentCardId == paymentMethodId) {
            return;
        }
        try {
            const response = await addUserCardAPI(api, token, paymentMethodId);
            dispatch(userCardsReceived(response));
        } catch (err: any) {
            Alert.alert(Strings("error"), err?.message || Strings("internal_error_if_persists"));
        }
    }

    const [loading, setLoading] = useState(false);

    const navigation = useNavigation();

    const hasVehicle = useSelector(state =>
        !!state.user.currentVehicleId ||
        !!state.user.user?.vehicleId ||
        (state.user.vehicles?.length ?? 0) > 0);

    const onFinaliseAddCreditCardPress = async () => {

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        setLoading(true);

        try {
            const cardElement = elements.getElement(CardElement) as StripeCardElement;

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
            });

            if (error) {
                Alert.alert(`Error code: ${error.code}`, error.message);
                console.log("Setup intent confirmation error", error.message);
            } else if(paymentMethod){

                //add CreateCardTokenSuccess to UserAction
                await addUserActionAPI(api, token, CreateCardTokenSuccess);

                const facebookParams = {
                    fb_success: 1
                };

                logEvent(undefined, "fb_mobile_add_payment_info", facebookParams);

                await setDefaultCard(paymentMethod.id);

                onFinish();
            }

        } catch (err: any) {
            addUserActionAPI(
                api,
                token,
                CreateCardTokenFailed,
                "StripeAPI: " + (err.error || {}).message || ""
            ).catch(console.log);
        } finally {
            setLoading(false);
        }
    };

    const onFinish = () => {
        if (!destination) {
            navigation.goBack();
        } else if (!hasVehicle) {
            navigation.navigate(Routes.AddNewVehicleView, { destination });
        } else {
            // @ts-ignore
            navigation.navigate(destination.route, destination.params);
        }
    };

    return (<ParkableBaseView scrollable={false} loading={loading}>
        <View style={styles.mainContainer}>
            <Text h1>{Strings("whats_your_credit_card")}</Text>

            <View style={styles.cardField}>
                <CardElement options={{
                    hidePostalCode: true,
                    style: {base: {
                            color: Colours.NEUTRALS_BLACK,
                            fontFamily: '"Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                            lineHeight: '1.8',
                            fontSize: '16px',
                        }
                    }
                }} onReady={(el) => el.focus()}/>
            </View>

            <Button
                style={{
                    marginTop: 8,
                    justifyContent: "space-between"
                }}
                iconRight={"arrowboldright"}
                onPress={onFinaliseAddCreditCardPress}>
                {Strings("continue")}
            </Button>
        </View>
    </ParkableBaseView>);
}

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        paddingTop: 14,
        paddingBottom: 100,
    },
    cardField: {
        height: 50,
        marginVertical: 30
    },
});
