export enum MessageStatus {
    /**
     * Comment can be seen by user
     */
    Allowed= "Allowed",
    /**
     * Comment is waiting for approval/rejection by Parkable admin
     */
    Pending = "Pending",
    /**
     * Comment is rejected and cannot be seen by user
     */
    Barred = "Barred"
}

export enum MessageType {
    /**
     * Messages sent by an user.
     */
    User = "User",
    /**
     * Messages sent by the system when some events happen.
     */
    Event = "Event"
}

export interface ChatMessageDTO {
    id?: number,
    chat?: number,
    body: string,
    createdAt?: string,
    photos?: string[]  | null,
    /** Id of the message sender */
    senderId?: number,
    /** False if the receiver has not yet read the comment */
    receiverHasRead: boolean  | null,
    /** Comment status, defines whether the comment will be seen by the recipient */
    status?: MessageStatus | null;
    /** message sent from user / parkable events (auto) */
    type: MessageType | null;
}