import React, { Fragment, useContext, useEffect } from "react";
import { ChatContext, ProblemActionContext, SupportContext } from "../Context";
import { BubbleLeft, BubbleRight, BubbleRightWithAction, Chat, TandemBubbleRightOption } from "../Chat";
import _ from "lodash";
import { Bay, Feature } from "../../../model/Bay";
import Constants from "../../../util/constants";
import Strings from "../../../util/localization/localization";
import { useOrganisation } from "../../../api/organisations";
import { useOrganisationContactOptions } from "../../../api/organisationOptions/organisationOptions.api";
import { AllProblemViewProps } from "../../../views/support/ProblemView";
import { QuestionOption, QuestionOptionKey, QuestionType, QuestionTypes } from "./ProblemFlow.types";
import { usePark } from "../../../api/park";
import { useBay } from "../../../api/bay/bay.api";
import { useParkSessionV3 } from "../../../api/parkSession/parkSession.api";
import { useTerritory } from "../../../api/territory/territory.api";
import { useUserRoles } from "react/api/user/user.api";
import { userIsOrgMember } from "react/util/getUserMember";

const defaultFlow = (title: string): QuestionTypes => ({
    default:	{
        isItSupport: true,
        title: Strings("support_greeting_msg"),
        type: "options",
        options: [
            {
                title: Strings("vehicle_in_my_bay"),
                action: "onVehicleInBayPress",
                key: "vehicleInMyBay"
            },
            {
                title: Strings("something_wrong_with_bay"),
                action: "onSomethingWrongPress",
                key: "somethingWrongBay"
            },
            {
                title: Strings("message_parkable"),
                action: "onMessageParkablePress",
                key: "messageParkable"
            },
            {
                title: Strings("contact_organisation")(title),
                action: "onMessageOrganisationPress",
                key: "messageOrganisation"
            },
            {
                title: Strings("ev_faqs"),
                action: "onEvFaqsPress",
                key: "evFaqs"
            }
        ]
    }
});

const tandemFlow = (title: string): QuestionTypes => ({
    default:	{
        isItSupport: true,
        title: Strings("support_greeting_msg"),
        type: "options",
        options: [
            {
                title: Strings("contact_tandem_parkers"),
                action: "onMessageTandemParkersPress",
                key: "messageTandemParkers"
            },
            {
                title: Strings("contact_organisation")(title),
                action: "onMessageOrganisationPress",
                key: "messageOrganisation"
            },
        ]
    }
});

type ActionsWithConditions = {
    [key in QuestionOptionKey]: boolean
}

export const MainSupport = (props: AllProblemViewProps)=> {

    const {parkId, sessionId, subscription, bayId} = props.route.params;

    const { park } = usePark(parkId);
    const { territory } = useTerritory(park?.territory);
    const { bay } = useBay(parkId, bayId);
    const { parkSession: session } = useParkSessionV3(sessionId);
    const { organisation } = useOrganisation(park?.organisation);
    const { options } = useOrganisationContactOptions(organisation?.id);

    const { messages, showKeyboard, onSendPress } = useContext(ChatContext);
    const { problemActions } = useContext(ProblemActionContext);
    const { updateSupportFlow } = useContext(SupportContext);

    const { userRoles } = useUserRoles();
    const isOrgMember = userIsOrgMember(userRoles, organisation?.id);

    useEffect(() => {
        if (!options || !bay || !organisation) {
            return;
        }
        if (!bay.tandemPod) {
            updateSupportFlow(
                "main",
                defaultFlow(options.contactName || organisation.name),
                "default"
            );
        } else {
            const optionTitle = options.contactName || organisation.name;
            updateSupportFlow("main", tandemFlow(optionTitle), "default");
        }
    }, [bay, options, organisation]);

    const isSessionBay = !!(session || {}).bay;
    const bays = (bay || (subscription || session || {}).bay) as Bay | undefined;
    const hasSignage = !!bays?.signage;
    const isEmployeeSubscription = !!subscription && !!park?.organisation;
    const isEvBay = !!bays && _.includes(bays.features, Feature.ELECTRIC);
    const isUKPark = !!territory && Constants.COUNTRY_CODE_UK === territory.countryCode;

    const problemActionsWithConditions: Partial<ActionsWithConditions> = {
        'vehicleInMyBay': ((isSessionBay || isEmployeeSubscription) && hasSignage && (!isUKPark || !isEvBay)),
        'somethingWrongBay': (isSessionBay && hasSignage && (!isUKPark || !isEvBay)),
        'evFaqs': isEvBay,
        'messageOrganisation': isOrgMember,
        'messageParkable': true,
        'vehicleInMyBayYes': true,
        'vehicleInMyBayNo': true,
        'helpMessageNo': true,
        'helpMessageYes': true
    };

    const renderQuestion = (question: QuestionType, key: number) => {
        const title = question.title;
        const allowToCopyToClipboard = question.allowToCopyToClipboard;
        if(question.type === 'options') {
            return <Fragment key={key}>
                {(question.isItSupport) ?
                    <BubbleLeft content={title} allowToCopyToClipboard={allowToCopyToClipboard}/> :
                    <BubbleRight content={title}/>
                }
                {
                    question.options?.map((option: QuestionOption, index: number) => {
                        if (problemActionsWithConditions[option.key] !== false) {
	                        if (option.tandemPodId) {
		                        return (
			                        <TandemBubbleRightOption
				                        key={index}
				                        content={option}
				                        problemAction={() => {
                                            if (option.action) {
                                                problemActions?.[option.action]?.(question, option);
                                            }
				                        }}
			                        />
		                        );
	                        }
                            return <BubbleRightWithAction key={index} content={option.title} problemAction={() => {
                                if (option.action) {
                                    problemActions?.[option.action]?.(question, option)
                                }
                            }}/>
                        }
                    })
                }
            </Fragment>
        }

        if(question.type === 'content') {
            return <Fragment key={key}>
                {(question.isItSupport) ?
                    <BubbleLeft content={title} allowToCopyToClipboard={allowToCopyToClipboard}/> :
                    <BubbleRight content={title}/>
                }
            </Fragment>
        }
    };

    return (
        <Chat showKeyboard={showKeyboard} onSendPress={showKeyboard ? (text: string) => onSendPress(text) : undefined}>
            {messages && messages.map((question: QuestionType, i: number)=> {
                if(!question) {
                    return <div key={i}/>
                }
                return renderQuestion(question, i)
            })}
        </Chat>
    );
};
