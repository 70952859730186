import {StyleSheet} from "react-native";

export enum ErrorCodes {
    SETUP_REQUIRED = "SETUP_REQUIRED",
    INTERACTIVE_AUTH_REQUIRED = "INTERACTIVE_AUTH_REQUIRED",
}

export const validOrigins = ["https://app.parkable.com", "https://app-qa.parkable.com", "https://app-qa-teams.parkable.com", "https://martin.parkable.com"];

export const scopes = ['user.read'];

export const style = StyleSheet.create({
    container: {
        height: "20%",
        width: "100%",
    },
    image: {
        height: "60%",
        width: "80%",
        resizeMode: "contain",
        alignSelf: "center",
        marginTop: 50
    },
    text: {
        marginTop: 25
    }
});
