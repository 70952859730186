import React, {useEffect, useState} from 'react'
import {TextInput, Platform, StyleSheet, TouchableOpacity, View, Keyboard, KeyboardAvoidingView} from 'react-native';
import Strings from "../../util/localization/localization";
import {Icon, IconName, Colours, Spinner} from 'react/legacy/parkable-components';
import {PADDING} from "../../root/root.constants";

const iosTextHeight = 30;
const androidTextHeight = 30;
const textHeight = Platform.OS === 'ios' ? iosTextHeight : androidTextHeight;

type MessageInputProps = {
    numberOfLines?: number,
    onTextChange?: (text: string) => any,
    onActionPress?: (text: string, onSuccess: () => void, onError?: () => void) => any,
    placeholder?: string,
    actionText?: string,
    actionButtonDisabled?: boolean,
    actionButtonHidden?: boolean,
    textProps?: any,
    onHeightChange?: (height: number) => any,
    scrollToEnd?: () => void,
    value?: string,
    style?: any,
    leftIcon?: IconName,
    onLeftIconPress?: () => void,
};

const MessageInput = (props: MessageInputProps) => {

    const numberOfLines = props.numberOfLines??3;
    const actionText = props.actionText??Strings("send");
    const actionButtonDisabled = props.actionButtonDisabled??false;
    const actionButtonHidden = props.actionButtonHidden??false;
    const {
        onHeightChange,
        scrollToEnd,
        textProps,
        placeholder,
        value,
        style,
        leftIcon,
        onLeftIconPress
    } = props;

    const [text, setText] = useState("");
    const [loading, setLoading] = useState(false);
    const [height, setHeight] = useState(textHeight * 2);
    const [lines, setLines] = useState(1);

    useEffect(() => {
        if (value === '') {
            setHeight(textHeight * 2);
            setLines(1);
        }
    }, [value]);

    const onContentSizeChange = async (event: any) => {
        if (!text) {
            await setHeight(textHeight * 2);
            await setLines(1);
            await onHeightChange?.(height);
        } else {
            const height = Platform.OS === 'ios'
                ? event.nativeEvent.contentSize.height
                : event.nativeEvent.contentSize.height - androidTextHeight;
            const lines = Math.round(height / textHeight);
            const visibleLines = lines < numberOfLines ? lines : numberOfLines;
            const visibleHeight = textHeight * (visibleLines + 1) + textHeight;

            await setHeight(visibleHeight);
            await setLines(visibleLines);
            await onHeightChange?.(height);
        }
    };

    const onTextChange = (text: string) => {
        setText(text);
        props.onTextChange?.(text);
    };

    const onActionPress = () => {
        if(!loading) {
            if (!!text.length) {
                setLoading(true);
            }
            props.onActionPress?.(text, clear, onError);
            Keyboard.dismiss();
        }
    };

    const onError = () =>{
        setLoading(false);
        Keyboard.dismiss();
    };

    const clear = () => {
        setLoading(false);
        Keyboard.dismiss();
        setText("");
        scrollToEnd?.();
    };

    return (
        <View style={[styles.container, {height: height}, style]}>
            {leftIcon && onLeftIconPress && (
                <TouchableOpacity style={styles.leftIcon} onPress={onLeftIconPress}>
                    <Icon small name={leftIcon} iconStyle={{ fontSize: 20 }} />
                </TouchableOpacity>
            )}
            <View style={styles.textInputContainer}>
                <TextInput
                    testID={placeholder}
                    underlineColorAndroid={"transparent"}
                    placeholder={placeholder ?? Strings("start_typing")}
                    selectionColor={Colours.BLUE_300}
                    style={[styles.textInput, { fontStyle: text ? "normal" : "italic" }]}
                    autoCapitalize={"sentences"}
                    onChangeText={onTextChange}
                    multiline
                    value={value || text}
                    onContentSizeChange={onContentSizeChange}
                    {...textProps}
                />
                {!actionButtonHidden && (
                    <TouchableOpacity
                        testID={actionText}
                        activeOpacity={0.65}
                        style={styles.actionButton}
                        onPress={onActionPress}
                        disabled={actionButtonDisabled}
                    >
                        {!loading && (
                            <Icon
                                small
                                name="paperplane"
                                color={text ? Colours.BLUE_300 : Colours.GREY_50}
                                iconStyle={{ fontSize: 20 }}
                            />
                        )}
                        {loading && <Spinner />}
                    </TouchableOpacity>
                )}
            </View>
        </View>
    );
};

export default MessageInput as React.FunctionComponent<MessageInputProps>;

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
        marginTop: 7,
        marginBottom: -7,
        maxHeight: 80,
        backgroundColor: Colours.NEUTRALS_WHITE,
        paddingHorizontal: PADDING,
    },
    textInputContainer: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        borderColor: Colours.GREY_BORDER,
        paddingHorizontal: 5,
        paddingVertical: Platform.OS === "ios" ? 5 : 0,
        borderWidth: 2,
        borderRadius: 5,
    },
    textInput: {
        flex: 1,
        flexGrow: 1,
        paddingBottom: Platform.OS === "ios" ? 5 : 2,
    },
    actionButton: {
        marginLeft: 9,
    },
    leftIcon: {
        marginRight: 9,
        padding: Platform.OS === "ios" ? 5 : 6,
        borderRadius: 5,
        borderColor: Colours.GREY_BORDER,
        borderWidth: 2,
    },
});
