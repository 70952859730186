import { useMySWR, useMySWRInfinite } from "../http/use-swr";
import { ParkSession2Response } from "../parking";
import { get, put } from '../http/rest';
import { Nully } from "../../util/nully";
import { BonusSessionDiscountResponse, ParkSessions2CursorResponse } from './dto/parkingSessions.response';
import { mutate } from "swr";
import EntityResponse from "../../dto/EntityResponse";

export const useActiveParkSession = () => {
    return useMySWR<ParkSession2Response>(`v3/parksessions/active`);
}

export const useFailedSessionTransaction = () => {
    return useMySWR<ParkSession2Response>(`v3/parksessions/failedTransaction`, get, {
        onErrorRetry: (error) => { return /*dont retry, as we get an error response when no failed transaction found*/ }
    })
}

export const useParkSessionV3 = (sessionId: Nully<number>) => {
    return useMySWR<ParkSession2Response>(sessionId ? `v3/parksessions/${sessionId}` : null);
}

export const useBonusBayParkSession = (sessionId: Nully<number>) => {
    return useMySWR<ParkSession2Response>(sessionId ? `v3/parksessions/bonusBaySession/${sessionId}` : null)
}

export const useParkSessions = (count: number, cursor: string | undefined | null = undefined) => {
    let uri = `v3/parksessions?count=${count}`;

    if (cursor !== undefined) {
        uri = `v3/parksessions?count=${count}&cursor=${cursor}`;
    }
    return useMySWR<ParkSessions2CursorResponse>(uri);

}

export const getParkSession = (sessionId: string) => {
    return get(`v3/parksessions/${sessionId}`) as Promise<ParkSession2Response>;
}

export const getParkSessions = (count: number, cursor: string | undefined | null = undefined) => {
    let uri = `v3/parksessions?count=${count}`;

    if (cursor !== undefined) {
        uri = `v3/parksessions?count=${count}&cursor=${cursor}`;
    }
    return get<ParkSessions2CursorResponse>(uri);
}

export const refreshSessionHistory = () => {
    mutate(`v3/parksessions`).catch(console.error);
}

export const useParkSessionsWithCursor = (count: number) => {
    const getKey = (pageIndex: number, previousPageData: ParkSessions2CursorResponse | null) => {
        const params = { count };
        if (pageIndex === 0) {
            return [`v3/parksessions`, JSON.stringify(params)]
        }

        return [`v3/parksessions`, {
            ...params,
            cursor: previousPageData?.cursor
        }]
    };

    return useMySWRInfinite<ParkSessions2CursorResponse>(getKey);
}

export const useBonusSessionDiscount = (sessionId: Nully<number>) => {
    return useMySWR<BonusSessionDiscountResponse>(sessionId ? `v4/parksessions/${sessionId}/getBonusSessionDiscount` : null);
}

export const updateParkSessionVehicle = (sessionId: Nully<number>, vehicleId: Nully<number>) => put<ParkSession2Response>(`v3/parksessions/${sessionId}`, { vehicleId });

export const useIsPublicBay = (parkId: Nully<number>, bayId: Nully<number>) =>
    useMySWR<EntityResponse<boolean>>(parkId && bayId ?`v4/parksessions/${parkId}/${bayId}/is-public-bay` : null);

