import * as Types from "../actions/ev";
import { OcpiEvse } from "../../model/ocpi/OcpiEvse";
import {OcpiSession} from "../../model/ocpi/OcpiSession";
import {OcpiToken} from "../../model/ocpi/OcpiToken";

const init = {
    evses: {} as { [key: string]: { [key: string]: Array<OcpiEvse> } },
    ocpiSessions: {} as { [key: number]: Array<OcpiSession> },
    ocpiTokens: {} as {[key: string]: OcpiToken}
}

export type IEVReducer = typeof init
type Actions = Types.EV_ACTION
    | Types.EVSESSION_ACTION
    
export default function evReducer(state = init, action: any): IEVReducer {
    switch (action.type) {

        case Types.OCPI_EVSE_RECEIVED: {
            const { provider, location, evses } = action.payload;

            const newEvses = Object.assign({}, state.evses);
            newEvses[provider] = newEvses[provider] || {};
            newEvses[provider][location] = evses;

            return {
                ...state,
                evses: newEvses
            }
        }
        case Types.OCPI_EVSESESSION_RECEIVED: {
            const sessions = Object.assign({}, state.ocpiSessions);
            const sessionSessions = (sessions[action.payload.parkSessionId] || []);
            sessionSessions.push(action.payload.evSession);
            sessions[action.payload.parkSessionId] = sessionSessions;

            return {
                ...state,
                ocpiSessions: sessions
            }
        }
        case Types.OCPI_EVSESESSIONS_RECEIVED: {
            const sessions = Object.assign({}, state.ocpiSessions);
            sessions[action.payload.parkSessionId] = action.payload.evSessions;

            return {
                ...state,
                ocpiSessions: sessions
            }
        }

        default:
            return state;
    }
}
