import React from 'react';
import {FlatList, ListRenderItem, ScrollView} from 'react-native';

type ListProps = {
    renderRow?: ListRenderItem<any> | null,
    data?: any[] | null,
    scrollToEnd?: boolean,
    children?: any,
}

const List = (props: ListProps) => {

    let scrollView: any = null;

    if (props.data && props.renderRow) {
        return (
            <FlatList
                bounces={false}
                data={props.data}
                {...props}
                keyExtractor={(data, index) => `${index}`}
                renderItem={props.renderRow}
            />
        );
    }
    return (
        <ScrollView
            bounces={false}
            {...props}
            ref={ref => scrollView = ref}
            onContentSizeChange={() => {
                if (props.scrollToEnd && scrollView.scrollToEnd) {
                    scrollView.scrollToEnd();
                }
            }}>
            {props.children}
        </ScrollView>
    );
}

export default List;