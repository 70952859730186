import React, { PropsWithChildren, ReactNode } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import HelperBlock from "../helperBlock/HelperBlock";
import Icon, { IconProps } from "../icon/Icon";
import { IconName } from "../icon/Icons";
import Colours from "../styles/Colours";
import Text from "../text/Text";
import Touchable from "../touchable/Touchable";
import Nullable from "../types/Nullable";
import classnames from "../util/classnames";

const styles = StyleSheet.create({
    container: {},
    base: {
        width: "100%",
        borderRadius: 3,
        borderWidth: 1,
        borderColor: Colours.GREY_10,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    focused: {
        borderColor: Colours.BLUE_300,
    },
    error: {
        borderColor: Colours.RED,
    },
    warning: {
        borderColor: Colours.ORANGE_DARK,
    },
    input: {
        flex: 1,
        minHeight: 25,
        marginLeft: 18,
        marginRight: 18,
        justifyContent: "center",
        marginVertical: 9,
    },
    inputIconLeft: {
        marginLeft: 9,
    },
    inputIconRight: {
        marginRight: 9,
    },
    iconLeft: {
        marginLeft: 9,
        padding: 0,
        paddingHorizontal: 9,
    },
    iconRight: {
        marginRight: 9,
        padding: 0,
        paddingHorizontal: 9,
    },
});

const cn = classnames(styles);

export type InputWrapperProps = PropsWithChildren<{
    content?: ReactNode;
    style?: StyleProp<ViewStyle>;
    containerStyle?: StyleProp<ViewStyle>;
    inputStyle?: StyleProp<ViewStyle>;

    focused: boolean;
    value?: string;
    placeholder?: string;
    selectable?: boolean;
    label?: string;
    helper?: string;
    error?: Nullable<string>;
    warning?: Nullable<string>;
    onPress?: () => void;

    iconLeft?: IconName | JSX.Element;
    iconLeftProps?: Omit<IconProps, "name">;
    iconRight?: IconName | JSX.Element;
    iconRightProps?: Omit<IconProps, "name">;
    disabled?: boolean;
}>;

export default function InputWrapper(props: InputWrapperProps) {
    const {
        children,
        content,
        focused,
        value,
        placeholder,
        selectable = false,
        label,
        helper,
        error,
        warning,
        onPress,
        iconLeft,
        iconLeftProps,
        iconRight,
        iconRightProps,
        disabled,
    } = props;

    const containerStyle = [styles.container, props.containerStyle];

    const baseStyle = [
        cn.styles("base", {
            focused: focused,
            error: !!error,
            warning: !!warning,
        }),
        props.style,
    ];

    const inputStyle = [
        cn.styles("input", {
            inputIconLeft: !!iconLeft,
            inputIconRight: !!iconRight,
        }),
        props.inputStyle,
    ];

    const actualIconLeftStyle = [styles.iconLeft, iconLeftProps?.style];
    const actualIconRightStyle = [styles.iconRight, iconRightProps?.style];

    return (
        <View style={containerStyle}>
            {!!label && (
                <Text grey={disabled} bold strapline>
                    {label}
                </Text>
            )}

            <Touchable onPress={onPress} disableFeedback={!selectable}>
                <View style={baseStyle}>
                    {iconLeft && <>
                        {typeof iconLeft === "string"
                            ? <Icon
                                name={iconLeft}
                                {...(iconLeftProps || {})}
                                style={actualIconLeftStyle}
                            />
                            : <View style={actualIconLeftStyle}>
                                {iconLeft}
                            </View>
                        }
                    </>
                    }
                    <View style={inputStyle}>
                        {!!(value || placeholder) && (
                            <Text small grey={!value}>
                                {value || placeholder}
                            </Text>
                        )}
                        {children}
                    </View>
                    {iconRight && <>
                        {typeof iconRight === "string"
                            ? <Icon
                                name={iconRight}
                                {...(iconRightProps || {})}
                                style={actualIconRightStyle}
                            />
                            : <View style={actualIconLeftStyle}>
                                {iconRight}
                            </View>
                        }
                    </>}
                </View>
                {content}
            </Touchable>

            <HelperBlock error={error} warning={warning} />
            <HelperBlock helper={helper} />
        </View>
    );
}
