import React, { useCallback, useRef, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import Spinner from "react/legacy/parkable-components/spinner/Spinner";
import Icon from "react/legacy/parkable-components/icon/Icon";
import { DialogRef } from "react/legacy/parkable-components/dialog/Dialog";
import Dialog from "react/components/dialog/Dialog";
import Colours from "react/legacy/parkable-components/styles/Colours";
import moment from "moment";
import Strings from "../../../util/localization/localization";
import getParkAvailability from "../../../util/getParkAvailability";
import MakeBayAvailableView from "../../employeeSubscription/MakeBayAvailableView";
import { ParkDTO } from "../../../model/ParkDTO";
import Modal from "../../common/Modal";
import { MakeBayAvailableProvider } from "../../employeeSubscription/context";
import { EmployeeSubscriptionDTO } from "../../../dto/EmployeeSubscriptionDTO";

type AllProps = {
    subscription: EmployeeSubscriptionDTO,
    park?: Pick<ParkDTO, 'availability' | 'timeZoneId'>,
    style: {},
    disabled: boolean
    mutate: () => void;
}

export default function calendarButton(props: AllProps) {
    const dialogRef = useRef<DialogRef | null>(null);

    const [modalVisible, setModalVisible] = useState(false);
    const [currentSharingText, setCurrentSharingText] = useState("");

    const onSubUpdated = useCallback((subscription?: EmployeeSubscriptionDTO) => {
        setModalVisible(false);
        let count = 0;
        if (!!subscription?.availabilityRules &&
            !!subscription.availabilityRules.availableTo &&
            subscription.availabilityRules.availableTo.length > 0) {

            const uniqueDays = new Set();

            subscription.availabilityRules.availableTo.forEach((rule) => {
                console.log("RULE DATE: " + rule.date);
                const mmnt = moment(rule.date, 'YYYY-MM-DD');
                const startOfToday = moment().startOf('day').subtract(1, 'day');

                const isNew = !uniqueDays.has(rule.date);
                uniqueDays.add(rule.date);

                if (mmnt.isAfter(startOfToday)) {
                    console.log("is after");
                    if (isNew) {
                        ++count;
                    }
                }
                else {
                    console.log("is not after");
                }
            });
        }
        setCurrentSharingText(Strings("subscription_shared_for_x_days")(count));
        //if no sub then dialog was cancelled
        if (!!subscription) {
            dialogRef.current?.show();
        }

    }, []);

    return (
        <View style={[styles.mainView, props.style]} >
            <TouchableOpacity disabled={!props.park || props.disabled} onPress={() => setModalVisible(!modalVisible)}>
                {!!props.park
                    ? <Icon name={"calendar"} color={Colours.NEUTRALS_BLACK} />
                    : <Spinner color={Colours.GREEN_300} />
                }
            </TouchableOpacity>
            {!!props.park && (
                <Modal
                    plain
                    animationType="slide"
                    visible={modalVisible}
                    transparent={true}
                >
                    <MakeBayAvailableProvider>
                        <MakeBayAvailableView
                            onClose={onSubUpdated}
                            employeeSubscriptionId={props.subscription.id}
                            parkAvailability={getParkAvailability(props.park)}
                            mutate={props.mutate}
                        />
                    </MakeBayAvailableProvider>
                </Modal>
            )}
            <Dialog ref={dialogRef}
                label={Strings("subscription_updated")} labelProps={{ style: styles.dialogHeader }}
                positiveText={Strings("ok")}
                titleProps={{ style: { textAlign: "left" }, h2: undefined }}
                title={currentSharingText} />
        </View>)

}


const styles = StyleSheet.create({
    mainView: {
        borderColor: Colours.GREY_BORDER,
        borderWidth: 1,
        borderRadius: 3,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: "center",
        backgroundColor: "white"
    },
    dialogHeader: {
        textAlign: "left"
    }
});
