import React, {useState} from "react";
import Colours from "react/legacy/parkable-components/styles/Colours";
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import Text from "react/legacy/parkable-components/text/Text";
import { Routes } from "react/navigation/root/root.paths";
import {Platform, StyleSheet, TouchableOpacity} from "react-native";
import {ScrollView} from "react-native-gesture-handler";
import {usePark} from "../../../api/park";
import {useOrganisationContactOptions} from "../../../api/organisationOptions/organisationOptions.api";
import {useTandemChatParticipants, useTandemPod} from "../../../api/tandem/tandem.api";
import Strings from "../../../util/localization/localization";
import Spinner from "../../../components/common/Spinner";
import ChatParticipantRow from "../../../components/tandemChat/ChatParticipantRow";
import { useParkSessionV3 } from "../../../api/parkSession/parkSession.api";
import { isTandemSessionActive } from "../../../util/tandemChatConstants";
import { useUserOptions } from "../../../api/userOptions";
import {createRoute, NavigationProps, useNavigation} from "../../../navigation/constants";
import ParkableBaseView from "../../../components/common/ParkableBaseView";

class TandemChatViewParams {
    tandemPodId?: number;
    chatId?: number;
    parkSessionId?: number;
}

const ChatDetailsView = (props: NavigationProps<Routes.ChatDetailsView>) => {
    const { chatId, tandemPodId, parkSessionId } = props.route.params ?? {};

    const navigation = useNavigation();

    const { parkSession } = useParkSessionV3(parkSessionId);
    const { tandemPod } = useTandemPod(tandemPodId);
    const { park } = usePark(tandemPod?.park);
    const { options } = useOrganisationContactOptions(park?.organisation);
    const { userOptions } = useUserOptions();
    const { participants, isLoading: loadingParticipants } = useTandemChatParticipants(tandemPodId, chatId);
    const inactiveParticipantsLength = participants?.filter(p => !p.isActive).length??0;
    const hasInactiveParticipants = !!inactiveParticipantsLength;

    const [hideInactiveParkers, setHideInactiveParkers] = useState(false);

    const orgShowEmail = options?.privacyShowMemberEmail;
    const orgShowPhone = options?.privacyShowMemberPhone;
    const orgShowPrivateDetails = orgShowPhone || orgShowEmail;
    const userShowEmail = userOptions?.privacyOptions?.showEmail;
    const userShowPhone = userOptions?.privacyOptions?.showPhone;
    const userShowPrivateDetails = userShowPhone || userShowEmail;
    const privacyText = !orgShowPrivateDetails
        ? Strings("org_does_not_allow_contact_sharing")
        : userShowPrivateDetails
            ? Strings("your_contact_details_are_shared")
            : Strings("your_contact_details_are_not_shared");

    const tandemSessionActive = parkSession && isTandemSessionActive(parkSession.endedAt);

    const handlePrivacyChangePress = () => {
        navigation.push(Routes.PrivacySettingsView);
    }

    const renderParticipants = () => {
        return participants
            ?.filter((p) => !hideInactiveParkers || p.isActive)
            .map((participant, index) => {
                const correctIndex = index - (hideInactiveParkers ? 0 : inactiveParticipantsLength);
                return (
                    <ChatParticipantRow
                        hasPermissionToSeePrivacyDetails={tandemSessionActive}
                        key={participant.userId}
                        index={correctIndex}
                        participant={participant}
                    />
                );
            });
    };

    return (
        <ParkableBaseView scrollable={false} toolbarTitleText={"Chat"}>
            <ScrollView style={styles.details}>
                { tandemSessionActive && <TableRow
                    iconLeft="shieldwithtick"
                    label={Strings("privacy")}
                    backgroundColor={Colours.ORANGE_100}
                    contentRight={ orgShowPrivateDetails &&
                        <TouchableOpacity
                            activeOpacity={1}
                            style={styles.privacyRowButton}
                            onPress={handlePrivacyChangePress}
                        >
                            <Text small bold style={styles.privacyRowButtonText}>
                                {Strings("change")}
                            </Text>
                        </TouchableOpacity>
                    }
                >
                    <Text small style={styles.rowElementsColour}>
                        { privacyText }
                    </Text>
                </TableRow> }
                {loadingParticipants ? <Spinner/> :
                    !!participants?.length ? renderParticipants() :
                        <Text center grey style={styles.noParticipantsText}>{Strings("number_other_parkers")(0) }</Text>
                }

                {hasInactiveParticipants && <TouchableOpacity onPress={() => {setHideInactiveParkers(!hideInactiveParkers)}}>
                    <Text center grey style={styles.link}>{hideInactiveParkers ? Strings("show_inactive_parkers") : Strings("hide_inactive_parkers") }</Text>
                </TouchableOpacity>}
            </ScrollView>
        </ParkableBaseView>
    );
};

export default ChatDetailsView;

export const ChatDetailsRoute = createRoute({
    path: Routes.ChatDetailsView,
    params: {
        type: TandemChatViewParams
    }
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colours.NEUTRALS_WHITE,
    },
    statusBar: {
        marginTop: Platform.OS === "ios" ? -15 : 0
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    spacer: {
        width: "24%",
    },
    details: {
        paddingHorizontal: 9,
        marginBottom: Platform.OS === "ios" ? 0 : 10,
    },
    privacyRowButton: {
        backgroundColor: Colours.ORANGE,
        padding: 9,
        marginRight: 9,
        borderRadius: 3,
    },
    privacyRowButtonText: {
        color: Colours.NEUTRALS_WHITE,
    },
    rowElementsColour: {
        color: Colours.NEUTRALS_BLACK,
    },
    title:{
        alignItems: "center",
        justifyContent: "center",
        width: "52%",
        textAlign: "center"
    },
    link:{
        marginTop: 27,
        textDecorationLine: "underline"
    },
    noParticipantsText:{
        marginTop: 27,
    }
});
