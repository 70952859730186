/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Bruno on 16/03/22.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import React from 'react';
import {
    TouchableOpacity,
} from 'react-native';
import {TableRow} from 'react/legacy/parkable-components';
import moment from 'moment';
import Strings from "../../util/localization/localization";
import { Routes } from "react/navigation/root/root.paths";
import {ChatDTO} from "../../model/chat/ChatDTO";
import {useLastMessageOfTandemChat} from "../../api/tandem/tandem.api";
import {useNavigation} from "../../navigation/constants";

type MessageProps = {
    message: ChatDTO,
    id: number | string,
    parkSessionId: number | null,
    updatedAt: string | undefined,
    messageDateFormat: string,
    currentUserId: number | null,
}

export const Message = (props: MessageProps) => {

    const navigation = useNavigation();

    const {message, id, messageDateFormat, parkSessionId} = props;

    const {message: lastMessage} = useLastMessageOfTandemChat((message as ChatDTO).id);

    const updatedAt = moment(props.updatedAt);

    const body = lastMessage?.body;
    const userName = Strings("tandem_chat");

    const onMessageSelect = () => {
        navigation.push(Routes.TandemChatView, {
            tandemPodId: message.tandemPod ?? undefined,
            sessionId: parkSessionId ?? undefined,
            chatId: typeof id === "string" ? parseInt(id) : id,
        });
    };

    return (
        <TouchableOpacity key={id} onPress={() => onMessageSelect()}>
            <TableRow
                label={userName}
                chevronText={updatedAt.format(messageDateFormat)}
                textProps={{ numberOfLines: 1, ellipsizeMode: "tail" }}
                chevron
            >
                {body}
            </TableRow>
        </TouchableOpacity>
    );
};
