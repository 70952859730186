import { StyleSheet, View } from 'react-native';
import React from 'react';
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import Spinner from "react/legacy/parkable-components/spinner/Spinner";
import Text from "react/legacy/parkable-components/text/Text";
import Strings from '../../util/localization/localization';
import { useParks } from '../../api/park/park.api';
import {createRoute, NavigationProps, useNavigation} from "../../navigation/constants";
import {Routes} from "../../navigation/root/root.paths";
import ParkableBaseView from '../../components/common/ParkableBaseView';

class SelectParkViewProps {
    orgId: number;
    campusParksIds: number[];
}

export default function SelectParkView (props: NavigationProps<Routes.SelectParkView>) {
    const { orgId, campusParksIds } = props.route.params;
    const navigation = useNavigation();

    const {parks} = useParks(campusParksIds);

    return (
        <ParkableBaseView>
            <View style={styles.container}>
                <Text h1 bold>{Strings("select_park")}</Text>
                {!parks && <Spinner small />}
                {parks && parks.map((park) =>
                    park.availability.globalAvailability && (
                        <TableRow
                            key={park.id}
                            label={park.displayName ?? ""}
                            iconLeft="locationpin"
                            chevron
                            onPress={() =>
                                navigation.push(
                                    Routes.PreferredBaysView,
                                    { orgId, parkId: park.id }
                                )
                            }
                        >
                            {park.address}
                        </TableRow>
                    )
                )}
            </View>
        </ParkableBaseView>
    );
};


export const SelectParkRoute = createRoute({
    path: Routes.SelectParkView,
    params: {type: SelectParkViewProps},
});

const styles = StyleSheet.create({
    container: {
        marginBottom: 30,
    },
});
