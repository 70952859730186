import { StripePaymentsResponse } from "react/api/stripe";
import { useMySWR } from "../http/use-swr";
import { StripePaymentMethodsResponse } from "./dto/stripe-payment-methods.response";
import {FailedPaymentsResponse} from "react/api/stripe/dto/FailedPaymentsResponse";
import EntityResponse from "react/dto/EntityResponse";
import {PaymentDetails} from "react/api/stripe/dto/PaymentDetails";
import {FailedPaymentInfo} from "react/model/FailedPaymentInfo";
import {post} from "react/api/http/rest";
import { RetryPaymentRequest } from "./dto/RetryPaymentRequest";

export const useUserCards = () => {
	const res = useMySWR<StripePaymentMethodsResponse>("v3/stripe")

	return {
		...res,
		cards: res.cards,
		currentCardId: res.currentCardId
	}
}

export const useStripePayments = (subscriptionId: number) => {
	return useMySWR<StripePaymentsResponse>(`v2/stripe/${subscriptionId}/payments`);
};

export const usePaymentsRequiringAuthentication = () => {
	return useMySWR<FailedPaymentsResponse>(`v1/payments/authRequired`);
};

export const useFailedPayment = () => {
	return useMySWR<EntityResponse<FailedPaymentInfo>>(`v1/payments/failed-payment`);
};

export const usePaymentsDetail = (entityId?: number|string, entityType?: string) => {
	return useMySWR<EntityResponse<PaymentDetails>>(entityId && entityType ? `v1/payments/paymentsDetail?entityId=${entityId}&entityType=${entityType}`: null);
};

export const retryPayment = (data: RetryPaymentRequest) => {
	return post<EntityResponse<PaymentDetails>>(`v1/payments/retry-payment`,data)
}
