import { SHOW_MODAL, HIDE_MODAL, ParkableModal } from "../actions/modal";

export interface IModal {
  show: boolean;
  modal: ParkableModal | null;
  data: any;
}
const init: IModal = {
  show: false,
  modal: null,
  data: null,
};

export default function modalReducer(state = init, action: any): IModal {
  switch (action.type) {
    case SHOW_MODAL: {
      const { modal, data } = action.payload;

      return {
        show: true,
        modal,
        data,
      };
    }

    case HIDE_MODAL: {
      return {
        modal: null,
        data: null,
        show: false,
      };
    }

    default:
      return state;
  }
}
