import { activeParkingRequestStatus, ParkingRequest } from "../model/ParkingRequest";
import { Routes } from "react/navigation/root/root.paths";
import { logEvent } from "react/util/analytics";
import { Nully } from "./nully";
import { getNextConfirmedParkingRequestForCurrentUser } from "../api/parking-request/parking-request.api";
import { useNavigation } from "../navigation/constants";

export const onParkTomorrowPress = async (
    parkId: number | undefined,
    organisationId: Nully<number>,
    campusId: Nully<number>,
    userId: number | undefined,
    parkingRequests: ParkingRequest[],
    navigation: ReturnType<typeof useNavigation>
) => {

    const checkRequest = (pr: ParkingRequest | null) => {
        return pr && activeParkingRequestStatus.includes(pr.status) && (pr.park === parkId || pr.campus === campusId);
    };

    const parkingRequestFromRedux = parkingRequests.find(checkRequest);
    if (parkingRequestFromRedux) {
        //get latest value from api as it may be no longer active
        const response = await getNextConfirmedParkingRequestForCurrentUser();
        if (checkRequest(response?.parkingRequest)) {
            navigation.push(Routes.ConfirmedBookingView, {parkingRequestId: response?.parkingRequest!.id, backButtonToMapView: true});
            return;
        }
    }

    const params = {
        userId: `${userId??0}`,
        parkId: `${parkId??0}`,
        campusId: `${campusId??0}`,
        organisationId: `${organisationId??0}`,
    };
    logEvent(undefined,'park_tomorrow_button',params);
    navigation.push(Routes.ParkTomorrowView, {
        parkId,
        campusId,
        organisationId,
    });
}
