export type PromiseStatus = 'resolved' | 'rejected';
export type SettledPromise<T> = {
    status: PromiseStatus,
    value: T | undefined,
    reason: any | undefined
}

export const allSettled = <T> (promises: Promise<T>[]): Promise<Array<SettledPromise<T>>> => {
    return Promise.all(promises.map((promise): Promise<SettledPromise<T>> => {
            return promise
                .then((value): SettledPromise<T> => ({
                    status: "resolved",
                    value,
                    reason: undefined
                }))
                .catch((reason): SettledPromise<T> => ({
                    status: "rejected",
                    value: undefined,
                    reason,
                }));
        }
    ));
}