export const mapStyle = [
    {
        featureType: "administrative",
        stylers: [
            {
                color: "#5f6672",
            },
        ],
    },
    {
        featureType: "administrative",
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#a5a9b0",
            },
        ],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#8b8b8b",
            },
        ],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "labels.text",
        stylers: [
            {
                color: "#535b5f",
            },
        ],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#dde2e4",
            },
        ],
    },
    {
        featureType: "landscape",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#9e9e9f",
            },
        ],
    },
    {
        featureType: "landscape.man_made",
        elementType: "geometry",
        stylers: [
            {
                color: "#9e9e9f",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#a5a9b0",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#404549",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#b1afb3",
            },
        ],
    },
    {
        featureType: "poi.business",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.park",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#839a83",
            },
        ],
    },
    {
        featureType: "poi.park",
        elementType: "labels.icon",
        stylers: [
            {
                color: "#659863",
            },
        ],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#406d3f",
            },
        ],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#94a194",
            },
        ],
    },
    {
        featureType: "poi.school",
        elementType: "labels.icon",
        stylers: [
            {
                color: "#9aa69b",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#b4b4b4",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#8d8d8d",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#404549",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#c4c3c5",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#a9a174",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#a49052",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#313639",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#beb584",
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#b4b4b4",
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#8d8d8d",
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#4a5155",
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#bfbdbf",
            },
        ],
    },
    {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#535b5f",
            },
        ],
    },
    {
        featureType: "transit.station",
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#d9d9d9",
            },
        ],
    },
    {
        featureType: "transit.station.airport",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#7191a9",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#2d5484",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#9cbada",
            },
        ],
    },
];
