import { CredentialActionTypes, SET_CREDENTIALS, CLEAR_CREDENTIALS } from '../actions/credential';

interface CredentialState {
    email?: string;
    password?: string;
}

const initialState: CredentialState = {
};

const credentialReducer = (state = initialState, action: CredentialActionTypes): CredentialState => {
    switch (action.type) {
        case SET_CREDENTIALS:
            return {
                ...state,
                email: action.payload.email,
                password: action.payload.password
            };
        case CLEAR_CREDENTIALS:
            return {
                ...state,
                email: undefined,
                password: undefined
            };
        default:
            return state;
    }
};

export default credentialReducer;
