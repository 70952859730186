import { useEffect, useState } from "react";
import { getDistance } from "geolib";
import { Coords } from "google-map-react";
import { getCampuses } from "react/api/campus/campus.api";
import { getCampusesToMap } from "react/redux/actions/campuses";
import { CampusDTO } from "react/api/campus/dto/CampusDTO";
import { useAppDispatch, useSelector } from "../../../../../redux/redux";
import { getClosestParks } from "../../../../../redux/actions/parks";
import { Token } from "../../../../../api/rest";

export type ILoadPins = {
    min: number;
    max: number;
    pinDistanceMetres: number;
    loadPinsCount: number;
    radius: number;
};

export const LOAD_PINS: ILoadPins[] = [
    {
        min: 0,
        max: 12,
        pinDistanceMetres: 10000,
        loadPinsCount: 200,
        radius: 20000,
    },
    {
        min: 12,
        max: 13,
        pinDistanceMetres: 6000,
        loadPinsCount: 200,
        radius: 10000,
    },
    {
        min: 13,
        max: 14,
        pinDistanceMetres: 4000,
        loadPinsCount: 200,
        radius: 6000,
    },
    {
        min: 14,
        max: 15,
        pinDistanceMetres: 2000,
        loadPinsCount: 100,
        radius: 4000,
    },
    {
        min: 15,
        max: 16,
        pinDistanceMetres: 1000,
        loadPinsCount: 50,
        radius: 2500,
    },
    {
        min: 16,
        max: 17,
        pinDistanceMetres: 200,
        loadPinsCount: 25,
        radius: 1500,
    },
    {
        min: 17,
        max: 100,
        pinDistanceMetres: 100,
        loadPinsCount: 20,
        radius: 1000,
    },
];

export const MARKER_EXPIRATION_SECONDS = 120;

export const findPinLoadOptions = (distanceMetres: number) => {
    return [...LOAD_PINS].sort(
        (a, b) => {
            const deltaA = Math.abs(a.pinDistanceMetres - distanceMetres)
            const deltaB = Math.abs(b.pinDistanceMetres - distanceMetres)
            return deltaA > deltaB ? 1 : -1
        }
    )[0]
}

const getDistanceBetweenCoords = (from: Coords, to: Coords) => {
    if (from.lat == undefined || from.lng == undefined || to.lat == undefined || to.lng == undefined) {
        //HJ: This is a workaround to prevent the app crashing when one of the above values is undefined
        return 0;
    } else {
        return getDistance(from, to);
    }
}


// Throttle GETs if the new area is within the previous area
const verifyShouldLoadMore = (
    centerPoint?: Coords,
    boundPoint?: Coords,
    lastFetchedLocation?: Coords
) => {
    if (centerPoint == null || boundPoint == null) {
        return false
    }

    if (lastFetchedLocation === undefined) {
        return true
    }

    const distanceBetweenPoints = getDistanceBetweenCoords(centerPoint, lastFetchedLocation);

    const distanceToBounds = getDistanceBetweenCoords(centerPoint, boundPoint);

    const lastLoadPinOpts = findPinLoadOptions(distanceToBounds)
    const lastLoadDistance = lastLoadPinOpts.pinDistanceMetres
    const _shouldLoad = lastLoadDistance <= distanceBetweenPoints || lastLoadDistance <= distanceToBounds

    return _shouldLoad
}

export type UseClosestParksArgs = {
    centerPoint?: Coords,
    boundPoint?: Coords // Point used to determine radius, i.e. the bounds of the map
}
export const useClosestParks = (args: UseClosestParksArgs) => {
    const dispatch = useAppDispatch();
    const closestParks = useSelector(state => state.parks.mapParks)
    const [lastFetchedLocation, setLastFetchedLocation] = useState<Coords | undefined>()

    const {boundPoint, centerPoint} = args

    const fetchClosestParks = async (args: Pick<UseClosestParksArgs, "boundPoint" | "centerPoint">) => {
        const {boundPoint, centerPoint} = args
        if (boundPoint == null || centerPoint == null) {
            return
        }
        console.log("Fetching closest parks")

        const distanceToBounds = getDistanceBetweenCoords(centerPoint, boundPoint);

        const loadPinOpts = findPinLoadOptions(distanceToBounds)

        dispatch(getClosestParks(
            {latitude: centerPoint.lat, longitude: centerPoint.lng},
            loadPinOpts.pinDistanceMetres,
            loadPinOpts.loadPinsCount,
            null,
            MARKER_EXPIRATION_SECONDS,
            true,
        ))
    }

    const shouldFetch = verifyShouldLoadMore(centerPoint, boundPoint, lastFetchedLocation)

    useEffect(() => {
        if (centerPoint == null) {
            return
        }
        if (shouldFetch) {
            fetchClosestParks(args)
            setLastFetchedLocation({
                lat: centerPoint.lat,
                lng: centerPoint.lng,
            })
        }
    }, [shouldFetch, centerPoint?.lat, centerPoint?.lng])


    return [closestParks, fetchClosestParks] as const
}

export type UseClosestCampusArgs = {
    centerPoint?: Coords,
    boundPoint?: Coords
}

export const useClosestCampuses = (args: UseClosestCampusArgs) => {
    const dispatch = useAppDispatch();
    const mapCampuses = useSelector(state => state.campuses.mapCampuses);

    const [lastFetchedLocation, setLastFetchedLocation] = useState<Coords | undefined>()
    const {boundPoint, centerPoint} = args;

    const fetchClosestCampuses = async (args: Pick<UseClosestCampusArgs, "boundPoint" | "centerPoint">) => {
        const {boundPoint, centerPoint} = args
        if (boundPoint == null || centerPoint == null) {
            return
        }
        console.log("Fetching closest campuses")

        const distanceToBounds = getDistanceBetweenCoords(centerPoint, boundPoint);

        const loadPinOpts = findPinLoadOptions(distanceToBounds)

        dispatch(getCampusesToMap(
            {latitude: centerPoint.lat, longitude: centerPoint.lng},
            loadPinOpts.pinDistanceMetres,
            loadPinOpts.loadPinsCount,
            null,
            MARKER_EXPIRATION_SECONDS
        ))
    }

    const shouldFetch = verifyShouldLoadMore(centerPoint, boundPoint, lastFetchedLocation)

    useEffect(() => {
        if (centerPoint == null) {
            return
        }
        if (shouldFetch) {
            fetchClosestCampuses(args)
            setLastFetchedLocation({
                lat: centerPoint.lat,
                lng: centerPoint.lng,
            })
        }
    }, [shouldFetch, centerPoint?.lat, centerPoint?.lng])


    return [mapCampuses, fetchClosestCampuses] as const
}

export type useClosestCampusArgs = {
    api: string,
    token: Token,
    centerPoint?: Coords,
    boundPoint?: Coords
}

export const getClosestCampus = async (args: useClosestCampusArgs) => {
    const {boundPoint, centerPoint} = args

    if (boundPoint == null || centerPoint == null) {
        return [];
    }

    const distanceToBounds = getDistanceBetweenCoords(centerPoint, boundPoint);

    const loadPinOpts = findPinLoadOptions(distanceToBounds);

    const _getCampus = async (all_campuses: CampusDTO[] = [], cursor: string | null): Promise<CampusDTO[]> => {

        try {
            const response = await getCampuses(args.api, args.token, centerPoint.lat, centerPoint.lng, loadPinOpts.pinDistanceMetres, loadPinOpts.loadPinsCount, cursor);

            if (response?.data) {
                all_campuses = all_campuses.concat(response.data);
            }

            if (!!response.cursor) {
                return _getCampus(all_campuses, response.cursor);
            }

            return all_campuses;
        } catch (error) {
            return all_campuses;
        }

    }

    return await _getCampus([], null);
}
