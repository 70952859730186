import React, { createContext, PropsWithChildren, useContext, useState } from "react";
import {Moment} from "moment";

type BeaconMap = Map<string /*beacon hash*/, Moment>;

interface IBeaconContext {
    beacons: BeaconMap,
    setBeacons: (beacons: BeaconMap) => void,
}

const BeaconContext = createContext<IBeaconContext>(undefined as any);

export const useBeaconContext = () => {
    return useContext(BeaconContext);
}

type BeaconProviderProps = {}

export const BeaconProvider = ({ children }: PropsWithChildren<BeaconProviderProps>) => {

    const [beacons, setBeacons] = useState<BeaconMap>(new Map<string, Moment>());

    return (
        <BeaconContext.Provider value={{
            beacons, setBeacons,
        }}>
            {children}
        </BeaconContext.Provider>
    );
};
