import React, { useCallback, useEffect, useRef, useState } from "react";
import { Image, Platform, StyleSheet, View } from "react-native";
import Strings from "react/util/localization/localization";
import Button from "react/legacy/parkable-components/button/Button";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Text from "react/legacy/parkable-components/text/Text";
import { getActiveEvSession, getOcpiCommand, startEvSession } from "react/redux/actions/ev";
import { OcpiSession, SessionStatus } from "react/model/ocpi/OcpiSession";
import { Routes } from "react/navigation/root/root.paths";
import { getCurrentParkingSession } from "react/redux/actions/parking";
import { CommandResultType, OcpiCommand } from "react/model/ocpi/OcpiCommand";
import { createRoute, NavigationProps } from "react/navigation/constants";
import ParkableBaseView from "react/components/common/ParkableBaseView";
import { PADDING } from "react/root/root.constants";
import { useParkSessionV3 } from "react/api/parkSession/parkSession.api";
import { usePark } from "react/api/park";
import { useBay } from "react/api/bay/bay.api";
import { useAppDispatch } from "react/redux/redux";

const EV_Charger_connect = require('react/resources/EV_Charger_connect.png');
const fingerLightning  = require('react/resources/fingerLightning.png');

export default function ConnectEVNozzleView (props: Props) {

    const { navigation } = props;

    const { sessionId, parkId, bayId, initialConnected, overrideBackButton } = props.route.params;

    const {parkSession} = useParkSessionV3(sessionId);
    const { park } = usePark(parkId);
    const { bay, isLoading: bayLoading } = useBay(parkId, bayId);

    const dispatch = useAppDispatch();

    enum State {
        Failed,
        Dormant,
        Success,
        WaitingForChargeCommand,
        WaitingForChargeSession,
        Timeout

    }
    const [state, setState] = useState<State>(State.Dormant);
    const stateRef = useRef<State|undefined>();
    const commandRef = useRef<OcpiCommand|undefined>();
    const [titleText, setTitleText] = useState("");
    const [descriptionText, setDescriptionText] = useState("");
    const [imageSource, setImageSource] = useState<number|undefined>(undefined);

    const timerStartedRef = useRef<undefined|Date>();

    const updateState = (newState:State)=> {
        setState(newState);
        stateRef.current = newState;

        console.log({newState});

        if(newState === State.Failed) {
            console.log("SET STATE FAILED");
            setTitleText(Strings("oh_no_something_went_wrong"));
            setImageSource(EV_Charger_connect);
        }
        else if(newState === State.WaitingForChargeCommand) {
            setTitleText(Strings("waiting_for_charger"));
            setDescriptionText(Strings("initialising_charge_experience"));
            setImageSource(fingerLightning);
            //reset timer
            timerStartedRef.current = new Date();
        }
        else if(newState === State.WaitingForChargeSession) {
            setTitleText(Strings("waiting_for_charger"));
            setDescriptionText(Strings("initialising_charge_experience"));
            setImageSource(fingerLightning);
            //reset timer
            timerStartedRef.current = new Date();
        }
        else if(newState === State.Timeout) {
            setTitleText(Strings("oh_no_something_went_wrong"));
            setDescriptionText(Strings("charger_timeout"));
            setImageSource(EV_Charger_connect);
        }
    };

    const onConnectedPress = () => {
        if (bay) {
            updateState(State.WaitingForChargeCommand);
            commandRef.current = undefined;
            dispatch(startEvSession(
                bay.cpoProvider ?? "",
                bay.ocpiLocation ?? "",
                bay.evse ?? "",
                sessionId,
                onEvStartSessionSuccess,
                onEvStartSessionError));
        }
    };

    const onEvStartSessionSuccess = (command: OcpiCommand) => {

        timerStartedRef.current = new Date();
        commandRef.current = command;
        dispatch(getCurrentParkingSession());
    };

    useEffect(() => {

        const timer = setInterval(async() => {
            switch(stateRef.current){
                case State.WaitingForChargeCommand:
                    await waitForChargeCommand();
                    break;
                case State.WaitingForChargeSession:
                    await waitForChargeSession();
                    break;
                default:
                    break;
            }
        }, 1000);

        return () => {clearInterval(timer);}

    }, []);

    useEffect(() => {
        if(!!initialConnected && !stateRef.current) {
            onConnectedPress();
        }
        else{
            // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
            setTitleText(Strings("connect_cable_heading"));
            setDescriptionText(Strings("please_ensure_cable_connected"));
            setImageSource(EV_Charger_connect);
        }
    }, [stateRef, initialConnected]);

    const waitForChargeCommand = async() => {

        console.log("waitForChargeCommand");

        if(!commandRef.current) {
            return;
        }

        let updatedCommand: OcpiCommand|null = null;
        console.log({ updatedCommand });
        try {
            //@ts-ignore
            updatedCommand = await dispatch(getOcpiCommand(commandRef.current.id));
        } catch (error) {
            console.log('Failed to checkStartCommand: ', {error});
        }
        if(!updatedCommand) {
            updateState(State.Failed);
            setDescriptionText(Strings("something_wrong_with_ev_desc"));
            return;
        }

        if(!!updatedCommand?.commandResult){
            if (updatedCommand?.commandResult.result === CommandResultType.ACCEPTED) {
                updateState(State.WaitingForChargeSession);
                // setDescriptionText(Strings("charge_request_accepted"));
                waitForChargeSession();
            }
            else {
                updateState(State.Failed);
                setDescriptionText(Strings("something_wrong_with_ev_desc_reason")(updatedCommand?.commandResult.result));
            }
        } else {
            checkTimeout(60);
        }
    };

    const waitForChargeSession = async() => {
        console.log("waitForChargeSession");
        const evSession: OcpiSession = await dispatch(getActiveEvSession(sessionId));

        if(!!evSession) {
            if (evSession.status === SessionStatus.ACTIVE) {
                navigateToActiveSession();
            }
            else if (evSession.status === SessionStatus.PENDING) {
                //90 second wait for session - there is nothing in the spec about this so I just chose
                //a time that should be way longer than required to be sure
                checkTimeout(90);
            }
            else{
                //we must be in an error state but dont currently have a way to get more info
                updateState(State.Failed);
                setDescriptionText(Strings("something_wrong_with_ev_desc_reason")(evSession.status));
            }
        }
        else{
            //90 second wait for session - there is nothing in the spec about this so I just chose
            //a time that should be way longer than required to be sure
            checkTimeout(90);
        }
    };

    const checkTimeout = (timeout: number) => {
        const now = new Date();
        const diff = now.getTime() - (!!timerStartedRef.current ? timerStartedRef.current.getTime() : now.getTime());
        console.log("diff secs", diff/1000);
        if ((diff / 1000) > timeout) {
            updateState(State.Timeout);
        }
    };

    const navigateToActiveSession = (showEVChargerWarning?: boolean) => {
        navigation.reset({
            index: 0,
            routes: [{
                name: Routes.ActiveSession,
                params: { showEVChargerWarning: showEVChargerWarning ?? false }
            }]
        });
    };

    const onEvStartSessionError = (err: any) => {
        updateState(State.Failed);
        setDescriptionText(Strings("something_wrong_with_ev_desc"));
        if (err?.status === 400 && err?.status_message.includes("EV charger is")) {
            navigateToActiveSession(true);
        }
    };

    const onProblemPress = useCallback(() =>{
        if (parkId) {
            navigation.push(Routes.Problem, { sessionId, bayId, parkId });
        }
    }, [parkSession, bay, park]);

    const onBackPress = () => {
        if(overrideBackButton) {
            navigation.reset({
                routes: [{
                    name: Routes.ActiveSession
                }]
            })
        }
    }

    return (
        <ParkableBaseView loading={bayLoading} scrollable={false} backButtonOverride={overrideBackButton ? onBackPress : undefined} removeStandardMargins toolbarStyle={{marginLeft: PADDING}}>
            <View style={{flex: 1}}>
                <View style={{paddingTop: 7, paddingHorizontal: PADDING}}>
                    <Text h1>{titleText}</Text>
                    <Text p>{descriptionText}</Text>
                </View>
                <View style={{flex: 1, flexDirection: "row"}}>
                    <Image key={imageSource} style={imageSource === fingerLightning ? styles.backgroundImage2 : styles.backgroundImage} source={imageSource!} resizeMode={"contain"} />
                </View>
                <View style={styles.bottomBtn}>
                    {(state === State.Failed || state === State.Timeout) && (
                        <Text p>{Strings("error_persists_support_below")}</Text>
                    )}
                    {(state === State.Failed || state === State.Dormant || state === State.Timeout) && (
                        <Button iconRight={"arrowboldright"} onPress={onConnectedPress} >
                            {(state === State.Failed || state === State.Timeout) ? Strings("try_again") : Strings("start_charging")}
                        </Button>
                    )}
                    {(state === State.Failed || state === State.Timeout) && (
                        <Button style={{marginTop: 9}}
                                iconLeft={"support"}
                                iconLeftProps={{color: Colours.NEUTRALS_BLACK}}
                                plain center border
                                textProps={{h5:true}}
                                onPress={onProblemPress}>
                            {Strings("i_have_a_problem")}
                        </Button>
                    )}
                </View>
            </View>
        </ParkableBaseView>
    );
};

class ConnectEVNozzleProps {
    sessionId: number;
    bayId: number;
    parkId: number;
    initialConnected?: boolean;
    overrideBackButton?: boolean;
}

type Props = NavigationProps<Routes.ConnectEVNozzle>;

export const ConnectEVNozzleRoute = createRoute({
    path: Routes.ConnectEVNozzle,
    params: { type: ConnectEVNozzleProps }
});

const styles = StyleSheet.create({
    navigation: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 72,
        backgroundColor: "#FFFFFF",
        paddingRight: 9
    },
    contentContainerStyle: {
        position: 'relative',
        flex: 1,
    },
    backgroundImage: {
        flex: 1,
        left: -120,
        marginTop: -20,
        aspectRatio: 526 / 389,
        height: 180,
        ...Platform.select ({
            web: {
                height: 400,
            }
        }),
    },
    backgroundImage2: {
        flex: 1,
        aspectRatio: 375 / 415,
        zIndex: 100,
        height: 430,
        ...Platform.select({
            web: {
                height: 600,
            }
        })
    },
    bottomBtn: {
        paddingHorizontal: PADDING,

    }
});



