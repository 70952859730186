import React from "react";
import {Platform, StyleSheet} from "react-native";
import { useNavigation } from "react/navigation/constants";
import { Routes } from "react/navigation/root/root.paths";
import Colours from "react/legacy/parkable-components/styles/Colours";
import {FAB} from "react/legacy/parkable-components";
import Icons from "react/legacy/parkable-components/icon/Icons";

const styles = StyleSheet.create({
    base: {
        backgroundColor: Colours.NEUTRALS_WHITE,
        position: "absolute",
        bottom: 45,
        left: (Platform.OS === "web" ? 65 : 18),
        borderRadius: 23,
    },
});

export default function () {

    const navigation = useNavigation()
    const onPress = () => {
        navigation.push(Routes.QrScannerView, {});
    }

    return (
        <FAB
            style={styles.base}
            icon={Icons.qrscanner}
            onPress={onPress}
            iconProps={{
                color: Colours.BLUE_300,
                iconStyle: {
                    fontSize: 30,
                }
            }}
        />
    );
}
