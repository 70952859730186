import * as Act from '../actions/vouchers'
import _ from 'lodash'
import {Voucher} from "../../model/Voucher";

type VoucherMap = {[voucherId: number]: Voucher};

const init = {
    vouchers: {} as VoucherMap
};

type IVoucherReducer = typeof init;

export default function dataReducer(state = init, action: any): IVoucherReducer {
    switch (action.type) {
        case Act.VOUCHERS_RECEIVED:

            return {
                ...state,
                vouchers: (action.vouchers ?? []).reduce((vouchers: VoucherMap, v: Voucher) => Object.assign(vouchers, {[v.id]: v}), _.clone(state.vouchers)),
            };

        default:
            return state;
    }
}