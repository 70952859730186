/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 18/10/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/
import {
    DayAvailability,
    fallbackHourlyAvailability,
    HourlyAvailability
} from "../../model/DayAvailability";


export default function getHourlyAvail(dayAvailability?: DayAvailability): HourlyAvailability {

    if (!dayAvailability) {
        return fallbackHourlyAvailability;
    }

    const {
        hourlyAvailability,
        available
    } = dayAvailability;

    if(!available){
        return fallbackHourlyAvailability;
    }

    if (typeof hourlyAvailability !== "string") {
        if (hourlyAvailability instanceof Array) {
            return hourlyAvailability;
        } else {
            return fallbackHourlyAvailability;
        }
    }

    let availArray = hourlyAvailability.split('');

    if (hourlyAvailability.indexOf(",") > -1) {
        availArray = hourlyAvailability.split(',');
    }

    if (availArray.length !== 48) {
        return fallbackHourlyAvailability;
    }

    return availArray.map(v => v.toLowerCase() === 't') as HourlyAvailability;
}