import React from 'react';
import Icons from "react/legacy/parkable-components/icon/Icons";
import Colours from "react/legacy/parkable-components/styles/Colours";
import FAB from "react/legacy/parkable-components/fab/FAB";
import {StyleSheet} from "react-native";

const styles = StyleSheet.create({
    base: {
        backgroundColor: Colours.NEUTRALS_WHITE,
        position: 'absolute',
        bottom: 45,
        right: 18,
        borderRadius: 23,
    },
});

export interface CenterMapViewProps {
    onPress: () => void
}

export default function({onPress}: CenterMapViewProps) {
    return (
        <FAB
            style={styles.base}
            icon={Icons.mapcenterlocation}
            onPress={onPress}
            iconProps={{
                color: Colours.BLUE_300,
                iconStyle: {
                    fontSize: 30,
                }
            }}
        />
    );
}
