import Strings from "../../../util/localization/localization";
import localizeCurrency from "../../../util/localization/localizeCurrency";
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import Spinner from "react/legacy/parkable-components/spinner/Spinner";
import React from "react";
import { ParkDTOWithTerritory } from "../../../redux/actions/parks";

type AllProps = {
    isWeeklyTerm?: boolean,
    price?: number,
    currencyCode?: string,
    remarks?: string,
    park?: ParkDTOWithTerritory,
    processingFee?: number
}

export default function subscriptionPriceTableRow(props: AllProps){
    const { price, processingFee, currencyCode, isWeeklyTerm, remarks } = props;
    const priceFormatted =
        (price === undefined || !currencyCode) ? Strings("loading") :
            price === 0 ? Strings("this_sub_is_free") :
                `${localizeCurrency(price, currencyCode, false)}${isWeeklyTerm ? Strings("per_week") : Strings("per_month")}`;

    return (<TableRow contentLeft={(price === undefined) && <Spinner/>}
                      iconLeft={"key"}
                      label={isWeeklyTerm ? Strings("weekly_subscription") : Strings("monthly_subscription")}
                      labelBottom={processingFee && currencyCode ?
                          Strings("plus_processing_fee")(localizeCurrency(processingFee, currencyCode, false))
                          : ""}
    >
        {`${priceFormatted} ${remarks??''}`}
    </TableRow>)
}
