import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {IRootReducer} from '../../redux/reducers/main';
import {DispatchFunc, ParkingType} from '../../model/Types';
import {getUserCards, getUserVehicles} from "../../redux/actions/user";
import {getEVUserBays, getPark, ParkDTOWithTerritory} from '../../redux/actions/parks';
import {Routes} from "react/navigation/root/root.paths";
import {Button, Colours, DialogRef, TableRow, Text} from 'react/legacy/parkable-components';
import Dialog from "react/components/dialog/Dialog";
import Strings from '../../util/localization/localization';
import {Bay} from '../../model/Bay';
import {startParking} from '../../redux/actions/parking';
import {getCurrentParkingSession} from '../../redux/actions/parking';
import {Image, Platform, StyleSheet, View} from 'react-native';
import {Vehicle} from '../../model/Vehicle';
import {Card} from '../../model/Card';
import {ActivityType, Voucher} from '../../model/Voucher';
import {retrieveLargestDiscountVoucher} from '../../redux/actions/vouchers';
import {ActivityOrigin} from "../../model/ActivityOrigin";
import {ParkSessionDTO} from "../../model/ParkSessionDTO";
import CasualCharge from "../../components/parkDetails/CasualCharge";
import localizeCurrency from "../../util/localization/localizeCurrency";
import {createRoute, NavigationProps, useNavigation} from "../../navigation/constants";
import ParkableBaseView from "../../components/common/ParkableBaseView";
import {PADDING} from "../../root/root.constants";
import {useBayGroup} from "react/api/bayGroup/bayGroup.api";
import {useParkingPrice} from "react/api/parkingPrice/parkingPrice.api";
import {isCreditCardRequired} from "react/util/services/parkingPrice.service";
import {handleFailedTransaction} from "react/util/ExceptionHandler";

const EV_Charger_connect = require ('../../resources/EV_Charger_connect.png');

type Props = {
    dispatch: DispatchFunc,
} & ReturnType<typeof reduxProps> & NavigationProps<Routes.StartChargingByLinkView>

/**
 * After user scanned the QR code and get the dynamic link and then get the parkId and bayId,
 * if user is eligible to park in the bay(ie bay vacant and bay available to user),
 * a session will be started for the user and nav user to start charging screen with the button 'start charging' being pressed.
 * Otherwise will popup an error dialog for user.
 * @param props
 */
function StartChargingByLinkView(props: Props) {

    const {parkId, bayId} = props.route.params;
    const {dispatch, currentSession} = props;

    const navigation = useNavigation();
    if (!parkId || !bayId) {
        navigation.reset({
            routes: [{
                name: Routes.ParkableMapView
            }],
        });
    }

    const errorDialogRef = useRef<DialogRef | null>(null);
    const [error, setError] = useState<{ title: string, desc: string } | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [onConfirm, setOnConfirm] = useState<undefined | (() => void)>(undefined);
    const [positiveText, setPositiveText] = useState<undefined | string>(undefined);
    const [negativeText, setNegativeText] = useState<undefined | string>(undefined);
    const [requiredData, setRequiredData] = useState<{
        park: ParkDTOWithTerritory,
        bay: Bay,
        hasCard: boolean
    } | undefined>(undefined);
    const [showButtonSpinner, setShowButtonSpinner] = useState(false);
    const [bayError, setBayError] = useState(false);
    const { bayGroup } = useBayGroup(requiredData?.park?.organisation, requiredData?.bay?.group);
    const { pricePeriods } = useParkingPrice(bayGroup?.parkingPrice??requiredData?.park?.parkingPrice);

    const resetToMap = useCallback(() => {
        navigation.reset({
            routes: [{
                name: Routes.ParkableMapView
            }]
        });
    }, [navigation]);

    const showError = useCallback((error: {
        title: string,
        desc: string,
        positiveText?: string,
        negativeText?: string,
        onConfirm?: () => void
    }) => {
        setLoading(false);
        setError(error);

        setPositiveText(error.positiveText);
        setNegativeText(error.negativeText);
        if (error.onConfirm) {
            setOnConfirm(() => error.onConfirm);
        } else {
            setOnConfirm(undefined);
        }

        errorDialogRef.current?.show();
    }, [errorDialogRef]);

    const checkCurrentSessionBay = useCallback((session: ParkSessionDTO | null) => {
        if (!session) return;
        if (session.bay === parseInt(`${bayId}`)) {
            resetToSession();
        } else {
            showError({title: Strings("you_are_currently_parking"), desc: Strings("you_are_currently_parking")});
        }
    }, [bayId]);

    const resetToSession = () => navigation.reset({
        routes: [{
            name: Routes.ActiveSession,
            params: {}
        }]
    });

    useEffect(() => {
        (async () => {
            if (!!parkId) {
                try {
                    if (!!currentSession) {
                        checkCurrentSessionBay(currentSession);
                    } else {
                        //@ts-ignore
                        const session: ParkSessionDTO = await dispatch(getCurrentParkingSession(checkCurrentSessionBay));
                        if (!!session) {
                            checkCurrentSessionBay(session);
                        } else {

                            const parkPromise = dispatch(getPark(parkId));

                            const vehiclePromise = dispatch(getUserVehicles());

                            const cardsPromise = dispatch(getUserCards());

                            const baysPromise = dispatch(getEVUserBays(parkId, ActivityOrigin.QrCode));

                            const voucherPromise = dispatch(retrieveLargestDiscountVoucher(parkId, ActivityType.Casual));

                            Promise.all([parkPromise, vehiclePromise, cardsPromise, baysPromise, voucherPromise]).then((results) => {
                                //@ts-ignore
                                const park: ParkDTOWithTerritory = results[0];
                                //@ts-ignore
                                const vehicles: Vehicle[] = results[1];
                                //@ts-ignore
                                const cards: Card[] = results[2];
                                //@ts-ignore
                                const bays: Bay[] = results[3];
                                //@ts-ignore
                                const voucher: Voucher = results[4];

                                if (!park) {
                                    resetToMap();
                                    return;
                                }
                                rocknrolla(park, bays, vehicles, cards, voucher);
                            })
                                .catch(() => {
                                    showError({title: Strings("retry"), desc: Strings("unknown_error_occurred")});
                                });
                        }
                    }
                } catch (error) {
                    showError({title: Strings("retry"), desc: Strings("unknown_error_occurred")});
                }
            }
        })();
    }, []);

    const rocknrolla = useCallback(async (park: ParkDTOWithTerritory, userBays: Bay[], vehicles: Vehicle[], cards: Card[], voucher: Voucher) => {
        console.log("WE HAVE RETURNED THIS MANY BAYS: ", userBays?.length);
        const bay = userBays?.find(b => b.id === parseInt(`${bayId}`));
        if (!bay) {
            setBayError(true);
            showError({title: Strings("bay_not_available"), desc: Strings("bay_not_available_desc")});
            return;
        }
        const hasCard = (cards?.length ?? 0) > 0;
        const hasVehicle = (vehicles?.length ?? 0) > 0;
        const cardRequired = park && pricePeriods && isCreditCardRequired(park, pricePeriods, hasCard, voucher, true);
        if (!cardRequired && hasVehicle) {
            setRequiredData({park, bay, hasCard});
            setLoading(false);
        } else {
            const destination = {
                route: Routes.StartChargingByLinkView,
                params: {
                    bayId,
                    parkId
                }
            };
            if (cardRequired) {
                navigation.push(Routes.AddNewCard, { destination });
            } else {
                navigation.push(Routes.AddNewVehicleView, { destination });
            }
        }
    }, [parkId, bayId, navigation, dispatch]);

    const onStartPress = useCallback(() => {
        if (!requiredData) {
            return;
        }
        setShowButtonSpinner(true);
        dispatch(startParking(
            requiredData.park.id,
            requiredData.bay.id,
            false,
            undefined,
            requiredData.park.territory,
            requiredData.hasCard,
            null,
            (parkSession) => {
                navigation.replace(Routes.ConnectEVNozzle, {
                    bayId: requiredData.bay.id,
                    parkId: requiredData.park.id,
                    sessionId: parkSession.id,
                    initialConnected: true,
                    overrideBackButton: true
                });
            }, (err) => {
                setShowButtonSpinner(false);
                if(err?.code == 321) {
                    showError({
                        title: Strings("missing_payment_method"),
                        desc: Strings("to_start_charging_you_must_add_a_payment_method"),
                        positiveText: Strings("add_payment"),
                        negativeText: Strings("cancel"),
                        onConfirm: () => showAddPaymentView(),
                    });
                } else if(!err || !handleFailedTransaction(navigation, err)){
                    showError({title: Strings("error"), desc: Strings("unknown_error_occurred")});
                }
            }));
    }, [requiredData]);

    const showRetryPaymentView = (sessionId: number) => {
        navigation.push(Routes.RetryPaymentRequest, {sessionId});
    };

    const showAddPaymentView = () => {
        navigation.push(Routes.AddNewCard, {});
    };

    return (
        <ParkableBaseView scrollable={false}
                          backButtonOverride={resetToMap}
                          loading={loading}>
            <View style={styles.base}>
                <View style={{paddingBottom: 4}}>
                    <Text h1>{Strings("connect_cable_heading")}</Text>
                    <Text p>{Strings("please_ensure_cable_connected")}</Text>
                </View>
                <View style={{flex: 1}}>
                    <Image style={styles.backgroundImage} source={EV_Charger_connect} resizeMode={"contain"}/>
                </View>
                <View style={styles.footer}>
                    <View style={{flex: 1}}>
                        {!!requiredData && <CasualCharge parkingType={ParkingType.ELECTRIC}
                                                         park={requiredData.park}
                                                         bay={requiredData.bay}
                                                         territory={requiredData.park?.territory}/>}

                        {!!requiredData &&
                            <TableRow label={Strings("electricity_price")} textProps={{small: true}} iconLeft={"electricvehicleplug"}>
                                {localizeCurrency(bayGroup?.pricePerKwh ?? requiredData.park?.pricePerKwh ?? 0, requiredData.park?.territory.currencyCode, false) + " " + Strings("perKwh")}
                            </TableRow>}
                    </View>
                    <View>
                    <Button
                        disabled={loading || bayError} loading={showButtonSpinner} center={showButtonSpinner}
                        iconRight={showButtonSpinner ? undefined : "arrowboldright"} onPress={showButtonSpinner ? () => {
                    } : onStartPress}>{showButtonSpinner ? null : Strings("start_charging")}</Button>
                    </View>
                </View>
            </View>
            <Dialog ref={errorDialogRef}
                    label={error?.title}
                    labelProps={{
                        style: {
                            color: Colours.NEUTRALS_BLACK,
                            textAlign: 'left',
                            borderBottomWidth: 1,
                            borderBottomColor: Colours.GREY_10
                        }
                    }}
                    title={error?.desc}
                    titleProps={{h2: undefined, small: true, style: {textAlign: 'left', lineHeight: 24}}}
                    positiveProps={{
                        textProps: {style: {color: Colours.NEUTRALS_BLACK}, h5: true},
                        style: {backgroundColor: Colours.ORANGE}
                    }}
                    positiveText={positiveText ?? Strings("back_to_map")}
                    negativeText={negativeText ?? Strings("close")}
                    onPositivePress={onConfirm ?? resetToMap}/>
        </ParkableBaseView>
    )
}

const reduxProps = (state: IRootReducer) => {
    const tokenObject = {
        firebaseToken: state.auth.fireBaseToken,
        parkableToken: undefined
    };

    return {
        currentSession: state.parking.currentSession,
        token: tokenObject,
        api: state.data.api
    }
};

class StartChargingByLinkViewParams {
    parkId?: number;
    bayId?: number;
}

const styles = StyleSheet.create({
    base: {
        flex: 1,
    },
    backgroundImage: {
        zIndex: -1,
        aspectRatio: 526/389,
        marginLeft: -PADDING,
        left: -90,
        height: 270,
        ...Platform.select({
            web: {
                left: -170,
                height: 340,
            },
        })
    },
    footer: {
        flex: 1,
    }
});

export default connect(reduxProps)(StartChargingByLinkView) as FunctionComponent<Props>

export const StartChargingByLinkRoute = createRoute({
    path: Routes.StartChargingByLinkView,
    params: {type: StartChargingByLinkViewParams},
});
