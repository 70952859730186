import React from "react";
import { Routes } from "../../../navigation/root/root.paths";
import { createRoute, NavigationProps } from "../../../navigation/constants";
import { ParkSession } from "react/model/ParkSession";
import { ParkSessionDTO } from "react/model/ParkSessionDTO";
import { Platform } from "react-native";
import RetryPaymentView from "react/views/account/payment/RetryPaymentView";
import RetryPaymentWebView from "./RetryPaymentWebView";

type Props = NavigationProps<Routes.RetryPaymentRequest>

function RetryPaymentRootView(props: Props) {
    return Platform.OS === "web"
        ? <RetryPaymentWebView {...props}/>
        : <RetryPaymentView {...props}/>;
}

class RetryPaymentParams {
    sessionId?: number;
    callback?: (session: ParkSession | ParkSessionDTO | null) => void
}

export default RetryPaymentRootView

export const RetryPaymentRoute = createRoute({
    options: {
        title: "Retry Payment"
    },
    path: Routes.RetryPaymentRequest,
    params: {
        type: RetryPaymentParams
    }
});
