import React from "react";
import { StyleSheet, View } from "react-native";
// @ts-ignore
import { CircleSnail } from "react-native-progress";
import Colours from "../styles/Colours";
import classnames from "../util/classnames";

import { ActivityIndicator } from "react-native-paper";

const styles = StyleSheet.create({
    base: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "baseline",
        height: 45,
        width: 45,
        overflow: "hidden",
        backgroundColor: "transparent",
    },
    large: {
        height: 54,
        width: 54,
    },
    small: {
        height: 36,
        width: 36,
    },

    spinnerBase: {
        height: 27,
        width: 27,
    },
    spinnerLarge: {
        height: 36,
        width: 36,
    },
    spinnerSmall: {
        height: 18,
        width: 18,
    },
});

const cn = classnames(styles);

export type SpinnerProps = {
    black?: boolean;
    blue?: boolean;
    white?: boolean;
    large?: boolean;
    small?: boolean;

    color?: string;
    size?: number;
    thickness?: number;
};

export default function Spinner(props: SpinnerProps) {
    const { black, blue, white, small, large } = props;
    let color = props.color || Colours.GREY_50;

    if (black) {
        color = Colours.NEUTRALS_BLACK;
    } else if (blue) {
        color = Colours.BLUE_300;
    } else if (white) {
        color = Colours.NEUTRALS_WHITE;
    }

    let size = props.size || 27;
    let thickness = props.thickness || 3;

    if (small) {
        size = 18;
        thickness = 2;
    } else if (large) {
        size = 36;
    }

    const style = cn.styles("base", {
        large: large,
        small: small,
    });

    return (
        <View style={style}>
            <ActivityIndicator color={color} />
        </View>
    );
}
