import React, { useContext, useEffect, useState } from "react";
import { ChatContext, ProblemActionContext, QuestionContext, SendPressCallback, SupportContext } from "../Context";
import { Bay } from "../../../model/Bay";
import { reportCarInBay } from "../../../redux/actions/parking";
import Strings from "../../../util/localization/localization";
import { Routes } from "react/navigation/root/root.paths";
import _ from "lodash";
import { AllProblemViewProps } from "../../../views/support/ProblemView";
import { QuestionOption, QuestionOptionKey, QuestionType, QuestionTypes } from "./ProblemFlow.types";
import { BayProblemResponseFullStatus } from "../../../model/Types";
import { useBayGroupsMemberInPark } from "../../../api/bayGroup/bayGroup.api";
import { useNavigation } from "../../../navigation/constants";
import { BayProblemResponse } from "../../../api/parking";
import { usePark } from "../../../api/park";
import { useBay } from "../../../api/bay/bay.api";
import { useParkSessionV3 } from "../../../api/parkSession/parkSession.api";

const flow: QuestionTypes = {
    default: {
        type: "content",
        title: Strings("vehicle_in_my_bay"),
        action: "onVehicleInMyBay",
        isItSupport: false
    },
    isVehicleInMyBay: {
        type: "options",
        title: Strings("please_check_parking_bay"),
        options: [
            {
                key: "vehicleInMyBayNo",
                title: Strings("no_its_wrong_one"),
                action: "updateVehicleInMyBayStateWithKey"
            },
            {
                key: "vehicleInMyBayYes",
                title: Strings("yes_its_correct"),
                action: "updateVehicleInMyBayStateWithKey"
            }
        ],
        isItSupport: true
    },
    vehicleInMyBayNo: {
        type: "content",
        title: Strings("no_worries_thanks"),
        isItSupport: true
    },
    vehicleInMyBayYes: {
        type: "content",
        title: Strings("enter_their_plate_number"),
        action: "onVehicleNumberPlate",
        showKeyboard: true,
        isItSupport: true
    },
    helpMessage: {
        type: "options",
        title: Strings("is_there_anything"),
        isItSupport: true,
        options: [
            {
                key: "helpMessageNo",
                title: Strings("take_me_to_my_active_session"),
                action: "redirectAction"
            },
            {
                key: "helpMessageYes",
                title: Strings("yes_message_parkable"),
                action: "onMessageParkablePress"
            }
        ]
    },
    helpMessageYes : {
        type: "content",
        title: Strings("message_parkable_support_message"),
        isItSupport: true
    }
}

export const VehicleInMyBay = (props: AllProblemViewProps) =>
{
    const {parkId, sessionId, subscription, bayId} = props.route.params;

    const { park } = usePark(parkId);
    const { bay } = useBay(parkId, bayId);
    const { parkSession: session } = useParkSessionV3(sessionId);

    const {dispatch} = props;
    const navigation = useNavigation();
    const { messages, updateMessages, updateShowKeyboard, updateSendPress, errorHandling, processOptionQuestion } =
        useContext(ChatContext);
    const {setCurrentQuestion, getNextQuestion, updateNextQuestion} = useContext(QuestionContext);
    const {problemActions, updateProblemActions } = useContext(ProblemActionContext)
    const {updateSupportFlow} = useContext(SupportContext);
    const { bayGroups } = useBayGroupsMemberInPark(park?.organisation, parkId);

    const [reportCarInBayResponseStatus, setReportCarInBayResponseStatus] = useState("");

    useEffect(()=>{
        problemActions["onVehicleInBayPress"] = onVehicleInBayPress;
        problemActions["onVehicleInMyBay"] = onVehicleInMyBay;
        problemActions["updateVehicleInMyBayStateWithKey"] = updateVehicleInMyBayStateWithKey;
        problemActions["onVehicleNumberPlate"] = onVehicleNumberPlate;
        problemActions["redirectAction"] = redirectAction;
        problemActions["showMessageParkableSupportMessage"] = showMessageParkableSupportMessage;

        updateProblemActions(problemActions);
    }, [messages])

    const updateFlow = (preExpectedAns: QuestionOptionKey) => {
        const _flow = _.cloneDeep(flow);
        if (_flow.isVehicleInMyBay) {
            _flow.isVehicleInMyBay.title = `${_flow.isVehicleInMyBay.title} ${bay?.signage ?? ''}?`;
        }

        updateSupportFlow('vehicleInMyBay', _flow, preExpectedAns);
    }

    const onVehicleInBayPress = () => {
        processOptionQuestion("vehicleInMyBay");
        updateFlow("isVehicleInMyBay");
    }

    const onVehicleInMyBay = () => {
        updateNextQuestion("vehicleInMyBay", "isVehicleInMyBay");
    }

    const updateVehicleInMyBayStateWithKey = (question: any, option?: QuestionOption) => {
        processOptionQuestion(option!!.key);
        updateNextQuestion("vehicleInMyBay", option!!.key);
    }

    const onVehicleNumberPlate = () => {
        updateSendPress(sendPress);
        updateShowKeyboard(true);
    }

    const sendPress: SendPressCallback = (text: string | undefined) => {
        if (!text || !park) {
            return;
        }

        updateShowKeyboard(false);

        setCurrentQuestion({
            type: "content",
            title: text,
            isItSupport: false
        });

        try {
            dispatch(reportCarInBay(
                park.id,
                bay?.id,
                text,
                session?.id,
                subscription?.id,
                completionCallback,
                failCallback
            ))
        } catch(error) {
            errorHandling(Strings("error_message_in_support"))
        }
    }

    const completionCallback = (reportResult: BayProblemResponse) => {
        supportMessage(reportResult.status, reportResult.bay);
    };

    const failCallback = (err?: any) => {
        if (err?.errorCode === "PARK_CLOSED" || err?.code === "PARK_CLOSED") {
            errorHandling(Strings("park_closed_error_message"))
        } else if (err?.errorCode === "LICENSE_TOO_LONG" || err?.code === "LICENSE_TOO_LONG") {
            errorHandling(Strings("error_message_for_vehicle_license"))
            onVehicleNumberPlate();
        } else {
            errorHandling(Strings("error_message_in_support"))
        }
    }

    const supportMessage = (status: BayProblemResponseFullStatus, alternate_bay: null | Bay) => {
        let title = '';
        let optionTitle = null;

        if(status === "AdminContacted") {
            title = Strings("colleague_let_down") + ' ' + Strings("find_alternative_parking_and_talk_to_team_lead");
            optionTitle = Strings("no_thats_all");
        }

        if( status === "SessionEnded" ) {
            title = Strings("sorry_no_available_bays") + ' ' + Strings("open_session_was_ended");
            optionTitle = Strings("take_me_to_my_session_summary");
        }

        if( status === "BayReturnedToGroup" ) {
            title = Strings("you_made_your_bay_available_to_others");
            optionTitle = Strings("no_thats_all");
        }

        if( status === "UpdatedBay" || status === "SessionCreated") {
            const alternativeBaySignage = !!alternate_bay?.signage
                ? Strings("new_bay_found") + alternate_bay.signage
                : Strings("please_park_anywhere_in")(bayGroups?.find(bg => bg.id === alternate_bay?.group)?.name ?? '');
            const alternateBayText = alternate_bay
                ? alternativeBaySignage
                : Strings("unfortunately_no_alternative_bays");
            title = Strings("thanks_for_reporting")(alternateBayText);
        }

        const currentQuestion: QuestionType = {
            type: "content",
            title,
            isItSupport: true
        };
        const newMessages = _.cloneDeep(messages);
        newMessages.push(currentQuestion);

        if (optionTitle) {
            const _flow = _.cloneDeep(flow);
            if (_flow.isVehicleInMyBay) {
                _flow.isVehicleInMyBay.title = `${_flow.isVehicleInMyBay.title} ${bay?.signage ?? ""}?`;
            }
            const option = _flow.helpMessage?.options?.find((option) => option.key === "helpMessageNo");
            if (!!option) {
                option.title = optionTitle;
            }
            updateSupportFlow("vehicleInMyBay", _flow);
        }

        const nextQuestion = getNextQuestion("vehicleInMyBay", "helpMessage");
        if (nextQuestion) {
            newMessages.push(nextQuestion);
        }

        updateMessages(newMessages);

        setReportCarInBayResponseStatus(status);
    }

    const redirectAction = () => {
        if( reportCarInBayResponseStatus === "SessionEnded" ) {
            navigation.push(Routes.SessionSummary);
        } else if(reportCarInBayResponseStatus === "UpdatedBay" || reportCarInBayResponseStatus === "SessionCreated") {
            navigation.push(Routes.ActiveSession, {});
        } else {
            navigation.pop();
        }
    }

    const showMessageParkableSupportMessage = (question: any, option?: QuestionOption) => {
        processOptionQuestion(option!!.key);
        updateNextQuestion("vehicleInMyBay", "helpMessageYes");
    }

    return <></>
}
