import React from "react";
import Text from "react/legacy/parkable-components/text/Text";
import { Image, View } from "react-native";
import { contentStyles } from "../../../components/future-booking/how-it-works/styles";
import Strings from "../../../util/localization/localization";
import { createRoute } from "../../../navigation/constants";
import { Routes } from "../../../navigation/root/root.paths";
import ParkableBaseView from "../../../components/common/ParkableBaseView";
import { PADDING } from "../../../root/root.constants";

const HandImage = require("../../../resources/hand.png");

const HowItWorksThisWeekView = () => {
    return (
        <ParkableBaseView scrollable={false} removeStandardMargins toolbarStyle={{marginLeft: PADDING}}>
            <View style={{flex: 1}}>
                <View style={{paddingHorizontal: PADDING}}>
                    <View style={contentStyles.heading}>
                        <Text h1>{Strings("booking_for_this_week")}</Text>
                    </View>

                    <Text style={contentStyles.mainText}>
                        {Strings("this_week_p1")[0]}
                    </Text>
                    <Text style={contentStyles.mainText}>
                        {Strings("this_week_p1")[1]}
                        <Text bold green>
                            {Strings("this_week_p1")[2]}
                        </Text>
                        {Strings("this_week_p1")[3]}
                    </Text>
                    <Text style={contentStyles.mainText}>
                        {Strings("this_week_p1")[4]}
                        <Text bold style={contentStyles.textOrange}>
                            {Strings("this_week_p1")[5]}
                        </Text>
                        {Strings("this_week_p1")[6]}
                    </Text>
                </View>
                <View style={contentStyles.graphicContainer}>
                    <Image source={HandImage} style={contentStyles.image}/>
                </View>
            </View>
        </ParkableBaseView>
    );
};

export default HowItWorksThisWeekView;

export const HowItWorksThisWeekRoute = createRoute({
    path: Routes.HowItWorksThisWeekView
})
