import React from "react";
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import Strings from "../../../util/localization/localization";
import { DayAvailabilityDTO } from "../../../model/DayAvailability";
import { getOpeningHoursFromAvailString } from "../../../util/availability/convert";

export type OpeningHoursRowProps = {
    dayAvailability?: DayAvailabilityDTO;
    isLoading?: boolean;
};

export const OpeningHoursRow = (props: OpeningHoursRowProps) => {
    const { dayAvailability, isLoading } = props;
    const [opening, closing] = dayAvailability?.hourlyAvailability
        ? getOpeningHoursFromAvailString(dayAvailability?.hourlyAvailability)
        : [undefined, undefined];
    const isClosed = dayAvailability?.available === false || opening === closing;

    return (
        <TableRow label={Strings("opening_hours")} iconLeft="clock" condensed>
            {isClosed && Strings("closed")}
            {!isClosed && <>{isLoading ? Strings("loading") : `${opening} - ${closing}`}</>}
        </TableRow>
    );
};
