import moment from "moment";
import { useEffect, useState } from "react";
import { useBay } from "../api/bay/bay.api";
import { ParkSession } from "../model/ParkSession";
import { ParkSessionDTOLocal } from "../model/ParkSessionDTO";

const BANNER_EXPIRY_MINUTES = 30;
export const TANDEM_CHAT_BANNER_EXPIRY_MS = BANNER_EXPIRY_MINUTES * 60 * 1000;

type ReturnProps = {
    showBanner: boolean;
    sessionId: number | null | undefined;
    tandemPodId: number | null | undefined;
};

export const useShowTandemChatBanner = (
    currentSession: ParkSessionDTOLocal | null,
    previousSession: ParkSession | undefined
): ReturnProps => {
    let parkId: number | undefined;
    if (typeof previousSession?.park === "number") {
        parkId = previousSession.park;
    } else {
        parkId = previousSession?.park.id;
    }

    const [showBanner, setShowBanner] = useState(false);
    const { bay } = useBay(parkId, previousSession?.bay);

    useEffect(() => {
        if (currentSession && currentSession?.id !== previousSession?.id) {
            setShowBanner(false);
            return;
        }
        if (previousSession?.endedAt && bay?.tandemPod) {
            if (isTandemSessionActive(previousSession.endedAt)) {
                setShowBanner(true);

                const showBannerInterval = () => {
                    if (!isTandemSessionActive(previousSession.endedAt)) {
                        setShowBanner(false);
                        clearInterval(interval);
                    }
                };
                // Check every ten seconds.
                const interval = setInterval(showBannerInterval, 10000);
            }
        }
    }, [bay, currentSession, previousSession]);

    return {
        showBanner: showBanner,
        sessionId: previousSession?.id,
        tandemPodId: bay?.tandemPod,
    };
};

export const isTandemSessionActive = (sessionEndedAt?: string | null) => {
    if (sessionEndedAt) {
        const endedAt = moment(sessionEndedAt);
        const now = moment();
        const millisecDiff = now.diff(endedAt);
        return millisecDiff < TANDEM_CHAT_BANNER_EXPIRY_MS;
    }
    return true;
};
