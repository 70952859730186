import React, { useEffect, useState, createContext, PropsWithChildren } from "react";
import _ from "lodash";
import Strings from "../../util/localization/localization";
import {
    ProblemActions,
    QuestionOptionKey,
    QuestionType, QuestionTypes
} from "./problem-type/ProblemFlow.types";

interface ProblemActionContext {
    problemActions: ProblemActions,
    updateProblemActions: (actions: React.SetStateAction<ProblemActions>) => void
}

interface QuestionContext {
    currentQuestion: QuestionType | null;
    setCurrentQuestion: (question: QuestionType) => void;
    updateNextQuestion: (flow_type: SupportFlowKey | null, exp_ans: QuestionOptionKey | null) => void;
    getNextQuestion: (flow_type: SupportFlowKey | null, exp_ans: QuestionOptionKey | null) => QuestionType | undefined;
}

interface ChatContext {
    messages: QuestionType[],
    updateMessages: (msgs: QuestionType[]) => void,
    updateChat: () => void,
    showKeyboard: boolean,
    updateShowKeyboard: (show: boolean) => void,
    onSendPress: SendPressCallback,
    updateSendPress: (callback: SendPressCallback) => void,
    errorHandling: (error_text: string | null) => QuestionType[],
    processOptionQuestion: (chosen_option_key: QuestionOptionKey) => QuestionType[]
}

type SupportFlowKey = 'main' | QuestionOptionKey;
interface SupportContext {
    updateSupportFlow: (type: SupportFlowKey, flow: QuestionTypes, expected_ans?: QuestionOptionKey | null) => void
}

export const ChatContext = createContext({} as ChatContext);
export const QuestionContext = React.createContext({} as QuestionContext);
export const ProblemActionContext = React.createContext({} as ProblemActionContext);
export const SupportContext = React.createContext({} as SupportContext);

type SupportFlow = Partial<{
    [type in SupportFlowKey]: QuestionTypes
}>

export type SendPressCallback = (text:string) => void;

export const ChatProvider = ({children} : PropsWithChildren<{}>) => {

    const [currentQuestion, setCurrentQuestion] = useState<null | QuestionType>(null);
    const [messages, setMessages] = useState<QuestionType[]>([]);
    const [callAction, setCallAction] = useState(false);
    const [problemActions, setProblemActions] = useState<ProblemActions>({});
    const [supportFlow, setSupportFlow] = useState<SupportFlow>({});
    const [showKeyboard, setShowKeyboard] = useState(false);
    const [onSendPress, setOnSendPress] = useState<SendPressCallback>(() => {} );

    const updateShowKeyboard = (show: boolean) => {
        setShowKeyboard(show)
    };

    const updateSendPress = (callback: SendPressCallback) => {
        setOnSendPress(() => callback)
    };

    const updateChat = ()=> {
        if(!currentQuestion) {
            return;
        }

        const msgs = _.cloneDeep(messages);
        msgs.push(currentQuestion);

        setCallAction(true);
        updateMessages(msgs);
    };

    const updateMessages = (msgs: QuestionType[]) => {
        setMessages(msgs);
    };

    const updateNextQuestion = (flow_type: SupportFlowKey | null, exp_ans: QuestionOptionKey | null) => {
        const nextQuestion = getNextQuestion(flow_type, exp_ans);
        if (nextQuestion) {
            setCurrentQuestion(nextQuestion);
        }
    };

    const updateProblemActions = (actions: React.SetStateAction<{}>) => {
        setProblemActions(actions)
    };

    const processOptionQuestion = (chosen_option_key: string): QuestionType[] => {
        if(messages.length == 0) {
            return [];
        }

        const updatedMsgs = _.cloneDeep(messages);
        const lastQuestion = updatedMsgs.pop();

        if( lastQuestion ) {
            const option = lastQuestion.options?.find((opt) => opt.key === chosen_option_key);

            //convert option to simple text
            updatedMsgs.push({
                isItSupport: lastQuestion.isItSupport,
                title: lastQuestion.title,
                type: "content"
            });

            updatedMsgs.push({
                isItSupport: false,
                title: option?.title??'',
                type: "content"
            });

            setCallAction(false);
            updateMessages(updatedMsgs);
        }

        return updatedMsgs;
    };

    useEffect(() => {
        updateChat();
    },[currentQuestion]);

    useEffect(() => {
        if (callAction) {
            setCallAction(false);
            if(!!currentQuestion && currentQuestion.action && problemActions) {
                problemActions[currentQuestion.action]?.(currentQuestion);
            }
        }
    }, [messages, callAction]);

    const getNextQuestion = (type: SupportFlowKey | null, expected_ans: QuestionOptionKey | null): QuestionType | undefined => {
        if(type == null) {
            type = "main";
        }

        if(expected_ans == null) {
            expected_ans = "default";
        }

        const flow = supportFlow[type]!!;
        return flow && flow[expected_ans] && flow[expected_ans]!!
    };

    const updateSupportFlow = (type: SupportFlowKey, flow: QuestionTypes, expected_ans: QuestionOptionKey | null = null): void => {
        setSupportFlow(prev => ({...prev, [type]: flow,}));

        if(expected_ans) {
            const quest = flow[expected_ans]!;
            setCurrentQuestion(quest);
        }
    };

    const errorHandling = (error_text:string|null = null) => {
        let updatedMsgs = _.cloneDeep(messages);

        if(updatedMsgs.length > 0) {
            const lastQuestion = updatedMsgs.pop();

            //convert option to simple text
            lastQuestion && updatedMsgs.push({
                isItSupport: lastQuestion.isItSupport,
                title: lastQuestion.title,
                type: "content",
            })
        }

        updatedMsgs.push({
            isItSupport: true,
            title: error_text? error_text: Strings("error_message_in_support"),
            type: "content",
        });

        setCallAction(false);
        setMessages(updatedMsgs);
        return updatedMsgs;
    };

    return (
        <ChatContext.Provider
            value={{
                messages,
                updateMessages,
                updateChat,
                showKeyboard,
                updateShowKeyboard,
                onSendPress,
                updateSendPress,
                errorHandling,
                processOptionQuestion,
            }}
        >
            <SupportContext.Provider value={{ updateSupportFlow }}>
                <QuestionContext.Provider
                    value={{ currentQuestion, setCurrentQuestion, updateNextQuestion, getNextQuestion }}
                >
                    <ProblemActionContext.Provider value={{ problemActions, updateProblemActions }}>
                        {children}
                    </ProblemActionContext.Provider>
                </QuestionContext.Provider>
            </SupportContext.Provider>
        </ChatContext.Provider>
    );
};
