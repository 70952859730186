import React from "react";
import Text from "react/legacy/parkable-components/text/Text";
import {Image, View} from "react-native";
import {contentStyles} from "../../../components/rewards/how-it-works.styles";
import Strings from "../../../util/localization/localization";
import {useTerritories} from "../../../api/territory/territory.api";
import {useOrganisation} from "../../../api/organisation/organisation.api";
import Constants from "../../../util/constants";
import { Routes } from "react/navigation/root/root.paths";
import ParkableBaseView from "../../../components/common/ParkableBaseView";
import {createRoute, NavigationProps} from "../../../navigation/constants";
import {PADDING} from "../../../root/root.constants";

const HandImage = require("react/resources/hand.png");

class HowItWorksRewardsParams {
    organisationId: number;
    territoryId?: number;
}

const HowItWorksRewardsView = (props: NavigationProps<Routes.HowItWorksRewardsView>) => {
    const params = props.route.params;
    const {territories, isLoading:territoryLoading} = useTerritories();
    const {organisation, isLoading} = useOrganisation(params?.organisationId);
    const territory = territories?.find(t => t.id === organisation?.territory);

    return (
        <ParkableBaseView removeStandardMargins toolbarStyle={{marginLeft: PADDING}} loading={isLoading||territoryLoading}>
            <View style={{flex: 1, paddingHorizontal: PADDING}}>
                <View style={contentStyles.heading}>
                    <Text h1>{Strings("how_it_works_rewards")}</Text>
                </View>

                {territory && <Text style={contentStyles.mainText}>
                    {Strings("how_it_works_describe")(organisation?.bonusSessionMargin ?? 0, territory?.currencyCode)}
                </Text>}

                <Text style={contentStyles.mainText}>
                    {Strings("how_it_works_describe_once_you_have")(Constants.minimumPointsToRedeemed)}
                </Text>
                <Text style={contentStyles.mainText}>
                    {Strings("how_it_works_expires_and_redeems")}
                </Text>
            </View>
            <View style={contentStyles.graphicContainer}>
                <Image source={HandImage} style={contentStyles.image}/>
            </View>
        </ParkableBaseView>
    );
};

export default HowItWorksRewardsView;

export const HowItWorksRewardsRoute = createRoute({
    path: Routes.HowItWorksRewardsView,
    params: {type: HowItWorksRewardsParams}
})
