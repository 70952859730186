// @ts-ignore
import React from 'react';
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import Text from "react/legacy/parkable-components/text/Text";
import {StyleSheet, View, Linking} from "react-native";
import Strings from "../../util/localization/localization";

export default function SupportFooterView() {

    const onParkerFaq = () => {
        (async () => {
            const URL = "https://www.parkable.com/how-tos?utm_source=parkableapp&utm_medium=howtobutton";
            if (await Linking.canOpenURL(URL)) {
                Linking.openURL(URL);
            }
        })();
    }

    return (
        <View style={styles.container}>
            <Text bold h4 style={styles.labelMoreInfo}>{Strings("more_information")}</Text>
            <TableRow chevron onPress={onParkerFaq}>
                {Strings("how_tos")}
            </TableRow>
        </View>
    );

};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: 16,
        paddingBottom: 26,
    },
    labelMoreInfo: {
        marginBottom: 18
    }
});
