import moment, {Moment} from "moment";
import {ParkAvailability} from "./getParkAvailability";
import { Availability, getDayAvailability } from "../model/Availability";
import { OrganisationDTO } from "../model/OrganisationDTO";
import {Park} from "../model/Park";
import {Feature} from "../model/Bay";
import {UsernameResponse} from "../model/UsernameResponse";
import {Voucher} from "../model/Voucher";
import {ParkDTOWithTerritory} from "../redux/actions/parks";
import {Linking} from "react-native";
import { Nully } from "./nully";
import {useEffect, useState} from "react";
import {Country, FlagType, getAllCountries} from "react-native-country-picker-modal";

const RESERVATION_TIME_MINUTES = 30;
export const parkReservationTime = (reservationMinutes: Nully<number>) => reservationMinutes ?? RESERVATION_TIME_MINUTES;

export type ILocation = {
    latitude: number,
    longitude: number
}

const deg2rad = (deg: number): number => {
    return deg * (Math.PI/180);
};

export const measureDistanceKms = (loc1: ILocation, loc2: ILocation): number => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(loc2.latitude - loc1.latitude);  // deg2rad below
    const dLon = deg2rad(loc2.longitude - loc1.longitude);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(loc1.latitude)) * Math.cos(deg2rad(loc2.latitude)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
};

export type NextOpenDay = {
    dayOfWeek: string;
    isNextWeek: boolean;
    openDay: moment.Moment;
}


const getNextDayOfWeek = (dayIndex: number, isNextWeek: boolean) => {
    const now = moment();
    const day = now.clone().day(dayIndex);
    if (day.isBefore(now) || isNextWeek) {
        return day.add({week: 1});
    } else {
        return day;
    }
}

/**
 * Calc the next open day according to the park availability,
 * @param {*} availability Park availability
 * @returns undefined if park never opens
 */
export const getNextOpenDay = (availability: Availability | undefined) : NextOpenDay|undefined => {
    if(!availability) {
        return undefined;
    }
    const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const offset = moment().day() + 1; //moment().day(): 0 = sunday, 6 = saturday

    for (let i = offset; i < 7; i ++) { //availability checkpoint from tomorrow, within the current week
        const dayAvailability = getDayAvailability(weekDays[i], availability);
        if (dayAvailability.available) {
            return {
                openDay: getNextDayOfWeek(i, false),
                dayOfWeek: weekDays[i],
                isNextWeek: false
            };
        }
    }

    //checkpoint is saturday and park still n/a, check next week
    for (let j = 0; j < offset; j++) {
        const dayAvailability = getDayAvailability(weekDays[j], availability);
        if (dayAvailability.available) {
            const isNextWeek = j !== 0; //avoid saying NEXT Sunday
            return {
                openDay: getNextDayOfWeek(j, isNextWeek),
                dayOfWeek: weekDays[j],
                isNextWeek
            };
        }
    }

    return undefined;
}

export const getSubscriptionsEndAtTermEnd = (organisation: OrganisationDTO, park: Park|ParkDTOWithTerritory|null) => {
    const nullOrUndef = (x:any) => x === null || x === undefined;
    return !nullOrUndef((park || {}).subscriptionsEndAtTermEnd) ?
        park?.subscriptionsEndAtTermEnd :
        !!(organisation || {}).subscriptionsEndAtTermEnd;
};

export const vehicleFeatureMatch = (vehicle: { feature?: Feature | null | undefined }, feature: Feature) => {
    if (feature === 'SEDAN') {
        return (vehicle.feature === undefined) || (vehicle.feature === null) || vehicle.feature === 'SEDAN';
    }
    return vehicle.feature === feature;
};

export const otherUserDisplayName = (userInfo: UsernameResponse): string => {
    //console.log('otherUserDisplayName', userInfo)
    if (userInfo.firstName) {
        return userInfo.firstName
    } else if ((userInfo.userName || '').includes("@")) {
        // noinspection JSUnusedLocalSymbols
        const [prefix, suffix] = userInfo.userName.split("@");
        return prefix
    } else {
        return userInfo.userName
    }
}

export function forceStringOrUndefined(value: any): string | undefined {
    return (value === undefined || value === null) ? undefined : value.toString();
}

export function getDiffMinutes(start: Moment, end: Moment) {
    const secondsPassed = getDiffSeconds(start, end);
    const minutes = Math.floor(secondsPassed / (60));
    const seconds = secondsPassed - (minutes * 60);

    return `${minutes}.${String("0" + seconds).slice(-2)}`;
}

export function getDiffMinutesRounded(start: Moment, end: Moment){
    const secondsPassed = getDiffSeconds(start, end);
    const minutes = Math.ceil(secondsPassed / (60));

    return `${minutes}`;
}

const getDiffSeconds = (start: Moment, end: Moment) => end.diff(start, "seconds");

export const getId = (o: null | undefined | number | {id: number}): number => !!o && typeof o === 'object' ? o.id : o!;

export function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
  }


export function voucherIsRedeemed(voucher: Voucher) {
        return voucher.deleted || !voucher.type || voucher.fullyRedeemed ||
            (voucher.validTo && moment().isAfter(moment(voucher.validTo))) ||
            (voucher.originalCreditAmount !== 0 && voucher.remainingCreditAmount === 0) ||
            (voucher.originalSessionCount !== 0 && voucher.remainingSessionCount === 0);
}

export function isFreeSessionVoucher(voucher: Voucher, pricePerDay: number, pricePerHour: number) {
    return !!voucher &&
        (!!voucher.remainingSessionCount || //FREE SESSION
            (voucher.percentDiscount??0) === 100 || //100% DISCOUNT VOUCHER
            ((voucher.remainingCreditAmount??0) >= pricePerDay && (voucher.remainingCreditAmount??0) >= pricePerHour) //Voucher covers the total amount
        );
}


export function rad(x:number) {
    return x * Math.PI / 180;
}

export function getDistanceInMeters(start: ILocation, end: ILocation) {
    return measureDistanceKms(start, end) * 1000;
}

export type PermanentlyAvailableParameters = Pick<ParkAvailability, 'permanentlyAvailable' | 'availability'>

export const isPermanentlyAvailable = (availability: PermanentlyAvailableParameters) : boolean => {

    if(!availability) {
        return true;
    }

    // if "is always open" is ticked, the button will be disabled
    if (availability.permanentlyAvailable){
        return true;
    }

    const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    for (let i = 0; i < 7; i ++) { //availability checkpoint from tomorrow, within the current week

        const dayAvailability = getDayAvailability(weekDays[i], availability.availability);

        // if any of the days are disabled, this is not 24/7 car park
        if (!dayAvailability.available) {
            return false
        }

        // checking if any of the days are not 24h
        if (dayAvailability.hourlyAvailability.some(h => !h)) {
            return false
        }
    }

    return true;
}

export const callPhoneNumber = (phoneNumber: string): void => {
    void Linking.openURL(`tel:${phoneNumber}`)
};

export const sendEmail = (email: string): void => {
    void Linking.openURL(`mailto:${email}`)
};

export const DayOptions = {
    Off: "Off",
    Daily: "Daily",
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    Sunday: "Sunday",
    Weekdays: "Weekdays"
}

export const TimeOptions = [
    {
        label: "12:00 am",
        value: 0
    },
    {
        label: "1:00 am",
        value: 1
    },
    {
        label: "2:00 am",
        value: 2
    },
    {
        label: "3:00 am",
        value: 3
    },
    {
        label: "4:00 am",
        value: 4
    },
    {
        label: "5:00 am",
        value: 5
    },
    {
        label: "6:00 am",
        value: 6
    },
    {
        label: "7:00 am",
        value: 7
    },
    {
        label: "8:00 am",
        value: 8
    },
    {
        label: "9:00 am",
        value: 9
    },
    {
        label: "10:00 am",
        value: 10
    },
    {
        label: "11:00 am",
        value: 11
    },
    {
        label: "12:00 pm",
        value: 12
    },
    {
        label: "1:00 pm",
        value: 13
    },
    {
        label: "2:00 pm",
        value: 14
    },
    {
        label: "3:00 pm",
        value: 15
    },
    {
        label: "4:00 pm",
        value: 16
    },
    {
        label: "5:00 pm",
        value: 17
    },
    {
        label: "6:00 pm",
        value: 18
    },
    {
        label: "7:00 pm",
        value: 19
    },
    {
        label: "8:00 pm",
        value: 20
    },
    {
        label: "9:00 pm",
        value: 21
    },
    {
        label: "10:00 pm",
        value: 22
    },
    {
        label: "11:00 pm",
        value: 23
    },
]

export const delay = (time: number) =>{
    return new Promise<void>(resolve => {
        setTimeout( ()=> {
            resolve();
        }, time);
    })
};

export const getDayOfWeek = (index: number): string => {
    const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return weekDays[index];
}

export const getCurrentDayOfWeek = (): string => {
    const now = moment();
    const currentDayOfWeek = now.day(); //moment().day(): 0 = sunday, 6 = saturday
    return getDayOfWeek(currentDayOfWeek);
};

export const getYesterdayDayOfWeek = (): string => {
    const now = moment();
    return getDayOfWeek(now.add({day: -1}).day());
};

export function removeSec(timeString:string) {
    const hmsrgx = /\d{1,2}:\d\d:\d\d/gi;
    if(hmsrgx.test(timeString)) {
        timeString = timeString.replace(/(:\d{1,2}$)|(:\d\d )/, ' ');
    }
    return timeString;
}

export const formatTimeNumberToString = (time: number) => {
    const paddedTime = time.toString().padStart(9, "0");
    const hour = paddedTime.substring(0, 2); // HH
    const minutes = paddedTime.substring(2, 4); // MM
    if(time < 120000000){
        return `${hour === "00" ? "12" : hour}:${minutes}am`
    }else{
        const integerHour = parseInt(hour);
        return `${integerHour === 12 ? integerHour : (integerHour - 12)}:${minutes}pm`
    }
}

export const useCountries = () => {
    const [countries, setCountries] = useState<Country[] | undefined>()
    useEffect(() => {
        getAllCountries(FlagType.FLAT).then(setCountries);
    }, [])
    return countries;
}

export const wordCount = (text: string | null | undefined) => {
    return text ? text.split(" ").filter(function(n) { return n != '' }).length : 0;
}
