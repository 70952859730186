import * as GoogleApi from "../../api/google";
import {getGeoLocationAPI} from "../../api/google";
import {DispatchFunc} from "../../model/Types";
import {GeoPointDTO} from "../../model/ParkDTO";
import { GooglePlace, IPrediction } from "../../dto/IPrediction";
import { Platform } from "react-native";
import { loadGoogleMaps } from "../../components/map/parkableMapView/web/google/google.web";

export const GOOGLE_GEO_LOCATION_DATA_RECEIVED = 'GOOGLE_GEO_LOCATION_DATA_RECEIVED';
export const GOOGLE_DRIVETIME_DATA_RECEIVED = 'GOOGLE_DRIVETIME_DATA_RECEIVED';
export const GOOGLE_LOADING = 'GOOGLE_LOADING';
export const SET_GOOGLE_SESSION_TOKEN = 'SET_GOOGLE_SESSION_TOKEN';

export function getGeoLocation(mapLocation: GeoPointDTO) {
    return (dispatch: DispatchFunc) => {

        const lat = mapLocation.latitude;
        const lng = mapLocation.longitude;

        getGeoLocationAPI(lat, lng).then((r: any) => {
            dispatch(geoLocationReceived(r.results));
        }).catch((err) => {
            //console.log('petrol error', err)
        })
    }
}

export function getDriveTime(startLatitude: number, startLongitude: number, endLatitude: number, endLongitude: number, departureTime: any) {
    return (dispatch: DispatchFunc) => {
        return GoogleApi.getDriveTime(startLatitude, startLongitude, endLatitude, endLongitude, departureTime).then((r) => {
            dispatch(driveTimeReceived(r));
            return r
        }).catch((err) => {
            throw err
            //console.log('petrol error', err)
        })
    }
}

export function driveTimeReceived(driveTimeData: any) {
    return {
        type: GOOGLE_DRIVETIME_DATA_RECEIVED,
        driveTimeData
    };
}

export function geoLocationReceived(locations: any) {
    return {
        type: GOOGLE_GEO_LOCATION_DATA_RECEIVED,
        locations
    };
}

export function loading(loading: any) {
    return {
        type: GOOGLE_LOADING,
        loading
    }
}

export function requestLocationData(placeId: string, sessionToken: string) {
    if (Platform.OS === "web") {
        return new Promise(async (resolve: (args: any) => void, reject) => {
            try {
                const google = await loadGoogleMaps();
                const service = new google.maps.places.PlacesService(document.createElement('div'));
                const request = {placeId};
                service.getDetails(request, (result) => resolve({result}));
            } catch (e) {
                reject(e);
            }
        });
    }
    return GoogleApi.requestLocationDataAPI(placeId, sessionToken) as Promise<{ result: GooglePlace }>;
}

export function getPredictionData(searchText: string, countryCodes: any[] | undefined, latitude: number | undefined, longitude: number | undefined, sessiontoken: string): Promise<IPrediction[]> {
    if (Platform.OS === "web") {
        return new Promise(async (resolve: (args: any) => void, reject) => {
            try {
                const google = await loadGoogleMaps();
                const service = new google.maps.places.AutocompleteService();
                const request = {
                    input: searchText,
                    // BAC-8387 Commented out because we're getting a cors error when the SDK tries to use the v3 API. Removing location causes the SDK to revert back to the legacy API which works.
                    radius: 20000,
                    location: latitude && longitude
                         ? new google.maps.LatLng(latitude, longitude)
                         : undefined
                };
                service.getQueryPredictions(request, resolve);
            } catch (e) {
                reject(e);
            }
        });
    }
    return GoogleApi.requestPredictions(searchText, countryCodes, latitude, longitude, sessiontoken) as Promise<IPrediction[]>;
}

export function setSessionToken(sessiontoken: string) {
    return {
        type: SET_GOOGLE_SESSION_TOKEN,
        sessiontoken
    }
}
