import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import * as MicrosoftTeams from "@microsoft/teams-js";
import {validOrigins} from "./constants";

interface ITeamsContext {
    insideTeamsEnvironment: boolean|undefined,
    teamsLoggingIn: boolean,
    setTeamsLoggingIn: (loggingIn: boolean) => void
}

const TeamsContext = createContext<ITeamsContext>(undefined as any);

export const useTeamsContext = () => {
    return useContext(TeamsContext);
}

type TeamsProviderProps = {}

export const TeamsProvider = ({ children }: PropsWithChildren<TeamsProviderProps>) => {

    const [insideTeamsEnvironment, setInsideTeamsEnvironment] = useState<boolean|undefined>(undefined);
    const [teamsLoggingIn, setTeamsLoggingIn] = useState<boolean>(false);
    useEffect(() => {
        const fun = async () => {

            try {

                console.log("useIsInsideTeamsEnvironment about to init teams.", validOrigins);
                await MicrosoftTeams.app.initialize(validOrigins);
                console.log("useIsInsideTeamsEnvironment TEAMS LOG: MicrosoftTeams initialized");
                console.log("useIsInsideTeamsEnvironment inside teams");
                setInsideTeamsEnvironment(true);
            } catch (err) {
                console.log("useIsInsideTeamsEnvironment not inside teams", err);
                setInsideTeamsEnvironment(false);
            }

        }
        void fun();
    }, []);


    return (
        <TeamsContext.Provider value={{
            insideTeamsEnvironment, teamsLoggingIn, setTeamsLoggingIn
        }}>
            {insideTeamsEnvironment === undefined ? <></> : children}
        </TeamsContext.Provider>
    );
};
