/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 18/10/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import getAvailabilityText from "./getAvailabilityText";
import {DayAvailability} from "../../model/DayAvailability";

export default function getOpeningTime(dayAvailability: DayAvailability): string {
    const text = getAvailabilityText(dayAvailability, false);
    const noWhitespace = text.replace(/ /g, "").trim();
    return noWhitespace.split("-")[0];
}

