import { View } from "react-native";
import React, {useMemo} from "react";
import Spinner from "react/legacy/parkable-components/spinner/Spinner";
import Select from "react/legacy/parkable-components/select/Select";
import Text from "react/legacy/parkable-components/text/Text";
import Strings from "../../util/localization/localization";
import moment from "moment";
import getParkAvailability from "../../util/getParkAvailability";
import { usePark } from "../../api/park";
import { Item } from "react-native-picker-select";

type EstimatedLeaveTimeProps = {
    parkId: number;
    handleChange: (value: string) => void;
    value: string | null;
};

const timeFormat = "h:mma";

export const EstimatedLeaveTimePicker = (props: EstimatedLeaveTimeProps) => {
    const { parkId, handleChange, value } = props;
    const { park, isLoading } = usePark(parkId);

    if (!park || isLoading) {
        return <Spinner small />;
    }

    const {isOpen24hours, closingTime} = getParkAvailability(park);

    const options = useMemo(() => {

        const REMAIN_TIME_MINUTES = 30;
        const timeOptions: Item[] = [];
        const startTime = moment().set({ seconds: 0, milliseconds: 0 });
        const remainder = REMAIN_TIME_MINUTES - (startTime.minute() % REMAIN_TIME_MINUTES);
        const time = startTime.add({ minutes: remainder });
        const twenty4HoursFromNow = moment().add({ hours: 24 });
        const endTime =
            !isOpen24hours && closingTime
                ? moment(closingTime, timeFormat)
                : twenty4HoursFromNow;

        timeOptions.push({
            label: Strings("leave_time_dont_know"),
            value: "null",
        });
        while (time.isSameOrBefore(endTime)) {
            timeOptions.push({
                label: time.format(timeFormat),
                value: time.toISOString(),
            });
            time.add({ minutes: REMAIN_TIME_MINUTES });
        }
        return timeOptions;
    }, [closingTime, isOpen24hours]);

    const selectedValue = value === null ? "null" : moment(value).toISOString();

    return (
        <View>
            <Text bold small grey style={{ textTransform: "uppercase" }}>
                {Strings("estimated_leave_time")}
            </Text>
            <Text grey small>
                {Strings("estimated_leave_time_dropdown_label")}
            </Text>
            <Select
                items={options}
                value={selectedValue}
                onValueChange={(value) => {
                    handleChange(value);
                }}
            />
        </View>
    );
};
