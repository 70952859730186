import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Image, StyleSheet, View } from "react-native";
import Spinner from "react/legacy/parkable-components/spinner/Spinner";
const parkableStack = require("../../../resources/parkableStack.png");

export default () => {
    const { user, getAccessTokenSilently, isAuthenticated, getIdTokenClaims } = useAuth0();
    const [error, setError] = useState<string|undefined>(undefined);

    useEffect(() => {
        const getAccessToken = async () => {

            if(user) {
                try {
                    await getAccessTokenSilently();
                    const idTokenClaims = await  getIdTokenClaims();
                    if(idTokenClaims) {
                        window.location.href = window.location.origin + `?id_token=${idTokenClaims.__raw}`;
                    }

                } catch (e: any) {
                    console.log("mdtest: err", e.message);
                    setError(e.message);
                }
            }
        };
        void getAccessToken();
    }, [user?.sub]);

    return <View style={styles.web}>
        <Image style={styles.logo}
               source={parkableStack}
        />
        {!error && <View>
            <Spinner />
        </View>}
        {error && <View>
            <>{error}</>
        </View>}
    </View>;
};

const styles = StyleSheet.create({
    web: {
        justifyContent: "center",
        alignItems: "center",
        height: "80%",
    },
    logo: {
        width: "50%",
        aspectRatio: 512/404,
    },
});
