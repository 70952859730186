import React from "react";
import Text from "react/legacy/parkable-components/text/Text";
import { Image, ScrollView, StyleSheet, View } from "react-native";
import { Routes } from "react/navigation/root/root.paths";
import { createRoute } from "react/navigation/constants";
import ParkableBaseView from "react/components/common/ParkableBaseView";
import { PADDING } from "react/root/root.constants";
import Strings from "react/util/localization/localization";
const Hand = require("react/resources/hand.png");

const SharingPoolView = () => (
    <ParkableBaseView scrollable={false} removeStandardMargins toolbarStyle={styles.horizontalPadding}>
        <Text h1 style={styles.horizontalPadding}>{Strings("sharing_pool")}</Text>
        <ScrollView bounces={false}>
            <View style={styles.horizontalPadding}>
                <Text p>{Strings("sharing_pool_description_part1")}</Text>
                <Text p>{Strings("sharing_pool_description_part2")}</Text>
                <Text p>{Strings("sharing_pool_description_part3")}</Text>
            </View>
            <Image style={styles.image} source={Hand} />
        </ScrollView>
    </ParkableBaseView>
);

const styles = StyleSheet.create({
    horizontalPadding: {
        marginHorizontal: PADDING
    },
    image: {
        marginTop: 20,
        marginBottom: 20,
        width: 295,
        height: 300,
    },
});

export const SharingPoolViewRoute = createRoute({
    path: Routes.SharingPoolView,
});

export default SharingPoolView;
