export enum BayGroupType {
    Standard = 'Standard',
    Visitor = 'Visitor',
    PublicDynamic = 'PublicDynamic',
    PublicSubscription = 'PublicSubscription'
}

export const PUBLIC_BAY_GROUP_TYPES = [
    BayGroupType.PublicDynamic,
    BayGroupType.PublicSubscription,
];
