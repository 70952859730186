import * as Types from "../actions/map";
import {RECEIVED_PARKING_TYPE, SET_LAST_MAP_LOCATION, TOGGLE_FAVOURITES_VIEW_STATE_MINIMISED, ENABLE_MAP_SCROLL} from '../actions/map';
import {defaultMapPreferences, MapPreferences, ParkingType} from "../../model/Types";
import {Region} from "react-native-maps";

const init = {
    preferences: defaultMapPreferences(),
    lastMapLocation: undefined as undefined | Region,
    favouritesViewStateMinimised: false,
    enableMapScroll: true
};

type IMapReducer = typeof init;

interface IAction {
    type: string,
    payload?: {
        maps?: {
            preferences?: MapPreferences,
            lastMapLocation?: Region,
            favouritesViewStateMinimised?: boolean
        }
    },
    preferences?: MapPreferences,
    parkingType?: ParkingType,
    lastMapLocation?: Region,
    favouritesViewStateMinimised?: boolean,
    enableMapScroll: boolean
}

export default function mapsReducer(state = init, action: any): IMapReducer {
    switch (action.type) {

        case 'persist/REHYDRATE': {
            // custom rehydration

            const {
                preferences: hydratePreferences,
            } = action.payload?.maps ?? {};

            return {
                ...state,
                preferences: hydratePreferences ?? state.preferences,
                lastMapLocation: action.payload?.maps?.lastMapLocation,
                favouritesViewStateMinimised: !!action.payload?.maps?.favouritesViewStateMinimised,
            };
        }

        case Types.SET_MAP_PREFERENCES: {
            console.log("SET_MAP_PREFERENCES", action);
            return {
                ...state,
                preferences: action.preferences ?? state.preferences
            };
        }

        case RECEIVED_PARKING_TYPE: {
            console.log('SET PARKING TYPE', action);

            const prefs = {...state.preferences};
            prefs.parkingType = action.parkingType ?? state.preferences.parkingType;
            return {
                ...state,
                preferences: prefs

            }

        }
        case SET_LAST_MAP_LOCATION: {
            return {
                ...state,
                lastMapLocation: action.lastMapLocation,
            };
        }
        case TOGGLE_FAVOURITES_VIEW_STATE_MINIMISED:
            return {
                ...state,
                favouritesViewStateMinimised: !state.favouritesViewStateMinimised
            }
        case ENABLE_MAP_SCROLL:
            return {
                ...state,
                enableMapScroll: action.enableMapScroll
            }
        default:
            return state;
    }
}
