import { useMySWR } from "../http/use-swr";
import { AccessGateOverridesResponse } from "./dto/AccessGateOverride.dto";

export const useAccessGateOverridesForCurrentUser = () => {
    return useMySWR<AccessGateOverridesResponse>(`v1/accessgate-override`);

    /* testing
    return {
        accessGateOverrides: [
            {
                park: 4728603111587840,
                user: 5563865903398912,
                limitDistanceToOpen: 125000000,
            }
        ]
    } as AccessGateOverridesResponse;*/
}