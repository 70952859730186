import React, { useEffect, useRef } from "react";
import { showAlert } from "../../../util/alerts";
import Strings from "../../../util/localization/localization";
import _ from "lodash";
import * as Push from "../../../navigation/pushNotifications/constants";
import {addNotificationListener} from "../../../navigation/pushNotifications/notificationListener";
import {
    createTandemChat,
    sendMessageInChat,
    useTandemChat,
    useTandemChatBySessionId,
    useTandemChatParticipants,
    useTandemPod,
} from "../../../api/tandem/tandem.api";
import { ChatContainer } from "../../../components/messages/ChatContainer";
import { ChatMessageDTO } from "../../../model/chat/ChatMessageDTO";
import { useCurrentUser } from "../../../api/user/user.api";
import { ChatMessage } from "../../../components/messages/ChatMessage";
import { Routes } from "react/navigation/root/root.paths";
import Icons from "react/legacy/parkable-components/icon/Icons"
import EstimatedLeaveTimeModal, {
    EstimatedLeaveTimeModalRef,
} from "../../../components/tandemParking/EstimatedLeaveTimeModal";
import {useActiveParkSession, useParkSessionV3} from "../../../api/parkSession/parkSession.api";
import moment from "moment";
import {createRoute, NavigationProps, useNavigation} from "../../../navigation/constants";
import ParkableBaseView from "../../../components/common/ParkableBaseView";
import {PADDING} from "../../../root/root.constants";

class TandemChatParams {
    sessionId?: number;
    tandemPodId?: number;
    chatId?: number;
}

const TandemChatView = (props: NavigationProps<Routes.TandemChatView>) => {
    const { sessionId, tandemPodId, chatId } = props.route.params ?? {};
    const { parkSession: currentSession } = useActiveParkSession();
    const { parkSession: _parkSession, mutate: mutateParkSession } = useParkSessionV3(sessionId);

    const navigation = useNavigation();

    const { user, isLoading: loadingUser } = useCurrentUser();
    const { tandemPod } = useTandemPod(tandemPodId);
    const leaveTimeDialogRef = useRef<EstimatedLeaveTimeModalRef>(null);

    const { chat: _chat, isLoading: loadingChat, mutate: mutateChat } = useTandemChat(tandemPodId, chatId);

    const parkSession = !!_parkSession ? _parkSession : !!currentSession && _chat?.tandemSessions?.includes(currentSession.id) ? currentSession : undefined;

    const {
        chat: chatBySessionId,
        isLoading: loadingChatBySessionId,
        mutate: mutateChatBySessionId,
    } = useTandemChatBySessionId(tandemPodId, sessionId);
    const chat = _chat ?? chatBySessionId;
    const mutate = _chat ? mutateChat : mutateChatBySessionId;

    const { participants, isLoading: loadingParticipants } = useTandemChatParticipants(tandemPodId, chat?.id);

    const loading = loadingUser || loadingChat || loadingChatBySessionId || loadingParticipants;

    useEffect(() => {
        const notifListener = addNotificationListener(onNotificationReceived, "TandemChatView");
        return () => {
            notifListener.remove();
        };
    }, ["once"]);

    const onNotificationReceived = async (code: string) => {
        switch (code) {
            case Push.TandemMessageReceived: {
                await mutate(undefined, true);
                return true;
            }
        }
    };

    const onSendPress = async (text: string, onSuccess: any, onError: any) => {
        if (!text.length) {
            return;
        }

        if (!tandemPodId) {
            showAlert(Strings("unable_to_send_message"), Strings("whoops"));
            return;
        }

        try {
            const request = {
                body: text,
            };

            if (chat) {
                await sendMessageInChat(chat.id, request);
            } else {
                await createTandemChat(tandemPodId, request);
            }

            await mutate();
            onSuccess();
        } catch (e) {
            onError();
            showAlert((e as any)?.response?.data?.message ?? Strings("unable_to_send_message"), Strings("whoops"));
        }
    };

    const messages = _.sortBy(chat?.messages || [], (c) => c.createdAt);

    const renderMessage = (message: ChatMessageDTO, index: number, previousMessage?: ChatMessageDTO) => {
        const participantIndex = participants?.findIndex((p) => p.userId === message.senderId);
        const participant = participants?.find((p) => p.userId === message.senderId);

        const currentMessageTime = moment(message.createdAt).seconds(0).milliseconds(0);
        const displayDateTime = !previousMessage || !currentMessageTime.isSame(moment(previousMessage.createdAt).seconds(0).milliseconds(0));

        return (
            <ChatMessage
                key={index}
                index={index}
                hideDateTime={!displayDateTime}
                message={message}
                isCurrentUser={message.senderId === user?.id}
                participantIndex={participant?.isActive ? participantIndex : -1}
                sender={{ userName: participant?.registration }}
            />
        );
    };

    const onMessageInputIconPress = () => {
        leaveTimeDialogRef.current?.show();
    };

    const onChatDetailsPress = () => {
        navigation.push(Routes.ChatDetailsView, {
            tandemPodId: tandemPodId,
            chatId: chat?.id,
            parkSessionId: parkSession?.id
        });
    };

    return (
        <ParkableBaseView
            scrollable={false}
            toolbarStyle={{marginLeft: PADDING}}
            removeStandardMargins
            toolbarTitleText={!!tandemPod?.name ? tandemPod?.name : Strings("tandem_chat")}
            toolbarIconText={"Details"}
            toolbarIcon={Icons.informationCircle}
            onIconClick={onChatDetailsPress}>
            <ChatContainer
                messageInputIcon={!parkSession || parkSession?.endedAt ? undefined : Icons.handwatch}
                onMessageInputIconPress={!parkSession || parkSession?.endedAt ? undefined : onMessageInputIconPress}
                messages={messages}
                onSendPress={onSendPress}
                renderMessage={renderMessage}
                loading={loading}
            />
            {parkSession && (
                <EstimatedLeaveTimeModal
                    ref={leaveTimeDialogRef}
                    mutate={async () => {
                        await mutateParkSession();
                        await mutate();
                    }}
                    onNegativePress={() => leaveTimeDialogRef.current?.hide()}
                    currentSession={parkSession}
                />
            )}
        </ParkableBaseView>
    );
};

export default TandemChatView;

export const TandemChatRoute = createRoute({
    path: Routes.TandemChatView,
    params: {
        type: TandemChatParams
    }
})
