import { Text } from 'react/legacy/parkable-components'
import React from 'react'
import { Image, StyleSheet, View } from 'react-native'
import {Routes} from "../../navigation/root/root.paths";
import {createRoute, NavigationProps} from "../../navigation/constants";
import ParkableBaseView from "../../components/common/ParkableBaseView";
import {PADDING} from "../../root/root.constants";
const Hand = require("../../resources/hand.png");

export class ParkAvailabilityViewParams {
    isOpen24hours: boolean;
    closesAtMidnightTonight: boolean;
    info: string;
    title: string;
    description: string;
    bonusBayStyle: boolean;
    disabled: boolean;
}

type Props = NavigationProps<Routes.ParkAvailabilityView>

function ParkAvailabilityView(props: Props) {
    const {title, description} = props.route.params;
    return (
        <ParkableBaseView scrollable={false} removeStandardMargins toolbarStyle={{marginHorizontal: PADDING}}>
            <View style={styles.base}>
                <View style={styles.mainContainer}>
                    <Text h1>{title}</Text>
                    <Text h5 style={{lineHeight: 24}}>{description}</Text>
                </View>
                <View style={styles.imageContainer}>
                    <Image style={styles.image} source={Hand} />
                </View>
            </View>
        </ParkableBaseView>);
}

export const ParkAvailabilityViewRoute = createRoute({
    path: Routes.ParkAvailabilityView,
    params: {type: ParkAvailabilityViewParams},
});

const styles = StyleSheet.create({
    base: {
        flex: 1,
    },
    mainContainer: {
        paddingTop: 28,
        paddingHorizontal: PADDING,
    },
    imageContainer: {
        flexDirection: "row",
    },
    image: {
        marginTop: 20,
        resizeMode: "contain",
        aspectRatio: 273 / 278,
        zIndex: 100,
        height: 300,
    },
});
export default ParkAvailabilityView as React.FunctionComponent<Props>;
