import * as PartialParkApi from "../../api/partialPark";
import * as TerritoryApi from "../../api/territory";
import fetch from "../reducers/fetch";
import {PartialPark} from "../../model/PartialPark";
import {Territory} from "../../model/Territory";
import {DispatchFunc} from "../../model/Types";
import {Token} from "../../api/rest";

//Partial Park
export const SET_PARTIAL_PARK = 'SET_PARTIAL_PARK';

export interface PartialParkWithTerritory extends PartialPark {
    territory: Territory | null
}

const getPartialParkTerritory = (api: string, token: Token, partialPark: PartialPark | null): Promise<[PartialPark | null, {territory: Territory | null}]> => {
    if (!partialPark) {
        return Promise.all([Promise.resolve(null), Promise.resolve({territory: null})]) as Promise<[null, {territory: Territory | null}]>
    }

    const territory = partialPark.territory;

    if (typeof territory === 'number') {
        partialPark.territory = null;
        return Promise.all([Promise.resolve(partialPark), TerritoryApi.getTerritoryAPI(api, token, territory)]);
    }

    return Promise.all([Promise.resolve(partialPark), Promise.resolve({territory})]);
};

const setPartialParkWithTerritory = (partialPark: PartialPark | null, territory: Territory | null, dispatch: DispatchFunc, onSuccess?: null | ((p: PartialParkWithTerritory) => void)) => {
    if(!partialPark) {
        return;
    }

    const partialParkWithTerritory: PartialParkWithTerritory = {
        ...partialPark,
        territory,
    };
    dispatch(setPartialPark(partialParkWithTerritory));
    onSuccess?.(partialParkWithTerritory)
};

const partialParkOnError = (err: any, onError?: null | ((err: any) => void)) => {
    if (err?.code !== 'network_error') {
        if (onError) {
            onError(err)
        } else {
            throw err;
        }
    }
};

export function getUnpublishedPartialPark(onSuccess?: (p: PartialParkWithTerritory) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return PartialParkApi.getUnpublishedPartialParkAPI(api, token)
            .then(({partialPark}) => getPartialParkTerritory(api, token, partialPark))
            .then(([partialPark, {territory}]) => setPartialParkWithTerritory(partialPark, territory, dispatch, onSuccess))
            .catch((err) => partialParkOnError(err, onError))
    }, arguments);
}

export function createOrGetUnpublishedPartialPark(onSuccess?: (p: PartialParkWithTerritory) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return PartialParkApi.createOrGetUnpublishedPartialParkAPI(api, token)
            .then(({partialPark}) => getPartialParkTerritory(api, token, partialPark))
            .then(([partialPark, {territory}]) => setPartialParkWithTerritory(partialPark, territory, dispatch, onSuccess))
            .catch((err) => partialParkOnError(err, onError))
    }, arguments);
}

export function updatePartialPark(partialParkId: number, data: any, onSuccess: null | ((p: PartialParkWithTerritory) => void), onError: null | ((err: any) => void)) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return PartialParkApi.updatePartialParkAPI(api, token, partialParkId, data)
            .then(({partialPark}) => getPartialParkTerritory(api, token, partialPark))
            .then(([partialPark, {territory}]) => setPartialParkWithTerritory(partialPark, territory, dispatch, onSuccess))
            .catch((err) => partialParkOnError(err, onError))
    }, arguments);
}

export function discardPartialPark(partialParkId:number, onSuccess: ((p: PartialParkWithTerritory) => void) | null, onError: ((err: any) => void) | null) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return PartialParkApi.discardPartialParkAPI(api, token, partialParkId)
            .then(({partialPark}) => getPartialParkTerritory(api, token, partialPark))
            .then(([partialPark, {territory}]) => setPartialParkWithTerritory(partialPark, territory, dispatch, onSuccess))
            .catch((err) => partialParkOnError(err, onError))
    }, arguments);
}

export const setPartialPark = (partialPark:Object | null) => ({
    type: SET_PARTIAL_PARK,
    partialPark
});