import React, { useEffect } from "react";
import {StyleSheet} from "react-native";
import Colours from "react/legacy/parkable-components/styles/Colours";
import SubscriptionView from "../../components/parkingViews/Subscriptions/SubscriptionView";
import {connect} from "react-redux";
import {IRootReducer} from "../../redux/reducers/main";
import {getEmployeeSubscription} from "../../redux/actions/subscriptions";
import {createRoute, NavigationProps} from "../../navigation/constants";
import {Routes} from "../../navigation/root/root.paths";
import ParkableBaseView from "../../components/common/ParkableBaseView";
import { PADDING } from "../../root/root.constants";

class SingleSubscriptionViewParams {
    subscriptionId: number;
}

type Props = NavigationProps<Routes.SingleSubscriptionView>;

type AllProps = Props & ReturnType<typeof getReduxProps> & typeof actions;

function SingleSubscriptionView(props: AllProps) {
    const { subscription, subscriptionId, getEmployeeSubscription } = props;

    useEffect(() => {
        if (!subscription){
            getEmployeeSubscription(subscriptionId);
        }
    }, [subscription]);

    return (
        <ParkableBaseView removeStandardMargins toolbarStyle={{marginLeft: PADDING}}>
            {!!subscription &&
            <SubscriptionView subscriptionId={subscriptionId} style={{}} />
            }
        </ParkableBaseView>
    );
}

const getReduxProps = (state: IRootReducer, props: Props) => {
    const subscriptionId = props.route.params?.subscriptionId;

    const subscription = state.subscriptions.employeeSubscriptions[subscriptionId];

    return {
        subscriptionId,
        subscription
    }
}

const actions = {
    getEmployeeSubscription,
};

export default connect(getReduxProps, actions)(SingleSubscriptionView) as React.FunctionComponent<AllProps>;

export const SingleSubscriptionRoute = createRoute({
    path: Routes.SingleSubscriptionView,
    params: {type: SingleSubscriptionViewParams},
});

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colours.NEUTRALS_WHITE,
    },
});
