import Colours from "react/legacy/parkable-components/styles/Colours";
import { spacing } from "./spacing";

export const theme = {
  spacing,
  palette: Colours,
  typography: {
    subtitle: {
      fontSize: 12,
      color: Colours.GREY_50,
      lineHeight: 12,
    },
  },
};
