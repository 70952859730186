import moment from 'moment-timezone';
import getTodayAvailability from "./availability/getTodayAvailability";
import getTomorrowAvailability from "./availability/getTomorrowAvailability";
import getAvailabilityText from "./availability/getAvailabilityText";
import get24HoursAvailable from "./availability/get24HoursAvailable";
import {ParkInfo} from "../model/ParkInfo";
import {Availability, AvailabilityDTO, convertAvailabilityDTO, fallbackAvailability} from "../model/Availability";
import {DayAvailabilityBools, fallbackDayAvailabilityBools} from "../model/DayAvailability";
import getDayAvailability, { DayAvailabilityBoolsWithToday } from './availability/getDayAvailability';
import {Park} from "../model/Park";
import {ParkDTO} from "../model/ParkDTO";
import Strings from "../util/localization/localization";
import { ParkInfo2 } from "../model/ParkInfo2";

export interface ParkAvailability {
    closingTime: string | undefined,
    openingTime: string | undefined,
    tomorrowClosingTime: string | undefined,
    otherDayClosing: undefined | {
        day: moment.Moment,
        closingTime: string,
    },
    isOpenNow: boolean,
    globalAvailability: boolean,
    permanentlyAvailable: boolean,
    todayAvailability: DayAvailabilityBools | DayAvailabilityBoolsWithToday,
    tomorrowAvailability: DayAvailabilityBools | DayAvailabilityBoolsWithToday,
    availability: Availability,
    availText: string,
    isOpen24hours: boolean,
    hoursUntilClose: number,
    isMidnightTonight: boolean,
}

export interface ParkCurrentAvailability {
    parkIsOpen: boolean,
    hasLongTerm: boolean,
    hasShortTerm: boolean,
    hasEVCharger: boolean,
    parkLTFull: boolean,
    parkSTFull:boolean
}

const fallbackParkAvailability: ParkAvailability = {
    closingTime: undefined,
    openingTime: undefined,
    tomorrowClosingTime: undefined,
    otherDayClosing: undefined,
    isOpenNow: false,
    globalAvailability: false,
    permanentlyAvailable: false,
    todayAvailability: fallbackDayAvailabilityBools,
    tomorrowAvailability: fallbackDayAvailabilityBools,
    availability: fallbackAvailability,
    availText: "",
    isOpen24hours: false,
    hoursUntilClose: 0,
    isMidnightTonight: false,
};

function convertToAvailability(park: Pick<ParkDTO | ParkInfo, 'availability'> | Pick<Park, 'availability'>): Availability | null {
    let availability: Availability | AvailabilityDTO | null | undefined;
    // noinspection SuspiciousTypeOfGuard

    if (typeof park.availability === "string") {
        try {
            availability = JSON.parse(park.availability) as (Availability | AvailabilityDTO)
        } catch (e) {
            console.error('Could not parse park.availability', park.availability);
            throw e;
        }
    } else {
        availability = park.availability;
    }

    if (!availability) {
        return null;
    }

    if (typeof availability.sundayAvailability.hourlyAvailability === 'string') {
        return convertAvailabilityDTO(availability as AvailabilityDTO)
    }

    return availability as Availability;
}

export default function getParkAvailability(park:
    Pick<ParkDTO | ParkInfo, 'timeZoneId' | 'availability'> |
    Pick<Park, 'timeZoneId' | 'availability'>): ParkAvailability {

    const availability = convertToAvailability(park);
    if (!availability) {
        return fallbackParkAvailability;
    }

    const todayAvailability = getTodayAvailability(availability);
    const tomorrowAvailability = getTomorrowAvailability(availability);
    if (!todayAvailability || !tomorrowAvailability) {
        return fallbackParkAvailability;
    }

    const availText = getAvailabilityText(todayAvailability, availability.permanentlyAvailable);

    const isOpen24hours = get24HoursAvailable(availability, availability.permanentlyAvailable);

    const now = moment().tz(park.timeZoneId);
    const nowIndex = now.hour()*2 + (now.minute() >= 30 ? 1 : 0);

    const isAvailable = (val: boolean | string) => val === true || (typeof val === "string" && val.toLowerCase() === 't');

    const isOpenNow = availability.permanentlyAvailable ||
        (todayAvailability.available && isAvailable(todayAvailability.hourlyAvailability[nowIndex]));

    let isMidnightTonight = false;
    let closingTime = undefined;
    let openingTime = undefined;
    let tomorrowClosingTime = undefined;
    let otherDayClosing = undefined as undefined | {
        day: moment.Moment,
        closingTime: string
    };
    if(!availability.permanentlyAvailable){
        openingTime = getOpeningTime(todayAvailability.hourlyAvailability, nowIndex);
    }

    if (!availability.permanentlyAvailable && isOpenNow) {
        closingTime = getClosingTime(todayAvailability.hourlyAvailability, nowIndex);
        if (!closingTime) {
            if (!isAvailable(tomorrowAvailability.hourlyAvailability[0] || !tomorrowAvailability.available)) {
                closingTime = Strings("midnight_tonight");
                isMidnightTonight = true;
            } else {
                tomorrowClosingTime = getClosingTime(tomorrowAvailability.hourlyAvailability, 0);
                if (!tomorrowClosingTime) {
                    for (let i = 2; i < 7; i++) {
                        const day = now.clone().add({days: i});

                        const avail = getDayAvailability(day.day(), availability, now.day());

                        if(!avail.available){
                            const dayBefore = day.clone().add({days: -1});
                            otherDayClosing = {
                                day: dayBefore,
                                closingTime: Strings("midnight")
                            };
                            break;
                        }

                        const closingTime = getClosingTime(avail.hourlyAvailability, 0);
                        if (closingTime) {
                            otherDayClosing = {
                                day,
                                closingTime
                            };
                            break;
                        }
                    }
                }
            }
        }
    }

    const hoursUntilClose = getHoursUntilClose(nowIndex, todayAvailability, tomorrowAvailability);

    return {
        closingTime,
        openingTime,
        tomorrowClosingTime,
        otherDayClosing,
        isOpenNow,
        globalAvailability: availability.globalAvailability,
        permanentlyAvailable: availability.permanentlyAvailable,
        todayAvailability,
        tomorrowAvailability,
        availability,
        availText,
        isOpen24hours,
        hoursUntilClose,
        isMidnightTonight
    };
}

function getHoursUntilClose(nowIndex: number, todayAvailability: DayAvailabilityBoolsWithToday, tomorrowAvailability: DayAvailabilityBoolsWithToday) {
    //count up 30 minute segments from now until we find a false.  This function only looks to the end of the following day because it is used in conjunction
    //with the parking session reminders and they only go up to 24 hours

    if(!todayAvailability.available){
        return 0;
    }

    let thirtyMinSegments = 0;

    for(let i = nowIndex; i< 48;++i){
        if(todayAvailability.hourlyAvailability[i] === true){
            ++thirtyMinSegments;
        }
        else{
            return thirtyMinSegments/2;
        }
    }

    if(tomorrowAvailability.available) {
        for (let j = 0; j < 48; ++j) {
            if (tomorrowAvailability.hourlyAvailability[j] === true) {
                ++thirtyMinSegments;
            } else {
                return thirtyMinSegments/2;
            }
        }
    }

    return thirtyMinSegments/2;
}

export function getOpeningTime(hourlyAvailability: string | boolean[], startFromIndex: number): string | undefined {
    for(let i = startFromIndex; i < hourlyAvailability.length; i++) {
        if(hourlyAvailability[i] !== "t") {
            continue;
        }

        const isPm = Math.floor(i/2) >= 12;
        const apdx = isPm ? 'pm' : 'am';
        let hour = Math.floor(i/2);
        if (isPm) {
            hour = hour === 12 ? hour : hour - 12;
        }
        const minutes = (i % 2) === 1 ? "30" : "00";
        return `${hour}:${minutes}${apdx}`;
    }
    return;
}

export function getClosingTime(hourlyAvailability: string | boolean[], startFromIndex: number): string | undefined {
    for(let i = startFromIndex; i < hourlyAvailability.length; i++) {
        if(hourlyAvailability[i] === "t" || hourlyAvailability[i] === true) {
            continue;
        }

        const isPm = Math.floor(i/2) >= 12;
        const apdx = isPm ? 'pm' : 'am';
        let hour = Math.floor(i/2);
        if (isPm) {
            hour = hour === 12 ? hour : hour - 12;
        }
        const minutes = (i % 2) === 1 ? "30" : "00";
        return `${hour}:${minutes}${apdx}`;
    }
    return;
}

export function getParkAvailabilityInfo(park: ParkInfo2): ParkCurrentAvailability {
    const { numberOfLeasesAvailable, numberOfPublicSubscriptionBays,
            currentParksAvailable, currentLeasesAvailable, numberOfPublicDynamicBays} = park;
    const hasLongTerm = ((numberOfLeasesAvailable??0) + (numberOfPublicSubscriptionBays??0)) > 0;
    const hasShortTerm = ((currentParksAvailable??0) + (numberOfPublicDynamicBays??0)) > 0
    const hasEVCharger = (park.numberOfEVBays ?? 0) > 0; //todo
    const parkAvailability = getParkAvailability(park);
    const parkIsOpen = parkAvailability.isOpenNow;
    const parkLTFull = (currentLeasesAvailable??0) === 0;
    const parkSTFull = (currentParksAvailable??0) === 0;
    return {hasLongTerm, hasShortTerm, hasEVCharger, parkIsOpen, parkLTFull, parkSTFull};
}
