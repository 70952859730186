/**
 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 24/09/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import React from 'react';
import {StyleSheet, View} from 'react-native';

const SiteAvailabilityTileDayEmpty = () => {
    return (
        <View style={styles.dayOfWeekContainer}/>
    );
}

const styles = StyleSheet.create({
    dayOfWeekContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
});

export default SiteAvailabilityTileDayEmpty;