import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    AppState,
    AppStateStatus,
    Linking,
    NativeModules,
    Platform,
    StyleSheet,
    TouchableOpacity,
    View
} from "react-native";
import Button from "react/legacy/parkable-components/button/Button";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Icon from "react/legacy/parkable-components/icon/Icon";
import { IconName } from "react/legacy/parkable-components/icon/Icons";
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import Text from "react/legacy/parkable-components/text/Text";
import Strings from "../../util/localization/localization";
import BluetoothScannerKontakt, { Beacon } from "../common/BluetoothScannerKontakt";
import {
    AccessGate,
    AccessGateEx,
    AccessGateProximity,
    Orientation
} from "../../model/AccessGate";
import BleManager from "react-native-ble-manager";
import { openGate } from "../../redux/actions/parks";
import { setMuteErrAlert } from "../../redux/actions/userOptions";
import AccessControlModal from "./AccessControlModal";
import { MuteErrAlert } from "../../redux/reducers/userOptions";
import { getDistanceInMeters, getId, ILocation } from "../../util/Util";
import GPSLocationDialogView from "../map/parkableMapView/GPSLocationDialogView";
import { BluetoothState } from "../../model/Types";
import Constants, { AppStateChangeThrottleMillis } from "../../util/constants";
import moment from "moment";
import { useTerritory } from "../../api/territory/territory.api";
import { ParkDTO } from "../../model/ParkDTO";
import { useBeaconContext } from "./context";
import { useAccessGateOverridesForCurrentUser } from "../../api/accessGateOverride/accessGateOverride.api";
import { useParks } from "../../api/park/park.api";
import { Routes } from "../../navigation/root/root.paths";
import { useNavigation } from "../../navigation/constants";
import Dialog from "../dialog/Dialog";
import * as Device from "expo-device";
import * as Location from "expo-location";
import { serverLog } from "../../redux/actions/data";
import { useAppDispatch, useSelector } from "../../redux/redux";
import useConfig from "react/util/hooks/useConfig";
import { useAccessGatesInPark } from "../../api/accessGate/accessGate.api";
import { usePark } from "../../api/park";
import { throttle } from "lodash";
import { ParkSession } from "react/model/ParkSession";
import { useUserVehicles } from "react/api/vehicle/vehicle.api";

export enum ComponentType {
    // noinspection JSUnusedGlobalSymbols
    Carousel = "Carousel",
    TableRow = "TableRow",
    Invisible = "Invisible",
    Button = "Button"
}

export type AccessGateComponentPark = Pick<ParkDTO, 'id' | 'address' | 'management' | 'organisation' | 'owner'> & {territory: number | { id: number }};

type Props = {
    componentType: ComponentType,
    style?: {},
    parkId: number,
    buttonIcon?: IconName,
    buttonDisabled?: boolean,
    buttonText?: string,
    selectOnly?: boolean,
    onSelectGate?: (gateId?: string) => void,
    displayModalWhenNotInRange: boolean,
    centreButton?: boolean
    showEntranceGates?: boolean
    showExitGates?: boolean,
    subSession?: ParkSession | null,
    subscriptionId?: number,
    mutateEmployeeSubscription?: (id: number) => any
}

const getBeaconHash = (beacon: Pick<Beacon, "uuid" | "major" | "minor">) => `${beacon.uuid.toUpperCase()}_${beacon.major}_${beacon.minor}`;

const accessGateToBeacon = (gate: AccessGate) => ({uuid: gate.bluetoothId, major: gate.major ?? 0, minor: gate.minor ?? 0});

const getAccessGateHash = (gate: AccessGate) => gate.beaconId ? getBeaconHash(accessGateToBeacon(gate)) : gate.id;

const getAllowedGateProximityTypes = (gate: AccessGate): AccessGateProximity[] => {
    const result = [];
    if (gate.beaconId !== null) {
        result.push(AccessGateProximity.Bluetooth);
    }
    if (gate.limitDistanceToOpen !== null) {
        result.push(AccessGateProximity.GPS);
    }
    return result;
}

export default function AccessGateComponent(props: Props) {

    const {
        componentType,
        style,
        parkId,
        showEntranceGates,
        showExitGates,
        displayModalWhenNotInRange,
        selectOnly,
        onSelectGate,
        centreButton,
        buttonIcon,
        buttonText,
        buttonDisabled,
        subscriptionId,
        subSession,
        mutateEmployeeSubscription,
    } = props;

    const navigation = useNavigation();
    const dispatch = useAppDispatch();
    const config = useConfig();

    const {
        bluetooth: muteBluetoothAlert,
        location: muteLocationAlert,
        locationPermission: muteLocationPermissionAlert
    } = useSelector(state => state.userOptions.muteErrAlert);

    const { status: locationStatus, gpsLocation } = useSelector(state => state.geoLocation);

    const { gates: accessGates } = useAccessGatesInPark(parkId);
    const gates: AccessGateEx[] = useMemo(() => (accessGates ?? [])
        .filter((gate) =>
            !gate.orientation ||
            gate.orientation === Orientation.EntranceExit ||
            (!!showEntranceGates && gate.orientation === Orientation.Entrance) ||
            (!!showExitGates && gate.orientation === Orientation.Exit)
        )
        .map((gate: AccessGate) => ({
            ...gate,
            hash: getAccessGateHash(gate),
            allowedProximityTypes: getAllowedGateProximityTypes(gate),
        })), [accessGates, showEntranceGates, showExitGates]);

    const {beacons: detectedBeacons, setBeacons: setDetectedBeacons} = useBeaconContext();

    const [scanServicesErrorDialogIsVisible, setScanServicesErrorDialogIsVisible] = useState(false);
    const [bluetoothState, setBluetoothState] = useState('on' as BluetoothState);
    const [hasLocationPermission, setHasLocationPermission] = useState(true);
    const [servicesErrorLabel, setServicesErrorLabel] = useState("");
    const [servicesErrorDescription, setServicesErrorDescription] = useState("");
    const [servicesErrorOkButton, setServicesErrorOkButton] = useState(Strings("open_settings"));
    const [gateOpening, setGateOpening] = useState<string|undefined>(undefined);
    const [gateOpenResult, setGateOpenResult] = useState<boolean|undefined>(undefined);
    const [showOpenGateModal, setShowOpenGateModal] = useState(false);
    const [gateIsOpening, setGateIsOpening] = useState(false);

    const {accessGateOverrides} = useAccessGateOverridesForCurrentUser();
    const {parks: accessGateOverrideParks} = useParks(accessGateOverrides?.map(ago => ago.park));

    const {park} = usePark(parkId);
    const {territory} = useTerritory(getId(park?.territory));
    const { vehicles } = useUserVehicles();

    const bluetoothOffOrDenied = bluetoothState.match(/off|unauthorized/);
    const hasGatesWithGeofence = !!accessGates && accessGates.filter((ag) => ag.limitDistanceToOpen !== null).length > 0
    //BAC-10287 show location permissions/download app dialog only for android or ios, or for web app - only if gates have geofence enabled
    const showScanServicesErrorDialog = Platform.OS === "android" || Platform.OS === "ios" || (Platform.OS === "web" && hasGatesWithGeofence);

    const inRangeGPS = useMemo<Set<string /*gate hash*/>>(() => {
        if (gates.length > 0 && !!gpsLocation) {
            const gateHashes = new Set<string>(gates.filter(gate => gate.latitude && gate.longitude && gate.limitDistanceToOpen)
                .map(gate => ({gate, distance: getDistanceInMeters(gpsLocation, {latitude: gate.latitude!, longitude: gate.longitude!})}))
                .filter(({gate, distance}) => distance <= gate.limitDistanceToOpen!)
                .map(({gate}) => gate.hash));

            gates.forEach(gate => {
                const p = accessGateOverrideParks?.find(p => !!p.accessGates?.includes(gate.id));
                if (p) {
                    // gate may not have lat long set, fall back to park location
                    const gateLocation: ILocation = {
                        latitude: gate.latitude ?? p.latitude,
                        longitude: gate.longitude ?? p.longitude
                    }
                    const distance = getDistanceInMeters(gpsLocation, gateLocation);
                    const limitDistance = accessGateOverrides?.find(ago => ago.park === p.id)?.limitDistanceToOpen;
                    if (!!limitDistance && distance <= limitDistance) {
                        gateHashes.add(gate.hash);
                    }
                }
            });

            return gateHashes;
        } else {
            return new Set<string>();
        }
    }, [JSON.stringify(gpsLocation), gates, accessGateOverrideParks, accessGateOverrides]);

    const inRangeBeacons = useMemo(() => [...detectedBeacons]
        .filter(([, lastSeenAt]) => !!lastSeenAt && moment().diff(lastSeenAt, "seconds") <= Constants.bluetoothRangeToExpireSeconds)
        .map(([hash]) => hash), [detectedBeacons]);

    const inRangeGates = useMemo(() => gates.filter(gate => {
        if (gate.allowedProximityTypes.length === 0) {
            return true;
        }
        const hash = getAccessGateHash(gate);
        if (gate.allowedProximityTypes.includes(AccessGateProximity.GPS) && inRangeGPS.has(hash)) {
            return true;
        }
        if (gate.allowedProximityTypes.includes(AccessGateProximity.Bluetooth) && inRangeBeacons.includes(hash)) {
            return true;
        }
    }), [gates, inRangeBeacons, inRangeGPS]);

    const inRangeGateKeys = inRangeGates.map(getAccessGateHash);

    const anyGPSInRange = inRangeGPS.size > 0;

    const addInRangeBeacon = useCallback((beacon: Beacon) => {
        const beaconHash = getBeaconHash(beacon);
        const beaconIsForThisPark = gates.some(g => g.hash === beaconHash);
        if (beaconIsForThisPark) {
            setDetectedBeacons(new Map([...[...detectedBeacons].filter(([key,]) => key !== beaconHash), [beaconHash, moment()]]));
        }
    }, [detectedBeacons, gates]);

    const disableOpenButton = inRangeGates.length === 0 || vehicles?.length === 0 || !!subSession;

    const doOpenGate = useCallback((gateId: string) => {
        if(gateIsOpening){
            //do nothing if a gate is already opening
            return;
        }

        if(park) {
            setGateOpening(gateId);
            setGateOpenResult(undefined);
            setGateIsOpening(true);
            const proximity: AccessGateProximity[] = [];
            if (anyGPSInRange) {
                proximity.push(AccessGateProximity.GPS);
            }
            if (inRangeBeacons.length > 0) {
                proximity.push(AccessGateProximity.Bluetooth);
            }
            if ((accessGateOverrideParks ?? []).length > 0) {
                proximity.push(AccessGateProximity.AccessGateOverride);
            }
            if (gates.find(g => g.id === gateId)?.allowedProximityTypes.length == 0) {
                proximity.push(AccessGateProximity.AlwaysAllowed);
            }

            dispatch(openGate(park.id, gateId, proximity,(result: boolean) => {
                setGateIsOpening(false);
                setGateOpenResult(result);
                subscriptionId && mutateEmployeeSubscription?.(subscriptionId)
            }));
        }
    }, [park, gateIsOpening, anyGPSInRange, inRangeBeacons, accessGateOverrideParks]);

    const onOpenPress = useCallback((gateId: string | undefined) => {

        if (inRangeGates.length === 0 && !displayModalWhenNotInRange && !!onSelectGate) {
            onSelectGate(undefined);
            return;
        }

        let selectedGateId: string | undefined = gateId;

        if (!selectedGateId) {
            const gatesInParkCount = gates.length;
            if (gatesInParkCount === 1 && !!inRangeGates.length) {
                selectedGateId = gates[0].id;
            }
        }

        if (selectOnly) {
            onSelectGate?.(selectedGateId);
        } else {
            setShowOpenGateModal(true);
            setGateOpening(undefined);
            setGateOpenResult(undefined);
            setGateIsOpening(false);
            if (!!selectedGateId) {
                doOpenGate(selectedGateId);
            }
        }
    }, [inRangeGates, inRangeGPS, displayModalWhenNotInRange, onSelectGate, gates, selectOnly]);

    const openSettings = useCallback(async () => {
        try {
            if (Platform.OS === "ios") {
                await Linking.openSettings();
            } else if (bluetoothOffOrDenied) {
                await BleManager.enableBluetooth();
            } else if (!hasLocationPermission) {
                NativeModules.RNAndroidOpenSettings.appDetailsSettings();
            }
        } catch (e) {
            console.error(e);
        }
    }, [bluetoothOffOrDenied, hasLocationPermission]);

    useEffect(() => {
        console.log({ anyGPSInRange, bluetoothOffOrDenied, hasLocationPermission });
        if (anyGPSInRange) {
            setScanServicesErrorDialogIsVisible(false);
            return
        }
        if(bluetoothOffOrDenied || !hasLocationPermission){
            if (bluetoothOffOrDenied && !muteBluetoothAlert) {
                setServicesErrorLabel(Strings("bluetooth_off"));
                setServicesErrorDescription(Strings("bluetooth_required_for_access_gate"));
                setServicesErrorOkButton(Strings("open_settings"));
                dispatch(setMuteErrAlert({
                    bluetooth: true,
                    location: muteLocationAlert,
                    locationPermission: muteLocationPermissionAlert
                }));
                setScanServicesErrorDialogIsVisible(true);
            } else if (!hasLocationPermission && !muteLocationPermissionAlert) {
                setServicesErrorLabel(Platform.OS === "web" ? Strings("download_app_to_open_gate") : Strings("location_permissions_required"));
                setServicesErrorDescription(Platform.OS === "web" ? Strings("ios_or_android_for_location") : Strings("location_required_for_access_gate"));
                setServicesErrorOkButton(Platform.OS === "web" ? Strings("okay") : Strings("open_settings"));
                dispatch(setMuteErrAlert({
                    locationPermission: true,
                    bluetooth: muteBluetoothAlert,
                    location: muteLocationAlert
                }));
                if(showScanServicesErrorDialog) {
                    setScanServicesErrorDialogIsVisible(true);
                }
            }
        } else {
            setScanServicesErrorDialogIsVisible(false);
        }
    }, [
        anyGPSInRange,
        bluetoothOffOrDenied,
        hasLocationPermission,
        muteBluetoothAlert,
        muteLocationPermissionAlert,
        muteLocationAlert,
        setServicesErrorLabel,
        setServicesErrorDescription,
        setServicesErrorOkButton,
    ]);

    //CHECK PERMISSION TO USE USER LOCATION
    useEffect(() => {

        const subscription = AppState.addEventListener('change', onAppStateChange);

        checkLocationPermissions();

        return () => {
            subscription.remove();
            dispatch(setMuteErrAlert({bluetooth: false, locationPermission: false, location: false} as MuteErrAlert));
        }
    },[]);

    const checkLocationPermissions = useCallback(() => {
        Location.getForegroundPermissionsAsync()
            .then(({ granted }) => setHasLocationPermission(granted));
    }, []);

    const throttledAppStateChange = useCallback(throttle(() => {
        checkLocationPermissions();
    }, AppStateChangeThrottleMillis), []);

    const onAppStateChange = (nextAppState: AppStateStatus) => {

        if (nextAppState !== 'active') {
            return;
        }

        throttledAppStateChange();
    };

    const onProblemPress = useCallback(async () => {
        const supportPhone = territory?.supportPhone;
        if(!!supportPhone){
            let url;

            if (Platform.OS === 'android') {
                url = `tel:${territory?.phoneCode}${supportPhone}`;
            } else {
                url = `telprompt:${territory?.phoneCode}${supportPhone}`;
            }

            if (await Linking.canOpenURL(url)) {
                await Linking.openURL(url);
            }

        }else{
            navigation.push(Routes.Problem, {parkId});
            setShowOpenGateModal(false);
        }
    }, [parkId, territory]);

    const doServerLog = (log: string) => dispatch(serverLog(log, `${config.version}:${config.build}`))

    if (!gates.length) {
        return null;
    }

    return (
        <View>
            {componentType === ComponentType.Button && <Button center={centreButton}
                                                                     onPress={() => onOpenPress(undefined)}
                                                                     disabled={buttonDisabled||false}
                                                                     iconRight={buttonIcon}>{buttonText}</Button>}
            {componentType === ComponentType.Carousel && <TouchableOpacity activeOpacity={0.6} style={[styles.carouselStyle, style]} onPress={() => onOpenPress(undefined)}>
                <Icon color={disableOpenButton ? Colours.GREY_50 : Colours.NEUTRALS_BLACK} style={{width: "100%", flex:2}} iconStyle={{fontSize: 81,}} name={'carandbarrier'}/>
                <Text h3 style={{flex:1, color:disableOpenButton ? Colours.GREY_50 : Colours.NEUTRALS_BLACK}}>{Strings("open_gate")}</Text>
            </TouchableOpacity>}
            {componentType === ComponentType.TableRow && <TableRow contentRight={undefined} iconLeft="carandbarrier" buttonProps={{
                iconRight: "cheveronright",
                disabled: disableOpenButton,
                onPressIn: () => onOpenPress(undefined),
            }} buttonText={Strings("open")} >
                {Strings("open_gate")}
            </TableRow>}
            {Platform.OS !== "web" && Device.isDevice && (
                <BluetoothScannerKontakt key={"BluetoothScannerKontakt"}
                                         regions={gates.map(g => ({
                                             uuid: g.bluetoothId,
                                             major: g.major ?? undefined,
                                             minor: g.minor ?? undefined,
                                             identifier: g.name,
                                         })) ?? []}
                                         onBluetoothStateChange={setBluetoothState}
                                         addInRangeBeacon={addInRangeBeacon}
                                         locationStatus={locationStatus}
                                         log={doServerLog}
                />
            )}
            <Dialog
                isVisible={scanServicesErrorDialogIsVisible}
                onClose={() => setScanServicesErrorDialogIsVisible(false)}
                label={servicesErrorLabel}
                labelProps={{style: {color: Colours.NEUTRALS_BLACK, textAlign: 'left', borderBottomWidth: 1, borderBottomColor: Colours.GREY_10}}}
                title={servicesErrorDescription}
                titleProps={{h2: undefined, small:true, style: {textAlign: 'left', lineHeight: 24}}}
                positiveProps={{textProps: {style: {color: Colours.NEUTRALS_BLACK}, h5:true}, style: {backgroundColor: Colours.ORANGE}}}
                positiveText={servicesErrorOkButton}
                onPositivePress={Platform.OS !== "web" ? openSettings : undefined}
            />
            <AccessControlModal onProblemPress={onProblemPress}
                                territory={territory}
                                gateOpening={gateOpening}
                                gateOpenResult={gateOpenResult}
                                onPressGate={(gateId) => onOpenPress(gateId)}
                                inRangeGateKeys={inRangeGateKeys}
                                accessGates={gates}
                                isVisible={showOpenGateModal}
                                gateIsOpening={gateIsOpening}
                                setHidden={() => setShowOpenGateModal(false)}/>
            <GPSLocationDialogView />

        </View>

    );
}

const styles = StyleSheet.create({
    carouselStyle: {
        borderWidth: 1,
        borderColor: Colours.GREY_BORDER,
        paddingTop: 18,
        paddingLeft: 18,
        paddingRight: 18,
        alignItems:"center",
        justifyContent: "space-between"

    },
});
