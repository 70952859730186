import { useMySWR } from "../http/use-swr";
import {SupportCaseResponse, SupportCasesResponse} from "./dto/SupportCase.dto";
import {Nully} from "react/util/nully";
import { get, patch, post } from "react/api/http/rest";
import { CreateSupportCaseRequest } from "react/api/support/dto/CreateSupportCaseRequest";
import { UpdateSupportCaseRequest } from "react/api/support/dto/UpdateSupportCaseRequest";
import { UserOptionsDTOResponse } from "../../dto/userOptionsDTO";

export const useSupportCases = () => {
    return useMySWR<SupportCasesResponse>(`v1/salesforce/cases`, get, {refreshInterval:5000});
}

export const useSupportCase = (caseId: Nully<string>) => {
    return useMySWR<SupportCaseResponse>(caseId ? `v1/salesforce/case/${caseId}`: undefined);
}

export const useSupportAccount = () => useMySWR<UserOptionsDTOResponse>(`v1/salesforce/account`);

export const createCase = async (request: CreateSupportCaseRequest) => {
    return post<SupportCaseResponse>(
        `v1/salesforce/case`,
        request
    );
};

export const updateCase = async (caseId: string, request: UpdateSupportCaseRequest) => {
    return patch<SupportCaseResponse>(
        `v1/salesforce/case/${caseId}`,
        request
    );
};
