import Strings from "react/util/localization/localization";
import { VehicleDTO } from "react/api/vehicle/dto/vehicleDTO";

export const validateAndSanitiseRego = (rego: string, userVehicles?: VehicleDTO[]) => {
    const regex = /^[a-zA-Z\d\/\- ]+$/;
    const sanitisedRego = rego
        .replace(/[/-]|\s/g, "")
        .trim()
        .toUpperCase();
    if (!regex.test(rego)) {
        return { valid: false, message: Strings("oops_please_use_only_alphanumeric_characters") };
    } else if (userVehicles?.map((v) => v.registration).includes(sanitisedRego)) {
        return { valid: false, message: Strings("this_registration_is_already_added") };
    }
    return { valid: true, rego: sanitisedRego };
};
