/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Bruno on 04/02/19.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import getHourlyAvail from "./getHourlyAvail";
import {Availability, AvailabilityDTO} from "../../model/Availability";
import {HourlyAvailability} from "../../model/DayAvailability";

export default function get24HoursAvailable(availability: Availability | AvailabilityDTO, permanentlyAvailable: boolean): boolean {
    if (permanentlyAvailable) {
        return true;
    }

    return ![
        availability.sundayAvailability,
        availability.mondayAvailability,
        availability.tuesdayAvailability,
        availability.wednesdayAvailability,
        availability.thursdayAvailability,
        availability.fridayAvailability,
        availability.saturdayAvailability
    ]
        .map(getHourlyAvail)
        .map(availableFullDay)
        .includes(false);
}

export const availableFullDay = (hourly: HourlyAvailability): boolean => !hourly.includes(false);

