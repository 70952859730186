/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 18/10/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import { DayAvailability, fallbackDayAvailabilityBools } from "../../model/DayAvailability";
import { Availability, AvailabilityDTO } from "../../model/Availability";

export type DayOfWeek = "sunday" | "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday";

export type DayAvailabilityBoolsWithToday = DayAvailability & { todayDayOfWeek: number }

export default function getDayAvailability(day: DayOfWeek | number, availability: Availability | undefined, todayDayOfWeek: number): DayAvailabilityBoolsWithToday {
    return {
        ...getDayAvailabilityInternal(day, availability),
        todayDayOfWeek
    }
}

export function getDayAvailabilityInternal<T extends Availability | AvailabilityDTO>(
    day: DayOfWeek | number,
    availability: T | undefined
): T["fridayAvailability"] {

    if (!availability) {
        return fallbackDayAvailabilityBools;
    }

    switch (day) {
        case "sunday":
        case 0:
        case 7:
            return availability.sundayAvailability;
        case "monday":
        case 1:
            return availability.mondayAvailability;
        case "tuesday":
        case 2:
            return availability.tuesdayAvailability;
        case "wednesday":
        case 3:
            return availability.wednesdayAvailability;
        case "thursday":
        case 4:
            return availability.thursdayAvailability;
        case "friday":
        case 5:
            return availability.fridayAvailability;
        case "saturday":
        case 6:
            return availability.saturdayAvailability;
        default:
            return fallbackDayAvailabilityBools;
    }
}

