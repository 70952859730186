import React, { useContext, useEffect } from "react";
import { ChatContext, ProblemActionContext } from "../Context";
import { AllProblemViewProps } from "../../../views/support/ProblemView";
import { Routes } from "react/navigation/root/root.paths";
import { useNavigation } from "react/navigation/constants";

export const MessageParkable = (props: AllProblemViewProps) => {

    const { user } = props;
    const {sessionId, subscription, bayId} = props.route.params;
    const navigation = useNavigation();

    const {messages} = useContext(ChatContext);
    const {problemActions, updateProblemActions } = useContext(ProblemActionContext);

    useEffect(()=> {
        problemActions["onMessageParkablePress"] = onMessageParkablePress;
        updateProblemActions(problemActions);
    }, [messages])

    const onMessageParkablePress = () => {
        if(user.user){
            navigation.navigate(Routes.AddNewCaseView, { sessionId: sessionId, subscriptionId: subscription?.id });
        }
    };

    return <></>
}
