import React from 'react';
import {FlatList, StyleSheet, View} from 'react-native';

type CarouselProps = {
    style: {};
    renderItem: any;
    data?: any;
}

const Carousel = (props: CarouselProps) => {
    let list: any = null;

    if (!props.data) {
        return null
    }
    return (
        <View style={[styles.carousel]}>

            <FlatList
                ref={(ref) => {
                    list = ref
                }}
                style={props.style}
                keyExtractor={(item, index) => `${index}`}
                renderItem={props.renderItem}
                data={props.data}
                pagingEnabled={false}
                horizontal={true}
                endFillColor={'transparent'}
                // @ts-ignore
                backfaceVisibility={'hidden'}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                overScrollMode={'always'}
                directionalLockEnabled={false}
                initialNumToRender={5}
                bounces={true}
            />
        </View>
    );
}

export default Carousel;

const styles = StyleSheet.create({
    carousel: {
        flex: 1,
    },
});