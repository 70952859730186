import { Nully } from "react/util/nully";
import { ParkingActivity } from "react/model/Types";
import { generateQueryString } from "../http/rest";
import { useMySWR } from "../http/use-swr";
import { BayDTO } from "react/model/BayDTO";
import { Feature } from "react/model/Types";

export const useSharingPoolBaysForUser = (
    orgId: Nully<number>,
    parkId: Nully<number>,
    options?: {
        requestDate?: string,
        feature: Nully<Feature>;
        parkingType?: ParkingActivity;
    }
) => {
    const queryString = generateQueryString(options ?? {});
    const response = useMySWR<{ bays: BayDTO[] }>(
        orgId && parkId ? `/organisations/${orgId}/parks/${parkId}/sharing-pool/user-bays${queryString}` : null
    );

    return {
        ...response,
        bays: response.bays,
    };
};
