import * as Type from '../actions/userOptions';
import {Park} from "../../model/Park";
import {UserSearch} from "../../model/UserSearch";
import {UserOptions} from "../../model/UserOptions";
import { USER_LOGGED_OUT } from '../actions/user';

export type Favourites = {[text:string]: Park}
export type MuteErrAlert = {
    bluetooth: boolean,
    location: boolean,
    locationPermission: boolean
}

const init = {
    userOptions: null as UserOptions | null,
    favouriteParks: null as Favourites | null,
    favouriteParksByChoice: null as Favourites | null,
    userSearches: [] as UserSearch[],
    showSaveFavouriteModal: false,
    showDeleteFavouriteModal: false,
    muteErrAlert: {bluetooth: false, location: false, locationPermission: false} as MuteErrAlert
};

export type IUserOptions = typeof init;

export default function userOptionsReducer(state = init, action: any): IUserOptions {
    switch (action.type) {
        case Type.RECEIVED_USER_OPTIONS:
            return {
                ...state,
                userOptions: action.userOptions,
            };
        case Type.RECEIVED_FAVOURITE_PARKS:
            return {
                ...state,
                favouriteParks: action.favouriteParks,
                favouriteParksByChoice: action.favouriteParksByChoice
            };
        case Type.SHOW_SAVE_FAVOURITE_MODAL:
            return {
                ...state,
                showSaveFavouriteModal: action.isShowing
            };
        case Type.SHOW_DELETE_FAVOURITE_MODAL:
            return {
                ...state,
                showDeleteFavouriteModal: action.isShowing
            };
        case Type.SET_MUTE_ERR_ALERT: 
            return {
                ...state,
                muteErrAlert: action.muteErrAlert
            };
        case USER_LOGGED_OUT:
            return {
                ...init
            }
        default:
            return state;
    }
}