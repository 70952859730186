import { get, post, put } from "./rest";
import { EmployeeSubscriptionStatus, Term } from "../model/Types";
import { Token } from "./rest";
import { useMySWR } from "./http/use-swr";
import { LastInvoiceAmountResponse } from "../dto/LastInvoiceAmountResponse";
import { EmployeeSubscriptionDTOsResponse } from "../dto/EmployeeSubscriptionDTOsResponse";
import { EmployeeSubscriptionDTOResponse } from "../dto/EmployeeSubscriptionDTOResponse";

export const createPublicSubscriptionAPI = (api: string, token: Token, data: { parkId: number, bayId: number, term: Term, start: string | undefined, vehicleIds: number[] }) =>
    post(token, `${api}v3/employeesubscriptions`, data) as Promise<EmployeeSubscriptionDTOResponse>;

export function acceptEmployeeSubscription(api: string, token: Token, id: number) {
    return updateEmployeeSubscription(api, token, id, 'Active') as Promise<EmployeeSubscriptionDTOResponse>;
}

export function retryEmployeeSubscriptionPaymentAPI(api: string, token: Token, employeeSubscriptionId: number) {
    return post(token, `${api}v3/employeesubscriptions/${employeeSubscriptionId}/retryPayment`) as Promise<EmployeeSubscriptionDTOResponse>;
}

export function getEmployeeSubscriptionAPI(api: string, token: Token, id: number) {
    return get(token, `${api}v2/employeesubscriptions/${id}`);
}

export function updateEmployeeSubscriptionAvailability(api: string, token: Token, id: number, days: any) {
    return put(token, `${api}v3/employeesubscriptions/${id}/updateBayAvailable`, {days}) as Promise<EmployeeSubscriptionDTOResponse>;
}

export const cancelEmployeeSubscription = (api: string, token: Token, id: number, atTermEnd: boolean, leaveReason?: string) =>
    updateEmployeeSubscription(api, token, id, atTermEnd ? 'LeaserEnding' : "Deleted", leaveReason) as Promise<EmployeeSubscriptionDTOResponse>;

export const updateEmployeeSubscription = (api: string, token: Token, id: number, status: EmployeeSubscriptionStatus, leaveReason?: string) =>
    put(token, `${api}v3/employeesubscriptions/${id}`, {status, leaveReason}) as Promise<EmployeeSubscriptionDTOResponse>;

export function getEmployeeSubscriptionsByUserAPI(api: string, token: Token, onlyActive: boolean) {
    return get(token, `${api}v3/employeesubscriptions?onlyActive=${onlyActive}`) as Promise<EmployeeSubscriptionDTOsResponse>
}

export const getSessionInSubscriptionBayAPI = (url: string, token: Token, subscriptionId: number) => get(token, `${url}v2/employeesubscriptions/${subscriptionId}/session`);

export const useLastTermAmount = (subscriptionId: number) => {
    const url = `v3/employeesubscriptions/calculateLastTermAmount/${subscriptionId}`;
    const {data, ...rest} = useMySWR<LastInvoiceAmountResponse>(url);
    return {amount: data?.amount, ...rest};
};
