import { BayGroup } from "../../model/BayGroup";
import { Feature } from "../../model/Bay";
import _ from "lodash";
import { BayDTO } from "../../model/BayDTO";
import { ParkDTO } from "../../model/ParkDTO";
import { ParkSessionDTO } from "../../model/ParkSessionDTO";
import { BayGroupType } from "../../model/BayGroupType";

export interface AvailableBays{
    sedanCasualAvailable: number,
    sedanLongTermAvailable: number,

    motorbikeCasualAvailable: number,
    motorbikeLongTermAvailable: number,

    electricAvailable: number,

    sedanCasualBays: BayDTO[]
    sedanLongTermBays: BayDTO[]

    motorbikeCasualBays: BayDTO[]
    motorbikeLongTermBays: BayDTO[]

    electricBays: BayDTO[],
    classicLeasePerWeek: number|null,
    classicLeasePerMonth: number|null
}

/*
Separates bays into the different groups and orders by price
 */
export function calculateBaysAvailable(
    isPublicParker: boolean,
    park: ParkDTO,
    bayGroups: BayGroup[],
    ownerBayGroups: BayGroup[],
    parkBays: BayDTO[],
    sharingPoolBays: BayDTO[],
    parkSession?: ParkSessionDTO | null
): AvailableBays {
    if (parkSession) {
        parkBays = parkBays.filter(b => b.id !== parkSession.bay)
    }

    const baysAvailable: AvailableBays = {
        sedanCasualAvailable: park.currentParksAvailable,
        sedanLongTermAvailable: park.currentLeasesAvailable,

        motorbikeCasualAvailable: 0,
        motorbikeLongTermAvailable: 0,

        electricAvailable: 0,

        sedanCasualBays: [],
        sedanLongTermBays: [],

        motorbikeCasualBays: [],
        motorbikeLongTermBays: [],

        electricBays: [],

        classicLeasePerWeek: null,
        classicLeasePerMonth: null
    };

    if (!!park.organisation) {

        const publicSubGroupIds = new Set((bayGroups || [])
            .filter(bg => bg.groupType === BayGroupType.PublicSubscription)
            .map(bg => bg.id));

        const publicDynamicGroupIds = new Set((ownerBayGroups || [])
            .filter(bg => bg.groupType === BayGroupType.PublicDynamic)
            .map(bg => bg.id));

        //      const userBays = (parkBays || []).filter(b => (!isPublicParker || !groupTypeIsPublic(toBayGroupType(b.type))));
        //remove PublicSubscription bay in dynamicBays (if users ever parked in this group before, they become members of that group)
        const regularDynamicBays = parkBays.filter(b =>
          (!isPublicParker && !publicSubGroupIds.has(b.group ?? -1))
          || publicDynamicGroupIds.has(b.group ?? -1)
          || !!b.subscription);

        const dynamicBays = [...regularDynamicBays, ...sharingPoolBays];

        const subBays = parkBays.filter(b => publicSubGroupIds.has(b.group ?? -1) && !b.subscription);

        const motorbikeCasualBays = dynamicBays.filter(b => _.includes(b.features, Feature.MOTORBIKE));
        //BAC-8245 - removed EV bays from showing as casual
        const sedanCasualBays = dynamicBays.filter(b => _.includes(b.features, Feature.SEDAN) );

        const motorbikeLongTermBays = subBays.filter(b => _.includes(b.features, Feature.MOTORBIKE));
        const sedanLongTermBays = subBays.filter(b => _.includes(b.features, Feature.SEDAN));

        const electricBays = dynamicBays.filter(b => _.includes(b.features, Feature.ELECTRIC));

        baysAvailable.sedanCasualAvailable = sedanCasualBays.length;
        baysAvailable.sedanLongTermAvailable = sedanLongTermBays.length;

        baysAvailable.motorbikeCasualAvailable = motorbikeCasualBays.length;
        baysAvailable.motorbikeLongTermAvailable = motorbikeLongTermBays.length;

        baysAvailable.electricAvailable = electricBays.length;

        baysAvailable.sedanCasualBays = sedanCasualBays;
        baysAvailable.sedanLongTermBays = sedanLongTermBays;

        baysAvailable.motorbikeCasualBays = motorbikeCasualBays;
        baysAvailable.motorbikeLongTermBays = motorbikeLongTermBays;

        baysAvailable.electricBays = electricBays;
    }

    return baysAvailable;
}
