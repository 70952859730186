import { Platform } from "react-native";
import * as Keychain from "react-native-keychain";
import * as FileSystem from "expo-file-system";

let getRefreshTokenAndroid = (s: string) => Promise.resolve(null);
if (process.env.PLATFORM !== 'web') {
    getRefreshTokenAndroid = require("@parkable/react-native-firebase-refresh-token").getRefreshTokenAndroid;
}

export async function getLegacyRefreshToken() {
    try {
        if (Platform.OS === "android") {
            return await getAndroidRefreshToken();
        }
    } catch (e) {
        console.error(e);
    }
}

export async function removeLegacyRefreshToken(firebaseAppId: string) {
    if(Platform.OS === "ios") {
        await removeLegacyRefreshTokenIOS(firebaseAppId);
    }
    else if(Platform.OS === "android") {
        await removeLegacyRefreshTokenAndroid();
    }
}

export async function removeLegacyRefreshTokenIOS(firebaseAppId: string) {
    try {
        const services = await Keychain.getAllGenericPasswordServices();
        const removePromises = services
          .filter(value => value.includes(firebaseAppId))
          .map(service => Keychain.resetGenericPassword({ service }));
        await Promise.all(removePromises);
    } catch (e) {
        console.error(e);
    }
}

const getAndroidRefreshToken = async () => {
    let dir = await FileSystem.readDirectoryAsync(FileSystem.documentDirectory!);

    for (const f in dir) {
        const uri = FileSystem.documentDirectory + dir[f];

        if (uri.indexOf("PersistedInstallation") > 0) {
            const parts = dir[f].split(".");
            try {
                const fileName = "com.google.firebase.auth.api.Store." + parts[1];
                console.log("fileName ", fileName);
                const refreshToken = await getRefreshTokenAndroid(fileName) as string | null;
                if (refreshToken !== null) {
                    console.log("MD refreshToken ", refreshToken);
                    return refreshToken;
                }
            } catch (e) {
                //any errors and we wil just bail. user can log in with pwd
                console.log(e);
            }
        }
    }
    return null;
}

const removeLegacyRefreshTokenAndroid = async () => {
    let dir = await FileSystem.readDirectoryAsync(FileSystem.documentDirectory!);

    for (const f in dir) {
        const uri = FileSystem.documentDirectory + dir[f];

        if (uri.indexOf("PersistedInstallation") > 0) {
            try {
                await FileSystem.deleteAsync(uri);
            } catch (e) {
                //do nothing
            }
        }
    }
}
