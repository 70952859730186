import * as Type from '../actions/parks'
import * as UserType from '../actions/user';
import _ from 'lodash'
import {Park} from "../../model/Park";
import {ParkInfo} from "../../model/ParkInfo";
import {BayGroup} from "../../model/BayGroup";
import {Bay} from "../../model/Bay";
import {BayDTO} from "../../model/BayDTO";
import {ParkDTOWithTerritory} from "../actions/parks";
import {ParkAvailabilityNotification} from "../../model/ParkAvailabilityNotification";
import { MAP_PARKS_RECEIVED, SET_PARK, SET_USER_BAYS } from "../actions/types";

type IHostedParks  = {[key:number]: Park};

export interface IMapPark extends ParkInfo {
    time: number,
}

const init = {
    parks: {} as {[parkId:number]: ParkDTOWithTerritory},
    bays: {} as { [parkId:number]: {[bayId:number]: Bay} },
    userBays: {} as {[parkId:number]: {[bayId: number]: BayDTO} },
    parkBays: {} as {[parkId:number]: any },
    hostIds: {} as {[parkId: number]: number},
    hostedParks: null as IHostedParks | null,
    recentlyParkedIn: [] as Park[],
    publicParker: {} as {[parkId:number]: boolean},
    mapParks: {} as {[key:number]: IMapPark},
    bayGroups: {} as {[key:number]: BayGroup[]},
    availabilityNotifications: {} as {[parkId:number]: ParkAvailabilityNotification},
};

export type IParks = typeof init;

export default function dataReducer(state = init, action: any): IParks {
    const {parkId, bay} = action;

    switch (action.type) {

        case SET_PARK:
            return {
                ...state,
                parks: {
                    ...state.parks,
                    [action.park.id]: action.park,
                }
            };

        case UserType.USER_LOGGED_OUT: {
            return init;
        }

        case Type.SET_HOSTED_PARKS: {
            const hostedParks: IHostedParks = {...state.hostedParks};

            action.parks.forEach((park: Park) => {
                hostedParks[park.id] = park;
            });

            return {
                ...state,
                hostedParks,
            };
        }
        case Type.SET_HOST_PARK: {
            const park = action.park;
            const hostedParks: IHostedParks = {...state.hostedParks};
            hostedParks[park.id] = park;

            return {
                ...state,
                hostedParks,
            };
        }
        case Type.SET_BAY:
            return {
                ...state,
                bays: {
                    ...state.bays,
                    [parkId]: {
                        ...state.bays[parkId],
                        [bay.id]: bay,
                    },
                }
            };

        case Type.SET_PARK_BAYS:
            return {
                ...state,
                parkBays: {
                    ...state.parkBays,
                    [parkId]: action.parkBays,
                }
            };

        case SET_USER_BAYS:
            const userBays = {} as {[bayId:number]: BayDTO};

            action.bays.map((bay: BayDTO) => {
                userBays[bay.id] = bay;
            });

            return {
                ...state,
                userBays: {
                    ...state.userBays,
                    [parkId]: {
                        ...userBays,
                    },
                }
            };

        case Type.SET_BAYS:
            return {
                ...state,
                bays: action.bays,
            };

        case Type.SET_HOST_ID:
            return {
                ...state,
                hostIds: {
                    ...state.hostIds,
                    [action.parkId]: action.hostId
                }
            };

        case Type.SET_PARK_BAY_GROUPS:

            const bayGroups = _.clone(state.bayGroups || {});
            bayGroups[action.parkId] = action.bayGroups;

            return {
                ...state,
                bayGroups
            };

        case Type.PARK_DELETED: {

            const hostedParks = {...state.hostedParks} as IHostedParks;
            delete hostedParks[action.parkId];

            return {
                ...state,
                hostedParks,
            };
        }

        case MAP_PARKS_RECEIVED: {

            const mapParks = {...state.mapParks};
            const now = new Date().getTime();
            Object.values(state.mapParks)
                .filter(p => (now - p.time) > (action.expirySeconds * 1000))
                .forEach(p => delete mapParks[p.parkId]);

            action.parks.map((p: IMapPark) => ({
                ...p,
                time: now
            }))
                .forEach((p: IMapPark) => mapParks[p.parkId] = p);

            return {
                ...state,
                mapParks,
            };
        }

        case Type.RECENTLY_PARKED_IN: {
            return {
                ...state,
                recentlyParkedIn: action.parks
            }
        }

        case Type.IS_PUBLIC_PARKER: {
            return {
                ...state,
                publicParker: Object.assign(_.clone(state.publicParker), {[action.parkId]: action.publicParker})
            }
        }

        case Type.ADD_PARK_AVAILABILITY_NOTIFICATION: {
            return {
                ...state,
                availabilityNotifications: Object.assign(_.clone(state.availabilityNotifications), {[action.parkId]: action.notification})
            }
        }
        case Type.ADD_PARK_AVAILABILITY_NOTIFICATIONS: {

            // console.log("action.notifications ", action.notifications);
            return {
                ...state,
                availabilityNotifications: action.notifications
            }
        }
        case Type.REMOVE_PARK_AVAILABILITY_NOTIFICATION: {

            const newOnes = Object.assign(_.clone(state.availabilityNotifications));
            delete newOnes[action.parkId]
            return {
                ...state,
                availabilityNotifications: newOnes
            }
        }

        default:
            return state
    }
}
