import fetch from "../reducers/fetch";
import * as Api from "../../api/data";
import {DispatchFunc} from "../../model/Types";
import {IRootReducer} from "../reducers/main";
import {getPushNotificationToken} from "../../util/services/pushNotification.service";
import { logEvent } from "react/util/analytics";
import {Token} from "../../api/rest";
import { FirebaseService } from "../../util/services/firebase.service";

export const SET_USER_ID = 'SET_USER_ID';
export const SET_KEY_LOCATIONS = 'SET_KEY_LOCATIONS';
export const SET_API_URL = 'SET_API_URL';
export const SET_SAML_IDENTITY_PROVIDER = "SET_SAML_IDENTITY_PROVIDER";

export function createUser(email: string, password: string|undefined, firstName: string, lastName: string,
                           phone: string|undefined, countryCode: string, samlSignup: boolean, samlToken:string|undefined ) {
    return fetch(async (dispatch: DispatchFunc, api: string) => {
        try {
            const pushToken = await getPushNotificationToken();
            console.log({pushToken});
            const {user, firebaseCustomToken} = await Api.createUserAPI(api, email, password, firstName,
                lastName, phone, countryCode, pushToken, samlSignup, samlToken);

            logEvent(
                undefined,
                'fb_mobile_complete_registration',
                {
                    fb_registration_method: 'email',
                    userId: user.id,
                    phone: user.phone ?? '',
                    email,
                }
            );

            await FirebaseService.signInWithCustomToken(firebaseCustomToken);

            return user;
        } catch (err) {
            console.log("CREATE USER ERROR", err);
            throw err;
        };
    }, arguments, true)
}

export function setSamlIdentityProvider(idp: string) {
    return {
        type: SET_SAML_IDENTITY_PROVIDER,
        identityProvider: idp
    }

}

export function serverLog(log: string, version: string) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token, getState: () => IRootReducer) => {
        const state = getState();
        const enableApplicationLog = state.userOptions?.userOptions?.clientFlags?.["ENABLE_APPLICATION_LOG"];

        console.log(`SERVER_LOG {sending=${!!enableApplicationLog}; version="${version}"}: ${log}`);

        if(!enableApplicationLog){
            return Promise.resolve();
        }

        return Api.serverLog(token, api, log, version).then(() => {
            return Promise.resolve();
        }).catch((err) => {
            if (err === 'network_error') {
                throw err;
            }
        });
    }, arguments);
}

export function getKeyLocations(coordinates?: {lat: number, lng: number}) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getKeyLocations(api, token, coordinates).then((locations) => {
            dispatch(setKeyLocations(locations));
        });
    }, arguments);
}

export function setKeyLocations(locations: any) {
    return {
        type: SET_KEY_LOCATIONS,
        locations: locations.keyLocations
    }
}

export function setUserId(userId: number | null) {
    return {
        type: SET_USER_ID,
        userId: userId,
    };
}

export function setAPIUrl(api: string, name: string, service?: string) {
    return {
        type: SET_API_URL,
        api,
        name,
        service,
    };
}
