import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import React, { FC } from "react";
import Strings from "../../../util/localization/localization";
import { Nully } from "../../../util/nully";
import { Routes } from "react/navigation/root/root.paths";
import { useNavigation } from "../../../navigation/constants";
import {VehicleDTO} from "../../../api/vehicle/dto/vehicleDTO";

export type VehicleRowProps = {
	selectedVehicle: VehicleDTO | undefined;
	isLoading?: boolean;
	onChange: (vehicleId: Nully<number>) => void;
	showVehicleModificationButtons?: boolean
}

const VehicleRow = (props: VehicleRowProps) => {
    const navigation = useNavigation();

	const {onChange, selectedVehicle, isLoading} = props

	const handleChangePress = () => {
		navigation.push(Routes.VehiclesView, {
			onVehicleSelect: onChange,
			showVehicleModificationButtons: false,
			selectedVehicleId: selectedVehicle?.id
		})
	}

	const isChangeable = onChange != null && !isLoading
	const vehicleRego = selectedVehicle?.registration && !isLoading ? selectedVehicle.registration : Strings("loading")

	return <TableRow iconLeft={"car"}
		label={Strings("vehicle")}
		textProps={{ rego: true }}
		chevron={isChangeable}
		onPress={isChangeable ? handleChangePress : undefined}
	>
		{vehicleRego}
	</TableRow>
}

export default VehicleRow as FC<VehicleRowProps>;
