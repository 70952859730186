import { View } from "react-native";
import React from "react";

type AllProps = {
    colour: string;
    backgroundColour: string;
    marginTop?: number;
};

export default function Dashes(props: AllProps) {
    const {colour, backgroundColour, marginTop} = props;
    // These views are to draw the dotted line - dashes dont work if you just set
    // one border side so we draw four borders and then draw over the top, left and right ones...*sigh*
    return (
        <View
            style={{
                height: 1,
                backgroundColor: backgroundColour,
                borderWidth: 1,
                borderColor: colour,
                borderRadius: 1,
                borderStyle: "dashed",
                marginTop: marginTop,
            }}
        >
            <View
                style={{
                    position: "absolute",
                    left: -1,
                    top: -1,
                    width: "100%",
                    height: 1,
                    backgroundColor: backgroundColour,
                    zIndex: 1,
                }}
            />
            <View
                style={{
                    position: "absolute",
                    left: -1,
                    top: -1,
                    width: 1,
                    height: "100%",
                    backgroundColor: backgroundColour,
                    zIndex: 1,
                }}
            />
            <View
                style={{
                    position: "absolute",
                    right: -1,
                    top: -1,
                    width: 1,
                    height: "100%",
                    backgroundColor: backgroundColour,
                    zIndex: 1,
                }}
            />
        </View>
    );
}
