/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 25/08/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import {connect as reduxConnect, MapStateToPropsParam} from "react-redux";
import {IRootReducer} from "../redux/reducers/main";

export default function connect(connectFunc: (state: IRootReducer, ownProps: any, defaultRootState: any) => any) {
    return reduxConnect(((state: IRootReducer, props: any, defaultRootState: any): any => {
        let newProps = {...props};
        if (!!props.navigation && !!props.navigation.state && props.navigation.state.params) {

            newProps = {
                ...newProps,
                ...props.navigation.state.params,
            };
            delete newProps.api;
            delete newProps.token;
            delete newProps.user;
            delete newProps.userId;
        }

        if (!!connectFunc) {
            newProps = {
                ...newProps,
                ...connectFunc(state, newProps, defaultRootState),
            };
        }

        return {
            ...newProps,
        };
    }) as MapStateToPropsParam<IRootReducer, any, any>);
}