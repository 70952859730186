import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Text from "react/legacy/parkable-components/text/Text";
import InputWrapper from "react/legacy/parkable-components/inputWrapper/InputWrapper";
import Input from "react/legacy/parkable-components/input/Input";
import { DialogRef } from "react/legacy/parkable-components/dialog/Dialog";
import Colours from "react/legacy/parkable-components/styles/Colours";
import CheckBox from "react/legacy/parkable-components/checkBox/CheckBox";
import Button from "react/legacy/parkable-components/button/Button";
import Dialog from "react/components/dialog/Dialog";
import {
    Keyboard,
    KeyboardEvent,
    LayoutChangeEvent,
    Linking,
    ScrollView,
    StyleSheet,
    TextInput,
    TouchableOpacity,
    View,
} from "react-native";
import { connect } from "react-redux";
import { IRootReducer } from "../../redux/reducers/main";
import { createUser, setUserId } from "../../redux/actions/data";
import validatePhoneNumber from "../../util/validatePhoneNumber";
import Strings from "../../util/localization/localization";
import CountryPicker, {
    CallingCode,
    Country,
    CountryCode
} from "react-native-country-picker-modal";
import { createRoute, NavigationProps, useNavigation } from "../../navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import { setCreateUserData, setUser } from "../../redux/actions/user";
import {useAppDispatch, useReduxCredentials, useSelector} from "../../redux/redux";
import { useLogout } from "../../root/auth/logoutProvider";
import ParkableBaseView from "../../components/common/ParkableBaseView";
import {useCountries} from "react/util/Util";
import {screenWidth} from "../../util/constants";
import {clearCredentials} from "../../redux/actions/credential";
import { useSecureOrganisationOptions } from "react/api/organisationOptions/organisationOptions.api";

class CreateAccountParams {
    samlToken?: string;
    firstName?: string;
    lastName?: string;
    organisationId?: number;
    usingSSO?: boolean;
    logoutOnBack?: boolean;
}

const CreateAccountView = (props: ReturnType<typeof getReduxProps> & typeof actions & NavigationProps<Routes.CreateAccountView>) => {
    const { createUser } = props;

    const { createUserData } = useSelector((state: IRootReducer) => state.user);

    const {
        usingSSO,
        firstName,
        lastName,
        samlToken,
        logoutOnBack,
    } = props.route.params ?? {};

    const {email: emailRedux, password} = useReduxCredentials();

    const email = createUserData?.email ?? emailRedux;

    const navigation = useNavigation();
    const dispatch = useAppDispatch();
    const logout = useLogout();
    const user = useSelector((state: IRootReducer) => state.user.user)

    const [page, setPage] = useState(usingSSO ? 1 : 0);
    const [loading, setLoading] = useState(false);
    const { data: orgOptions, isLoading: loadingOrgOptions } = useSecureOrganisationOptions(email);

    const [part1, setPart1] = useState<Part1 | Partial<Part1>>({ email, password, firstName, lastName, usingSSO });
    const [failMessage, setFailMessage] = useState<string>();

    useEffect(() => {
        setPart1((prev) => ({
            ...prev,
            showTcsAndCs: orgOptions && orgOptions.length > 0 ? orgOptions.some(opt => opt.showMembersTcsAndCs) : true
        }));
    }, [orgOptions]);

    const dialogRef = useRef<DialogRef>(null);

    useEffect(() => {
        page < 0 && navigation.pop()
    }, [page])

    const onAcknowledgeError = () => {
        navigation.pop();
    }

    useEffect(() => {
        if (user) {
            navigation.reset({
                routes: [{
                    name: Routes.ParkableMapView,
                }],
            })
        }
    }, [user])

    const onSignUp = async (newPart1: Part1) => {

        const data = {
            ...part1,
            firstName: newPart1.firstName,
            lastName: newPart1.lastName,
            countryCode: newPart1.countryCode
        }

        setPart1(data);
        setLoading(true);

        try {
            const user = await createUser(
              data!.email!,
              data!.password,
              data.firstName,
              data.lastName,
              undefined,
              data.countryCode,
              !!usingSSO,
              samlToken
            );

            if(usingSSO){
                //onAuthStateChanged event wont be triggered as firebase already logged in for SSO users
                dispatch(setUser(user));
                dispatch(setUserId(user.id));
            }
            dispatch(clearCredentials());
            dispatch(setCreateUserData(undefined));
        } catch (error) {
            setLoading(false)
            setFailMessage((error as any)?.message || Strings("try_again"))
            dialogRef.current?.show();
        }
    }

    const doLogout = async () => {
        dispatch(setCreateUserData(undefined));
        await logout();

        navigation.reset({ routes: [{
                name: Routes.LandingView
            }]});
    }


    return <ParkableBaseView scrollable={false} loading={loading || loadingOrgOptions} backButtonOverride={logoutOnBack ? doLogout : () => setPage(page - 1)}>
        <ScrollView
          style={{ flex: 1 }}
          keyboardShouldPersistTaps="handled">
            <View style={styles.body}>
                <Text allowFontScaling={false} style={styles.welcome}>{Strings("sign_up")}</Text>
                {usingSSO && <Text>{Strings("signed_on_need_a_few_more_details")}</Text>}
                <Page1 part1={part1} onSignUp={(part1: Part1) => onSignUp(part1)} />
            </View>
        </ScrollView>
        <Dialog ref={dialogRef} label={Strings("failed_to_signup")} labelProps={{ red: true }} title={failMessage} positiveText={Strings("ok")} onPositivePress={onAcknowledgeError} />
    </ParkableBaseView>
}

type GeoLocation = {
    ip: string,
    hostname: string,
    city: string,
    region: string,
    country: string,
    loc: string,
    org: string,
    postal: string,
    timezone: string,
    readme: string
};

const useGeoLocation = () => {
    const [geoLocation, setGeoLocation] = useState<GeoLocation | undefined>();
    useEffect(() => {
        fetch("https://ipinfo.io")
          .then(value => value.json())
          .then(value => setGeoLocation(value))
    }, []);
    return geoLocation;
}

const useCallingCode = (allCountries?: Country[], countryCode?: CountryCode) => {
    const [callingCode, setCallingCode] = useState<CallingCode | undefined>()

    useEffect(() => {
        if (!allCountries || !countryCode) {
            return;
        }
        const country = allCountries.find(value => value.cca2 === countryCode);
        if (!country) {
            return;
        }
        setCallingCode(country.callingCode[0]);
    }, [allCountries, countryCode])

    return callingCode
}

interface Page1Props {
    onSignUp: any,
    part1: Part1 | Partial<Part1>,
}

const Page1 = ({ onSignUp, part1 }: Page1Props) => {

    const [firstName, setFirstName] = useState<string>(part1?.firstName ?? '');
    const [lastName, setLastName] = useState<string>(part1?.lastName ?? '');
    const [keyboardHeight, setKeyboardHeight] = useState<number>(0)
    const [bottomAt, setBottomAt] = useState<number>(0)
    const [termsChecked, setTermsChecked] = useState(false);
    const [privacyChecked, setPrivacyChecked] = useState(false);

    const countries = useCountries();
    const geoLocation = useGeoLocation();

    const preferredCountries: CountryCode[] = ["NZ", "AU", "US", "GB"]
    const [selectedCountry, setSelectedCountry] = useState<CountryCode>(preferredCountries[0])
    const [isCountrySelectVisible, setIsCountrySelectVisible] = useState(false)
    const callingCode = useCallingCode(countries, selectedCountry)
    const countryName = countries?.find(value => value.cca2 === selectedCountry)?.name as string;

    const geoCountry = geoLocation?.country;
    useEffect(() => {
        if(geoCountry && countries?.find(value => value.cca2 === geoCountry)) {
            setSelectedCountry(geoCountry as CountryCode)
        }
    }, [geoCountry, countries])

    useEffect(() => {
        const listenerKBShow = Keyboard.addListener('keyboardDidShow', keyboardDidShow);
        const listenerKBHide = Keyboard.addListener('keyboardDidHide', keyboardDidHide);
        return () => {
            listenerKBShow.remove();
            listenerKBHide.remove();
        }
    }, [])

    const keyboardDidShow = useCallback((e: KeyboardEvent) => {
        setKeyboardHeight(e.endCoordinates.height);
    }, [])

    const keyboardDidHide = useCallback(() => {
        setKeyboardHeight(0)
    }, [])

    const onViewLayout = useCallback((event: LayoutChangeEvent) => {
        const { y, height } = event.nativeEvent.layout;
        setBottomAt(y + height)
    }, [])

    const onTermsClick = (type: "privacy-policy" | "tcs-and-cs") => {
        (async () => {
            const URL = type === "tcs-and-cs"
                ? "https://www.parkable.com/terms-and-conditions"
                : "https://www.parkable.com/privacy-policy";
            if (await Linking.canOpenURL(URL)) {
                await Linking.openURL(URL);
            }
        })();
    }

    const onSignUpPress = useCallback(() => {
        onSignUp({
            firstName,
            lastName,
            countryCode: selectedCountry === "GB" ? "UK" : selectedCountry
        })
    }, [firstName, lastName, selectedCountry])

    const toggleTermsChecked = () => {
        setTermsChecked(!termsChecked);
    }

    const togglePrivacyChecked = () => {
        setPrivacyChecked(!privacyChecked);
    }

    const isTablet =  screenWidth >= 500;
    return <TouchableOpacity style={[styles.base]} onPress={Keyboard.dismiss} activeOpacity={1}>
        <View style={{top: 14, flexDirection: 'row', justifyContent: 'space-between'}}>
            <View style={{ flex: 2 }}>
                <Input
                  autoFocus
                  label={Strings("first_name").toUpperCase()}
                  onChangeText={v => setFirstName(v.trim())}
                  defaultValue={firstName} />
            </View>
            <View style={{ width: 10 }} />
            <View style={{ flex: 3 }}>
                <Input
                  label={Strings("last_name").toUpperCase()}
                  onChangeText={v => setLastName(v.trim())}
                  defaultValue={lastName} />
            </View>
        </View>

        <View style={{ marginTop: 14 }}>
            <InputWrapper label="Country"
                          iconRight={"cheverondown"}
                          iconRightProps={{ small: true }}
                          onPress={() => setIsCountrySelectVisible(true)}
                          focused={false}>
                <View style={{flexDirection: 'row'}}>
                    {countryName && <Text small>{countryName}</Text>}
                    {selectedCountry != null && <CountryPicker visible={isCountrySelectVisible}
                                                               onClose={() => setIsCountrySelectVisible(false)}
                                                               containerButtonStyle={{ marginTop: -9 }}
                                                               onSelect={country => setSelectedCountry(country.cca2)}
                                                               preferredCountries={preferredCountries}
                                                               countryCode={selectedCountry}
                                                               withCloseButton
                                                               withEmoji={false}
                                                               withFlagButton={false}
                                                               withFilter />}
                </View>
            </InputWrapper>
        </View>
        <View style={{ flex: 1 }} onLayout={onViewLayout}>
            {part1?.showTcsAndCs && <View style={{flexDirection: "row", marginBottom: isTablet ? 9 : 18, marginTop: 5}}>
                <View style={{ width: 30, overflow: "hidden", marginRight: 9 }}>
                    <CheckBox checked={termsChecked} onChange={toggleTermsChecked} />
                </View>
                <Text small style={{
                    fontWeight: "normal",
                    padding: isTablet ? 6 : 0,
                    flexWrap: "wrap",
                    width: !isTablet ? 0.75 * screenWidth : '100%',
                    textAlignVertical: "center"}}>
                    {Strings("by_signing_up")}{' '}
                    <TouchableOpacity onPress={() => onTermsClick("tcs-and-cs")}>
                        <Text small style={{
                            color: Colours.BLUE_300,
                            fontWeight: "normal",
                            textDecorationLine: "underline" }}>
                            {Strings("terms_conditions").toLowerCase()}
                        </Text>
                    </TouchableOpacity>
                </Text>
            </View>}
            <View style={{flexDirection: "row", marginBottom: isTablet ? 75 : 18, marginTop: 5}}>
                <View style={{ width: 30, overflow: "hidden", marginRight: 9 }}>
                    <CheckBox checked={privacyChecked} onChange={togglePrivacyChecked} />
                </View>
                <Text small style={{
                    fontWeight: "normal",
                    padding: isTablet ? 6 : 0,
                    flexWrap: "wrap",
                    width: !isTablet ? 0.75 * screenWidth : '100%',
                    textAlignVertical: "center"}}>
                    {Strings("by_signing_up")}{' '}
                    <TouchableOpacity onPress={() => onTermsClick("privacy-policy")}>
                        <Text small style={{
                            color: Colours.BLUE_300,
                            fontWeight: "normal",
                            textDecorationLine: "underline" }}>
                            {Strings("privacy_policy").toLowerCase()}
                        </Text>
                    </TouchableOpacity>
                </Text>
            </View>
        </View>

        <View style={{ flex: 1 }} onLayout={onViewLayout}>
            <Button center form textProps={{ h5: false, h3: true }} disabled={!firstName || !lastName || (!termsChecked && part1.showTcsAndCs) || !privacyChecked} onPress={onSignUpPress}>{Strings("sign_up")}</Button>

        </View>
    </TouchableOpacity>
}

type Part1 = {
    email?: string|null,
    password?: string,
    firstName: string,
    lastName: string,
    phone: string|null,
    countryCode: string,
    usingSSO: boolean,
    showTcsAndCs: boolean,
}

const actions = {
    createUser
}

const getReduxProps = (state: IRootReducer) => {
    return {
        api: state.data.api,
    }
}

export const CreateAccountRoute = createRoute({
    path: Routes.CreateAccountView,
    params: {type: CreateAccountParams,}
})


const styles = StyleSheet.create({
    body: {
        flex: 1,
        paddingBottom: 18,
    },
    welcome: {
        fontSize: 45,
        lineHeight: 49,
        marginBottom: 18,
        backgroundColor: Colours.NEUTRALS_WHITE,
        fontFamily: "GTEestiDisplay-Bold",
    },
    topLeft: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    topTitle: {
        position: 'absolute',
        paddingTop: 15,
        alignSelf: 'center'
    },
    base: {
        flex: 1,
    },
    subText: {
        top: 20,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    phoneContainer: {
    },
    phoneInputs: {
    }
})

export default connect(getReduxProps, actions)(CreateAccountView);
