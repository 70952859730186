import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Platform } from "react-native";
import { useIsAdminImpersonating } from "./useIsAdminImpersonating";

export default function useSafeArea() {
    const isAdminImpersonating = useIsAdminImpersonating();
    const insets = useSafeAreaInsets();
    return {
        top: isAdminImpersonating ? 9 : Math.max(insets.top, Platform.OS === "web" ? 0 : 30),
        bottom: insets.bottom,
        left: 0,
        right: 0
    };
}

export function useMapSafeArea() {
    const safeArea = useSafeArea();
    return {
        ...safeArea,
        top: Platform.OS === "web" ? 12 : safeArea.top
    }
}
