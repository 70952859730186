import fetch from "../reducers/fetch";
import { DispatchFunc } from "../../model/Types";
import { Token } from "../../api/rest";
import * as qrApi from '../../api/qrAction';
import QRAction from "../../model/QRAction";

export function getQRAction(id: string, onSuccess?: (qrAction: QRAction) => void, onFail?: () => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return qrApi.getQRAction(api, token, id).then(({qrAction}: {qrAction: QRAction}) => {
            onSuccess?.(qrAction)
        }).catch(e => {
            onFail?.();
        });
    }, arguments);
}