export default {
  api: {
    name: "production",
    url: "https://api.parkable.com/api/",
  },
  firebase: {
    apiKey: "AIzaSyCuyCrj3IPokYGJwC3ITb83w5o7_pEUbcE",
    authDomain: "parkable-app.firebaseapp.com",
    appId: "1:1047279485966:web:2c77024191097fd78d285f",
    databaseURL: "https://parkable-app.firebaseio.com",
    messagingSenderId: "",
    projectId: "parkable-app",
    storageBucket: "parkable-app.appspot.com",
  },
  showBuildNumber: false,
  teamsClientId: "49849e77-926b-490c-9656-78ad3fe94436",
  teamsAuthorityUrl: "https://login.microsoftonline.com/",
}
