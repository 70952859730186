/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 21/09/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


// @ts-ignore
import { PhoneNumberUtil } from 'google-libphonenumber';
import Strings from "./localization/localization";

export default function validatePhoneNumber(phoneNumber: string): string | null {

    let isValid = false;
    try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        isValid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
    } catch (err) {
        console.log("PHONE NUMBER PARSE ERROR", err)
    }

    if (!isValid) {
        return Strings("invalid_phone");
    }
    return null;
}

export function getAreaCodeFromPhoneNumber(phoneNumber: string): string | null {
    try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        const phone = phoneUtil.parse(phoneNumber);
        return `+${phone.getCountryCode()}`;
    } catch (err) {
        console.log("PHONE NUMBER PARSE ERROR", err)
    }
    return null;
}

export function getCountryFromPhoneNumber(phoneNumber: string): string | null {
    try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        const phone = phoneUtil.parse(phoneNumber);
        return phoneUtil.getRegionCodeForCountryCode(phone.getCountryCode());
    } catch (err) {
        console.log("PHONE NUMBER PARSE ERROR", err)
    }
    return null;
}
