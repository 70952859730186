import React, { useState, useCallback } from 'react';
import {StyleSheet, View, Platform} from 'react-native';
import {ActivityType, Voucher, ValidForParking} from "../../../model/Voucher";
import Strings from "../../../util/localization/localization";
import Text from "react/legacy/parkable-components/text/Text";
import Input from "react/legacy/parkable-components/input/Input";
import Button from "react/legacy/parkable-components/button/Button";
import HelperBlock from "react/legacy/parkable-components/helperBlock/HelperBlock";
import {IRootReducer} from "../../../redux/reducers/main";
import {connect} from "react-redux";
import { Park } from '../../../model/Park';
import { checkForVoucherWithCode } from "../../../redux/actions/vouchers";
import { setVoucherForPark } from "../../../redux/actions/vouchers";
import {createRoute, NavigationProps} from "../../../navigation/constants";
import {Routes} from "../../../navigation/root/root.paths";
import ParkableBaseView from "../../../components/common/ParkableBaseView";

class AddVoucherCodeParams {
    park?: Pick<Park, 'id'>;
    activity?: ActivityType;
}

type Props = ReturnType<typeof reduxProps> & typeof actions & NavigationProps<Routes.AddVoucherCode>;

const reduxProps = (state: IRootReducer) => {
    return {
    }
};

const actions = {
    checkForVoucherWithCode,
    setVoucherForPark
};

function AddVoucherCodeView(props: Props) {
    const {
        checkForVoucherWithCode,
        setVoucherForPark,
        navigation,
    } = props;

    const {
        park,
        activity,
    } = props.route.params ?? {};

    const [error, setError] = useState<string | undefined>();
    const [voucherCode, setVoucherCode] = useState<string | undefined>();
    const [valid, setValid] = useState(false);
    const [loading, setLoading] = useState(false);

    const onCheck = useCallback(() => {
        if (!voucherCode) {
            return
        }

        setLoading(true);
        checkForVoucherWithCode(voucherCode.trim().toUpperCase(),
            (vouchers: Voucher[]) => {
                const voucher = vouchers[0];
                if(!!activity && (!voucher || !voucher.validForParking || !voucher.validForParking.find(v =>
                    (activity === ActivityType.Casual && (v === ValidForParking.ClassicCasual || v === ValidForParking.PfBCasual)) ||
                        (activity === ActivityType.LongTerm && (v === ValidForParking.PfBSubscription))))){
                    setError(Strings("voucher_not_applicable"));
                    setValid(false);
                    setLoading(false);
                } else if(!!park && !!activity){
                    setVoucherForPark(voucher, park.id, activity);
                    setLoading(false);
                    navigation.pop();
                }else{
                    setLoading(false);
                    navigation.pop();
                }
            }, (err:any) => {
                setError(err?.message);
                setValid(false);
                setLoading(false);
            }
        );
    }, [activity, park, navigation, voucherCode, setLoading, setError, setValid, checkForVoucherWithCode, setVoucherForPark]);

    const onCodeChange = useCallback((raw: string) => {
        raw = (/[^ ].*/g.exec(raw) || [''])[0];
        if(Platform.OS === 'android') {
            if(!!voucherCode && !!raw && raw.length > voucherCode.length) {
                if(raw.indexOf(voucherCode) === 0) {
                    //appending, RN bug happens
                    raw = voucherCode + raw.substr(raw.length - 1);
                } else if(raw.indexOf(voucherCode) === -1) {
                    //inserting, RN bug not happens here
                } else {
                    console.log('Unexpected condition', {raw, voucherCode})
                }

            }
        }
        setVoucherCode(Platform.OS === 'android' ? raw : raw.toUpperCase());
        setValid(raw.length > 0);
        setError(undefined);
    }, [setValid, setVoucherCode, setError]);

    return  <ParkableBaseView scrollable={false}>
                <View style={{flex: 1}}>
                    <View style={styles.mainContainer}>
                        <Text h1 style={{marginTop: 10}}>{Strings("what_is_your_voucher")}</Text>
                        <Input  autoFocus autoCapitalize="characters"
                                label={Strings("voucher_code")}
                                placeholder={Strings("voucher_code_place_holder")}
                                value={voucherCode}
                                onChangeText={onCodeChange} />
                        {!!error && <HelperBlock error={error} />}
                        <Button center disabled={!valid || loading} style={{top: 18}} onPress={onCheck} loading={loading}>{Strings("check")}</Button>
                    </View>
                </View>
    </ParkableBaseView>;
}

export default connect(reduxProps, actions)(AddVoucherCodeView) as any as React.FunctionComponent<Props>;

export const AddVoucherCodeRoute = createRoute({
    path: Routes.AddVoucherCode,
    params: {type: AddVoucherCodeParams},
});

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        paddingVertical: 7,
    },
});
