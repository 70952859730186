import { IconName } from "react/legacy/parkable-components/icon/Icons";
import { MapPreferences, ParkingType } from "react/model/Types";

export type ShowEvPinProps = {
	preferences: Pick<MapPreferences, "parkingType">
	isFavouritePark?: boolean | null
	isOrgMember?: boolean | null
	hasEvCharger?: boolean
	hideEVBays?: boolean | null,
	isParkOwner?: boolean,
}
export const getShowEvPin = ({ preferences, isFavouritePark, isOrgMember, hasEvCharger, hideEVBays, isParkOwner }: ShowEvPinProps) => {
	if (hideEVBays) {
		return isParkOwner;
	}

	if (preferences.parkingType !== ParkingType.ELECTRIC) {
		return false
	}
	if (isFavouritePark || isOrgMember) {
		return true;
	}
	return hasEvCharger
}


export const generateEvText = (args: {
	isOrgMember?: boolean
}) => {
	if (args.isOrgMember) {
		return ""
	}
	return "";
};

export const generateEvIcon = (args: {
	isFavouritePark?: boolean
	isOrgMember?: boolean
	isMotorbikeDefault?: boolean
}): IconName => {

	if (args.isOrgMember) {
		return 'buildingwork'
	}

	if (args.isFavouritePark) {
		return "star"
	}

	return args.isMotorbikeDefault ? "motorbike" : "car"
}
