import React from "react";
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import Strings from "../../../util/localization/localization";
import { Routes } from "react/navigation/root/root.paths";
import { useUserCards } from "../../../api/stripe/stripe.api";
import { useNavigation } from "../../../navigation/constants";

export type CreditCardRowProps = {
    disableChanging?: boolean;
};

export const CreditCardRow = ({disableChanging}: CreditCardRowProps) => {
    const { cards, currentCardId, isLoading } = useUserCards();
    const currentCard = currentCardId ? cards?.find((x) => x.id === currentCardId) : cards?.[0];

    const navigation = useNavigation();

    const isChangeable = !Boolean(disableChanging) && !isLoading;

    const handleChange = () => {
        navigation.push(Routes.CreditCardView);
    };

    return (
        <TableRow
            label={Strings("payment_method")}
            iconLeft="cardpayment"
            chevron={isChangeable}
            onPress={isChangeable ? handleChange : undefined}
        >
            {isLoading && Strings("loading")}
            {!isLoading && <>{currentCard ? `${Strings("card_ending")} ${currentCard.last4}` : Strings("no_cards")}</>}
        </TableRow>
    );
};
