import {
    CURRENT_PARKING_SESSION,
    FINISH_PARKING_DATA,
    HISTORY_PARKING_SESSION, PARKING_ERROR,
    PAYMENTS_REQUIRING_AUTHENTICATION,
    SET_SESSIONS
} from "../actions/types";
import { ParkSession, ParkSessionMap } from "../../model/ParkSession";
import { OcpiSession } from "../../model/ocpi/OcpiSession";
import { SessionLineItem } from "../../model/SessionLineItem";
import { FailedPaymentInfo } from "../../model/FailedPaymentInfo";
import { ParkSessionDTOLocal } from "../../model/ParkSessionDTO";

export type FinishParkingData = {
    sessionLineItem: SessionLineItem,
    ocpiSessions: Array<OcpiSession>;
}

const initialState = {
    parkingError: null,
    currentSession: null as ParkSessionDTOLocal | null,
    parkingRequest: null,
    sessionHistory: {} as ParkSessionMap,
    finishParkingData: {} as FinishParkingData,
    paymentsRequiringAuthentication: [] as FailedPaymentInfo[]
};

type IParkingReducer = typeof initialState;

export default function parkingReducer(state = initialState, action: any): IParkingReducer {
    switch (action.type) {
        case PARKING_ERROR:
            return {
                ...state,
                parkingError: action.error
            };
        case CURRENT_PARKING_SESSION:
            return {
                ...state,
                currentSession: action.currentSession || undefined
            };

        case SET_SESSIONS: {
            const sessionHistory = {...state.sessionHistory};
            action.sessions.forEach((session: ParkSession) => {
                sessionHistory[session.id] = session;
            });

            return {
                ...state,
                sessionHistory
            };

        }
        case HISTORY_PARKING_SESSION: {
            const sessionHistory = {...state.sessionHistory};
            const session = sessionHistory[action.session.id];
            sessionHistory[action.session.id] = {
                ...action.session,
                _territory: session?._territory,
                park: session?.park,
                parkOwner: session?.parkOwner,
                parker: session?.parker,
                vehicle: session?.vehicle,
            };

            return {
                ...state,
                sessionHistory
            };
        }
        case FINISH_PARKING_DATA: {
            return {
                ...state,
                finishParkingData: action.finishParkingData
            }
        }
        case PAYMENTS_REQUIRING_AUTHENTICATION:
            return {
                ...state,
                paymentsRequiringAuthentication: action.paymentsRequiringAuthentication
            }
        default:
            return state;
    }
}
