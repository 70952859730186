import React, { useCallback, useEffect } from "react";
import Text from "react/legacy/parkable-components/text/Text";
import { AppState, AppStateStatus, View, StyleSheet, ScrollView } from "react-native";
import { ActiveParkingRequest, filterActiveRequests } from "../../util/parking-request.constants";
import _ from "lodash";
import * as Push from "react/navigation/pushNotifications/constants";
import { spacing } from "react/util/theme/spacing";
import Colours from "react/legacy/parkable-components/styles/Colours";
import moment from "moment";
import { useCurrentUser } from "react/api/user/user.api";
import { Spinner } from "react/legacy/parkable-components";
import { useParkingRequestsForUser } from "react/api/parking-request/parking-request.api";
import { addNotificationListener } from "react/navigation/pushNotifications/notificationListener";
import Strings from "react/util/localization/localization";
import ParkableBaseView from "react/components/common/ParkableBaseView";
import { createRoute } from "react/navigation/constants";
import { Routes } from "react/navigation/root/root.paths";
import ParkingRequestCard from "../../components/future-booking/ParkingRequestCard";

const BookingsAndRequestsView = () => {
    const { user: currentUser } = useCurrentUser();
    const { parkingRequests, mutate, loading } = useParkingRequestsForUser(currentUser?.id);

    const activeRequests = _.orderBy(filterActiveRequests(parkingRequests ?? []), "date");

    const groupedByMonth = Object.values(
        _.groupBy(activeRequests, (request) => {
            return moment(request.date).format("MMMM-YYYY");
        })
    );

    const requestsByMonth: { month: string; requests: ActiveParkingRequest[] }[] = groupedByMonth.map((g) => {
        return {
            month: moment(g[0].date).format("MMMM"),
            requests: g,
        };
    });

    const onNotificationReceived = useCallback((code: string) => {
        if (code === Push.ParkingRequestUpdated || code === Push.OpenBookingsView) {
            void mutate();
        }
        return false;
    }, []);

    useEffect(() => {
        const notifListener = addNotificationListener(onNotificationReceived, "BookingsAndRequestsView");
        return () => {
            notifListener.remove();
        };
    }, []);

    const onAppStateChange = (nextAppState: AppStateStatus) => {
        if (nextAppState === "active") {
            void mutate();
        }
    };

    useEffect(() => {
        const subscription = AppState.addEventListener("change", onAppStateChange);
        return () => subscription.remove();
    }, []);

    const renderSubheading = () => {
        if (loading) {
            return <Spinner small />;
        }
        return (
            <Text h4 grey>
                {requestsByMonth.length === 0 ? Strings("no_bookings_or_requests") : Strings("manage_bookings")}
            </Text>
        );
    };

    return (
        <ParkableBaseView scrollable={false}>
            <View>
                <Text h1 bold>
                    {Strings("my_bookings_and_requests")}
                </Text>
                {renderSubheading()}
            </View>
            <ScrollView showsVerticalScrollIndicator>
                {requestsByMonth.map((r, i) => (
                    <View key={`${r.month}_${i}}`}>
                        <Text h4 bold style={styles.monthText}>
                            {r.month}
                        </Text>
                        {r.requests.map((r) => (
                            // Horizontal padding needed to account for shadow in BookingCard
                            <View style={{ paddingHorizontal: 1 }} key={r.id}>
                                <ParkingRequestCard request={r} />
                            </View>
                        ))}
                    </View>
                ))}
            </ScrollView>
        </ParkableBaseView>
    );
};

export default BookingsAndRequestsView;

export const BookingsAndRequestsRoute = createRoute({
    path: Routes.UserBookingsAndRequestsView,
});

const styles = StyleSheet.create({
    monthText: {
        paddingTop: spacing(3),
        marginBottom: 0,
        color: Colours.NEUTRALS_700,
    },
});
