import * as Type from '../actions/partialParks'
import {PartialParkWithTerritory} from "../actions/partialParks";

const init = {
    partialPark: null as PartialParkWithTerritory | null,
};

type IPartialParkReducer = typeof init;

export default function dataReducer(state = init, action: any): IPartialParkReducer {

    switch (action.type) {

        case Type.SET_PARTIAL_PARK:
            return {
                ...state,
                partialPark: action.partialPark
            };

        default:
            return state
    }
}