import { RootStackParamList } from "./root.routes";
import * as React from "react";
import { Routes } from "./root.paths";
import LandingView from "react/views/landing/LandingView";
import ParkDetailView from "react/views/parking/ParkDetailView";
import LoginOrSignupView from "react/views/landing/LoginOrSignupView";
import ParkableMapRootView from "react/views/map/ParkableMapRootView";
import ChangeServerView from "react/views/landing/ChangeServerView";
import FindParkView from "react/views/parking/FindParkView";
import SupportView from "react/views/support/SupportView";
import CaseDetailsView from "react/views/support/CaseDetailsView";
import ForgotPasswordView from "react/views/landing/ForgotPasswordView";
import ConfirmStartParkingView from "react/views/parking/confirmation/ConfirmStartParkingView";
import ActiveSessionView from "react/views/parking/ActiveSessionView";
import AccountView from "react/views/account/AccountView";
import PreferredBaysView from "react/views/preferred-bays/PreferredBaysView";
import SelectParkView from "react/views/preferred-bays/SelectParkView";
import PricingView from "react/views/parking/PricingView";
import MapSettingsView from "react/views/account/MapSettingsView";
import MyDetailsView from "react/views/account/MyDetailsView";
import SessionHistoryView from "react/views/account/session/SessionHistoryView";
import BookingsAndRequestsView from "react/views/future-booking/BookingAndRequestsView";
import ListSubscriptionView from "react/views/subscriptions/ListSubscriptionView";
import AddNewVehicleView from "react/views/account/vehicle/AddNewVehicleView";
import VehiclesView from "react/views/account/vehicle/VehiclesView";
import CreateAccountView from "react/views/landing/CreateAccountView";
import SamlLoginView from "react/views/landing/SamlLoginView";
import MessagesView from "react/views/account/MessagesView";
import VouchersView from "react/views/account/VouchersView";
import SessionSummaryView from "react/views/parking/SessionSummaryView";
import NotificationSettingsView from "react/views/account/NotificationSettingsView";
import RfidCardsView from "react/views/account/rfid/RfidCardsView";
import AddEditRfidCardView from "react/views/account/rfid/AddEditRfidCardView";
import CreditCards from "react/views/account/payment/CreditCardsView";
import ParkAvailabilityView from "react/views/parking/ParkAvailabilityView";
import VoucherDetailView from "react/views/account/voucher/VoucherDetailView";
import AddVoucherCodeView from "react/views/account/voucher/AddVoucherCodeView";
import SessionHistoryDetailView from "react/views/account/session/SessionHistoryDetailView";
import SelectSubscriptionPlanView from "react/views/subscriptions/SelectSubscriptionPlanView";
import ParkTomorrowView from "react/views/future-booking/ParkTomorrowView";
import ConfirmStartReservationView from "react/views/parking/confirmation/ConfirmStartReservationView";
import StartSubscriptionSummaryView from "react/views/subscriptions/StartSubscriptionSummaryView";
import PrivacySettingsView from "react/views/account/PrivacySettingsView";
import ChangePasswordView from "react/views/account/ChangePasswordView";
import AddNewCardRootView from "react/views/account/payment/AddNewCardRootView";
import ProblemView from "react/views/support/ProblemView";
import SubscriptionCancelView from "react/views/subscriptions/SubscriptionCancelView";
import FutureBookingView from "react/views/future-booking/FutureBookingView";
import HowItWorksUpcomingWeeksView from "react/views/future-booking/how-it-works/UpcomingWeeksView";
import HowItWorksThisWeekView from "react/views/future-booking/how-it-works/ThisWeekView";
import SubscriptionDetailsView from "react/views/subscriptions/SubscriptionDetailsView";
import PreferredBaysSelectionView from "react/views/preferred-bays/PreferredBaysSelectionView";
import VerifyEmailView from "react/views/landing/VerifyEmailView";
import SingleSubscriptionView from "react/views/subscriptions/SingleSubscriptionView";
import StartChargingByLinkView from "react/views/ev/StartChargingByLinkView";
import SubscriptionInvoicesView from "react/views/subscriptions/SubscriptionInvoicesView";
import ConfirmedBookingView from "react/views/future-booking/ConfirmedBookingView";
import SubscriptionSharingCreditView from "react/views/subscriptions/SubscriptionSharingCreditView";
import ChatDetailsView from "react/views/parking/tandem/ChatDetailsView";
import CampusScreenView from "react/views/parking/CampusScreenView";
import ConnectEVNozzleView from "react/views/ev/ConnectEVNozzleView";
import TandemChatView from "react/views/parking/tandem/TandemChatView";
import ConfirmStartTandemParkingView from "react/views/parking/confirmation/ConfirmStartTandemParkingView";
import MagicLinkView from "react/views/landing/MagicLinkView";
import MyRewardsView from "react/views/subscriptions/rewards/MyRewardsView";
import RetryPaymentRootView from "react/views/account/payment/RetryPaymentRootView";
import QrScannerRootView from "react/views/qr-scanner/QrScannerRootView";
import HowItWorksRewardsView from "react/views/subscriptions/rewards/HowItWorksRewardsView";
import AdminLoginAsUserView from "react/views/account/admin/AdminLoginAsUserView";
import WarningOpenSessionView from "react/views/parking/WarningOpenSessionView";
import AutoEndSessionView from "react/views/parking/AutoEndSessionView";
import UpdateCheckView from "react/views/landing/UpdateCheckView";
import TeamsLandingView from "react/views/landing/teams/TeamsLandingView";
import TeamsAuthStartView from "react/views/landing/teams/TeamsAuthStartView";
import TeamsAuthEndView from "react/views/landing/teams/TeamsAuthEndView";
import SharingPoolView from "react/views/parking/SharingPoolView";
import EditVehicleView from "react/views/account/vehicle/EditVehicleView";
import AddNewCaseView from "react/views/support/AddNewCaseView";
import SelectBayView from "react/views/parking/SelectBayView";
import VtsLoginView from "../../views/landing/vts/VtsLoginView";

export type RouteComponents = {
    [Property in keyof RootStackParamList]: React.ComponentType<any>;
};

const routeComponents: RouteComponents = {
    [Routes.LandingView]: LandingView,
    [Routes.ParkDetailView]: ParkDetailView,
    [Routes.CampusScreenView]: CampusScreenView,
    [Routes.ConnectEVNozzle]: ConnectEVNozzleView,
    [Routes.LoginOrSignUpView]: LoginOrSignupView,
    [Routes.SubscriptionDetailsView]: SubscriptionDetailsView,
    [Routes.VerifyEmailView]: VerifyEmailView,
    [Routes.MagicLinkView]: MagicLinkView,
    [Routes.ParkableMapView]: ParkableMapRootView,
    [Routes.ChangeServerView]: ChangeServerView,
    [Routes.UpdateCheckView]: UpdateCheckView,
    [Routes.FindParkView]: FindParkView,
    [Routes.SupportView]: SupportView,
    [Routes.AddNewCaseView]: AddNewCaseView,
    [Routes.CaseDetailsView]: CaseDetailsView,
    [Routes.MyDetailsView]: MyDetailsView,
    [Routes.CreateAccountView]: CreateAccountView,
    [Routes.ConfirmedBookingView]: ConfirmedBookingView,
    [Routes.StartSubscriptionSummaryView]: StartSubscriptionSummaryView,
    [Routes.ConfirmStartParkingView]: ConfirmStartParkingView,
    [Routes.PreferredBaysView]: PreferredBaysView,
    [Routes.PreferredBaysSelectionView]: PreferredBaysSelectionView,
    [Routes.SelectParkView]: SelectParkView,
    [Routes.PricingView]: PricingView,
    [Routes.ActiveSession]: ActiveSessionView,
    [Routes.ChangePasswordView]: ChangePasswordView,
    [Routes.ForgotPasswordView]: ForgotPasswordView,
    [Routes.AccountView]: AccountView,
    [Routes.SamlLoginView]: SamlLoginView,
    [Routes.SingleSubscriptionView]: SingleSubscriptionView,
    [Routes.MapSettingsView]: MapSettingsView,
    [Routes.SubscriptionCancelView]: SubscriptionCancelView,
    [Routes.SessionHistoryView]: SessionHistoryView,
    [Routes.UserBookingsAndRequestsView]: BookingsAndRequestsView,
    [Routes.SubscriptionListView]: ListSubscriptionView,
    [Routes.SubscriptionInvoicesView]: SubscriptionInvoicesView,
    [Routes.SubscriptionSharingCreditView]: SubscriptionSharingCreditView,
    [Routes.ConfirmStartTandemParkingView]: ConfirmStartTandemParkingView,
    [Routes.AddNewVehicleView]: AddNewVehicleView,
    [Routes.EditVehicleView]: EditVehicleView,
    [Routes.VehiclesView]: VehiclesView,
    [Routes.MessagesView]: MessagesView,
    [Routes.SelectSubscriptionPlanView]: SelectSubscriptionPlanView,
    [Routes.ParkTomorrowView]: ParkTomorrowView,
    [Routes.ConfirmStartReservationView]: ConfirmStartReservationView,
    [Routes.VouchersView]: VouchersView,
    [Routes.ChatDetailsView]: ChatDetailsView,
    [Routes.TandemChatView]: TandemChatView,
    [Routes.SessionSummary]: SessionSummaryView,
    [Routes.FutureBookingView]: FutureBookingView,
    [Routes.Problem]: ProblemView,
    [Routes.QrScannerView]: QrScannerRootView,
    [Routes.HowItWorksThisWeekView]: HowItWorksThisWeekView,
    [Routes.HowItWorksUpcomingWeeksView]: HowItWorksUpcomingWeeksView,
    [Routes.NotificationSettingsView]: NotificationSettingsView,
    [Routes.RfidCardsView]: RfidCardsView,
    [Routes.AddEditRfidCardView]: AddEditRfidCardView,
    [Routes.CreditCardView]: CreditCards,
    [Routes.AddNewCard]: AddNewCardRootView,
    [Routes.RetryPaymentRequest]: RetryPaymentRootView,
    [Routes.SelectBayView]: SelectBayView,
    [Routes.ParkAvailabilityView]: ParkAvailabilityView,
    [Routes.SharingPoolView]: SharingPoolView,
    [Routes.VoucherDetail]: VoucherDetailView,
    [Routes.AddVoucherCode]: AddVoucherCodeView,
    [Routes.StartChargingByLinkView]: StartChargingByLinkView,
    [Routes.PrivacySettingsView]: PrivacySettingsView,
    [Routes.SessionHistoryDetailView]: SessionHistoryDetailView,
    [Routes.WarningOpenSessionView]: WarningOpenSessionView,
    [Routes.AutoEndSessionView]: AutoEndSessionView,
    [Routes.MyRewardsView]: MyRewardsView,
    [Routes.HowItWorksRewardsView]: HowItWorksRewardsView,
    [Routes.AdminLoginAsUserView]: AdminLoginAsUserView,
    [Routes.TeamsLandingView]: TeamsLandingView,
    [Routes.TeamsAuthStartView]: TeamsAuthStartView,
    [Routes.TeamsAuthEndView]: TeamsAuthEndView,
    [Routes.VtsLoginView]: VtsLoginView,
};

export default routeComponents;
