import { IconName, Icons } from 'react/legacy/parkable-components';
import React from 'react';
import { Marker } from "react-native-maps";
import { CasualDisplayType, IMarker, LTDisplayType, MapPreferences, ParkingType } from "react/model/Types";
import { MarkerContent } from "./MarkerContent";
import Strings from "react/util/localization/localization";

const out = (s?: string | null) => s || "";
export type MarkerTextAndIconProps = {
    markerText: string;
    iconName: IconName;
};
export const getMarkerTextAndIcon = (
    marker: IMarker,
    preferences: MapPreferences,
    isMotorBikeDefault: boolean,
    parkingType?: ParkingType
): MarkerTextAndIconProps => {
    const { casualDisplayType, ltDisplayType } = preferences;

    const { extra: markerExtraProps } = marker;

    const {
        parkIsOpen,
        parkIsFull,
        favPark,
        userIsOrgMember,
        perHourText,
        perMonthText,
        perWeekText,
        spotsLeftText,
    } = markerExtraProps;

    const result: MarkerTextAndIconProps = {
        markerText: "",
        iconName: "" as IconName,
    };

    switch (parkingType ?? preferences.parkingType) {

        case ParkingType.LONG_TERM: {
            result.iconName = Icons.key;
            switch (ltDisplayType) {
                case LTDisplayType.PRICE_PER_MONTH:
                    result.markerText = out(perMonthText);
                    break;
                case LTDisplayType.SPOTS_LEFT:
                    result.markerText = out(spotsLeftText);
                    break;
                case LTDisplayType.PRICE_PER_WEEK:
                default:
                    result.markerText = out(perWeekText);
                    break;
            }
            break;
        }

        case ParkingType.ELECTRIC:
        case ParkingType.CASUAL: {
            result.iconName = isMotorBikeDefault ? Icons.motorbike : Icons.car;
            switch (casualDisplayType) {
                case CasualDisplayType.SPOTS_LEFT:
                    result.markerText = out(spotsLeftText);
                    break;
                case CasualDisplayType.PRICE_PER_HOUR:
                default:
                    result.markerText = out(perHourText);
                    break;
            }
            break;
        }
    }

    if (userIsOrgMember) {
        result.markerText = "";
        result.iconName = Icons.buildingwork;
    } else if (favPark) {
        result.iconName = Icons.star;
    }
    if (!parkIsOpen) {
        result.markerText = Strings("closed");
    }
    if (parkIsFull) {
        result.markerText = Strings("full");
    }

    return { iconName: marker.isCampus ? Icons.buildingwork : result.iconName, markerText: result.markerText };
};

export type MarkerProps = {
    marker: IMarker;
    preferences: MapPreferences & { parkingType?: ParkingType };
    onMarkerPress: (e: { nativeEvent: { id: string } }) => void;
    parkingType: ParkingType;
    isMotorBikeDefault: boolean;
    isHidePrice?: boolean;
};

type Props = Omit<MarkerProps, "parkingType"> & { parkingType?: ParkingType };

const MyMarker = (props: Props) => {
    const { onMarkerPress, marker, preferences, parkingType, isMotorBikeDefault, isHidePrice } = props;

    const { markerText, iconName } = getMarkerTextAndIcon(marker, preferences, isMotorBikeDefault, parkingType);

    return (
        <Marker
            key={marker.id}
            identifier={`${marker.id}`}
            zIndex={marker.extra.zIndex}
            coordinate={marker.coordinate}
            onPress={onMarkerPress}
            tracksViewChanges={false}
            flat={true}
        >
            <MarkerContent
                iconName={iconName}
                markerText={markerText}
                isHidePrice={isHidePrice}
                parkIsOpen={marker.extra.parkIsOpen}
                userIsOrgMember={marker.extra.userIsOrgMember}
                favPark={marker.extra.isOrgOrFav}
                parkIsFull={marker.extra.parkIsFull}
                hasEVCharger={marker.extra.hasEVCharger}
            />
        </Marker>
    );
};

export default React.memo(MyMarker, (prevProps: Props, nextProps: Props) => {
    return prevProps.marker.id === nextProps.marker.id;
}) as React.FunctionComponent<Props>;
