import { StyleSheet, View, ViewStyle } from "react-native";
import React from "react";
import { Routes } from "react/navigation/root/root.paths";
import { ParkSessionDTOLocal } from "../../../model/ParkSessionDTO";
import { Bay } from "../../../model/Bay";
import { useTandemChatParticipants } from "../../../api/tandem/tandem.api";
import { TouchableOpacity } from "@gorhom/bottom-sheet";
import Spinner from "react/legacy/parkable-components/spinner/Spinner";
import Text from "react/legacy/parkable-components/text/Text";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Icon from "react/legacy/parkable-components/icon/Icon";
import Strings from "../../../util/localization/localization";
import { useNavigation } from "../../../navigation/constants";

type Props = {
    navigation: ReturnType<typeof useNavigation>;
    bay: Bay;
    session: ParkSessionDTOLocal;
    style?: ViewStyle;
};

const TandemChatCarouselItem = (props: Props) => {
    const { navigation, style, bay, session } = props;
    const { participants, isLoading } = useTandemChatParticipants(bay.tandemPod, session.chat);

    const activeParticipantsLength = participants?.filter(p => p.isActive).length??0;

    if (isLoading || !participants) {
        return <Spinner />;
    }

    const goToTandemChat = () => {
        navigation.push(Routes.TandemChatView, {
            sessionId: session.id,
            tandemPodId: bay.tandemPod ?? undefined,
        });
    };

    return (
        <TouchableOpacity onPress={goToTandemChat} activeOpacity={0.7}>
            <View style={[styles.container, style]}>
                <Icon
                    color={Colours.GREY_50}
                    style={styles.iconStyle}
                    iconStyle={styles.iconFontStyle}
                    name="multipleUsers"
                />
                <View style={styles.textContainer}>
                    <Text grey small>
                        {Strings("tandem_parking")}
                    </Text>
                    <Text bold>{Strings("number_other_parkers")(activeParticipantsLength)}</Text>
                    <Text grey small style={styles.chatLink}>
                        {Strings("open_chat")}
                    </Text>
                </View>
            </View>
        </TouchableOpacity>
    );
};

export default TandemChatCarouselItem;

const styles = StyleSheet.create({
    container: {
        backgroundColor: Colours.GREY_10,
        paddingTop: 18,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 18,
        borderRadius: 9,
        alignItems: "center",
    },
    iconStyle: {
        width: "100%",
        flex: 1,
    },
    iconFontStyle: {
        fontSize: 81,
    },
    textContainer: {
        alignItems: "center",
    },
    chatLink: {
        textDecorationLine: "underline",
    },
});
