import React, {Component, useCallback} from 'react';
import Carousel from "./Carousel";


type CarouselProps = {
    style: {}
}

export default function ComponentCarousel(props: React.PropsWithChildren<CarouselProps>) {

    const renderItem = useCallback((item : {item: Component, index: number}) => {
        return item.item;
    }, []);

    return (
        <Carousel style={props.style}
                  renderItem={renderItem}
                  data={props.children}/>
    );
}
