import {AvailabilityDTO} from "../../model/Availability";
import {convertDayAvailabilityDTO, DayAvailabilityDTO} from "../../model/DayAvailability";

export function isAvailableOnDay(availability: AvailabilityDTO, day: string): boolean {
    let dayAvailability: DayAvailabilityDTO;
    switch(day){
        case "Sun":
            dayAvailability = availability.sundayAvailability;
            break;
        case "Mon":
            dayAvailability = availability.mondayAvailability;
            break;
        case "Tue":
            dayAvailability = availability.tuesdayAvailability;
            break;
        case "Wed":
            dayAvailability = availability.wednesdayAvailability;
            break;
        case "Thu":
            dayAvailability = availability.thursdayAvailability;
            break;
        case "Fri":
            dayAvailability = availability.fridayAvailability;
            break;
        case "Sat":
            dayAvailability = availability.saturdayAvailability;
            break;
        default: return false;
    }

    if(!dayAvailability.available){
        return false;
    }

    return !!convertDayAvailabilityDTO(dayAvailability)
        .hourlyAvailability
        .find(v => v)
}