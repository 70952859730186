import React from 'react';
import { IRootReducer } from '../../../redux/reducers/main';
import { connect } from "react-redux";
import ReservationTimeOutModal from './ReservationTimeOutModal';
import { showHideReservationTimeOutModal, ParkableModal, showHideMemberInvitedModal, showHideReservationCancelledModal } from '../../../redux/actions/modal';
import MemberInvitationModal from '../MemberInvitationModal';
import ReservationCancelled from './ReservationCancelled';
import _ from "lodash";
import {Feature} from "../../../model/Bay";


const ModalContainer = (p: ReturnType<typeof getReduxProps> & ActionType) => {

    const { show, modal, data, showHideReservationTimeOutModal, showHideMemberInvitedModal , showHideReservationCancelledModal, currentVehicle} = p;
    const isMotorBikeDefault = currentVehicle?.feature === Feature.MOTORBIKE;
    
    if (!show) return null;

    if (modal === ParkableModal.ReservationEnded) {
        return <ReservationTimeOutModal
            show={show}
            onHide={() => { showHideReservationTimeOutModal(false); }}
        />
    }
    if (modal === ParkableModal.ReservationCancelled) {
        return <ReservationCancelled
            show={show}
            message={data.massage}
            onHide={() => { showHideReservationCancelledModal(false); }}
        />
    }
    if (modal === ParkableModal.MemberInvited) {
        return <MemberInvitationModal show={show}
            organisation={data.organisation}
            parksInOrg={data.parksInOrg}
            onHide={() => { showHideMemberInvitedModal(false); }}
            isMotorBikeDefault={isMotorBikeDefault}
        />
    }

    return null;
}


const getReduxProps = (state: IRootReducer) => {
    const { show, modal, data } = state.parkableModal;
    
    const currentVehicle = _.first(state.user.vehicles?.filter(v => v.id === state.user.currentVehicleId)) || null;
    
    return {
        show,
        modal,
        data,
        currentVehicle
    }
}

const actions = {
    showHideReservationTimeOutModal,
    showHideMemberInvitedModal,
    showHideReservationCancelledModal
}

type ActionType = typeof actions;

export default connect(getReduxProps, actions)(ModalContainer);
