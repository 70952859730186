/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 12/09/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import React, {Component} from 'react';
import { StyleSheet, View, Modal as ModalComponent, Platform } from "react-native";
import PropTypes from 'prop-types';
import {Colours} from "../../util/colors";
import Container from "./Container";
import { MAX_WIDTH_WEB } from "../../root/container/container";

export default class Modal extends Component<any, { shown: boolean }> {

    static propTypes = {
        title: PropTypes.string,
        shown: PropTypes.bool,
    };

    static defaultProps = {
        title: "",
        shown: false,
    };


    constructor(props: any) {
        super(props);

        this.state = {
            shown: !!props.shown,
        };
    }

    show = () => {
        this.setState({
            shown: true,
        });
    };

    dismiss = () => {
        this.setState({
            shown: false,
        });
    };

    toggle = () => {

        if (this.state.shown) {
            this.dismiss();
        } else {
            this.show();
        }
    };


    onShow = () => {
        this.props.onShow?.();
    }

    onDismiss = () => {
        this.props.onDismiss?.();
    }

    onRequestClose = () => {
        this.props.onRequestClose?.();
    }

    render() {
        return (
            <ModalComponent animationType="slide"
                            transparent={false}
                            visible={this.state.shown}
                            onRequestClose={this.onRequestClose}
                            onShow={this.onShow}
                            {...this.props}>
                {!!this.props.plain ?
                    <View style={[styles.plain, this.props.style]}>
                        <View style={styles.inner}>
                            {this.props.children}
                        </View>
                    </View> :
                    <Container {...this.props}
                               overrideOnback
                               onBackPress={this.dismiss}
                               loading={!!this.props.loading}
                               style={[styles.mainContainer, this.props.style]}
                               contentContainerStyle={[{backgroundColor: Colours.ParkableWeirdBlue}, this.props.contentContainerStyle]}>
                        {this.props.children}
                    </Container>}
            </ModalComponent>
        );
    }
}


const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        backgroundColor: Colours.ParkableWeirdBlue,
    },
    plain: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    },
    inner: {
        ...Platform.select({
            web: {
                maxWidth: MAX_WIDTH_WEB,
                marginHorizontal: "auto"
            },
            default: {}
        }),
        height: "100%",
        backgroundColor: "#00000055",
    }
});
