import { StyleSheet } from "react-native";
import { theme } from "../../../util/theme";

export const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		marginBottom: theme.spacing(0.5),
	},

    seperator: {
        position: "absolute",
        height: '140%',
        width: 1,
        backgroundColor: theme.palette.GREY_20,
        marginRight: 4,
        left: -3,
        top: "-25%"
    },
})
