import { SharingPoolBayDTO } from "./BayDTO";
import {BayGroupType} from "./BayGroupType";
import {BayStatus} from "./Types";

export enum BayType {
    Dynamic = 'Dynamic',
    /**
     * Bay is used for classic Leases
     */
    Leasable = 'Leasable',

    /**
     * Bay is used for visitor parks
     */
    Visitor = 'Visitor',

    /**
     * Bay is used for public dynamic parking
     */
    PublicDynamic = 'PublicDynamic',

    /**
     * Bay is used for public subscription parking
     */
    PublicSubscription = 'PublicSubscription',
}

export enum Feature {
    SEDAN = "SEDAN",
    // 2-wheeled vehicles, for motorbike-only parking
    MOTORBIKE = "MOTORBIKE",
    ELECTRIC = "ELECTRIC",
}

export function toBayGroupType(bayType:BayType): BayGroupType{
    switch (bayType) {
        case BayType.Visitor: return BayGroupType.Visitor;
        case BayType.PublicDynamic: return BayGroupType.PublicDynamic;
        case BayType.PublicSubscription: return BayGroupType.PublicSubscription;
        case BayType.Dynamic:
        case BayType.Leasable:
        default: return BayGroupType.Standard;
    }
}

export type Bay = {
    termsOfLease: string | null;
    id:number,
    park: number,
    group:number | null,
    signage:string | null,
    subscription: number | null,
    type: BayType,
    features: Feature[] | null,
    cpoProvider:string | null,
    ocpiLocation:string | null,
    evse:string | null,
    sensor: string | null,
    leaseAvailableUntil: string | null;
    status: BayStatus | null;
    tandemPod: number | null | undefined;
}

export type SharingPoolBayWithTandemPod = Omit<SharingPoolBayDTO, "tandemPod"> & {
    tandemPod: number
}
