import {get, put} from './rest'
import { put as httpPut } from './http/rest'
import {UserOptions} from "../model/UserOptions";
import {UpdateUserOptionsRequest} from "../dto/UpdateUserOptionsRequest";
import {UserOptionsFavouriteParksResponse} from "../dto/UserOptionsFavouriteParksResponse";
import {Token} from "./rest";
import { useMySWR } from './http/use-swr';

export const getUserOptionsAPI = (url: string, token: Token) => get(token, `${url}v2/userOptions`) as Promise<{userOptions: UserOptions}>;

export const useUserOptions = () => useMySWR<{ userOptions: UserOptions }>(`v2/userOptions`);

export const updateUserOptionsAPI = (url: string, token: Token, data: UpdateUserOptionsRequest) => put(token, `${url}v2/userOptions`, data) as Promise<{userOptions: UserOptions}>;

export const updateUserOptions = (data: UpdateUserOptionsRequest): Promise<{ userOptions: UserOptions }> =>
    httpPut(`v2/userOptions`, data);

export const getFavouriteParksAPI = (url: string, token: Token) => get(token, `${url}v2/userOptions/favouriteParks`) as Promise<UserOptionsFavouriteParksResponse>;

export const saveParkToFavourite = (url:string, token:Token, parkLabel:string, parkId:number) => updateUserOptionsAPI(url, token, {favouriteParkLabel: parkLabel, favouritePark: parkId});

export const deleteFavouritePark = (url:string, token:Token, parkId:number) => updateUserOptionsAPI(url, token, {favouriteParkRemove: parkId});

export const setClientFlag = (url: string, token: Token, flag: string, value: boolean) => put(token, `${url}v2/userOptions/clientFlag?flag=${flag}&value=${value}`) as Promise<{userOptions: UserOptions}>;

