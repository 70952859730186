import * as React from 'react';
import {StyleSheet, View, Keyboard} from "react-native";
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import Text from "react/legacy/parkable-components/text/Text";
import {Park} from "../../model/Park";
import Strings from "../../util/localization/localization";
import { connect } from "react-redux";
import { IRootReducer } from '../../redux/reducers/main';
import { getActiveSubscriptions } from '../../util/getActiveSubscriptions';
import {useCallback} from "react";
import {ParkingType} from "../../model/Types";
import { Routes } from "../../navigation/root/root.paths";

type Props = {
    favouriteParks: {[text:string]: Park} | null,
    push: Function,
    parkingType: ParkingType
};

const getReduxProps = (state: IRootReducer) => {
    const activeSubscriptions = getActiveSubscriptions(state.subscriptions.employeeSubscriptions);
    return {
        currentSession: state.parking.currentSession,
        activeSubscriptions,
    }
}

const FavouriteParks = (props: Props & ReturnType<typeof getReduxProps>) => {

    const { favouriteParks: _favouriteParks, push, currentSession, activeSubscriptions, parkingType } = props;

    if(!_favouriteParks){
        return null;
    }

    const favouriteParks: {[text: string]: Park} = {};
    Object.keys(_favouriteParks).forEach((key) => {
        switch(parkingType) {
            case ParkingType.CASUAL: {
                if (_favouriteParks[key].hideCasualBays) {
                    break;
                }
                favouriteParks[key] = _favouriteParks[key];
                break;
            }
            case ParkingType.LONG_TERM: {
                if (_favouriteParks[key].hideSubscriptionBays) {
                    break;
                }
                favouriteParks[key] = _favouriteParks[key];
                break;
            }
            case ParkingType.ELECTRIC: {
                if (_favouriteParks[key].hideEVBays) {
                    break;
                }
                favouriteParks[key] = _favouriteParks[key];
                break;
            }
            default:
                favouriteParks[key] = _favouriteParks[key];
        }
    });

    const onPressRecent = useCallback((park: Park) => {
        Keyboard.dismiss();
        const parkId = park.id;
        const subInPark = activeSubscriptions.filter(s => s.park === parkId)[0];
        if(!!currentSession && !!currentSession.startedAt && currentSession.park === parkId) {
            push(Routes.ActiveSession);
        } else if(!!subInPark) {
            push(Routes.SingleSubscriptionView, {subscriptionId: subInPark.id})
        } else {
            push(Routes.ParkDetailView, {parkId, parkingType: parkingType});
        }
    }, [currentSession, parkingType, activeSubscriptions]);

    return <View style={styles.favouriteCarParksBox}>
            <Text bold small style={styles.favouriteCarParksTitle}>{Strings("favourite_car_parks")}</Text>
            {Object.keys(favouriteParks).map(entry =>
                <TableRow chevron
                          onPress={() => onPressRecent(favouriteParks[entry])}
                          labelBottom={favouriteParks[entry].address}
                          key={entry}
                          iconLeft={"star"}>
                    {entry}
                </TableRow>)}
    </View>
}

export default connect(getReduxProps)(FavouriteParks) as React.FunctionComponent<Props>;

const styles = StyleSheet.create({
    favouriteCarParksBox: {
        paddingTop: 27,
    },
    favouriteCarParksTitle:{
        textTransform: "uppercase",
    },
});
