import React from "react";
import { Routes } from "../../../navigation/root/root.paths";
import { createRoute, NavigationProps } from "../../../navigation/constants";
import { Platform } from "react-native";
import AddNewCardView from "./AddNewCardView";
import AddNewCardWebView from "./AddNewCardWebView";

export type AddNewCardProps = NavigationProps<Routes.AddNewCard>;

function AddNewCardRootView(props: AddNewCardProps) {
    return Platform.OS === "web"
        ? <AddNewCardWebView {...props}/>
        : <AddNewCardView {...props} />;
}

class AddNewCardParams {
    destination?: {
        route: Routes,
        params: any,
    };
}

export default AddNewCardRootView;

export const AddNewCardRoute = createRoute({
    options: {
        title: "Add New Card"
    },
    path: Routes.AddNewCard,
    params: {
        type: AddNewCardParams,
        deserialize: d => ({
            destination: d.Json(),
        })
    }
})
