import React from "react";
import { TouchableOpacity } from "react-native";
import { Bay } from "react/model/Bay";
import BayComponent from "./BayComponent";
import { getBayFeature, getBayTileColour } from "./util";
import { Colours } from "react/legacy/parkable-components";

type BayProps = {
    bays?: Bay[];
    signage: string;
    isSelected: boolean;
    isSubscription?: boolean;
    isSharingPool?: boolean;
    onPress?: (bay?: Bay) => void;
};

export const BayOption = (props: BayProps) => {
    const { bays, signage, isSelected, isSubscription, isSharingPool, onPress } = props;

    const backgroundColor = getBayTileColour(isSelected, isSubscription, isSharingPool);
    const { isEV, isMotorbike } = getBayFeature(bays);

    return (
        <TouchableOpacity activeOpacity={1} onPress={() => onPress?.(bays?.[0])}>
            <BayComponent
                signage={signage}
                displayMotorbikeIcon={isMotorbike}
                displayEvIcon={isEV}
                iconColour={isSelected ? Colours.NEUTRALS_WHITE : Colours.GREY_60}
                textColour={isSelected ? Colours.NEUTRALS_WHITE : Colours.GREY_60}
                backgroundStyles={{ backgroundColor }}
            />
        </TouchableOpacity>
    );
};
