import {StyleSheet, View} from 'react-native';
import React, {useMemo} from 'react';
import {Colours, Text} from 'react/legacy/parkable-components';
import Strings from '../../util/localization/localization';
import getParkAvailability from "react/util/getParkAvailability";
import localizeCurrency from "react/util/localization/localizeCurrency";
import {PricePeriod, PricePeriodType} from "react/model/parkingPrice/PricePeriod";
import {getPricePeriodByTypeAndDay} from "react/util/services/parkingPrice.service";
import getDayAvailability from "react/util/availability/getDayAvailability";
import {TerritoryDTO} from "react/api/territory/dto/TerritoryDTO";
import {ParkDTO} from "react/model/ParkDTO";
import {getPeriodsFormatted} from "react/components/pricing/constants";
import RatesTable from "react/components/pricing/RatesTable";

class CustomRatesTableProps {
    pricePeriods?: PricePeriod[];
    territory?: TerritoryDTO;
    park?: ParkDTO;
}

export default function CustomRatesTable(props: CustomRatesTableProps) {
    const {pricePeriods, territory, park} = props;

    const {periods } = getPeriodsFormatted(pricePeriods);
    const earlyBirdPeriod = periods?.find(p => p.type === PricePeriodType.EarlyBird);
    const nightPeriod = periods?.find(p => p.type === PricePeriodType.Night);
    const availability = park && getParkAvailability(park).availability;
    const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const otherRatesTableValues = useMemo(() => {
        const rowValues = [];
        if(!pricePeriods || !availability){
            return [];
        }

        for(let i = 0; i < weekdays.length; i++) {
            const day = weekdays[i];
            const isParkAvailable = getDayAvailability((weekdays.indexOf(day) + 1) % 7, availability, (weekdays.indexOf(day) + 1) % 7).available;

            const rowValue = [day.substring(0,3)];
            if(earlyBirdPeriod){
                const earlyBird = getPricePeriodByTypeAndDay(pricePeriods, PricePeriodType.EarlyBird, day)?.price ?? 0;
                rowValue.push(isParkAvailable ?
                    `${localizeCurrency(earlyBird, territory?.currencyCode, false)} ${Strings("max")}`
                    : `${Strings("closed")}`)
            }
            if(nightPeriod){
                const night = getPricePeriodByTypeAndDay(pricePeriods, PricePeriodType.Night, day)?.price ?? 0;
                rowValue.push(isParkAvailable ?
                    `${localizeCurrency(night, territory?.currencyCode, false)} ${Strings("max")}`
                    : `${Strings("closed")}`)
            }

            rowValues.push(rowValue);
        }
        return rowValues;
    }, [earlyBirdPeriod, nightPeriod, pricePeriods, availability])


    const columns = useMemo(() => {
        const columns = [""];
        if(earlyBirdPeriod){
            columns.push(Strings("earlyBird"));
        }
        if(nightPeriod){
            columns.push(Strings("night"));
        }
        return columns
    },[earlyBirdPeriod, nightPeriod]);

    if (!earlyBirdPeriod && !nightPeriod){
        return <></>;
    }

    return (<View>
        <Text p bold style={styles.paddingTitle}>{Strings("other_rates")}</Text>
        {earlyBirdPeriod && <Text p>
            {Strings("period_description")(Strings("earlyBird"), earlyBirdPeriod.start, earlyBirdPeriod.end, earlyBirdPeriod.cap)}
        </Text>
        }
        {nightPeriod && <Text p>
            {Strings("period_description")(Strings("night"), nightPeriod.start, nightPeriod.end, nightPeriod.cap)}
        </Text>
        }
        <RatesTable rows={otherRatesTableValues} columns={columns} />
    </View>);
};

const styles = StyleSheet.create({
    paddingTitle:{
        paddingTop: 24
    }
});
