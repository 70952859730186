import moment from "moment";
import {Term} from "../../../model/Term";

export function getNextPaymentDate(term: Term, anchorDay: number|null, weekAnchorDay: string|null, termStartedAt?: string | null){
    if(term === Term.Month && !!anchorDay){

        if (!!termStartedAt) {
            return moment(termStartedAt)
                .add({month: 1})
        }

        return moment()
            .date(1).hour(0).minute(0).second(0)
            .add((moment().date() >= +anchorDay! ? 1 : 0), "months")
            .date( +anchorDay! );
    }

    if(term === Term.Week && !!weekAnchorDay){

        if (!!termStartedAt) {
            return moment(termStartedAt)
                .add({week: 1})
        }

        const isNextWeek = moment().day(weekAnchorDay!).date() <= moment().date();

        return moment().add(isNextWeek ? 1 : 0, 'week')
            .day(weekAnchorDay!).hour(0).minute(0).second(0);
    }

    return moment();
}

export function getNextPaymentStartDate(termStartDate: string, term: Term){
    const startDate = moment(termStartDate);
    const now = moment();
    let nextDate = startDate;
    const period = term === Term.Month ? 'M' : 'week';
    while(nextDate <= now){
        nextDate = nextDate.add(1, period);
    }
    return nextDate;
}