/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 18/10/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import Strings from "../localization/localization";
import getHourlyAvail from "./getHourlyAvail";
import {DayAvailability} from "../../model/DayAvailability";
import {availableFullDay} from "./get24HoursAvailable";

export default function getAvailabilityText(dayAvailability: DayAvailability, permanentlyAvailable: boolean): string {

    if (!!permanentlyAvailable) {
        return Strings("open_247");
    }

    if (!dayAvailability.available) {
        return Strings("unavailable");
    }

    const availabilityArray = getHourlyAvail(dayAvailability);

    if (availableFullDay(availabilityArray)) {
        return Strings("available_all_day");
    }

    let text = "";
    let first = true;
    let openState = false;
    for (let i = 0; i < availabilityArray.length; i++) {
        const c = availabilityArray[i];
        if (!openState) {
            if (c) {

                if (first) {
                    // text += " ";
                }

                text += Math.floor(i / 2);//Hour
                text += ":";
                text += ((i % 2) === 1 ? "30" : "00");//minutes
                openState = true;
            }
        } else {
            if ((!c) || (c && i === availabilityArray.length - 1)) {

                if (c) {
                    i++;
                }

                text += " - ";
                text += Math.floor(i / 2);//Hour
                text += ":";
                text += ((i % 2) === 1 ? "30" : "00") + " ";//minutes
                openState = false;
            }
        }
    }
    return text;
}
