import QRAction, { ActionType } from "../../../model/QRAction";
import { useNavigation } from "react/navigation/constants";
import { Routes } from "react/navigation/root/root.paths";
import { logEvent } from "react/util/analytics";
import { MapPreferences, ParkingType } from "react/model/Types";
import { Feature } from "react/model/Bay";
import { BayDTO } from "react/model/BayDTO";

export default function (
    qrAction: QRAction,
    navigation: ReturnType<typeof useNavigation>,
    preferences: MapPreferences,
    bay?: BayDTO,
) {
    const { parkId, bayId, campusId, organisationId } = qrAction.actionParams;
    switch (qrAction.actionType) {
        case ActionType.ViewPark: {
            if (!parkId) return;
            navigation.navigate(Routes.ParkDetailView, { parkId: Number(parkId) });
            break;
        }

        case ActionType.ViewCampus: {
            if (!campusId) return;
            navigation.navigate(Routes.CampusScreenView, {
                campusId: Number(campusId),
                organisationId: Number(organisationId),
                parkingType: preferences.parkingType,
            });
            break;
        }

        case ActionType.StartCharging: {
            if (!parkId || !bayId || !bay) return;
            navigation.push(Routes.ConfirmStartParkingView, {
                parkId: Number(parkId),
                feature: Feature.ELECTRIC,
                parkingType: ParkingType.ELECTRIC,
                bay: bay,
                holdBay: true,
            });
            break;
        }
        case ActionType.ApplyVoucher:
        case ActionType.StartParking:
        default:
            break;
    }

    const params = {
        ...(qrAction?.actionParams ?? []),
        actionType: qrAction.actionType,
        actionStatus: qrAction.status,
    };
    logEvent(undefined, "qr_action", params);
}
