import fetch from "../reducers/fetch";
import * as Api from "../../api/voucher";
import {DispatchFunc} from "../../model/Types";
import {Token} from "../../api/rest";
import { ActivityType, Voucher } from "../../model/Voucher";
import { Moment } from "moment";

export const VOUCHERS_RECEIVED = 'VOUCHERS_RECEIVED';
export const SET_CASUAL_VOUCHER_PARK = 'SET_CASUAL_VOUCHER_PARK';
export const SET_LONG_TERM_VOUCHER_PARK = 'SET_LONG_TERM_VOUCHER_PARK';

const vouchersReceived = (vouchers: any[]) => ({
    type: VOUCHERS_RECEIVED,
    vouchers
})

export function getVouchers(onSuccess?: (vouchers: any[]) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getVouchers(api, token).then(({vouchers}) => {
            dispatch(vouchersReceived(vouchers));
            onSuccess?.(vouchers);
        }).catch((err) => {
            if(err !== 'network_error') {
                onError?.(err);
            }
            else throw err;
        })
    }, arguments);
}

export function checkForVoucherWithCode(code: string, onSuccess?: (vouchers: any[]) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.checkForVoucherWithCode(api, token, code).then(({voucher}) => {
            dispatch(vouchersReceived([voucher]));
            onSuccess?.([voucher]);
        }).catch((err) => {
            if(err !== 'network_error') {
                onError?.(err);
            }
            else throw err;
        })
    }, arguments);
}

export function setVoucherForPark(voucher: Voucher|null, parkId:number, activity: ActivityType, subscriptionId?: number) {
    return {
        type: activity === 'Casual' ? SET_CASUAL_VOUCHER_PARK : SET_LONG_TERM_VOUCHER_PARK,
        voucher,
        parkId,
        subscriptionId
    }
}

export function retrieveLargestDiscountVoucher(parkId: number, activity: ActivityType, subscriptionId?:number, date?: Moment, onSuccess?: (voucher: Voucher) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.retrieveLargestDiscountVoucher(api, token, parkId, activity, date).then(({voucher}) => {

            dispatch(setVoucherForPark(voucher, parkId, activity, subscriptionId));
            if(onSuccess){
                onSuccess(voucher);
            }
            return voucher;
        }).catch((err) => {
            if(onError){
                onError(err);
            }
            if(err === 'network_error'){
                throw err;
            }
        });
    }, arguments);
}
