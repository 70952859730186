import _, { isEqual } from "lodash";
import React, {useCallback} from "react";
import {Image, Platform, View} from "react-native";
import {connect} from "react-redux";
import { Routes } from "react/navigation/root/root.paths";
import {IMarker} from "../../../model/Types";
import {IRootReducer} from "../../../redux/reducers/main";
import MyMarker from "../Marker";
import {buildMarker, buildCampusMarker, markerVisibleWithPreferences} from "../ParkMarkerBuilder";
import { Marker, LatLng } from "react-native-maps";
import { getActiveSubscriptions } from "../../../util/getActiveSubscriptions";
import {getId} from "../../../util/Util";
import {Feature} from "../../../model/Bay";
import {useNavigation} from "../../../navigation/constants";
const searchMarkerImage = require("../../../resources/redMapPin.png");

function zOrderMarkers(markers: IMarker[], zIndexOffset: number): IMarker[] {

    const latSorted = _.orderBy(markers, 'coordinate.latitude', 'desc');

    latSorted.forEach((m, i) => {
        m.extra.zIndex = zIndexOffset
            + i
            + (m.extra.isOrgOrFav ? 3 * latSorted.length : 0)
            + (m.extra.parkIsFull ? 0 : 2 * latSorted.length)
            + (m.extra.parkIsOpen ? latSorted.length : 0);
    });

    return latSorted;
}

type MapMarkersProps = {searchPoint?: LatLng};

type Props = ReturnType<typeof getReduxProps> & MapMarkersProps;

function MapMarkers(props: Props) {
    const navigation = useNavigation();
    const {markers, preferences, currentSession, searchPoint, activeSubscriptions, currentVehicle} = props;

    const isMotorBikeDefault = currentVehicle?.feature === Feature.MOTORBIKE;

    const onMarkerPress = useCallback((e: { nativeEvent: { id: string } }) => {
        const id = e.nativeEvent.id;

        // campus id sample - `campus_${id}_organisation_${organisation}_markertext_${markerText}`
        if (id.startsWith("campus_")) {
            const ids = id.split('_organisation_');
            const REGEX = /\D+/g;
            const sanitizedCampusId = ids[0].replace(REGEX, "");
            const sanitizedMarkerText = ids[1].split("_markertext_")
            const sanitizedOrganisationId = sanitizedMarkerText[0].replace(REGEX, "");
            const campusId = parseInt(sanitizedCampusId);
            const organisationId = parseInt(sanitizedOrganisationId);
            navigation.navigate(Routes.CampusScreenView, {campusId, organisationId, parkingType: preferences.parkingType});
        } else {

            const parkId = parseInt(e.nativeEvent.id, 10);
            const subInPark = activeSubscriptions.filter(s => s.park === parkId)[0];
            if (!currentSession?.endedAt && currentSession?.park === parkId && !!currentSession.startedAt) {
                navigation.navigate(Routes.ActiveSession, {});
            } else if (!!subInPark) {
                navigation.navigate(Routes.SingleSubscriptionView, {subscriptionId: subInPark.id});
            } else {
                navigation.navigate(Routes.ParkDetailView, {parkId, parkingType: preferences.parkingType});
            }
        }
    }, [currentSession, activeSubscriptions, preferences]);

    return (
        <>
            {!!searchPoint && <Marker
                zIndex={999999999}
                //@ts-ignore
                markerType={Platform.OS === 'ios' ? undefined : {type: 'search'}}
                coordinate={searchPoint}>
                {Platform.OS !== 'ios' ? undefined :<View style={{height: 54, width: 42}}>
                    <Image source={searchMarkerImage}/>
                </View>}
            </Marker>}
            {markers.map((marker: IMarker) => {
                return (
                    <MyMarker key={marker.id} marker={marker} preferences={preferences} isMotorBikeDefault={isMotorBikeDefault} onMarkerPress={onMarkerPress}/>
                );
            })}
        </>
    );
}

const getReduxProps = (state: IRootReducer) => {
    const user = state.user.user;
    const preferences = state.maps.preferences;
    const parks = state.parks.mapParks;
    const campuses = state.campuses.mapCampuses;
    const userRoles = state.user.userRoles;
    const parkingType = state.maps.preferences.parkingType;

    const activeSubscriptions = getActiveSubscriptions(state.subscriptions.employeeSubscriptions);

    const favParkIds:number[] = [
        ..._.values(state.userOptions.favouriteParks).map(p => p.id),
        ...activeSubscriptions.map(({park}) => getId(park))
    ];
    const currentVehicle = _.first(state.user.vehicles?.filter(v => v.id === state.user.currentVehicleId)) || null;

    const isMotorBikeDefault = currentVehicle?.feature === Feature.MOTORBIKE;

    const parkMarkers = ((!user ? [] : Object.values(parks))
        .map((park) => buildMarker(preferences, park, user, userRoles??[], isMotorBikeDefault, favParkIds))
        .filter(m => m !== null) as IMarker[])
        .filter(m => markerVisibleWithPreferences(m, preferences, parkingType))
        .filter(m => !m.isCampus)
        .filter(m => !!m?.id);

    const campusesMarkers = Object.values(campuses)
        .map(campus => buildCampusMarker(campus, userRoles??[], isMotorBikeDefault, favParkIds, preferences, parks));

    const markers = [...parkMarkers, ...campusesMarkers];

    const zOrderedMarkers = zOrderMarkers(markers, 0);

    return {
        user,
        favParkIds,
        currentVehicle,
        markers: zOrderedMarkers,
        preferences,
        currentSession: state.parking.currentSession,
        activeSubscriptions
    };
};

export default connect(getReduxProps)(
        React.memo(MapMarkers,
            (prevProps: Props, nextProps: Props) => {
                if (prevProps.user?.id !== nextProps.user?.id) {
                    return false
                }
                if (!isEqual(_.sortBy(prevProps.markers.map(m => m.id)), _.sortBy(nextProps.markers.map(m => m.id)))) {
                    return false;
                }
                if (!isEqual(prevProps.preferences, nextProps.preferences)) {
                    return false;
                }
                // noinspection RedundantIfStatementJS
                if (!isEqual(prevProps.currentSession, nextProps.currentSession)) {
                    return false;
                }
                if (!isEqual(_.sortBy(prevProps.favParkIds), _.sortBy(nextProps.favParkIds))) {
                    return false;
                }
                return true;
            })
) as React.FunctionComponent<MapMarkersProps>;

