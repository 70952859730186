import * as Type from '../actions/territories'
import {Territory} from "../../model/Territory";

export type ITerritoryMap = {[key:number]: Territory};

const init = {
    territories: {} as ITerritoryMap
};

export type ITerritoryReducer = typeof init;

export default function dataReducer(state = init, action: any): ITerritoryReducer {
    switch (action.type) {
        case Type.RECEIVED_TERRITORIES:
            const territories = { ...state.territories } as ITerritoryMap;
            action.territories.forEach((t: Territory) => {
                territories[t.id] = t;
            });
            return {
                ...state,
                territories,
            };
        default:
            return state;
    }
}