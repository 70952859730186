import React, { useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { INotification } from "./BuildNotifications";
import Card from "react/legacy/parkable-components/card/Card";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Text from "react/legacy/parkable-components/text/Text";

export interface NotificationWidgetProps {
    notification: INotification,
    style: any
}

function NotificationWidget ({notification, style}: NotificationWidgetProps) {

    const notificationColor = { backgroundColor: notification.color ?? Colours.BLUE_300 };

    const onPress = useCallback(() => {
        notification.action();
    }, [notification]);

    return (
        <Card activeOpacity={0.65} style={[styles.container, style]} onPress={onPress} testID={notification.mainText}>
            <View style={styles.innerContainer}>
                <View style={[styles.notificationColor, notificationColor]}/>
                <View style={[styles.msgContainer, {paddingVertical: 15}]}>
                    <View style={styles.mainTextContainer}>
                        <Text small style={styles.mainText}>{notification.mainText}</Text>
                    </View>
                    <View style={styles.actionTextContainer}>
                        <Text small style={styles.actionText}>{notification.actionText}</Text>
                    </View>
                </View>
            </View>
        </Card>);
}

export default React.memo(NotificationWidget);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
        justifyContent: 'flex-start',
        position: "relative",
    },
    innerContainer:{
        flex: 1,
        flexDirection: 'row',
        overflow: 'hidden',
    },
    msgContainer:{
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 9
    },
    mainTextContainer: {
        paddingRight: 9,
        flex: 1,
        justifyContent: "center",
    },
    mainText: {
        lineHeight: 14,
        fontSize: 13
    },
    actionTextContainer: {
        width: 63,
        alignItems: "flex-end",
        justifyContent: "center",
    },
    actionText: {
        color: Colours.BLUE_300,
        lineHeight: 14,
        fontSize: 13,
    },
    notificationColor: {
        width: 6
    }
});
