/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 15/01/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

// @ts-ignore
import currencyFormatter from 'currency-formatter';
import lowestCurrencyFormatter from "./currencies/fromLowestCurrencyFormatter";

const localizeCurrency = (amount: number, code?: string, addCode = true, decimalPlaceIfAvail = true, formatToLowest = true): string => {

    if (!code) {
        return ''
    }

    const formattedAmount = formatToLowest ? lowestCurrencyFormatter(amount, code) : amount;

    const options: any = {
        code
    };

    if (!decimalPlaceIfAvail) {
        options.precision = 0;
    }
    return currencyFormatter.format(formattedAmount, options);
};

export default localizeCurrency;
