import React, {FunctionComponent, useCallback, useState} from "react";
import {Linking, StyleSheet, View} from "react-native";
import Button from "react/legacy/parkable-components/button/Button";
import Colours from "react/legacy/parkable-components/styles/Colours";
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import Text from "react/legacy/parkable-components/text/Text";
import Dialog from "../../components/dialog/Dialog";
import Strings from '../../util/localization/localization';
import {Routes} from "react/navigation/root/root.paths";
import {connect} from 'react-redux';
import {IRootReducer} from "../../redux/reducers/main";
import {deleteInstallationId, removeUserToken, setUser, userLoggedOut} from "../../redux/actions/user";
import {DispatchFunc} from "../../model/Types";
import {FirebaseService} from "../../util/services/firebase.service";
import {createRoute, useNavigation} from "../../navigation/constants";
import ParkableBaseView from "../../components/common/ParkableBaseView";
import {useSelector} from "../../redux/redux";
import {useIsAdminImpersonating} from "react/util/hooks/useIsAdminImpersonating";
import {hasPermissionToFeature} from "react/util/security/permission";
import {useCurrentUser} from "react/api/user/user.api";


type Props = {
    dispatch: DispatchFunc
} & ReturnType<typeof reduxProps>;

const AccountView = (props: Props) => {
    const navigation = useNavigation();
    const { installationId, dispatch } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [showLogoutDialog, setShowLogoutDialog] = useState(false);

    const { user } = useCurrentUser();
    const { userRoles } = useSelector(state => state.user);
    const hasPermissionToLoginAsAnotherUser = user && userRoles && hasPermissionToFeature(user, userRoles, "app.account.settings.login-as", {});
    const isAdminImpersonating = useIsAdminImpersonating();

    const onMyDetails = useCallback(() => {
        navigation.push(Routes.MyDetailsView, {})
    }, [navigation]);

    const onMyVehicles = useCallback(() => {
        navigation.push(Routes.VehiclesView, {})
    }, [navigation]);

    const onPaymentMethods = useCallback(() => {
        navigation.push(Routes.CreditCardView)
    }, [navigation]);

    const onNotificationSettings = useCallback(() => {
        navigation.push(Routes.NotificationSettingsView)
    }, [navigation]);

    const onRfidCardPress = useCallback(() => {
        navigation.push(Routes.RfidCardsView)
    }, [navigation])

    const onPrivacySettingsPress = () => {
        navigation.push(Routes.PrivacySettingsView);
    };

    const onLoginAsPress = () => {
        navigation.push(Routes.AdminLoginAsUserView);
    }

    const onTermsClick = async () => {
        const URL = "https://www.parkable.com/terms-of-service?utm_source=parkableapp&utm_medium=TOSbutton";
        if (await Linking.canOpenURL(URL)) {
            Linking.openURL(URL).catch(console.error);
        }
    }

    const onLogoutPress = () => {
        setShowLogoutDialog(true);
    };

    const onLogoutNegativePress = () => {
        setShowLogoutDialog(false);
    };

    const onLogoutConfirmPress = async () => {
        setShowLogoutDialog(false);
        setLoading(true);
        // If the logout dialog is still visible when the nav stack is reset, then the app crashes.
        // So we need to delay the logout until after the dialog is gone.
        setTimeout(onLogout, 999);
    };

    const onLogout = async () => {
        if (!!installationId) {
            dispatch(deleteInstallationId(installationId));
        }
        dispatch(removeUserToken());
        dispatch(setUser(null));
        dispatch(userLoggedOut());
        FirebaseService.logout().catch(console.error);
        setLoading(false);
    };

    return <ParkableBaseView scrollable={false} loading={loading}>
            <View style={styles.base}>
                <View style={styles.header}>
                    <Text h1 bold>{Strings("account_settings")}</Text>
                </View>
                <View style={styles.section}>
                    <TableRow chevron onPress={onMyDetails}>
                        {Strings("my_details")}
                    </TableRow>
                    <TableRow chevron onPress={onMyVehicles}>
                        {Strings("my_vehicles")}
                    </TableRow>
                    <TableRow chevron onPress={onPaymentMethods}>
                        {Strings("payment_methods")}
                    </TableRow>
                    <TableRow chevron onPress={onRfidCardPress}>
                        {Strings("rfid_cards")}
                    </TableRow>
                    <TableRow chevron onPress={onNotificationSettings}>
                        {Strings("notifications_preferences")}
                    </TableRow>
                    <TableRow chevron onPress={onPrivacySettingsPress}>
                        {Strings("privacy_settings")}
                    </TableRow>
                    {hasPermissionToLoginAsAnotherUser && !isAdminImpersonating && (
                        <TableRow chevron onPress={onLoginAsPress}>
                            {Strings("login_as")}
                        </TableRow>
                    )}
                </View>
            </View>
            <View style={styles.footer}>
                <Button plain border center textProps={{h5:true}} style={[{flex: 15}, styles.buttonItem]} onPress={onTermsClick}>{Strings("terms_of_service")}</Button>
                <View style={{flex: 2}} />
                <Button plain border center textProps={{h5:true}} style={[{flex: 11}, styles.buttonItem]} onPress={onLogoutPress}>{Strings("log_out")}</Button>
            </View>
            <Dialog isVisible={showLogoutDialog}
                    label={Strings("log_out")}
                    labelProps={{style: {color: Colours.NEUTRALS_BLACK, textAlign: 'left'}}}
                    title={Strings("are_you_sure_logout")}
                    titleProps={{style: {textAlign: 'left'}, h2: undefined}}
                    negativeText={Strings("no")}
                    negativeProps={{textProps: {h5: true}}}
                    positiveText={Strings("log_out")}
                    positiveProps={{red: true, textProps: {h5: true}}}
                    onPositivePress={onLogoutConfirmPress}
                    onNegativePress={onLogoutNegativePress}
                    onBackdropPress={onLogoutNegativePress}
            />
    </ParkableBaseView>

}

const reduxProps = (state: IRootReducer) => {
    return {
        installationId: state.user.installationId,
        user: state.user.user,
        apiName: state.data.apiName,
    }
}

const styles = StyleSheet.create({
    base:{
        flex: 1,
    },
    header: {
        width: "100%",
    },
    section: {
        flex: 1,
    },
    footer: {
        flexDirection: 'row',
        paddingTop: 27,
    },
    buttonItem: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: 7,
    },
});

export default connect(reduxProps)(AccountView) as FunctionComponent<Props>

export const AccountRoute = createRoute({
    path: Routes.AccountView,
});
