import * as Push from "./constants";
import { StateType } from "./constants";
import React, { useEffect, useState } from "react";
import { Routes } from "../root/root.paths";
import * as Notifications from "expo-notifications";
import { Notification, NotificationResponse } from "expo-notifications/src/Notifications.types";
import { Route } from "../constants";
import { Nully } from "../../util/nully";
import { useActiveParkSession } from "../../api/parkSession/parkSession.api";
import notifyListeners from "./notificationListener";
import {
    showHideMemberInvitedModal,
    showHideReservationCancelledModal,
    showHideReservationTimeOutModal
} from "../../redux/actions/modal";
import { getFavouriteParks } from "../../redux/actions/userOptions";
import { getCurrentParkingSession, getPaymentsRequiringAuthentication, getSession } from "../../redux/actions/parking";
import { getParksInOrg } from "../../redux/actions/parks";
import { getOrganisation } from "../../redux/actions/organisations";
import { handleMessageModal, loadParkableUser, setUser } from "../../redux/actions/user";
import { useAppDispatch, useSelector } from "../../redux/redux";
import { ModalType } from "../../components/common/ModalMessageCard";
import { SnackOptions } from "../../root/alert-snackbar/options";
import { useAlertSnackbar } from "../../root/alert-snackbar/alert-snackbar";

export const usePushNotifications = () => {

    const dispatch = useAppDispatch();
    const {showSnackBar} = useAlertSnackbar();
    const { api } = useSelector(state => state.data);
    const { fireBaseToken } = useSelector(state => state.auth);

    const {parkSession, mutate: mutateCurrentSession} = useActiveParkSession();

    const [pushRoute, setPushRoute] = useState<Nully<Route>>(undefined);

    useEffect(() => {

        Notifications.addNotificationReceivedListener(onForegroundNotification);

        Notifications.addNotificationResponseReceivedListener(handleNotificationResponse);

    }, ["once"]);

    const handleNotificationResponse = (event: NotificationResponse) => {
        console.log("handleNotificationResponse", event);
        if (event && event.notification.request.content.data) {
            handlePushNotification(event.notification.request.content.data, Push.StateType.Closed);
        }
    };

    const onForegroundNotification = (notification: Notification) => {

        const msgData = notification.request.content.data;
        const body = notification.request.content.body || undefined;
        console.log("MESSAGES: onForegroundNotification data", msgData);

        if (msgData) {
            notifyListeners(dispatch, msgData).then(r => {
                if (!r) {
                    if (msgData.body) {
                        let organisationId = undefined;
                        if (!!msgData.organisationId) {
                            organisationId = Number.parseInt(msgData.organisationId as string);
                        }

                        switch (msgData.code) {

                            case Push.ReservationTimedOut: {
                                // show modal
                                console.log("ReservationTimedOut - ");
                                dispatch(showHideReservationTimeOutModal(true));
                                return;
                            }
                            case Push.ReservationCancelled: {
                                // show modal
                                console.log("ReservationCancelled - ", msgData.body);
                                dispatch(showHideReservationCancelledModal(true, body));
                                return;
                            }
                            case Push.EmployeeSubscriptionCancelled : {
                                dispatch(getFavouriteParks());
                                return;
                            }
                            case Push.EvSessionStarted:
                            case Push.EvSessionStopped: {
                                if (!!msgData.parkSessionId) {
                                    dispatch(getSession(msgData.parkSessionId as string));
                                }
                                return;
                            }
                            case Push.MemberInvited: {
                                //popup member invitation modal
                                if (!!organisationId) {
                                    (async () => {
                                        const user = await loadParkableUser(api, {firebaseToken: fireBaseToken, parkableToken: undefined});
                                        dispatch(setUser(user));
                                        const organisation = await dispatch(getOrganisation(organisationId));
                                        const parksInOrg = await dispatch(getParksInOrg(organisationId));
                                        // show modal
                                        dispatch(showHideMemberInvitedModal(true, organisation, parksInOrg));
                                    })()
                                }
                                return;
                            }
                            case Push.PaymentActionRequired: {
                                dispatch(getPaymentsRequiringAuthentication());
                                break;
                            }
                            default:
                                break;
                        }

                        const options = handlePushNotification(msgData, Push.StateType.Opened);

                        showSnackBar(options);
                    }
                }
            })
        }
    };

    const handlePushNotification = (notification: any, status: Push.StateType) => {
        console.log("handlePushNotification", notification, status);

        let isStatic = false;
        let route = undefined as Route|undefined;
        switch (notification.code) {
            case Push.EmployeeSubscriptionInvited:
                const onPress = () => {
                    dispatch(handleMessageModal(true, ModalType.SubscriptionInvitation, notification.employeeSubscriptionId));
                };

                if (status === Push.StateType.Closed) {
                    onPress();
                }

                return {onPress, text: notification.body, isStatic: true} as SnackOptions;
            case Push.EmployeeSubscriptionCreated:
            case Push.EmployeeSubscriptionUpdated:
                isStatic = true;
                    route = {
                        name: Routes.SingleSubscriptionView,
                        params: {
                            subscriptionId: notification.employeeSubscriptionId,
                            notificationCode: notification.code
                        }
                    }
                break;
            case Push.WarningSessionRunning:
                isStatic = true;
                route = {
                    name: Routes.WarningOpenSessionView,
                }
                break;
            case Push.AutoEndSession:
                isStatic = true;
                route = {
                    name: Routes.AutoEndSessionView,
                    params: {sessionId: notification.sessionId}
                }
                break;
            case Push.ParkingStarted:
                isStatic = true;
                route = {
                    name: Routes.ActiveSession,
                    params: {sessionId: notification.sessionId}
                }
                break;
            case Push.CurrentParkIsGoingUnavailable:
                isStatic = true;

                route = {
                    name: Routes.ActiveSession
                }
                break;
            case Push.ParkingEnded:
            case Push.SessionEndedInUnavailablePark:
                isStatic = true;
                route = {
                    name: Routes.SessionSummary,
                    params: {sessionId: notification.sessionId}
                }
                break;
            case Push.EmployeeSubscriptionWentUnavailable:
            case Push.UnpaidEmployeeSubscription:
            case Push.EmployeeSubscriptionPastDue:
            case Push.LeaseEnded:
            case Push.LeaseWentUnavailable:
            case Push.LeasePastDue:
            case Push.UnpaidLease:
            case Push.UnpaidLeaseEnded:
                isStatic = true;

                route = {
                    name: Routes.SubscriptionListView
                }
                break;
            case Push.EvSessionStarted:
            case Push.EvSessionStopped:
            case Push.EvSessionFailedToStart:
            case Push.EvSessionChargerIdle:

                isStatic = true;
                route = {
                    name: !!parkSession ? Routes.ActiveSession : Routes.SessionSummary,
                    params: {sessionId: notification.parkSessionId}
                }
                break;
            case Push.LeaseCreated:
            case Push.DefaultNotification:
            case Push.HostParkingStarted:
            case Push.HostParkingEnded:
            case Push.HostReservationStarted:
                break;
            case Push.ReservationTimedOut:
            case Push.ReservationCancelled:
                dispatch(getCurrentParkingSession());
                break;
            case Push.BayMadeAvailableInGroup:
            case Push.OrganisationGroupInvite:
            case Push.EmployeeSubscriptionCancelled:
            case Push.SessionBayChanged:
                break;
            case Push.TandemMessageReceived:
                isStatic = true;
                route = {
                    name: Routes.TandemChatView,
                    params: {tandemPodId: notification.tandemPodId, chatId: notification.chatId}
                }
                break;
            case Push.PaymentActionRequired:
                route = {
                    name: Routes.RetryPaymentRequest,
                    params: {sessionId: notification.sessionId}
                };
                break;
            case Push.ParkAvailabilityNotificaiton:
                isStatic = true;
                route = {
                    name: Routes.ParkDetailView,
                    params: {parkId: notification.parkId}
                }
                break;
            case Push.AddPaymentAndVehicleReminder:
                route = {
                    name: Routes.AccountView
                };
                break;
            case Push.OpenBookingsView: {
                route = {
                    name: Routes.UserBookingsAndRequestsView
                }
                break;
            }
            case Push.CustomRouter: {
                route = {
                    name: notification.routerName,
                }
                if (notification.routerParams) {
                    route.params = JSON.parse(notification.routerParams);
                }
                break;
            }
            default:
                break;
        }

        if(route && status === StateType.Closed){
            setPushRoute(route)
        }

        const onPress = () => {
            setPushRoute(route);
        }


        return {onPress, text: notification.body, isStatic} as SnackOptions;
    };

    return pushRoute;
}


