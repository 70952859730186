import { Nully } from "../../util/nully";
import { TandemPodDTO } from "../../model/TandemPod/TandemPodDTO";
import {useMySWR, useMySWRInfinite} from "../http/use-swr"
import {ChatDTO} from "../../model/chat/ChatDTO";
import {generateQueryString, post, put} from "../http/rest";
import {ChatParticipant} from "../../model/chat/ChatParticipantDTO";
import { TandemChatsSessionResponse } from "./dto/ChatsResponse";
import { ChatMessageDTO } from "react/model/chat/ChatMessageDTO";
import { UpdateEstimatedLeaveTimeRequest } from "./dto/updateTandemPodChat.request";
import { ParkSession2Response } from "../parking";

export const useTandemPod = (tandemPodId: Nully<number>) => {
    return useMySWR<{tandemPod: TandemPodDTO}>(tandemPodId ? `v1/tandemPods/${tandemPodId}` : null);
};

export const useTandemChat = (tandemPodId: Nully<number>, chatId: Nully<number>) => {
    return useMySWR<{chat: ChatDTO}>(tandemPodId && chatId ? `v1/tandemPods/${tandemPodId}/message/${chatId}` : null);
};

export const useTandemChatBySessionId = (tandemPodId: Nully<number>, sessionId: Nully<number>) => {
    return useMySWR<{chat: ChatDTO}>(tandemPodId && sessionId
        ? `v1/tandemPods/${tandemPodId}/message/session/${sessionId}`
        : null);
};

export const useTandemChatParticipants = (tandemPodId: Nully<number>, chatId: Nully<number>) => {
    const query = generateQueryString({chatId});
    return useMySWR<{participants: ChatParticipant[]}>(tandemPodId ? `v1/tandemPods/${tandemPodId}/participants${query}` : null);
};

export const updateEstimatedLeaveTime = (sessionId: number, data: UpdateEstimatedLeaveTimeRequest) => {
    return put(`v1/tandemPods/${sessionId}/updateEstimatedLeaveTime`, data) as Promise<ParkSession2Response>;
};

export const createTandemChat = (tandemPodId: number, request: { body: string; photos?: string[] }) => {
    return post<{chat: ChatDTO}>(`v1/tandemPods/${tandemPodId}/message`, request);
};

export const sendMessageInChat = (chatId: number, request: { body: string; photos?: string[] }) => {
    return put<{chat: ChatDTO}>(`v1/tandemPods/${chatId}/message`, request);
};

export const useTandemChatsWithCursor = () => {
    const getKey = (pageIndex: number, previousPageData: TandemChatsSessionResponse | null) => {
        if (pageIndex === 0) {
            return [`v2/tandemPods/chatsAndSession`];
        }

        return [
            `v2/tandemPods/chatsAndSession`,
            JSON.stringify({
                cursor: previousPageData?.cursor,
            }),
        ];
    };

    return useMySWRInfinite<TandemChatsSessionResponse>(getKey);
};

export const useLastMessageOfTandemChat = (chatId: Nully<number>) => {
    return useMySWR<{message: ChatMessageDTO}>(chatId ? `v1/tandemPods/chat/${chatId}/last/message` : null);
};
