import React, { useMemo, useState } from "react";
import { FlatList, StyleSheet, View } from "react-native";
import Button from "react/legacy/parkable-components/button/Button";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Text from "react/legacy/parkable-components/text/Text";
import { updateEmployeeSubscriptionAvailability } from "../../api/subscription";
import { setEmployeeSubscription } from "../../redux/actions/subscriptions";
import { showAlert } from "../../util/alerts";
import moment from "moment";
import SiteAvailabilityTileMonth from "../AvailabilityCalendar/SiteAvailabilityTileMonth";
import Strings from "../../util/localization/localization";
import { ParkAvailability } from "../../util/getParkAvailability";
import ParkableBaseView from "../common/ParkableBaseView";
import { PADDING } from "../../root/root.constants";
import _, { isEqual, sortBy } from "lodash";
import useAuthToken from "../../util/useAuthToken";
import { useAppDispatch, useSelector } from "../../redux/redux";
import {RecurrentSharingDialog} from "./RecurrentSharingDialog";
import {useMakeBayAvailableContext} from "./context";
import { UnshareBayDialog } from "./UnshareBayDialog";
import { useUnshareBayContext } from "../parkingViews/Subscriptions/context";
import { BayInUseDialog } from "../parkingViews/Subscriptions/BayInUseDialog";
import { EmployeeSubscriptionDTO } from "../../dto/EmployeeSubscriptionDTO";
import { RecurrentSharingActions, RecurrentSharingDurations } from "./types";

type Props = {
    employeeSubscriptionId: number,
    onClose: (sub?: EmployeeSubscriptionDTO) => void,
    parkAvailability: ParkAvailability,
    mutate: () => void
};

interface YearMonth {
    year: number,
    month: number,
}

export const generateDatesByDurationAndDaysOfWeek = (durationType: RecurrentSharingDurations, daysOfWeek: number[], duration: number) : string[] => {
    const today = moment(); // start from today
    const endDate = moment().add(duration, durationType === RecurrentSharingDurations.Week ? "week" : "month");

    const currentDate = today;
    const result = [];

    while (currentDate.isBefore(endDate)) {
        if (daysOfWeek.includes(currentDate.day())) {
            result.push(currentDate.clone().format("YYYY-MM-DD")); // clone to avoid mutating the original date
        }
        currentDate.add(1, 'days');
    }

    return result;
}

export default function MakeBayAvailableView(props: Props) {

    const {
        parkAvailability,
        onClose,
        mutate
    } = props;

    const token = useAuthToken();

    const { api } = useSelector(state => state.data);
    const { employeeSubscriptions } = useSelector(state => state.subscriptions);

    const employeeSubscription = employeeSubscriptions[props.employeeSubscriptionId];

    const [loading, setLoading] = useState(false);
    const [requestInBulkUnshare, setRequestInBulkUnshare] = useState<boolean>(false)
    const [numberOfMonths, setNumberOfMonths] = useState(2);
    const context = useMakeBayAvailableContext();
    const unshareContext = useUnshareBayContext();

    if(employeeSubscription) {
        unshareContext?.setSubscription(employeeSubscription)
    }

    const monthTiles = useMemo<YearMonth[]>(() => {
        const monthTiles = [];

        const currentMonth = moment().month();
        const currentYear = moment().year();

        for (let i = 0; i < numberOfMonths; i++) {
            const month = currentMonth + i;
            monthTiles.push({ month: month % 12, year: currentYear + Math.floor(month / 12) });
        }

        return monthTiles;

    }, [numberOfMonths]);

    const dispatch = useAppDispatch();

    const initialSelectedDays = useMemo(() => {
        if (!!employeeSubscription?.availabilityRules
            && !!employeeSubscription.availabilityRules.availableTo
            && employeeSubscription.availabilityRules.availableTo instanceof Array) {

            return employeeSubscription.availabilityRules.availableTo
                .map((d) => moment(d.date).format("YYYY-MM-DD"))
        }
    }, [employeeSubscription]);

    const bookedDays = useMemo(() => {
        if(unshareContext?.parkingRequests && unshareContext?.parkingRequests.length > 0) {
            const dates = unshareContext?.parkingRequests
                .map((request) => request.date);
            if(dates){
                return [...(new Set(dates))];
            }
        }
        return [] as string[];
    }, [unshareContext?.parkingRequests])

    const [newSelectedDays, setNewSelectedDays] = useState<string[]>(initialSelectedDays ?? []);

    const onConfirmPress = () => {
        setLoading(true);
        const subId = employeeSubscription.id;

        updateEmployeeSubscriptionAvailability(api, token, subId, newSelectedDays).then((r) => {
            dispatch(setEmployeeSubscription(r.employeeSubscription));
            onClose(r.employeeSubscription);
        }).catch((err) => {
            if (err && err.message) {
                showAlert(err.message);
            } else {
                showAlert(Strings("internal_error_if_persists"));
            }
            setLoading(false);
        });
    };

    const onCancelPress = () => {
        setNewSelectedDays(initialSelectedDays ?? []);
    }

    const onDayTilePress= (key: string, day: moment.Moment) => {

        if(unshareContext?.activeSession && moment(unshareContext.activeSession.startedAt).format("YYYY-MM-DD") === day.format("YYYY-MM-DD")) {
            return unshareContext?.setBayInUseDialog(true)
        }

        const result = Object.assign([] as string[], newSelectedDays);
        const formattedDay = day.clone().format("YYYY-MM-DD");
        if(bookedDays?.includes((formattedDay)) && newSelectedDays.includes(formattedDay)) {
            unshareContext?.setShowUnshareDialog(true);
            unshareContext?.setUnshareDate(formattedDay);
        } else {
            const index = (newSelectedDays).indexOf(formattedDay);

            if (index > -1) {
                result.splice(index, 1);
            } else {
                result.push(formattedDay);
            }

            setNewSelectedDays(result);
        }
    };

    const onSubmitSharingRecurrence = () => {

        if(!context?.selectedDaysOfWeek) {
            return;
        }

        if(context?.sharingAction === RecurrentSharingActions.Share && !!context?.sharingDurationValue && !!context?.sharingDurationType) {
            const datesToShare = generateDatesByDurationAndDaysOfWeek(context?.sharingDurationType, context?.selectedDaysOfWeek, +context?.sharingDurationValue);
            const newDaysToShare = _.uniq([...initialSelectedDays ?? [], ...datesToShare]);
            setNewSelectedDays(newDaysToShare);
        } else {
            const datesToUnshare = generateDatesByDurationAndDaysOfWeek(RecurrentSharingDurations.Month, context?.selectedDaysOfWeek, 12);
            const datesToUnshareWithoutBooking = datesToUnshare.filter((date) => !bookedDays.includes(date))
            if(datesToUnshare.length !== datesToUnshareWithoutBooking.length) {
                setRequestInBulkUnshare(true)
            }
            const remainingDatesToShare = initialSelectedDays?.filter(d => !datesToUnshareWithoutBooking.includes(d));
            setNewSelectedDays(remainingDatesToShare ?? []);
        }
        context?.setShowSharingRecurrenceDialog(false);

    }

    const renderMonthTile = (args: { item: YearMonth }) => {

        const { month, year } = args.item;

        return (
            <SiteAvailabilityTileMonth month={month}
                                       year={year}
                                       parkAvailability={parkAvailability.availability}
                                       selectedDays={newSelectedDays}
                                       onDayTilePress={onDayTilePress}
                                       bookedDays={bookedDays}
                                       />
        );
    };

    const endReached = () => {
        setNumberOfMonths(prevState => Math.min(prevState + 1, 12));
    };

    const hasChanged = !isEqual(
        sortBy(initialSelectedDays),
        sortBy(newSelectedDays));

    //return all the dates except for the unshared dates
    const updateUnshareBay = () => {
        if(unshareContext?.unshareDate && unshareContext?.unshareDate !== "") {
            return setNewSelectedDays([...initialSelectedDays?.filter((d) => !d.includes(unshareContext.unshareDate)) ?? []])
        }
    }

    return (
        <ParkableBaseView scrollable={false} backButtonOverride={onClose} loading={loading}>
            <View style={styles.base}>
                <Text h1>{Strings("share_my_bay")}</Text>
                <Text>{Strings("select_the_days_you_dont_need_park")}</Text>
                {requestInBulkUnshare && (
                    <Text style={styles.messageContainer}>{Strings("cannot_unshare_bay_due_to_booking")}</Text>
                )}
                <FlatList
                    data={monthTiles}
                    renderItem={renderMonthTile}
                    onEndReached={endReached}
                    onEndReachedThreshold={0.7}
                    showsVerticalScrollIndicator={false}
                />
                {hasChanged && (
                    <View style={styles.bottomBtnContainer}>
                        <Button plain border center onPress={onCancelPress} style={styles.bottomBtn}>
                            {Strings("cancel")}
                        </Button>
                        <Button center onPress={onConfirmPress} style={styles.bottomBtn}>
                            {Strings("save")}
                        </Button>
                    </View>
                )}
            </View>

            <RecurrentSharingDialog
                parkAvailability={parkAvailability}
                onSubmit={onSubmitSharingRecurrence}
            />
            <BayInUseDialog />
            <UnshareBayDialog updateSharedDays={updateUnshareBay} mutate={mutate}/>
        </ParkableBaseView>
    );
}

const styles = StyleSheet.create({
    base: {
        flex: 1,
        paddingTop: 49
    },
    bottomBtnContainer: {
        justifyContent: "center",
        flexDirection: "row",
        backgroundColor: Colours.NEUTRALS_WHITE,
        paddingTop: PADDING,
    },
    bottomBtn: {
        margin: 5,
        width: "50%"
    },
    messageContainer: {
        width: "100%",
        height: "auto",
        borderRadius: 5,
        padding: 8,
        backgroundColor: Colours.ORANGE_DARK,
        color: Colours.NEUTRALS_WHITE
    }
});
