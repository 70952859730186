import React, { useState, useEffect} from 'react';
import Icon from "react/legacy/parkable-components/icon/Icon";
import Text from "react/legacy/parkable-components/text/Text";
import Colours from "react/legacy/parkable-components/styles/Colours";
import {View, StyleSheet} from "react-native";
import Strings from "../../util/localization/localization";
import {getTimeElapsed} from "./SessionElapsedFunctions";
import {ParkSessionDTO} from "../../model/ParkSessionDTO";

type Props = {
    session: ParkSessionDTO,
    park: { reservationMinutes: number | null },
    style: {},
}

export default function TimeElapsedCarouselItem(props: Props) {

    const [timeElapsedString, setTimeElapsedString] = useState("");

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeElapsedString(getTimeElapsed(props.session, props.park));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <View style={[styles.mainStyle, props.style]}>
            <Icon color={Colours.GREY_50} style={{width: "100%", flex: 2}} iconStyle={{fontSize: 81,}} name={'stopwatch'}/>
            <View style={styles.subView}>
                <Text grey small>{Strings("time_elapsed")}</Text>
                <Text bold>{timeElapsedString}</Text>
            </View>
        </View>);
}

const styles = StyleSheet.create({
    mainStyle: {
        backgroundColor: Colours.GREY_10,
        paddingTop: 18,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 18,
        alignItems:"center",
        justifyContent: "space-between",
        flex: 1
    },
    subView: {
        alignItems:"center",
    }
})
