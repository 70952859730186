import {get, put, Token} from "../rest";
import {useMySWR} from "../http/use-swr";
import EntityResponse from "../../dto/EntityResponse";
import {SsoClientInfo} from "../../model/sso/SsoClientInfo";
import { StripePaymentMethodsResponse } from "../stripe/dto/stripe-payment-methods.response";


export const getSsoClientConfig = (api: string, token: Token, email: string): Promise<EntityResponse<SsoClientInfo>> => {
    const url = `v1/sso?email=${encodeURIComponent(email)}`;
    return get(token, `${api}${url}`);
};

export const useSsoClientConfig = (email?: string) => {
    return useMySWR<SsoClientInfo>(email ? `v1/sso?email=${encodeURIComponent(email)}` : null);
}

/**
 * Checks if an email update is required and updates as necessary
 * @param token
 */
export const updateSamlEmailAddress = (api: string, token: Token) =>
    put(token, `${api}v1/sso/email`);
