/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 18/10/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import moment from "moment";
import getDayAvailability, {DayAvailabilityBoolsWithToday} from "./getDayAvailability";
import { Availability, AvailabilityDTO, convertAvailabilityDTO } from "../../model/Availability";

export default function getTodayAvailability(availability: Availability): DayAvailabilityBoolsWithToday | null {
    if (!availability) {
        return null;
    }

    const today = moment();
    const todayDayOfWeek = today.clone().day();
    return getDayAvailability(todayDayOfWeek, availability, todayDayOfWeek);
}

export function getClosesAtHour(availabilityDTO: AvailabilityDTO) {
    const availability = convertAvailabilityDTO(availabilityDTO);
    const todayAvailability = getTodayAvailability(availability);
    for (let i = 47; i >= 0; --i) {
        if (todayAvailability?.hourlyAvailability[i] === true) {
            return Math.trunc((i + 1) / 2);
        }
    }
}
