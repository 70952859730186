import React, {FunctionComponent, useCallback, useState} from 'react';
import Strings from '../../util/localization/localization'
import { connect } from "react-redux";
import {IRootReducer} from "../../redux/reducers/main";
import {View, StyleSheet, Keyboard} from 'react-native';
import {Button, Colours, Input, Text} from 'react/legacy/parkable-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {sendPasswordResetLink} from "../../redux/actions/user";
import {createRoute} from "../../navigation/constants";
import {Routes} from "../../navigation/root/root.paths";
import ParkableBaseView from "../../components/common/ParkableBaseView";
import {useAlertSnackbar} from "react/root/alert-snackbar/alert-snackbar";
import {showAlert} from "react/util/alerts";

type Props = {} & ReturnType<typeof reduxProps> & typeof actions;

const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(Strings("required")),
});

const ForgotPasswordView = (props: Props) => {
    const { user } = props;
    const {showSnackBar} = useAlertSnackbar();
    const [loading, setLoading] = useState<boolean>(false);
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [error, setError] = useState<string|null>(null);
    const formSubmit = useCallback(async (values: EmailForm) => {
        setLoading(true);
        setEmailSent(false);
        Keyboard.dismiss();
        try {
            await props.sendPasswordResetLink(values.email);
            setEmailSent(true);
            showSnackBar({text: `${Strings("passwordResetSuccess")}${values.email}`, hideDismiss: true});
        } catch (ex) {
            const code = (ex as any)?.code;
            switch (code) {
                case "auth/user-not-found":
                    setError(Strings("email_not_found"));
                    break;
                case "auth/invalid-email":
                    setError(Strings("invalid_email"));
                    break;
                case "auth/network-request-failed":
                    setError(Strings("check_data_connection"));
                    break;
                default:
                    setError(Strings("please_try_again"));
                    // Sentry.captureMessage("Failed resetting password");
                    // Sentry.captureException(ex);
                    break;
            }
            showAlert(Strings("error"), error ? error : Strings("reset_password_error"));
        } finally {
            setLoading(false);
        }
    }, []);
    return <ParkableBaseView loading={loading} scrollable={false}>
        <Formik<EmailForm> initialValues={{email: user?.email ?? ''} as EmailForm} validationSchema={validationSchema}
                           onSubmit={formSubmit}>
            {({handleSubmit, handleBlur, handleChange, values, errors, isValid}) => {
                return <View style={styles.container}>
                    <Text allowFontScaling={false} style={styles.title}>
                        {Strings("reset_password")}
                    </Text>
                    <View style={styles.section}>
                        <Text small style={styles.description}>
                            {Strings("reset_password_description")}
                        </Text>
                        <Input label={Strings("email_address")}
                               placeholder={Strings("email_address")}
                               keyboardType={"email-address"}
                               autoCapitalize={"none"}
                               containerStyle={styles.input}
                               onChange={() => setError(null)}
                               onChangeText={handleChange('email')}
                               onBlur={handleBlur('email')}
                               value={values.email}
                               error={errors.email ?? error}/>
                    </View>
                    <View style={styles.section}>
                        <Button center
                                textProps={{h5: true}}
                                style={styles.button}
                                loading={loading}
                                disabled={loading || !isValid}
                                onPress={() => handleSubmit()}>
                            {emailSent ? Strings("resend") : Strings("send_reset_link")}
                        </Button>
                    </View>
                </View>
            }}
        </Formik>
    </ParkableBaseView>
}

const reduxProps = (root: IRootReducer) => {
    return {
        user: root.user.user
    }
}

const actions = {
    sendPasswordResetLink,
}

type EmailForm = { email: string }

export const ForgotPasswordRoute = createRoute({
    path: Routes.ForgotPasswordView,
})

export default connect(reduxProps, actions)(ForgotPasswordView) as FunctionComponent<Props>;

const styles = StyleSheet.create({
    header: {
        top: 9,
    },
    section: {
        top: 9,
    },
    text: {
        top: 15,
        marginTop: 15,
    },
    input: {
        marginTop: 4,
        marginBottom: 12
    },
    container: {
        position: 'relative',
        backgroundColor: Colours.NEUTRALS_WHITE,
        width: '100%',
        height: '100%'
    },
    title:{
        fontSize: 45,
        lineHeight: 49,
        marginBottom: 18,
        backgroundColor: Colours.NEUTRALS_WHITE,
        fontFamily: "GTEestiDisplay-Bold",
    },
    description: {
        marginTop: 4,
        marginBottom: 18,
    },
    button: {
        height: 37,
        width: 160,
    }
});
