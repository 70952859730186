import ValueOf from "../types/ValueOf";

enum Icons {
    add = ".",
    alert = "Q",
    arrowboldbottomleft = "a",
    arrowboldbottomright = "b",
    arrowbolddown = "c",
    arrowboldleft = "d",
    arrowboldright = "e",
    arrowboldtopleft = "f",
    arrowboldtopright = "g",
    arrowboldup = "h",
    arrowlightbottomleft = "i",
    arrowlightbottomright = "j",
    arrowlightdown = "k",
    arrowlightleft = "l",
    arrowlightright = "m",
    arrowlighttopleft = "A",
    arrowlighttopright = "z",
    arrowlightup = "y",
    arrowsexpand = "w",
    arrowsshrink = "v",
    awardTrophyStar = "*",
    batterycharging = "u",
    bay = "t",
    bell = "F",
    buildingwork = "-",
    calendar = "'",
    calendarAdd = "]",
    calendarDollar = "P",
    calendarEdit = "^",
    cancel = "T",
    car = "s",
    carandbarrier = "r",
    cardpayment = "n",
    checkboxtick = ")",
    cheverondown = "B",
    cheveronleft = "C",
    cheveronright = "D",
    cheveronup = "E",
    clock = "|",
    cross = "S",
    dollarandcalculator = "G",
    dollarfilled = "H",
    electricvehicleplug = "J",
    email = "=",
    flashlight = "L",
    folder = "o",
    hamburger = "M",
    handwatch = "<",
    heart = "q",
    informationCircle = "@",
    key = "N",
    linkexpand = "(",
    locationpin = "R",
    lock = "Z",
    mapcenterlocation = "x",
    messagebubble = "I",
    monitordownload = "}",
    motorbike = "X",
    multipleUsers = ";",
    notesQuestion = "`",
    notification = "p",
    paperplane = "W",
    parkingfilled = "V",
    phone = ">",
    qrcode = "O",
    qrscanner = "~",
    refresh = "[",
    reservedfilled = "+",
    roundarrowright = "\\",
    search = "%",
    settings = "U",
    shieldwithtick = "4",
    star = "5",
    stopwatch = "7",
    sun = "!",
    support = '"',
    tags = "K",
    tickfilled = "$",
    undo = "Y",
    user = "?",
    voucher = ",",
};

export type IconName = keyof typeof Icons | ValueOf<typeof Icons>;

export default Icons;
