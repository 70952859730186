import React, { useState } from "react";
import {ScrollView, StyleSheet, View} from "react-native";
import Text from "react/legacy/parkable-components/text/Text";
import Button from "react/legacy/parkable-components/button/Button";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Strings from "react/util/localization/localization";
import { Routes } from "react/navigation/root/root.paths";
import { updateVehicle, useUserVehicles } from "react/api/vehicle/vehicle.api";
import { createRoute, NavigationProps, useNavigation } from "react/navigation/constants";
import ParkableBaseView from "../../../components/common/ParkableBaseView";
import { useAlertSnackbar } from "react/root/alert-snackbar/alert-snackbar";
import { validateAndSanitiseRego } from "../../../components/vehicle/vehicle.util";
import { RegoInputBlock } from "../../../components/vehicle/RegoInputBlock";
import { VehicleRadioBlock } from "../../../components/vehicle/VehicleRadioBlock";
import {spacing} from "../../../util/theme/spacing";

class EditVehicleParams {
    id: number;
}

const topMargin = 49;

const EditVehicleView = (props: NavigationProps<Routes.EditVehicleView>) => {
    const navigation = useNavigation();
    const { id } = props.route.params;
    const { showSnackBar } = useAlertSnackbar();

    const { vehicles: userVehicles, mutate: mutateVehicles } = useUserVehicles();
    const vehicle = userVehicles?.find((v) => v.id === id);

    const [loading, setLoading] = useState(false);

    const [registration, setRegistration] = useState<string | undefined>(vehicle?.registration);
    const [isMotorbike, setIsMotorbike] = useState(vehicle?.isMotorbike ? "true" : "false");
    const [isElectricVehicle, setIsElectricVehicle] = useState(vehicle?.isEV ? "true" : "false");

    const editVehicle = async () => {
        if (!registration) return;

        // Exclude current vehicle being edited.
        const result = validateAndSanitiseRego(
            registration,
            userVehicles?.filter((v) => v.id !== vehicle?.id)
        );
        if (!result.valid || !result.rego) {
            showSnackBar({
                text: result.message ?? Strings("oh_no_something_went_wrong_support"),
                hideDismiss: false,
                style: { backgroundColor: Colours.RED },
            });
            return;
        }
        const isEVBoolean = isElectricVehicle === "true";
        const isMotorbikeBoolean = isMotorbike === "true";
        const request = { registration: result.rego, isEV: isEVBoolean, isMotorbike: isMotorbikeBoolean };
        try {
            setLoading(true);
            await updateVehicle(id, request);
            await mutateVehicles();
        } catch (err: any) {
            setLoading(false);
            showSnackBar({ text: err.message, hideDismiss: true, style: { backgroundColor: Colours.RED } });
            return;
        } finally {
            setLoading(false);
            navigation.goBack();
        }
    };

    const isValueChanged =
        isElectricVehicle !== (vehicle?.isEV ? "true" : "false") ||
        isMotorbike !== (vehicle?.isMotorbike ? "true" : "false") ||
        registration !== vehicle?.registration;

    return (
        <ParkableBaseView scrollable={false} loading={loading}>
            <ScrollView showsVerticalScrollIndicator={false}>
                <Text h1 style={styles.titleText}>
                    {Strings("edit_your_vehicle")}
                </Text>
                <Text style={styles.helperText}>{Strings("update_your_vehicle_details")}</Text>
                <RegoInputBlock value={registration} onChange={(text) => setRegistration(text)} />
                {vehicle && (
                    <VehicleRadioBlock
                        isEV={isElectricVehicle}
                        onEVChange={(value) => setIsElectricVehicle(value)}
                        isMotorbike={isMotorbike}
                        onMotorbikeChange={(value) => setIsMotorbike(value)}
                    />
                )}
            </ScrollView>
            <View style={styles.spacer}/>
            <View style={styles.saveButtonContainer}>
                <Button center disabled={!registration || !isValueChanged} onPress={editVehicle}>
                    {Strings("save")}
                </Button>
            </View>
        </ParkableBaseView>
    );
};

export default EditVehicleView;

export const EditVehicleRoute = createRoute({
    path: Routes.EditVehicleView,
    params: {
        type: EditVehicleParams,
    },
});

const styles = StyleSheet.create({
    titleText: {
        marginTop: 49,
        marginBottom: 15,
    },
    helperText: {
        fontSize: 18,
        color: Colours.NEUTRALS_500,
    },
    saveButtonContainer: {
        flex: 1,
        justifyContent: "flex-end",
    },
    // Offsetting top margin to display scroll view correctly on small screens
    spacer: {
        height: topMargin + spacing(2),
    },
});
