import Strings from "../../../util/localization/localization";
import { DialogRef } from "react/legacy/parkable-components/dialog/Dialog";
import Button from "react/legacy/parkable-components/button/Button";
import Spinner from "react/legacy/parkable-components/spinner/Spinner";
import Text from "react/legacy/parkable-components/text/Text";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Dialog from "react/components/dialog/Dialog";
import React, { useCallback, useState, useRef } from "react";
import { cancelEmployeeSubscription, acceptEmployeeSubscription } from "../../../redux/actions/subscriptions"
import { connect } from "react-redux";
import { StyleSheet, View } from "react-native";
import { OrganisationDTO } from "../../../model/OrganisationDTO";
import { IRootReducer } from "../../../redux/reducers/main";
import { NavigationInjectedProps } from "react-navigation";
import { Routes } from "react/navigation/root/root.paths";
import { useTerritory } from "../../../api/territory/territory.api";
import { useNavigation } from "../../../navigation/constants";
import { EmployeeSubscriptionDTO } from "../../../dto/EmployeeSubscriptionDTO";


type OwnProps = {
    subscription: EmployeeSubscriptionDTO | null,
    organisation: OrganisationDTO | null
}

type SubscriptionInviteComponentProps = OwnProps & ReturnType<typeof getReduxProps> & typeof actions;

const SubscriptionInviteComponent_ = (props: SubscriptionInviteComponentProps & NavigationInjectedProps) => {
    const {
        subscription,
        organisation,
        userVehicles,
        acceptEmployeeSubscription,
        cancelEmployeeSubscription
    } = props;

    const navigation = useNavigation();

    const [loading, setLoading] = useState<boolean>(false);
    const dialogRef = useRef<DialogRef | null>(null);

    const { territory } = useTerritory(organisation?.territory);

    const onAccept = useCallback(() => {
        if (!!subscription) {
            setLoading(true);
            acceptEmployeeSubscription(subscription.id, territory);
            if (!userVehicles || userVehicles.length === 0) {
                navigation.push(Routes.VehiclesView, {});
            }
        }
    }, [subscription, territory])

    const onDecline = () => {
        dialogRef.current?.show();
    }
    const onConfirmDecline = useCallback(() => {
        if (!!subscription) {
            setLoading(true);
            void cancelEmployeeSubscription(subscription.id, false);
        }
    }, [subscription])

    return (
        <View style={styles.container}>
            <View style={styles.infoMessageContainer}>
                <View style={styles.infoIcon}>
                    <Text white bold>!</Text>
                </View>
                <Text h5 style={styles.infoMessage}>
                    {organisation?.name ? Strings("org_assign_you_bay")(organisation.name) : Strings("loading")}
                </Text>
            </View>
            {loading ? (
                <View style={{ alignSelf: "center" }}>
                    <Spinner large />
                </View>
            ) : (
                <View style={styles.buttonContainer}>
                    <Button style={{ flex: 1 }} center textProps={{ h5: true }} onPress={onAccept} disabled={loading}>
                        {Strings("accept")}
                    </Button>
                    <Button
                        style={{ flex: 1, backgroundColor: Colours.NEUTRALS_WHITE, marginLeft: 10 }}
                        center
                        border
                        textProps={{ h5: true, style: { color: Colours.NEUTRALS_BLACK } }}
                        onPress={onDecline}
                        disabled={loading}
                    >
                        {Strings("no_thanks")}
                    </Button>
                </View>
            )}
            <Dialog
                ref={dialogRef}
                label={Strings("cancel_subscription")}
                labelProps={{ style: { color: Colours.NEUTRALS_BLACK, textAlign: "left" } }}
                title={Strings("are_you_sure_cancel_pending_subscription")}
                titleProps={{ style: { textAlign: "left" }, h2: undefined }}
                positiveText={Strings("yes")}
                positiveProps={{ red: true, textProps: { h5: true } }}
                negativeText={Strings("no")}
                negativeProps={{ textProps: { h5: true } }}
                onPositivePress={onConfirmDecline}
            />
        </View>
    );
}

const actions = {
    acceptEmployeeSubscription,
    cancelEmployeeSubscription
}

const getReduxProps = (state: IRootReducer) => ({
    userVehicles: state.user.vehicles
})

const styles = StyleSheet.create({
    container: { padding: 10 },
    infoMessageContainer: { display: "flex", flexDirection: "row", alignItems: "center" },
    infoIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 27,
        width: 27,
        backgroundColor: Colours.GREEN_300,
        borderRadius: 13.5,
    },
    infoMessage: { paddingLeft: 10, marginBottom: 0, flex: 1 },
    buttonContainer: { flex: 1, flexDirection: "row", marginTop: 10 },

});

export const SubscriptionInviteComponent = connect(getReduxProps, actions)(SubscriptionInviteComponent_ as any) as React.FunctionComponent<OwnProps>;
