import React, {ReactElement, useCallback, useState} from 'react';
import {View, StyleSheet} from 'react-native';
import {INotification} from "./BuildNotifications";
import Colours from "react/legacy/parkable-components/styles/Colours";
import NotificationWidget from "./NotificationWidget";
import Strings from "../../../util/localization/localization"

export interface NotificationWidgetProps {
    notification: INotification,
    style: any
}

function NotificationWidgetDropdown ({notification, style}: NotificationWidgetProps) {

    const notificationColor = { backgroundColor: notification.color ?? Colours.BLUE_300 };

    const [expanded, setExpanded] = useState(false);
    const onPress = useCallback(() => {
        notification.action();
    }, [notification]);

    if(notification.data && notification.data.length === 1){
        const id = notification.data[0].id;
        const n = {
            key: notification.key,
            actionText: Strings("view"),
            mainText: notification.mainText,
            action: () => {notification.action(id)},
        } as INotification;
        return (
            <NotificationWidget
                key={notification.key}
                style={style}
                notification={n}/>)
    }
    else {
        const individualNotifications = [] as ReactElement[];

        const no = {
            key: notification.key,
            actionText: expanded ? Strings("hide") : Strings("show"),
            mainText: notification.mainTextMultiple,
            action: () => {
                setExpanded(!expanded)
            },
        } as INotification;
        individualNotifications.push(
            <NotificationWidget
                key={notification.key}
                style={style}
                notification={no}/>);

        if(expanded) {
            notification.data?.forEach(d => {
                const n = {
                    key: d.id,
                    actionText: Strings("view"),
                    mainText: d.text,
                    action: () => {
                        notification.action(d.id)
                    },
                } as INotification;

                individualNotifications.push(<NotificationWidget
                    key={d.id}
                    style={style}
                    notification={n}/>);
            });
        }

        return <View>{individualNotifications}</View>
    }
}

export default React.memo(NotificationWidgetDropdown);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
        justifyContent: 'flex-start',
        position: "relative",
        overflow: 'hidden',
    },
    innerContainer:{
        flex: 1,
        flexDirection: 'row',
    },
    msgContainer:{
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 9
    },
    mainTextContainer: {
        paddingRight: 9,
        flex: 1,
        justifyContent: "center",
    },
    mainText: {
        lineHeight: 14,
        fontSize: 13
    },
    actionTextContainer: {
        width: 63,
        alignItems: "flex-end",
        justifyContent: "center",
    },
    actionText: {
        color: Colours.BLUE_300,
        lineHeight: 14,
        fontSize: 13,
    },
    notificationColor: {
        width: 6
    }
});
