export const VEHICLES_SELECTED = 'VEHICLES_SELECTED';
export const CLEAR_SELECTED_VEHICLES = 'CLEAR_SELECTED_VEHICLES';

export function vehiclesSelected(vehicleIds: number[]) {
    return {
        type: VEHICLES_SELECTED,
        vehicleIds
    }
}

export function clearSelectedVehicles() {
    return {
        type: CLEAR_SELECTED_VEHICLES,
    }
}
