import { Platform, StyleSheet } from "react-native";
import * as Linking from "expo-linking";
import * as Clipboard from "expo-clipboard";
import { useCallback } from "react";
import { ParkDTO } from "react/model/ParkDTO";
import { Colours } from "react/legacy/parkable-components";
import Strings from "react/util/localization/localization";
import { useActionSheet } from "@expo/react-native-action-sheet";

type DirectionOption = {
    label: string;
    onPress: () => void;
};

export const useDirectionsPressHandler = (park?: Pick<ParkDTO, "latitude" | "longitude" | "address"> | null) => {
    const { showActionSheetWithOptions } = useActionSheet();

    return useCallback(async () => {
        if (!park) {
            return;
        }
        const latLng = `${park.latitude},${park.longitude}`;

        const directionOptions: Map<number, DirectionOption> = new Map();
        const appleMapsOption: DirectionOption = {
            label: Strings("open_in_apple_maps"),
            onPress: async () => {
                await Linking.openURL(`maps:0,0?q=${latLng}`);
            },
        };
        const googleMapsOption: DirectionOption = {
            label: Strings("open_in_google_maps"),
            onPress: async () =>
                Platform.OS === "web"
                    ? window.open(`https://www.google.com/maps?daddr=${latLng}`, "_blank")
                    : Platform.OS === "ios"
                        ? await Linking.openURL(`comgooglemaps://?daddr=${latLng}`)
                        : await Linking.openURL(`geo:0,0?q=${latLng}`),
        };
        const copyAddressOption: DirectionOption = {
            label: Strings("copy_address"),
            onPress: async () => await Clipboard.setStringAsync(/([^,]+){1}(,[^,]+)?/gi.exec(park.address)?.[0] ?? ""),
        };
        const cancelOption: DirectionOption = {
            label: Strings("cancel"),
            onPress: () => {},
        };

        const canUseGoogleMaps = await Linking.canOpenURL(`comgooglemaps://?daddr=${latLng}`);

        if (Platform.OS === "ios" && canUseGoogleMaps) {
            directionOptions.set(0, appleMapsOption);
            directionOptions.set(1, googleMapsOption);
            directionOptions.set(2, copyAddressOption);
        } else {
            directionOptions.set(0, Platform.OS === "ios" ? appleMapsOption : googleMapsOption);
            directionOptions.set(1, copyAddressOption);
        }
        // Get current size and then add cancel option to the list. So cancel option will always be the last index.
        const cancelButtonIndex = directionOptions.size;
        directionOptions.set(cancelButtonIndex, cancelOption);

        const actionSheetOptions: string[] = [];
        directionOptions.forEach((value) => {
            actionSheetOptions.push(value.label);
        });

        showActionSheetWithOptions(
            {
                options: actionSheetOptions,
                cancelButtonIndex,
                containerStyle: styles.container,
                textStyle: styles.text,
            },
            (index) => {
                if (index === undefined || index > directionOptions.size) {
                    return;
                }
                directionOptions.get(index)?.onPress();
            }
        );
    }, [park]);
};

const styles = StyleSheet.create({
    container: {
        borderRadius: 20,
        margin: 10,
        backgroundColor: Colours.NEUTRALS_WHITE,
    },
    text: {
        textAlign: "center",
        width: "100%",
        fontSize: 20,
        fontWeight: "400",
        color: Colours.BLUE_300,
    },
});
