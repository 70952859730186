export const Colours = {
    Transparent: 'transparent',
    White: '#FFFFFF',
    Black: '#000000',
    Red: '#F14950',
    LIGHT_RED: '#FFE2E3',
    ParkableBlue: '#3399FF',
    ParkableWeirdBlueTransparent: '#3399FF50',
    ParkableMidBlue: '#1E75D2',
    ParkableDarkBlue: '#103B5D',
    ParkableOrange: '#FBB040',
    ParkableLightOrange: "#FDE9C9",
    ParkableYellow: '#FBB040',
    ParkableYellowBright: '#F39221',
    ParkableYellowPale: '#FDBB5B',
    ParkableDarkGrey: '#555555',
    ParkableXtraDarkGrey: '#333333',
    ParkableLightGrey: '#888888',
    ParkableXtraLightGrey: '#CCCCCC',
    ParkableGrey: '#F2F2F2',
    ParkableGreen: '#7AC943',
    ParkableLightGreen: '#E4FFD1',
    ParkableWeirdBlue: '#194263',
    BodyTextOnDarkBlueBackgroundColour: '#C3CDD6',
    Purple: '#A23CB9',
    Pink: "#FFE2E3",
    ParkablePurple: "#8B25AD",

    BORDER_GREY: "#F2F2F2",
    BORDER_GREY_TRANSPARENT: "#F2F2F250",
    BUTTON_GREY_TOP: "#F2F2F2",
    BUTTON_GREY_BOTTOM: "#DFDFDF",
    lightNavy: "#103b5d",
    lightPink: "#ffe2e3",

    placeholderColor: `#bcbcbc`,
};

export default Colours;

export const {
    Transparent,
    White,
    Black,
    Red,
    LIGHT_RED,
    ParkableBlue,
    ParkableWeirdBlueTransparent,
    ParkableMidBlue,
    ParkableDarkBlue,
    ParkableOrange,
    ParkableLightOrange,
    ParkableYellow,
    ParkableYellowBright,
    ParkableYellowPale,
    ParkableDarkGrey,
    ParkableXtraDarkGrey,
    ParkableLightGrey,
    ParkableXtraLightGrey,
    ParkableGrey,
    ParkableGreen,
    ParkableLightGreen,
    ParkableWeirdBlue,
    BodyTextOnDarkBlueBackgroundColour,
    Purple,
    Pink,
    BORDER_GREY,
    BORDER_GREY_TRANSPARENT,
    BUTTON_GREY_TOP,
    BUTTON_GREY_BOTTOM,

    placeholderColor

} = Colours