import React, { Ref, useState } from "react";
import {
    KeyboardTypeOptions,
    NativeSyntheticEvent,
    Platform,
    StyleSheet,
    TextInput,
    TextInputFocusEventData,
    TextInputProps,
} from "react-native";
import useForwardRef from "../hooks/useForwardRef";
import InputWrapper, { InputWrapperProps } from "../inputWrapper/InputWrapper";
import Colours from "../styles/Colours";
import classnames from "../util/classnames";

const styles = StyleSheet.create({
    input: {
        height: 25,
        color: Colours.NEUTRALS_BLACK,
        padding: 0,
        fontFamily: "GTEestiDisplay-Regular",
        lineHeight: 16,
    },
    multiline: {
        height: 108,
    },
});

const cn = classnames(styles);

export type InputProps = {
    multiline?: boolean;
    number?: boolean;
    phone?: boolean;
    password?: boolean;
    email?: boolean;
    disabled?: boolean;
} & TextInputProps &
    Omit<InputWrapperProps, "focused" | "value">;

function Input(props: InputProps, ref: Ref<TextInput>) {
    const {
        multiline,
        number,
        phone,
        password,
        email,
        placeholder,
        value,
        disabled,
        ...otherProps
    } = props;

    const inputRef = useForwardRef<TextInput>(ref);

    const [focused, setFocused] = useState(false);

    const isEmail = !!email;

    let keyboardType: KeyboardTypeOptions = "default";

    if (number) {
        keyboardType = "default";
    } else if (phone) {
        keyboardType = "phone-pad";
    } else if (isEmail) {
        keyboardType = "email-address";
    }

    const onContainerPress = () => {
        if (!focused) {
            inputRef?.current?.focus();
        }
    };

    const onFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
        setFocused(true);
        props?.onFocus?.(e);
    };

    const onBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
        setFocused(false);
        props?.onBlur?.(e);
    };

    const inputStyle = cn.styles("input", {
        multiline: multiline,
    });

    return (
        <InputWrapper
            disabled={disabled}
            {...otherProps}
            focused={focused}
            onPress={onContainerPress}
        >
            <TextInput
                editable={!disabled}
                placeholderTextColor={Colours.GREY_50}
                secureTextEntry={password}
                multiline={multiline}
                keyboardType={keyboardType}
                {...props}
                value={value}
                ref={inputRef}
                style={inputStyle}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </InputWrapper>
    );
}

export default React.forwardRef(Input);
