import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import React, { FunctionComponent, useMemo } from "react";
import { EmployeeSubscriptionDTO } from "react/dto/EmployeeSubscriptionDTO";
import localizeCurrency from "react/util/localization/localizeCurrency";
import Strings from "react/util/localization/localization";

type Props = {
    subscription: EmployeeSubscriptionDTO;
    lastTermAmount: number;
    loadingLastTermAmount: boolean;
    currencyCode?: string;
    daysUsed: number;
};

function SubscriptionAdditionalDaysDueNowRow(props: Props) {
    const { subscription, currencyCode, lastTermAmount, daysUsed } = props;

    const termPriceText = useMemo((): string => {
        return `${localizeCurrency(lastTermAmount, currencyCode ?? "NZD", false)}`;
    }, [lastTermAmount, subscription, currencyCode]);
    return (
        <TableRow iconLeft={"dollarfilled"} label={Strings("additional_days_due_now")}>
            {`${termPriceText} (${daysUsed} ${Strings("days")})`}
        </TableRow>
    );
}

export default SubscriptionAdditionalDaysDueNowRow as FunctionComponent<Props>;
