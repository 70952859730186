/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions, PathConfig, PathConfigMap } from "@react-navigation/native";
import * as Linking from "expo-linking";
import { BASE_ROUTE } from "./drawer/drawer.navigator";
import { RootStackParamList, routesList } from "./root/root.routes";


export const LinkingConfiguration: LinkingOptions<any> = {
    prefixes: [Linking.createURL("/")],
    config: {
        screens: {
            /**
             * Every route is nested inside the BASE_ROUTE which pertains to the drawer.
             * The `path` is intentionally set to an empty string to ensure
             * the nested routes are serialized at the root level in the url, like
             * `app.parkable.com/account`
             * see: https://reactnavigation.org/docs/configuring-links#mapping-path-to-route-names
             */
            [BASE_ROUTE]: {
                path: "",
                screens: {
                    ...routesList.reduce(
                        (screens: PathConfigMap<RootStackParamList>, route) => {
                            const pathConfig: PathConfig<RootStackParamList> = {
                                path: route.path,
                                parse: route.params.parse,
                            }
                            return {
                                ...screens,
                                [route.path]: pathConfig
                            };
                        },
                        {}
                    ),
                }
            },
        },

    }
}

