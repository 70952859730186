import moment from "moment";
import Strings from "../../util/localization/localization";
import {ParkSession} from "../../model/ParkSession";
import {ParkSessionDTO} from "../../model/ParkSessionDTO";
import { parkReservationTime } from "react/util/Util";

export function getTimeElapsed(session: ParkSession | ParkSessionDTO, park: { reservationMinutes: number | null }): string {

    const isReservation = !session.startedAt;

    const end = !!session.endedAt ? moment(session.endedAt) : moment();

    if (isReservation) {

        const start = moment(session.reservationStartedAt??new Date());
        const sessionDiffMins = end.diff(start, "minutes");
        const parkReservationMinutes = parkReservationTime(park?.reservationMinutes);
        let minutesPassed = parkReservationMinutes - sessionDiffMins;

        if (minutesPassed <= 0) {
            minutesPassed = 0;
        }

        return `${minutesPassed}${Strings("min")}${ minutesPassed > 1 ? 's' : ''} ${Strings("remaining")}`;
    } else {
        const start = moment(session.startedAt??new Date());

        const sessionDiffDays = end.diff(start, "day");
        let sessionDiffHours = end.diff(start, "hours");
        let sessionDiffMins = end.diff(start, "minutes");

        sessionDiffHours = sessionDiffHours - (sessionDiffDays * 24);
        sessionDiffMins = sessionDiffMins - (sessionDiffDays * 24 * 60) - (sessionDiffHours * 60);


        const dayText = sessionDiffDays > 0 ? `${sessionDiffDays} ${Strings("day")}${sessionDiffDays > 1 ? 's' : ''}` : '';
        const hourText = sessionDiffHours > 0 ? `${sessionDiffHours} ${Strings("hour")}${sessionDiffHours > 1 ? 's' : ''}` : '';
        const minuteText = sessionDiffMins > 0 ? `${sessionDiffMins} ${Strings("min")}${sessionDiffMins > 1 ? 's' : ''}` : '';

        let elapsedText = `${dayText} ${hourText} ${minuteText}`.trim();

        if (elapsedText.trim() === "") {
            elapsedText = `0 ${Strings("mins")}`;
        }

        return elapsedText;
    }
}
