import { DispatchFunc } from "../../model/Types";
import { OrganisationDTO } from "../../model/OrganisationDTO";
import { Park } from "../../model/Park";
import {Token} from "../../api/rest";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export enum ParkableModal {
  ReservationEnded = "ReservationEnded",
  ReservationCancelled = "ReservationCancelled",
  MemberInvited = "MemberInvited",
}

function showHideModal(show: boolean, modal: ParkableModal, data: any) {
  return {
    type: show ? SHOW_MODAL : HIDE_MODAL,
    payload: {
      modal,
      data,
    },
  };
}

export function showHideReservationTimeOutModal(show: boolean): any {
  return (dispatch: DispatchFunc) => {
    dispatch(showHideModal(show, ParkableModal.ReservationEnded, null));
  };
}
export function showHideReservationCancelledModal(
  show: boolean,
  message?: string
): any {
  return (dispatch: DispatchFunc) => {
    dispatch(
      showHideModal(show, ParkableModal.ReservationCancelled, { message })
    );
  };
}

export function showHideMemberInvitedModal(
  show: boolean,
  organisation?: OrganisationDTO,
  parksInOrg?: Park[]
): any {
  return (dispatch: DispatchFunc) => {
    dispatch(
      showHideModal(show, ParkableModal.MemberInvited, {
        organisation,
        parksInOrg,
      })
    );
  };
}
