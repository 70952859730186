import React from "react"
import {RewardDTO} from "../../api/rewards/dto/Reward";
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import Text from "react/legacy/parkable-components/text/Text";
import moment from "moment";
import {useBonusBayParkSession, useParkSessionV3} from "../../api/parkSession/parkSession.api";
import Strings from '../../util/localization/localization';
import _ from "lodash";

export type OwnProps = {
    creditReward?: RewardDTO,
    currencySymbol: string | undefined,
    discountRewards?: RewardDTO[]
}

export function getTimeDifference (startDate: string | null | undefined, endDate?: string | null) {

    if (!startDate) {return ''}
    const m = moment(startDate);
    const mEnd = endDate ? moment(endDate) : new Date()

    const days = -m.diff(mEnd, 'days')
    const hours = (-m.diff(mEnd, 'hours') % 24)
    const minutes = (-m.diff(mEnd, 'minutes') % 60)

    return `${days > 0 ? `${days}d ` : ''}${hours}h ${minutes}m`
}

export const SharingCreditActivityView = (props: OwnProps) => {
    const {creditReward, currencySymbol, discountRewards} = props

    const {parkSession} = useBonusBayParkSession(creditReward?.session)
    const sessionDuration = getTimeDifference(parkSession?.startedAt,parkSession?.endedAt)

    const sharingAmount = creditReward ? creditReward.amount/100 : 0
    const sessionDate = parkSession?.startedAt;

    const finalDiscountedReward = _.orderBy(discountRewards,r => r.redeemedAt,"desc") ?? undefined
    const finalDiscountAt = finalDiscountedReward[0]?.redeemedAt ?? undefined
    const discountAmount = discountRewards?.length ? discountRewards.filter(r => r.redeemedAt === finalDiscountAt).map(r => r.redeemedAmount).reduce((sum,amount) => sum + amount)/100 : 0

    const amountValue = creditReward ?
        `+${currencySymbol}${sharingAmount}` :
        `-${currencySymbol}${discountAmount}`

    return (<TableRow textProps={{small:true}}
                    label={moment(creditReward ? sessionDate : finalDiscountAt).format("Do MMMM YYYY")}
                      iconLeft={"dollarfilled"}
                      contentRight={<Text
                          small
                          green={!!creditReward}
                          red={!!discountRewards}
                          style={{fontStyle: 'normal'}}>
                          {amountValue}
                      </Text>}
    >
        {!!creditReward && <Text small grey>{Strings("a_parker_used_your_park_for")(sessionDuration)}</Text>}
        {!!discountRewards && <Text small grey>{Strings("credit_applied")}</Text>}

    </TableRow>)
}
