import Text from "react/legacy/parkable-components/text/Text";
import { IconName } from "react/legacy/parkable-components/icon/Icons";
import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { useNavigation } from "../../../navigation/constants";
import { TouchableOpacity } from "@gorhom/bottom-sheet";
import { Routes } from "../../../navigation/root/root.paths";
import Icon from "react/legacy/parkable-components/icon/Icon";

export type ToolbarProps = {
    style?: StyleProp<ViewStyle>,
    showBackButton?: boolean;
    backButtonOverride?: () => void,
    onBackPress?: () => void,
    toolbarTitleText?: string,
    toolbarIconText?: string,
    toolbarIcon?: IconName,
    onIconClick?: () => void,
};

function Toolbar(props: ToolbarProps) {
    const {
        style,
        showBackButton,
        onBackPress,
        backButtonOverride,
        toolbarTitleText,
        toolbarIconText,
        toolbarIcon,
        onIconClick,
    } = props;
    const navigation = useNavigation();

    const onBackButtonPress = () => {
        onBackPress?.();
        if (!!backButtonOverride) {
            backButtonOverride();
        } else if (navigation.canGoBack()) {
            navigation.pop();
        } else {
            //@ts-ignore
            navigation.reset({ routes: [{ name: Routes.ParkableMapView }] });
        }
    }

    return (
        <View style={[styles.base, style]}>
            {(showBackButton ?? true) && <Icon style={styles.backButton} onPress={onBackButtonPress} name={"arrowlightleft"}/>}
            <Text bold numberOfLines={1} style={[styles.toolbarTitle, toolbarIconText ? {width: "52%"} : {width: "47%",}]}>{toolbarTitleText}</Text>
            {(showBackButton ?? true) && <View style={styles.backButton}/>}
            <TouchableOpacity style={styles.iconContainer} onPress={onIconClick}>
                {toolbarIconText && <Text small style={styles.iconText}>{toolbarIconText}</Text>}
                {toolbarIcon && <Icon small style={styles.icon} iconStyle={{fontSize: 20}} name={toolbarIcon}/>}
            </TouchableOpacity>
        </View>
    );
}

export default (Toolbar) as React.FunctionComponent<ToolbarProps>;

const styles = StyleSheet.create({
    base: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    iconContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
        paddingTop: 9,
    },
    iconText: {
        paddingTop: 2,
    },
    icon: {
        marginTop: -7,
    },
    toolbarTitle: {
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        fontSize: 20,
        flex: 1,
    },
    backButton: {
        width: 28,
    },
});
