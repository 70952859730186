import { Platform } from "react-native";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import Constants from "expo-constants"

export async function getPushNotificationToken() {
    if (Platform.OS === "web") {
        return "WEB";
    }
    if (Device.isDevice) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== "granted") {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }
        if (finalStatus !== "granted") {
            return null;
        }

        const options: Notifications.ExpoPushTokenOptions = {
            projectId: Constants.expoConfig?.extra?.eas?.projectId
        };

        try {
            const token = (await Notifications.getExpoPushTokenAsync(options)).data;
            return token;
        } catch (e: any) {
            console.log("error getting expo push token", e);
            return;
        }
    } else {
        console.log("Push Notifications only supported on physical devices");
        return "SIMULATOR";
    }
}
