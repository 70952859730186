/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 26/02/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import convertHourlyAvailabilityToString from "./convertHourlyAvailabilityToString";
import { DayAvailabilityDTO } from "../../../../../model/DayAvailability";
import { AvailabilityDTO } from "../../../../../model/Availability";

const OPEN = "tttttttttttttttttttttttttttttttttttttttttttttttt";
//const CLOSED = "ffffffffffffffffffffffffffffffffffffffffffffffff";

export type Time = {
    hour: number,
    minute: number
}

export type Rule = {
    openTime: Time | null,
    closeTime: Time | null
}

export type DayAvailabilityRules = {
    available: boolean,
    openAllDay: boolean,
    availabilityRules: Rule[],
};


export default function convertFromAvailabilityString(dayAvailability: DayAvailabilityDTO, availability: AvailabilityDTO): DayAvailabilityRules {

    const newAvailability = {
        available: dayAvailability.available || availability.permanentlyAvailable,
        openAllDay: false,
        availabilityRules: []
    } as DayAvailabilityRules;

    dayAvailability = convertHourlyAvailabilityToString(dayAvailability);

    if (newAvailability.available) {
        if (dayAvailability.hourlyAvailability === OPEN || !!availability.permanentlyAvailable) {
            newAvailability.openAllDay = true;
        } else {
            const indexFirstT = dayAvailability.hourlyAvailability.indexOf('t');
            const availArray = dayAvailability.hourlyAvailability.split('');

            let isOpen = false;
            const availabilityRules = new Array<Rule>();

            let availRule = {} as Rule;

            for (let i = indexFirstT; i < 48; i++) {
                const availItem = availArray[i];

                if (!isOpen && availItem === "t") {
                    isOpen = true;
                    availRule.openTime = convertFromIndex(i);
                } else if (isOpen && availItem === "f") {
                    isOpen = false;
                    availRule.closeTime = convertFromIndex(i);
                    availabilityRules.push(availRule);
                    availRule = {openTime: null, closeTime: null};
                }
            }

            if(!availRule.closeTime && isOpen){
                availRule.closeTime = convertFromIndex(48);
                availabilityRules.push(availRule);
            }

            newAvailability.availabilityRules = availabilityRules;
        }
    }

    return newAvailability;
}

function convertFromIndex(index: number): Time{
    return TIMES[index];
}

const TIMES = Array.apply(null, Array(49))
    .map((v,i) => ({
        hour: i <= 1 ? 24 : Math.floor(i/2),
        minute: i % 2 === 0 ? 0 : 30
    }));
