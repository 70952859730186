export const SET_SESSIONS = 'SET_SESSIONS';
export const HISTORY_PARKING_SESSION = 'HISTORY_PARKING_SESSION';
export const PARKING_ERROR = 'PARKING_ERROR';
export const RECEIVED_LAST_SESSION = 'RECEIVED_LAST_SESSION';
export const FINISH_PARKING_DATA = 'FINISH_PARKING_DATA';
export const CURRENT_PARKING_SESSION = 'CURRENT_PARKING_SESSION';
export const PAYMENTS_REQUIRING_AUTHENTICATION = "PAYMENTS_REQUIRING_AUTHENTICATION";
export const MAP_PARKS_RECEIVED = 'MAP_PARKS_RECEIVED';
export const SET_PARK = 'SET_PARK';
export const SET_USER_BAYS = 'SET_USER_BAYS';
