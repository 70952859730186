/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 16/01/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import {get} from "./rest";
import {Territory} from "../model/Territory";
import {Token} from "./rest";

export function getTerritoryAPI(url: string, token: Token, id: number) {
    return get(token, `${url}v1/territories/${id}`) as Promise<{territory: Territory}>;
}

export function getAllTerritories(url: string, token: Token) {
    return get(token, `${url}v1/territories`) as Promise<{territories: Territory[]}>;
}
