import * as Type from '../actions/organisations'
import {OrganisationDTO, OrganisationDTOMap} from "../../model/OrganisationDTO";

const initialState = {
    organisations: {} as OrganisationDTOMap,
};

type IOrganisationReducer = typeof initialState;

export default function dataReducer(state = initialState, action: any): IOrganisationReducer {
    switch (action.type) {
        case Type.SET_ORGANISATION: {
            return {
                ...state,
                organisations: {
                    ...state.organisations,
                    [action.organisation.id]: action.organisation,
                },
            };
        }
        case Type.SET_ORGANISATIONS: {

            const organisations = {...state.organisations};

            action.organisations.forEach((org: OrganisationDTO) => organisations[org.id] = org);

            return {
                ...state,
                organisations,
            };
        }

        default:
            return state
    }
}