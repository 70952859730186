import Card from "react/legacy/parkable-components/card/Card";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Icons from "react/legacy/parkable-components/icon/Icons";
import React, {useCallback, useEffect, useState} from 'react';
import {StyleSheet, View,} from "react-native";
import {connect} from 'react-redux';
import Strings from '../../util/localization/localization';
import {ParkingType} from "../../model/Types";
import {setParkingType} from '../../redux/actions/map';
import {IRootReducer} from "../../redux/reducers/main";
import SearchView from "./parkableMapView/SearchView";
import ParkingTypeButton, {} from './ParkingTypeButton';
import {EV_ENABLED_PREFIXES} from "../../util/constants";
import _ from "lodash";
import {Feature} from "../../model/Bay";
import {useUserRoles} from "../../api/user/user.api";
import {getPrivateOrgs} from "../../util/getUserMember";


const styles = StyleSheet.create({
    base: {
        backgroundColor: Colours.NEUTRALS_WHITE,
        paddingTop: 18,
        paddingRight: 18,
        paddingLeft: 18,
    },
    parkingButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        paddingVertical: 18,
        paddingHorizontal: 9,
    },

    parkingButton: {
        flex: 1,
    },
});

export type MapFooterViewProps = {
    showSearchView: boolean,
    onSearchViewPressed: () => void,
}

type Props = MapFooterViewProps & typeof actions & ReturnType<typeof getReduxProps>;


function MapFooterView(props: Props) {
    const {
        setParkingType,
        showSearchView,
        organisations,
        user,
        currentVehicle
    } = props;

    const {userRoles} = useUserRoles();

    const [evEnabled, setEvEnabled] = useState(false);

    const setCasualParkingType = useCallback(() => {
        setParkingType(ParkingType.CASUAL)
    }, [setParkingType]);

    const setElectricParkingType = useCallback(() => {
        setParkingType(ParkingType.ELECTRIC)
    }, [setParkingType]);

    const setLongTermParkingType = useCallback(() => {
        setParkingType(ParkingType.LONG_TERM)
    }, [setParkingType]);

    useEffect(() => {
        let enable = false;
        if (!!organisations && Object.keys(organisations || {}).length > 0) {
            const organisationArray = Object.values(organisations || {});
            organisationArray.forEach(o => {

                const orgIds = getPrivateOrgs(userRoles);
                if (orgIds.find(id => id === o.id)) {
                    //user belongs to org
                    if (o.electricChargingEnabled) {
                        enable = true;
                    }
                }
            });
        }

        let supportedCountry = false;
        if(!!user){

            const phone = user.phone || "";
            EV_ENABLED_PREFIXES.forEach(prefix => {
                if(phone.indexOf(prefix) === 0){
                    supportedCountry = true;
                }
            });
        }
        setEvEnabled(enable || supportedCountry);
    }, [organisations, userRoles]);

    const isMotorBikeDefault = currentVehicle?.feature === Feature.MOTORBIKE;

    return (
        <Card style={styles.base}>
            {showSearchView && <SearchView onSearchViewPressed={props.onSearchViewPressed}/>}
            <View style={styles.parkingButtons}>
                <ParkingTypeButton style={styles.parkingButton}
                                   icon={isMotorBikeDefault ? Icons.motorbike : Icons.car}
                                   green={props.parkingType === ParkingType.CASUAL}
                                   onPress={setCasualParkingType}>{Strings("casual")}</ParkingTypeButton>
                <View style={{width: 9}}/>

                <ParkingTypeButton style={styles.parkingButton}
                                   icon={Icons.key}
                                   green={props.parkingType === ParkingType.LONG_TERM}
                                   onPress={setLongTermParkingType}>{Strings("long_term")}</ParkingTypeButton>
                <View style={{width: 9}}/>

                {evEnabled && <ParkingTypeButton style={styles.parkingButton}
                                   icon={Icons.electricvehicleplug}

                    green={props.parkingType === ParkingType.ELECTRIC}
                    onPress={setElectricParkingType}
                >{Strings("ev_charger")}
                </ParkingTypeButton>}

            </View>
        </Card>
    );
}


const getReduxProps = (state: IRootReducer) => {
    const currentVehicle = _.first(state.user.vehicles?.filter(v => v.id === state.user.currentVehicleId)) || null;

    return {
        canUseEV: state.userOptions.userOptions?.canUseEV || false,
        hasSeenWelcomeBackMessage: state.user.hasSeenWelcomeBackMessage,
        parkingType: state.maps.preferences.parkingType ?? ParkingType.CASUAL,
        organisations: state.organisations.organisations,
        user: state.user.user,
        currentVehicle
    };
};

const actions = {
    setParkingType
};

export default connect(getReduxProps, actions)(React.memo(MapFooterView) as any) as React.FunctionComponent<MapFooterViewProps>;
