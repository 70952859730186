import React from "react";
import {Image, StyleSheet, TouchableOpacity, View} from "react-native";
import {Radio, Text, Colours} from 'react/legacy/parkable-components';
import Strings from "../../../util/localization/localization";
import { Card } from "../../../model/Card";

const cross_on_red = require("../../../resources/cross-on-red.png");

function CreditCardItem({card, isEditing, currentCardId, onRemovePress, onCreditCardItemPress, i}: Props) {

    const { last4, expiryMonth, expiryYear } = card;
    const onPress = (card: Card) => {
        if (isEditing) {
            onRemovePress(card.id, i);
            return
        }
        onCreditCardItemPress(card.id)
    };

    if (isEditing && currentCardId === card.id) {
        return <></>
    }

    return <View style={styles.main}>
        <TouchableOpacity
            disabled={currentCardId === card.id}
            activeOpacity={0.65}
            onPress={onPress.bind(null, card)}>

            {isEditing && currentCardId !== card.id ?
                <TouchableOpacity activeOpacity={0.65} style={{position: 'absolute', top: -16, right: -14, zIndex: 500}}
                                  onPress={() => {
                                      onRemovePress(card.id, i)
                                  }}>
                    <Image source={cross_on_red} style={{height: 30, width: 30}}/>
                </TouchableOpacity> : null}

            <View style={[styles.item, {flexDirection: 'column'}]}>
                <Text bold >**** **** **** {last4}</Text>
                <Text small>{`${Strings("expiry")}: ${expiryMonth}/${expiryYear}`}</Text>
            </View>
            {isEditing === false &&
            <View style={[styles.item, styles.blackBorder]}>
                <Radio
                    key={card.id}
                    initialValue={card.id}
                    value={currentCardId??undefined}
                    onChange={currentCardId !== card.id ? onPress.bind(null, card): undefined}
                    options={[{label: Strings("useThisCard"), value: card.id}]}
                />
            </View>}
        </TouchableOpacity>
    </View>
}


const styles = StyleSheet.create({
    main: {
        position: 'relative',
        marginBottom: 20,
        borderWidth: 1,
        borderColor: Colours.GREY_BORDER,
        borderRadius: 3,
        flex: 1,
    },
    item: {
        // alignItems: "center",
        justifyContent: "center",
        flex: 1,
        paddingHorizontal: 18,
        paddingVertical: 9,
    },

    blackBorder: {
        borderWidth: 1,
        borderTopColor: Colours.GREY_BORDER,
        borderBottomColor: 'transparent',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
    },
});

interface Props {
    card: Card,
    currentCardId: null | string,
    isEditing: boolean,
    onRemovePress: (card: string, index: number) => void,
    onCreditCardItemPress: (card: string) => void,
    i: number,
}


export default CreditCardItem;

