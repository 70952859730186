import React, { useCallback, useContext, useEffect, useState } from "react";
import { changeSessionBay, getUserBays } from "react/redux/actions/parks";
import { ParkingActivity } from "react/model/Types";
import _ from "lodash";
import Strings from "../../../util/localization/localization";
import { ChatContext, ProblemActionContext, QuestionContext, SupportContext } from "../Context";
import { Routes } from "react/navigation/root/root.paths";
import { BayDTO } from "react/model/BayDTO";
import { AllProblemViewProps } from "../../../views/support/ProblemView";
import { QuestionOption, QuestionTypes } from "./ProblemFlow.types";
import { useBayGroupsMemberInPark } from "react/api/bayGroup/bayGroup.api";
import { useNavigation } from "react/navigation/constants";
import { usePark } from "react/api/park";
import { useParkSessionV3 } from "react/api/parkSession/parkSession.api";
import {useSharingPoolBaysForUser} from "react/api/sharingPool/sharingPool.api";
import {useCurrentUserVehicle} from "react/components/map/parkableMapView/web/google/pin.constants";

const flow: QuestionTypes = {
    default: {
        type: "options",
        title: Strings("please_choose_one_bay"),
        action: "onChangeBayPress",
        isItSupport: true,
        options: [
            {
                key: "loadingBays",
                title: "Loading...",
            }
        ]
    },
    sessionAlreadyInBay: {
        type: "content",
        title: Strings("session_already_in_this_bay"),
        action: "helpMessage",
        isItSupport: true
    },
    noBayAvailable: {
        type: "options",
        title: Strings("sorry_no_available_bays"),
        isItSupport: true,
        options: [
            {
                key: "helpMessageNo",
                title: Strings("take_me_to_my_active_session"),
                action: "redirectToActiveSession"
            },
            {
                key: "helpMessageYes",
                title: Strings("yes_message_parkable"),
                action: "showMessageParkableSupportMessage"
            }
        ]
    },
    helpMessage: {
        type: "options",
        title: Strings("is_there_anything"),
        isItSupport: true,
        options: [
            {
                key: "helpMessageNo",
                title: Strings("take_me_to_my_active_session"),
                action: "redirectToActiveSession"
            },
            {
                key: "helpMessageYes",
                title: Strings("yes_message_parkable"),
                action: "onMessageParkablePress"
            }
        ]
    },
    helpMessageYes: {
        type: "content",
        title: Strings("message_parkable_support_message"),
        isItSupport: true
    }
}

export const SomethingWrongWithBay = (props: AllProblemViewProps) => {
    const {parkId, sessionId, bayId} = props.route.params;

    const { park } = usePark(parkId);
    const { parkSession: session } = useParkSessionV3(sessionId);

    const {dispatch} = props;
    const navigation = useNavigation();
    const {messages, updateMessages, errorHandling, processOptionQuestion} = useContext(ChatContext)
    const {setCurrentQuestion, updateNextQuestion} = useContext(QuestionContext);
    const {problemActions, updateProblemActions} = useContext(ProblemActionContext)
    const {updateSupportFlow} = useContext(SupportContext);

    const { bayGroups } = useBayGroupsMemberInPark(park?.organisation, parkId);

    const [otherBays, setOtherBays] = useState<BayDTO[]>([]);

    const currentVehicle = useCurrentUserVehicle();

    const [userBays, setUserBays] = useState<BayDTO[]>([]);

    const { bays: sharingPoolBays} = useSharingPoolBaysForUser(
        park?.organisation, park?.id, { feature: currentVehicle?.feature }
    );

    useEffect(() => {
        (async () => {
            if(!!session) {

                const onlyShowSharingPoolBays = session?.isSharingPool;

                if(onlyShowSharingPoolBays){
                    setUserBays(sharingPoolBays || []);
                }else{
                    dispatch(getUserBays(session.park, ParkingActivity.Casual, undefined, (bays:BayDTO[]) =>{
                        setUserBays(bays);
                    }));
                }
            }
        })();
    }, [session, sharingPoolBays?.length]);

    useEffect(()=>{
        problemActions["onSomethingWrongPress"] = onSomethingWrongPress;
        problemActions["onChangeBayPress"] = onChangeBayPress;
        problemActions["onChooseAnotherBay"] = onChooseAnotherBay;
        problemActions["helpMessage"] = helpMessage;
        problemActions["loadMoreBay"] = loadMoreBay;
        problemActions["redirectToActiveSession"] = redirectToActiveSession;
        problemActions["showMessageParkableSupportMessage"] = showMessageParkableSupportMessage;

        updateProblemActions(problemActions);
    }, [messages])

    const onSomethingWrongPress = () => {
        processOptionQuestion("somethingWrongBay");
        updateSupportFlow('somethingWrongBay', flow, "default");
    }

    const getLoadMore = (): QuestionOption => ({
        title: "Load more",
        key: "loadMore",
        action: "loadMoreBay"
    })

    const onChangeBayPress = useCallback(() =>
    {
        try{
            const bays = userBays
                .filter((b, i, filteredBays) => {
                    // Filter first bay found in a tandem pod.
                    if (b.tandemPod) {
                        return filteredBays.findIndex((fb) => fb.tandemPod === b.tandemPod) === i;
                    }
                    // Filter signage bays. If no signage, filter first bay found in an unsigned group.
                    return !!b.signage || filteredBays.findIndex((fb) => fb.group === b.group) === i;
                })
                // Exclude current bay from the bays available.
                .filter((b) => bayId !== b.id);

            if (bays.length == 0) {
                let updatedMsgs = _.cloneDeep(messages);
                updatedMsgs.pop();
                updateMessages(updatedMsgs);
                updateNextQuestion("somethingWrongBay", 'noBayAvailable');
                return false;
            }

            // Sort other bays before a tandem pod bay or an unsigned bay.
            bays.sort((a) => (a.tandemPod || !a.signage ? 1 : -1));
            // Show only 5 bays, we can add "load more" option to get next 5 bays.
            const partialBays = bays.splice(0, 5);
            setOtherBays(bays);

            if(messages.length == 0) {
                return false
            }

            const updatedMsgs = _.cloneDeep(messages);
            const lastQuestion = updatedMsgs.pop();

            if (!lastQuestion) {
                return false;
            }
            lastQuestion.options = partialBays.map((b): QuestionOption  => ({
                title: !!b.signage ? b.signage : Strings("any_bay_in_group")(bayGroups?.find(bg => bg.id === b.group)?.name??''),
                action: "onChooseAnotherBay",
                // @ts-ignore
                key: "bay:"+(!!b.signage ? b.signage : Strings("any_bay_in_group")(bayGroups?.find(bg => bg.id === b.group)?.name??'')), //todo change to `${b.id}_${b.park}` ?
                bayId: b.id,
                tandemPodId: b.tandemPod,
                bayGroup: b.group
            }));

            // if park has more than 5 bays then show load more
            if(partialBays.length > 0 ) {
                lastQuestion.options?.push(getLoadMore());
            }

            lastQuestion.action = undefined;
            updatedMsgs.push(lastQuestion);

            updateMessages(updatedMsgs);
        }
        catch (err: any) {
            errorHandling(Strings("error_message_in_support"));
        }
    }, [messages]);

    const onChooseAnotherBay = (question: any, option?: QuestionOption) => {

        if (!session || !option) {
            return
        }

        if( bayId !== option.bayId ) {
            try {
                dispatch( changeSessionBay(session.id, option.bayId!!, option.bayGroup!!, park?.organisation ?? null, () =>
                {
                    processOptionQuestion(option.key);
                    setCurrentQuestion({
                        type: "content",
                        title: Strings("bay_has_been_changed") + (option.key).split(':')[1],
                        isItSupport: true
                    });

                    updateNextQuestion("somethingWrongBay", 'helpMessage');
                },
                (error: any) => {
                    console.log("error", error)
                    errorHandling(Strings("error_message_in_support"));
                }));
            } catch(error) {
                errorHandling(Strings("error_message_in_support"));
            }
        } else {
            processOptionQuestion(option.key);
            updateNextQuestion("somethingWrongBay", "sessionAlreadyInBay");
        }
    }

    const loadMoreBay = () => {
        const partialBays = otherBays.splice(0, 5);
        setOtherBays(otherBays);

        let updatedMsgs = _.cloneDeep(messages);
        const lastQuestion = updatedMsgs.pop();

        if (!lastQuestion) {
            return false;
        }

        lastQuestion.action = undefined;

        if (partialBays.length > 0) {

            const newOptions = partialBays.map((b): QuestionOption => ({
                title: !!b.signage ? b.signage : Strings("any_bay_in_group")(bayGroups?.find(bg => bg.id === b.group)?.name??''),
                action: "onChooseAnotherBay",
                // @ts-ignore
                key: "bay:" + (!!b.signage ? b.signage : Strings("any_bay_in_group")(bayGroups?.find(bg => bg.id === b.group)?.name??'')), //todo change to `${b.id}_${b.park}` ?
                bayId: b.id,
	            tandemPodId: b.tandemPod,
                bayGroup: b.group
            }));

            // remove the previously added load more option
            //and add again at the end after concatenating new options
            lastQuestion.options = [
                ...lastQuestion.options?.filter((option) => option.key !== 'loadMore')??[],
                ...newOptions, // add new options to the existing array
                ...(otherBays.length === 0 ?
                    [] as QuestionOption[] : // when no more bays available after showing all then remove load more option
                    [getLoadMore()])
            ];
        } else {
            lastQuestion.options = [
                ...lastQuestion.options?.filter((option) => option.key !== 'loadMore')??[]];
        }

        updatedMsgs.push(lastQuestion);

        updateMessages(updatedMsgs);
    }

    const helpMessage = () => {
        updateNextQuestion("somethingWrongBay", "helpMessage");
    }

    const redirectToActiveSession = () => {
        navigation.push(Routes.ActiveSession, {});
    }

    const showMessageParkableSupportMessage = (question: any, option?: QuestionOption) => {
        processOptionQuestion(option!!.key);
        updateNextQuestion("somethingWrongBay", "helpMessageYes");
    }

    return <></>
}
