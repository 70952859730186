import * as UserOptionsApi from "../../api/userOptions";
import fetch from "../reducers/fetch";
import {DispatchFunc} from "../../model/Types";
import {UpdateUserOptionsRequest} from "../../dto/UpdateUserOptionsRequest";
import {UserOptions} from "../../model/UserOptions";
import {UserOptionsFavouriteParksResponse} from "../../dto/UserOptionsFavouriteParksResponse";
import { MuteErrAlert } from "../reducers/userOptions";
import {Token} from "../../api/rest";

export const RECEIVED_USER_OPTIONS = 'RECEIVED_USER_OPTIONS';
export const RECEIVED_FAVOURITE_PARKS = 'RECEIVED_FAVOURITE_PARKS';
export const SHOW_SAVE_FAVOURITE_MODAL = 'SHOW_SAVE_FAVOURITE_MODAL';
export const SHOW_DELETE_FAVOURITE_MODAL = 'SHOW_DELETE_FAVOURITE_MODAL';
export const SET_MUTE_ERR_ALERT = 'SET_MUTE_ERR_ALERT';

export function receivedUserOptions(userOptions: UserOptions) {
    return {
        type: RECEIVED_USER_OPTIONS,
        userOptions
    }
}

export function receivedFavouriteParks(favouriteParks: UserOptionsFavouriteParksResponse) {
    return {
        type: RECEIVED_FAVOURITE_PARKS,
        favouriteParks: favouriteParks.favouriteParks,
        favouriteParksByChoice: favouriteParks.favouriteParksByChoice,
    }
}

export function getFavouriteParks(onSuccess?: (uo: UserOptionsFavouriteParksResponse) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return UserOptionsApi.getFavouriteParksAPI(api, token).then((favouriteParks) => {
            dispatch(receivedFavouriteParks(favouriteParks));
            if(onSuccess) {
                onSuccess(favouriteParks);
            }
        }).catch((err) => {
            if(onError){
                onError(err);
            }
            if(err === 'network_error'){
                throw err;
            }
        });
    }, arguments);
}

export function showDeleteFavouriteModal(isShowing: boolean) {
    return {
        type: SHOW_DELETE_FAVOURITE_MODAL,
        isShowing
    }
};

export function showSaveFavouriteModal(isShowing: boolean) {
    return {
        type: SHOW_SAVE_FAVOURITE_MODAL,
        isShowing
    }
};

export function getUserOptions(onSuccess?: (uo: UserOptions) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return UserOptionsApi.getUserOptionsAPI(api, token).then(({userOptions}) => {
            dispatch(receivedUserOptions(userOptions));
            if (onSuccess) {onSuccess(userOptions)}
        }).catch((err) => {
            if(err === 'network_error'){
                throw err;
            }
            else if (onError) {onError(err)}
        });
    }, arguments);
}

export function updateUserOptions(data: UpdateUserOptionsRequest, onSuccess?: (uo: UserOptions) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return UserOptionsApi.updateUserOptionsAPI(api, token, data).then(({userOptions}) => {
            dispatch(receivedUserOptions(userOptions));
            if (onSuccess) {onSuccess(userOptions)}
        }).catch((err) => {
            if(err === 'network_error'){
                throw err;
            }
            else if (onError) {onError(err)}
        });
    }, arguments);
}

export function saveFavouritePark(parkLabel: string, parkId: number, onSuccess?: (userOptions: UserOptions) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return UserOptionsApi.saveParkToFavourite(api, token, parkLabel, parkId).then(({userOptions}) => {
            const onSuccessGetFavourites = () => {
                dispatch(receivedUserOptions(userOptions))
                dispatch(showSaveFavouriteModal(false))
                if(onSuccess) {
                    onSuccess(userOptions);
                }
            }
            dispatch(getFavouriteParks(onSuccessGetFavourites, onSuccessGetFavourites));
        }).catch(err => {
            dispatch(showSaveFavouriteModal(false))
            if (err === 'network_error') {
                throw err;
            }
            else if (onError) {
                onError(err);
            }
        })
    }, arguments)
}

export function deleteFavouritePark(parkId: number, onSuccess?: (userOptions: UserOptions) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return UserOptionsApi.deleteFavouritePark(api, token, parkId).then(({userOptions}) => {
            const onSuccessGetFavourite = () => {
                dispatch(receivedUserOptions(userOptions))
                dispatch(showDeleteFavouriteModal(false))
                if(onSuccess) {
                    onSuccess(userOptions);
                }
            };
            dispatch(getFavouriteParks(onSuccessGetFavourite, onSuccessGetFavourite));
        }).catch(err => {
            dispatch(showDeleteFavouriteModal(false))
            if (err === 'network_error') {
                throw err;
            }
            else if (onError) {
                onError(err);
            }
        })
    }, arguments)
}

export function setClientFlag(flag: string, value: boolean){
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return UserOptionsApi.setClientFlag(api, token, flag, value).then(({userOptions}) => {
            dispatch(receivedUserOptions(userOptions))
        }).catch((err: any) => {
            if (err === 'network_error') {
                throw err;
            }
        })
    }, arguments)
}

export function setMuteErrAlert(muteErrAlert: MuteErrAlert) {
    return {
        type: SET_MUTE_ERR_ALERT,
        muteErrAlert
    }
}
