import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import Colours from "../styles/Colours";
import Text, { TextProps } from "../text/Text";
import Touchable from "../touchable/Touchable";
import classnames from "../util/classnames";

const styles = StyleSheet.create({
    base: {
        padding: 4,
        flexDirection: "row",
    },
    textContainer: {
        flex: 1,
        paddingLeft: 9,
        justifyContent: "center",
    },
    radioBase: {
        height: 27,
        width: 27,
        borderRadius: 27,
        borderWidth: 2,
        borderColor: Colours.NEUTRALS_BLACK,
        alignItems: "center",
        justifyContent: "center",
    },
    radioSelected: {
        borderColor: Colours.BLUE_300,
    },
    radioSelectedRed: {
        borderColor: Colours.RED,
    },
    radioSelectedGreen: {
        borderColor: Colours.GREEN_300,
    },
    radioDisabled: {
        borderColor: Colours.GREY_BORDER,
    },

    radioCenterBase: {
        height: 13,
        width: 13,
        borderRadius: 18,
        backgroundColor: Colours.BLUE_300,
    },
    radioCenterSelectedRed: {
        backgroundColor: Colours.RED,
    },
    radioCenterSelectedGreen: {
        backgroundColor: Colours.GREEN_300,
    },
    radioCenterDisabled: {
        backgroundColor: Colours.GREY_BORDER,
    },
});

const cn = classnames(styles);

export type RadioOption = {
    label: string;
    value: string;
};

export type RadioProps = {
    options: RadioOption[];
    initialValue?: string;
    value?: string;
    onChange?: (value: any) => void;
    red?: boolean;
    green?: boolean;
    textProps?: TextProps;
    disabled?: boolean;
    selected?: boolean;
};

export default function Radio(props: RadioProps) {
    const { options, initialValue, onChange, red, green, textProps, disabled } =
        props;

    const [stateValue, setValue] = useState<string>(initialValue || "");

    const value = props.value || stateValue;

    const onRadioPress = (option: RadioOption) => {
        setValue(option.value);
        onChange?.(option);
    };

    const radioItems = options.map((option: RadioOption) => {
        const { label, value: optionValue } = option;

        const isSelected =
            props.selected === undefined
                ? value === optionValue
                : props.selected;

        const radioStyle = cn.styles("radioBase", {
            radioSelected: isSelected,
            radioSelectedRed: isSelected && red,
            radioSelectedGreen: isSelected && green,
            radioDisabled: disabled,
        });

        const radioCenterStyle = cn.styles("radioCenterBase", {
            radioCenterSelectedRed: isSelected && red,
            radioCenterSelectedGreen: isSelected && green,
            radioCenterDisabled: disabled,
        });

        return (
            <Touchable
                key={optionValue}
                style={styles.base}
                disabledColor={"transparent"}
                onPress={() => onRadioPress(option)}
                disabled={disabled}
            >
                <View style={radioStyle}>
                    {isSelected && <View style={radioCenterStyle} />}
                </View>
                <View style={styles.textContainer}>
                    <Text small {...(textProps || {})}>
                        {label}
                    </Text>
                </View>
            </Touchable>
        );
    });

    return <View>{radioItems}</View>;
}
