import {get, post} from './rest';
import { OcpiEvse } from '../model/ocpi/OcpiEvse'
import {OcpiSession} from "../model/ocpi/OcpiSession";
import {OcpiCommand} from "../model/ocpi/OcpiCommand";
import {Token} from "./rest";
import {put} from "./http/rest"
import {OcpiToken} from "../model/ocpi/OcpiToken";
import { useMySWR } from './http/use-swr';

export type OcpiEvsesResponse = {
    evses: Array<OcpiEvse>;
}

export type OcpiSessionsResponse = {
    sessions: Array<OcpiSession>
}

export type OcpiSessionResponse = {
    session: OcpiSession
}

export type OcpiCommandResponse = {
    ocpiCommand: OcpiCommand
}

export type OcpiTokenResponse = {
    token: OcpiToken
}

export type OcpiTokensResponse = {
    tokens: OcpiToken[]
}

export const getEvses = (api:string, token: Token, provider: string, location: string): Promise<OcpiEvsesResponse> => {
    return get(token, `${api}v1/ev/provider/${provider}/locations/${location}/evse`)
}

export const startEvSession = (api:string, token: Token, cpoProviderId: string, location_id: string, evse_uid:string, parkSession:number): Promise<OcpiCommandResponse> => {

    const data = {
        cpoProviderId,
        location_id,
        evse_uid,
        parkSession
    }

    return post(token, `${api}v1/ev/command/startcommand`, data)
}

export const getActiveEvSession = (api:string, token: Token, parkSessionId:number): Promise<OcpiSessionResponse> => {
    return get(token, `${api}v1/ev/activeSession/${parkSessionId}`)
}

export const getEvSessions = (api:string, token: Token, parkSessionId:number): Promise<OcpiSessionsResponse> => {
    return get(token, `${api}v1/ev/sessions/${parkSessionId}`)
}

export const getOcpiCommand =  (api:string, token: Token, commandId:number): Promise<OcpiCommandResponse> => {
    return get(token, `${api}v1/ev/command/startcommand?commandId=${commandId}`)
}

export const getTokensForUser = (api: string, token: Token): Promise<OcpiTokensResponse> => {
    return get(token, `${api}v1/ev/rfid/tokens`)
}

export const useEvTokensForUsers = () => useMySWR<OcpiTokensResponse>(`v1/ev/rfid/tokens`)

export const attachEvRfidToUser = async (rfid: string) => {
    return put<OcpiTokenResponse>(`v1/ev/rfid/token/${rfid}/attach`);
}

export const detachEvRfidFromUser = async (rfid: string) => {
    return put<OcpiTokenResponse>(`v1/ev/rfid/token/${rfid}/detach`);
}

export const renameRfidToken = async (rfid: string, newName: string) => {
    const data = {
        alias: newName
    }

    return put<OcpiTokenResponse>(`v1/ev/rfid/token/${rfid}/rename`, data);
}