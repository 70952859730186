import { DayAvailabilityDTO } from "../../model/DayAvailability";

const indexToTime = (timeIndex: number) => {
	let  militaryHour = Math.floor(timeIndex / 2);
	const mins = (((timeIndex % 2) * 30) + "00").slice(0, 2);
	let standardHour = militaryHour % 12;
	if(standardHour === 0) standardHour = 12;

	return `${standardHour}${ mins === "00" ? "" : "." +  mins }${militaryHour < 12 ? "am" : "pm"}`
}

export const getOpeningHoursFromAvailString = (hourlyAvailability: DayAvailabilityDTO["hourlyAvailability"]) => {
	const openingIndex = (hourlyAvailability.indexOf("t") ?? 47)
	const closingIndex = openingIndex != null
		? openingIndex + (hourlyAvailability.slice(openingIndex + 1).indexOf("f") ?? 47)
		: 47

	const openingTime = indexToTime(openingIndex)
	const closingTime = indexToTime(closingIndex + 1)

	return [openingTime, closingTime] as const
}
