import React, { useCallback } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { ChatParticipant } from "../../model/chat/ChatParticipantDTO";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Icon from "react/legacy/parkable-components/icon/Icon";
import Icons from "react/legacy/parkable-components/icon/Icons";
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import Text from "react/legacy/parkable-components/text/Text";
import { pickColor } from "../messages/Util";
import { callPhoneNumber, sendEmail } from "../../util/Util";
import { formattedTime } from "../../util/tandemParking.constants";
import Strings from "../../util/localization/localization";
import { isTandemSessionActive } from "../../util/tandemChatConstants";

type Props = {
    index: number;
    participant: ChatParticipant;
    hasPermissionToSeePrivacyDetails?: boolean | null;
};

const ChatParticipantRow = (props: Props) => {
    const { index, participant, hasPermissionToSeePrivacyDetails } = props;

    const renderContentRight = useCallback(() => {
        if (hasPermissionToSeePrivacyDetails && isTandemSessionActive(participant.endedSessionAt)) {
            return (
                <View style={styles.participant}>
                    {participant.phone && (
                        <TouchableOpacity
                            onPress={() => callPhoneNumber(participant.phone!)}
                            style={styles.contactIconContainer}
                        >
                            <Icon name={Icons.phone} iconStyle={{ fontSize: 20 }} small style={styles.contactIcon} />
                        </TouchableOpacity>
                    )}
                    {participant.email && (
                        <TouchableOpacity
                            onPress={() => sendEmail(participant.email!)}
                            style={styles.contactIconContainer}
                        >
                            <Icon name={Icons.email} iconStyle={{ fontSize: 20 }} small style={styles.contactIcon} />
                        </TouchableOpacity>
                    )}
                </View>
            );
        }
    }, [participant, hasPermissionToSeePrivacyDetails]);

    return (
        <TableRow
            key={participant.userId}
            iconLeft="user"
            iconLeftProps={{ iconStyle: { color: pickColor(participant.isActive ? index : -1) } }}
            label={participant.registration}
            labelProps={{ style: styles.rego }}
            contentRight={renderContentRight()}
        >
            {!participant.endedSessionAt && !participant.estimatedLeaveTime && (
                <Text small grey>
                    {Strings("leave_time_unknown")}
                </Text>
            )}

            {!participant.endedSessionAt && !!participant.estimatedLeaveTime && (
                <Text small grey>
                    {Strings("leaving_around")(formattedTime(participant.estimatedLeaveTime))}
                </Text>
            )}

            {!!participant.endedSessionAt && (
                <Text small grey>
                    {Strings("left_at")(formattedTime(participant.endedSessionAt))}
                </Text>
            )}
        </TableRow>
    );
};

export default ChatParticipantRow;

const styles = StyleSheet.create({
    participant: {
        marginLeft: 9,
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
    },
    rego: {
        textAlign: "center",
        borderWidth: 1,
        borderColor: Colours.GREY_10,
        alignSelf: "flex-start",
        paddingLeft:3,
        paddingRight:3
    },
    contactIconContainer: {
        borderWidth: 1,
        borderColor: Colours.GREY_10,
        borderRadius: 30,
        marginHorizontal: 5,
    },
    contactIcon: {
        width: 30,
        height: 30,
    },
});
