import React, { useMemo } from "react";
import { Platform, StyleProp, StyleSheet, ViewStyle } from "react-native";
import { BottomSheetHandleProps, TouchableOpacity } from "@gorhom/bottom-sheet";
import Animated, {
    Extrapolate,
    interpolate,
    SharedValue,
    useAnimatedStyle,
    useDerivedValue
} from "react-native-reanimated";
import { toRad } from "react-native-redash";

// @ts-ignore
export const transformOrigin = ({ x, y }, ...transformations) => {
    "worklet";
    return [
        { translateX: x },
        { translateY: y },
        ...transformations,
        { translateX: x * -1 },
        { translateY: y * -1 }
    ];
};

interface HandleProps extends BottomSheetHandleProps {
    style?: StyleProp<ViewStyle>;
    onPress?: (animatedIndex: SharedValue<number>) => void,
}

const Handle: React.FC<HandleProps> = (props) => {

    const { style, animatedIndex, onPress } = props;

    //#region animations
    const indicatorTransformOriginY = useDerivedValue(() =>
        interpolate(animatedIndex.value, [0, 1, 2], [-1, 0, 1], Extrapolate.CLAMP)
    );
    //#endregion

    //#region styles
    const containerStyle = useMemo(() => [styles.header, styles.platformHeader, style], [style]);
    const containerAnimatedStyle = useAnimatedStyle(() => {
        const borderTopRadius = interpolate(
            animatedIndex.value,
            [1, 2],
            [20, 0],
            Extrapolate.CLAMP
        );
        return {
            borderTopLeftRadius: borderTopRadius,
            borderTopRightRadius: borderTopRadius
        };
    });
    const leftIndicatorAnimatedStyle = useAnimatedStyle(() => {
        const leftIndicatorRotate = interpolate(
            animatedIndex.value,
            [0, 1],
            [toRad(-30), toRad(30)],
            Extrapolate.CLAMP
        );
        return {
            transform: transformOrigin(
                {
                    x: 0,
                    y: indicatorTransformOriginY.value
                },
                {
                    rotate: `${leftIndicatorRotate}rad`
                }
            )
        };
    });
    const rightIndicatorAnimatedStyle = useAnimatedStyle(() => {
        const rightIndicatorRotate = interpolate(
            animatedIndex.value,
            [0, 1],
            [toRad(30), toRad(-30)],
            Extrapolate.CLAMP
        );
        return {
            transform: transformOrigin(
                {
                    x: 0,
                    y: indicatorTransformOriginY.value
                },
                {
                    rotate: `${rightIndicatorRotate}rad`
                }
            )
        };
    });
    //#endregion

    const handlePress = () => {
        if(onPress) {
            onPress(animatedIndex);
        }
    }

    return (
        <TouchableOpacity onPress={handlePress} activeOpacity={0.8}>
            <Animated.View
                style={[containerStyle, containerAnimatedStyle]}
                renderToHardwareTextureAndroid={true}
            >
                <Animated.View style={[styles.indicator, leftIndicatorAnimatedStyle]} />
                <Animated.View style={[styles.indicator, rightIndicatorAnimatedStyle]} />
            </Animated.View>
        </TouchableOpacity>
    );
};

export default Handle;

const styles = StyleSheet.create({
    header: {
        flexDirection: "row",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        paddingVertical: 14,
    },
    platformHeader: Platform.select({
        android: {
            borderColor: "#EEEEEE",
            borderWidth: 1,
            borderBottomWidth: 0,
            marginHorizontal: -1,
        },
        default: {}
    }),
    indicator: {
        width: 10,
        height: 4,
        backgroundColor: "#999",
        borderRadius: 2,
        marginHorizontal: -2,
    },
});
