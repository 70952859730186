import pickBy from "lodash/pickBy";
import React, {PropsWithChildren} from "react";
import {StyleSheet, Text as RNText, TextProps as RNTextProps,} from "react-native";
import Colours from "../styles/Colours";
import classnames from "../util/classnames";

const baseStyles = StyleSheet.create({
    base: {
        fontFamily: "GTEestiDisplay-Regular",
        fontSize: 18,
        lineHeight: 25,
        backgroundColor: "transparent",
        color: Colours.NEUTRALS_BLACK,
    },
    white: {
        color: Colours.NEUTRALS_WHITE,
    },
    grey: {
        color: Colours.GREY_60,
    },
    green: {
        color: Colours.GREEN_300,
    },
    red: {
        color: Colours.RED_DARK,
    },
    bold: {
        fontFamily: "GTEestiDisplay-Bold",
    },
    h1: {
        fontSize: 36,
        lineHeight: 39,
        marginBottom: 18,
        fontFamily: "GTEestiDisplay-Bold",
    },
    h2: {
        fontSize: 25,
        lineHeight: 27,
        marginBottom: 18,
        fontFamily: "GTEestiDisplay-Bold",
    },
    h3: {
        fontSize: 21,
        lineHeight: 36,
        marginBottom: 18,
        fontFamily: "GTEestiDisplay-Bold",
    },
    h4: {
        fontSize: 18,
        lineHeight: 23,
        marginBottom: 9,
    },
    h5: {
        fontSize: 16,
        lineHeight: 18,
        marginBottom: 9,
    },
    p: {
        marginBottom: 18,
    },
    small: {
        fontSize: 15,
        lineHeight: 18,
    },
    strapline: {
        fontSize: 13,
        lineHeight: 22,
        letterSpacing: 1,
        textTransform: "uppercase",
    },
    center: {
        textAlign: "center",
    },
    rego: {
        alignSelf: "baseline",
        borderWidth: 1,
        paddingHorizontal: 4,
        padding: 4,
        lineHeight: 22,
        paddingTop: 3,
        textTransform: "uppercase",
    },
});

const cn = classnames(baseStyles);

export type TextProps = PropsWithChildren<
    {
        white?: boolean;
        grey?: boolean;
        green?: boolean;
        red?: boolean;
        bold?: boolean;
        h1?: boolean;
        h2?: boolean;
        h3?: boolean;
        h4?: boolean;
        h5?: boolean;
        p?: boolean;
        small?: boolean;
        strapline?: boolean;
        center?: boolean;
        rego?: boolean;

        mt?: number;
        mr?: number;
        mb?: number;
        ml?: number;

        pt?: number;
        pr?: number;
        pb?: number;
        pl?: number;
    } & RNTextProps
>;

export default function Text(props: TextProps) {
    const {
        white,
        grey,
        green,
        red,
        bold,
        h1,
        h2,
        h3,
        h4,
        h5,
        p,
        small,
        strapline,
        center,
        rego,
        mt,
        mr,
        mb,
        ml,
        pt,
        pr,
        pb,
        pl,
        ...otherProps
    } = props;

    const styles = cn.styles("base", {
        white: white,
        grey: grey,
        green: green,
        red: red,
        bold: bold,
        h1: h1,
        h2: h2,
        h3: h3,
        h4: h4,
        h5: h5,
        p: p,
        small: small,
        strapline: strapline,
        center: center,
        rego: rego,
    });

    const margins = pickBy({
        marginTop: mt,
        marginRight: mr,
        marginBottom: mb,
        marginLeft: ml,
        paddingTop: pt,
        paddingRight: pr,
        paddingBottom: pb,
        paddingLeft: pl,
    });

    return <RNText {...otherProps} style={[styles, margins, props.style]} />;
}
