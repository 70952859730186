export enum ParkingPreferenceStatus {
    ACTIVE = 'Active',
    DELETED = 'Deleted'
}

export interface ParkingPreferenceDTO {
    id: number,
    user: number,
    bay: number,
    park: number,
    status: ParkingPreferenceStatus,
}

export interface ParkingPreferenceResponse {
    preference: ParkingPreferenceDTO
}
