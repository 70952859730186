export default interface QRAction {
    id: string;
    actionType: ActionType;
    actionParams: {[key:string]: string};
    status: ActionStatus;
}

export enum ActionType {
    ViewPark = 'ViewPark',
    ViewCampus = 'ViewCampus',
    StartParking = 'StartParking',
    StartCharging = 'StartCharging',
    ApplyVoucher = 'ApplyVoucher'
}

export enum ActionStatus {
    Active = "Active",
    Deleted = "Deleted",
}