import {DispatchFunc} from "../../model/Types";

const NOTIFICATION_LISTENERS = {} as {[id: string]: NotificationListener};

type NotificationListener = (code: string, payload: any) => (void | boolean | Promise<any>);

export default async function notifyListeners(dispatch: DispatchFunc, notificationPayload: any) {
    console.log("NOTIF RECEIVED", notificationPayload);
    const code = notificationPayload.code;

    const results = [];
    for (let i = 0; i < Object.values(NOTIFICATION_LISTENERS).length; i++) {
        const func = Object.values(NOTIFICATION_LISTENERS)[i];
        const result = await func(code, notificationPayload);
        results.push(result);
    }
    return results.filter(r => r).length > 0;
}

export function addNotificationListener(func: NotificationListener, componentName: string) {
    const id = `${componentName || 'Component'}${Math.floor(Math.random() * 10000)}`;
    NOTIFICATION_LISTENERS[id] = func;
    return {
        remove: () => delete NOTIFICATION_LISTENERS[id]
    }
}