import moment from 'moment-timezone'
import Strings from "../../../util/localization/localization";
import {OcpiSession, SessionStatus} from "../../../model/ocpi/OcpiSession";

// export function getTimeElapsedText(session: OcpiSession): string {
//
//     let elapsedText: string | null = null;
//
//     let currentCharge = null;
//     const end = !!session.end_date_time ? moment.tz(session.end_date_time, "UTC") : moment();
//
//     const start = moment.tz(session.start_date_time??new Date(), "UTC");
//
//     const sessionDiffDays = end.diff(start, "day");
//     let sessionDiffHours = end.diff(start, "hours");
//     let sessionDiffMins = end.diff(start, "minutes");
//
//     sessionDiffHours = sessionDiffHours - (sessionDiffDays * 24);
//     sessionDiffMins = sessionDiffMins - (sessionDiffDays * 24 * 60) - (sessionDiffHours * 60);
//
//     const dayText = sessionDiffDays > 0 ? `${sessionDiffDays} ${Strings("day")}${sessionDiffDays > 1 ? 's' : ''}` : '';
//     const hourText = sessionDiffHours > 0 ? `${sessionDiffHours} ${Strings("hour")}${sessionDiffHours > 1 ? 's' : ''}` : '';
//     const minuteText = sessionDiffMins > 0 ? `${sessionDiffMins} ${Strings("min")}${sessionDiffMins > 1 ? 's' : ''}` : '';
//
//     elapsedText = `${dayText} ${hourText} ${minuteText}`.trim();
//
//     if (elapsedText.trim() === "") {
//         elapsedText = `0 ${Strings("mins")}`;
//     }
//
//     return elapsedText;
// }

export function getTimeElapsedText(sessions: Array<OcpiSession>): string {

    let elapsedText: string | null = null;

    let currentCharge = null;
    let allDiffDays = 0;
    let allDiffHours = 0;
    let allDiffMins = 0;

    sessions.forEach(session => {
        if(session === null || session.status === SessionStatus.INVALID){
            return;
        }
        const end = !!session.end_date_time ? moment.tz(session.end_date_time, "UTC") : moment();

        const start = moment.tz(session.start_date_time??new Date(), "UTC");

        const sessionDiffDays = end.diff(start, "day");
        let sessionDiffHours = end.diff(start, "hours");
        let sessionDiffMins = end.diff(start, "minutes");

        sessionDiffHours = sessionDiffHours - (sessionDiffDays * 24);
        sessionDiffMins = sessionDiffMins - (sessionDiffDays * 24 * 60) - (sessionDiffHours * 60);

        allDiffDays += sessionDiffDays;
        allDiffHours += sessionDiffHours;
        allDiffMins += sessionDiffMins;
    })
    const dayText = allDiffDays > 0 ? `${allDiffDays} ${Strings("day")}${allDiffDays > 1 ? 's' : ''}` : '';
    const hourText = allDiffHours > 0 ? `${allDiffHours} ${Strings("hour")}${allDiffHours > 1 ? 's' : ''}` : '';
    const minuteText = allDiffMins > 0 ? `${allDiffMins} ${Strings("min")}${allDiffMins > 1 ? 's' : ''}` : '';

    elapsedText = `${dayText} ${hourText} ${minuteText} ${Strings("charging")}`.trim();

    if (allDiffDays === 0 && allDiffHours === 0 && allDiffMins === 0) {
        elapsedText = `0 ${Strings("mins")} ${Strings("charging")}`;
    }

    return elapsedText;
}
