export enum SessionStatus {
    //The session has been accepted and is active. All pre-conditions were met: Communication between EV and
    //EVSE (for example: cable plugged in correctly), EV or driver is authorized. EV is being charged, or can be charged. Energy is, or is not, being transfered.
    ACTIVE= "ACTIVE",
    //The session has been finished successfully. No more modifications will be made to the Session object using this state.
    COMPLETED = "COMPLETED",
    //The Session object using this state is declared invalid and will not be billed.
    INVALID = "INVALID",
    //The session is pending, it has not yet started. Not all pre-conditions are met. This is the initial state. The session might never become an active session.
    PENDING = "PENDING",
    //The session is started due to a reservation, charging has not yet started. The session might never become an active session.
    RESERVATION = "RESERVATION",
    //The session has not completed but is currently not drawing any power
    IDLE = "IDLE"
}

export enum SessionActiveStatus {
    //This is the first state once the session becomes active
    STARTED,
    //This is a Parkable state that means charger not currently delivering charge but may do in the future
    IDLE,
    //This is a Parkable state that means charger has resumed charging after a previously idle period
    RESUMED
}

export type Price = {
    /**
     * Price/Cost excluding VAT.
     */
    excl_vat: number,

    /**
     * Price/Cost including VAT.
     */
    incl_vat: number
}

export type ChargingPeriod = {

    /**
     * Start timestamp of the charging period. A period ends when the next period starts. The last period ends when the session ends.
     */
    start_date_time: string;

    /**
     * List of relevant values for this charging period.
     */
    dimensions: CdrDimension,

    /**
     * Unique identifier of the Tariff that is relevant for this Charging Period. If not provided, no Tariff is relevant during this period.
     */
    tariff_id: string;

}

export type CdrDimension = {
    /**
     * Type of CDR dimension.
     */
    type: CdrDimensionType;

    /**
     * Volume of the dimension consumed, measured according to the dimension type.
     */
    volume: number
}

export enum CdrDimensionType {
    //Average charging current during this ChargingPeriod: defined in A (Ampere). When negative, the current is flowing from the EV to the grid.
    CURRENT,
    //Total amount of energy (dis-)charged during this ChargingPeriod: defined in kWh. When negative, more energy was feed into the grid then charged into the EV. Default step_size is 1.
    ENERGY,
    //Total amount of energy feed back into the grid: defined in kWh.
    ENERGY_EXPORT,
    //Total amount of energy charged, defined in kWh.
    ENERGY_IMPORT,
    //Sum of the maximum current over all phases, reached during this ChargingPeriod: defined in A (Ampere).
    MAX_CURRENT,
    //Sum of the minimum current over all phases, reached during this ChargingPeriod, when negative, current has flowed from the EV to the grid. Defined in A (Ampere).
    MIN_CURRENT,
    //Maximum power reached during this ChargingPeriod: defined in kW (Kilowatt).
    MAX_POWER,
    //Minimum power reached during this ChargingPeriod: defined in kW (Kilowatt), when negative, the power has flowed from the EV to the grid.
    MIN_POWER,
    //Time during this ChargingPeriod not charging: defined in hours, default step_size multiplier is 1 second.
    PARKING_TIME,
    //Average power during this ChargingPeriod: defined in kW (Kilowatt). When negative, the power is flowing from the EV to the grid.
    POWER,
    //Time during this ChargingPeriod Charge Point has been reserved and not yet been in use for this customer: defined in hours, default step_size multiplier is 1 second.
    RESERVATION_TIME,
    //Current state of charge of the EV. See note below.
    STATE_OF_CHARGE,
    //Time charging during this ChargingPeriod: defined in hours, default step_size multiplier is 1 second.
    TIME

}

export type OcpiSession = {

    id: string,

    country_code: string,

    party_id: string,

    start_date_time: string,

    location_id: string,
    end_date_time: string,
    kwh: number | null,

    authorization_reference: string,

    evse_uid: string,
    connector_id: string,
    meter_id: string,
    currency: string,
    charging_periods: ChargingPeriod,
    total_cost: Price,
    status: SessionStatus,
    last_updated: string
    activeStatus: SessionActiveStatus
}
