import {BayDTO} from "react/model/BayDTO";

const initialState = {
    baysAvailableForVehicle: [] as BayDTO[]
};
const SET_BAYS_AVAILABLE_FOR_VEHICLE = "SET_BAYS_AVAILABLE_FOR_VEHICLE";

export type IDataReducer = typeof initialState;

export function setBaysAvailableForVehicleRedux(baysAvailableForVehicle: BayDTO[]) {
    return {
        type: SET_BAYS_AVAILABLE_FOR_VEHICLE,
        baysAvailableForVehicle
    }
}

export default function dataReducer(state = initialState, action: any): IDataReducer {
    switch (action.type) {
        case SET_BAYS_AVAILABLE_FOR_VEHICLE:
            return {
                ...state,
                baysAvailableForVehicle: action.baysAvailableForVehicle
            };
        default:
            return state;
    }
}