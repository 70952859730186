import Colours from "react/legacy/parkable-components/styles/Colours";

export default {
    api: {
        name: "qa",
        url: "https://api-qa.parkable.com/api/",
        serviceUrl: (service) => `https://api-qa.parkable.com/service/${service}/api/`,
    },
    firebase: {
        apiKey: "AIzaSyBlqvH1TZGy5OxoISBWqpUtJRdcHbdGfbU",
        authDomain: "parkable-qa.firebaseapp.com",
        appId: "1:106705050091:web:99f44f17c2770c5e65a1ae",
        databaseURL: "https://parkable-qa.firebaseio.com",
        messagingSenderId: "106705050091",
        projectId: "parkable-qa",
        storageBucket: "parkable-qa.appspot.com",
    },
    environmentDisplayName: "QA",
    borderColour: Colours.GREEN_300,
    teamsClientId: "18cde738-d43b-4280-a669-40f7d5a84343",
    teamsAuthorityUrl: "https://login.microsoftonline.com/",
};
