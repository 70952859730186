import React from "react";
import { StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import Text from "react/legacy/parkable-components/text/Text";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Icon from "react/legacy/parkable-components/icon/Icon";
import Strings from "../../../util/localization/localization";
import { ParkSessionDTOLocal } from "../../../model/ParkSessionDTO";
import { formattedTime } from "../../../util/tandemParking.constants";

type Props = {
    session: ParkSessionDTOLocal;
    handleClick: () => void;
    style?: ViewStyle;
};

const TandemLeaveTimeCarouselItem = (props: Props) => {
    const { style, session, handleClick } = props;

    return (
        <TouchableOpacity onPress={handleClick} activeOpacity={0.7}>
            <View style={[styles.container, style]}>
                <Icon
                    color={Colours.GREY_50}
                    style={styles.iconStyle}
                    iconStyle={styles.iconFontStyle}
                    name="handwatch"
                />
                <View style={styles.textContainer}>
                    <Text grey small>
                        {Strings("estimated_leave_time")}
                    </Text>
                    <Text bold>{formattedTime(session.estimatedLeaveTime)}</Text>
                    <Text grey small style={styles.changeLink}>
                        {Strings("change")}
                    </Text>
                </View>
            </View>
        </TouchableOpacity>
    );
};

export default TandemLeaveTimeCarouselItem;

const styles = StyleSheet.create({
    container: {
        backgroundColor: Colours.GREY_10,
        paddingTop: 18,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 18,
        borderRadius: 9,
        alignItems: "center",
    },
    iconStyle: {
        width: "100%",
        flex: 1,
    },
    iconFontStyle: {
        fontSize: 81,
    },
    textContainer: {
        alignItems: "center",
    },
    changeLink: {
        textDecorationLine: "underline",
    },
});
