import { DrawerNavigationProp } from "@react-navigation/drawer";
import { RouteProp, useNavigation as _useNavigation, useRoute as _useRoute } from "@react-navigation/native";
import {
    NativeStackNavigationOptions,
    NativeStackNavigationProp,
    NativeStackScreenProps
} from "@react-navigation/native-stack";
import { Routes } from "./root/root.paths";
import { RootStackParamList } from "./root/root.routes";
import * as Linking from "expo-linking";
import {getInitialURLAndroid} from "@parkable/react-native-firebase-refresh-token";
import { Platform } from "react-native";

export const RouteDeserializers = {
    Number: <Union extends number = number>() => (value: string) => value != null ? Number(value) as Union : undefined,
    String: <Union extends string = string>() => (value: string) => value != null ? String(value) as Union : undefined,
    Boolean: () => (value: string) => value != null ? Boolean(value) : undefined,
    Array: <F extends (value: string) => any>(mapFunc: F) => (value: string) => value != null ? value.split(",").map(v => mapFunc(v)) : [],
    Json: <T = any>() => (value: string) => value != null ? JSON.parse(value) as T : undefined,
}

export const RouteSerializers = {
    Number: () => (value: unknown) => value != null ? Number(value) : undefined,
    String: () => (value: string) => value != null ? String(value) : undefined,
    Boolean: () => (value: unknown) => value != null ? String(value) : undefined,
    Array: <F extends (value: unknown) => any>(mapFunc: F) => (values: unknown[]) => values != null ? values.map(mapFunc).join(",") : undefined,
    Json: () => (value: unknown) => value != null ? JSON.stringify(value) : undefined,
}


type CreateRouteBaseOptions<T extends Routes[keyof Routes]> = {
    path: T;
    options?: NativeStackNavigationOptions;
}

type CreateRouteParamOptions<P = any> = {
    initialParams?: P;
    type: new () => P;
    /** Required to inform React Navigation how to deserialize the url into parameters with correct types */
    deserialize?: (deserializers: typeof RouteDeserializers) => {
         [key in keyof Required<P>]: ((value: string) => P[key] | undefined)
    },
    /** Only required if you want to override regular serialization. Useful for JSONifying params */
    serialize?: (serializers: typeof RouteSerializers) => {
        [key in keyof P]?: (value: unknown) => string | undefined
    }
}

/** Convenience function for enforcing various constraints on routes */
export const createRoute = <
    T extends Routes[keyof Routes],
    P = any
>(opts: CreateRouteBaseOptions<T> & { params?: CreateRouteParamOptions<P> }
) => {
    return {
        ...opts,
        params: {
            ...opts.params,
            parse: opts.params?.deserialize?.(RouteDeserializers),
            __type: {} as P // Used only to communicate type to RootStackParamList
        }
    };
};

export const useRoute = <P extends keyof RootStackParamList = any>(path?: P & Routes) =>
    _useRoute<RouteProp<RootStackParamList, P>>();

export const useNavigation = <P extends keyof RootStackParamList = any>(path?: P & Routes) =>
    _useNavigation<NativeStackNavigationProp<RootStackParamList, P>>();

export type Navigation = ReturnType<typeof useNavigation>

export type NavigationProps<RouteName extends keyof RootStackParamList = any> =
    NativeStackScreenProps<RootStackParamList, RouteName>;

export const useRouteParams = <P extends keyof RootStackParamList>(path: P & Routes) => {
    const { params, } = useRoute(path)
    const { setParams } = _useNavigation<NativeStackNavigationProp<RootStackParamList, P>>()

    return [params ?? {} as Partial<RootStackParamList[P]>, setParams] as const
}


export const useDrawerNavigation = () => _useNavigation<DrawerNavigationProp<any, any>>()

/**
 * Hook used to control opening and closing the drawer
 */
export const useDrawerActions = () => {
    const { toggleDrawer, openDrawer, closeDrawer, } = useDrawerNavigation()

    return { toggleDrawer, openDrawer, closeDrawer }
}

export type Route = {
    name: Routes,
    params?: any,
}

async function getFallbackInitialUrl(): Promise<string | null> {
    return Platform.OS === "android"
        ? await getInitialURLAndroid()
        : null;
}

export async function getInitialUrl() {
    try {
        const fallback = getFallbackInitialUrl();
        let url = await Linking.getInitialURL();
        if (url) {
            return url;
        }
        return await fallback;
    } catch (e) {
        console.error("Couldn't get initial URL.", e);
        return null;
    }
}
