import { useEffect, useRef } from "react";

export default function usePrevious<T = any>(props: T): T {
    const ref = useRef(props);
    useEffect(() => {
        ref.current = props;
    });

    return ref.current;
}
