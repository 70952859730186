import {
  ActiveParkingRequestStatus,
  activeParkingRequestStatus,
  ParkingRequest,
} from "../model/ParkingRequest";

type PartialParkingRequest = Partial<ParkingRequest> &
  Pick<ParkingRequest, "status">;

export type ActiveParkingRequest = Omit<ParkingRequest, "status"> & {
  status: ActiveParkingRequestStatus;
};

export const filterActiveRequest = <T extends PartialParkingRequest>(
  parkingRequest: T
) => {
  return activeParkingRequestStatus.includes(parkingRequest.status);
};

export const filterActiveRequests = <T extends PartialParkingRequest>(
  parkingRequests: T[]
) => {
  return parkingRequests.filter(filterActiveRequest) as ActiveParkingRequest[];
};
