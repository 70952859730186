import getParkAvailability from "../../util/getParkAvailability";
import localizeCurrency from "../../util/localization/localizeCurrency";
import { IMarker, MapPreferences, ParkingType } from "../../model/Types";
import { ParkInfo } from "../../model/ParkInfo";
import { User } from "../../model/User";
import { Empty } from "../../redux/reducers/user";
import { getMarkerTextAndIcon } from "./Marker";
import { CampusDTO } from "../../api/campus/dto/CampusDTO";
import { userIsOrgMember as checkOrgMember } from "../../util/getUserMember";
import { UserRoleDTO } from "../../api/user/dto/user.dto";
import { getCurrentDailyPricePeriod, getCurrentHourlyPricePeriod } from "react/util/services/parkingPrice.service";
import { IMapPark } from "../../redux/reducers/parks";
import Strings from "../../util/localization/localization";

const greaterThan0 = (x?: number) => x !== undefined && x !== null && x > 0;

export function markerVisibleWithPreferences(marker: IMarker, mapPreferences: MapPreferences, parkingType: ParkingType): boolean {

    if (marker.extra.hideCasualBays && parkingType === ParkingType.CASUAL) {
        return false;
    }

    if (marker.extra.hideSubscriptionBays && parkingType === ParkingType.LONG_TERM) {
        return false;
    }

    if (marker.extra.hideEVBays && parkingType === ParkingType.ELECTRIC) {
        return false;
    }

    if (marker.extra.userIsParkOwner) {
        return true;
    }

    if (marker.extra.userIsOrgMember) {
        return true;
    }

    if(marker.extra.favPark) {
        return true; //always shows fav parks
    }

    switch(mapPreferences.parkingType) {
        case ParkingType.CASUAL: {
            if (!marker.extra.hasShortTerm) {
                return false;
            }

            if(mapPreferences.hideFullParks && marker.extra.parkIsFull) {
                return false;
            }

            return !(mapPreferences.hideClosedParks && !marker.extra.parkIsOpen);
        }
        case ParkingType.ELECTRIC: {
            return !!marker.extra.hasEVCharger;
        }
        case ParkingType.LONG_TERM: {
            if (!marker.extra.hasLongTerm) {
                return false;
            }

            if(mapPreferences.hideFullParks && marker.extra.parkIsFull) {
                return false;
            }

            return !(mapPreferences.hideClosedParks && !marker.extra.parkIsOpen);
        }
    }
}

export const getRelevantBays = (
    park: Pick<ParkInfo, "currentLeasesAvailable" | "currentParksAvailable" | "numberOfEVBays">,
    parkingType?: ParkingType
) => {
    const { currentParksAvailable, currentLeasesAvailable, numberOfEVBays } = park;

    const longTerm = parkingType === ParkingType.LONG_TERM;

    return longTerm
        ? currentLeasesAvailable ?? 0
        : parkingType === ParkingType.ELECTRIC
        ? numberOfEVBays ?? 0
        : currentParksAvailable ?? 0;
};

export function buildMarker(
    mapPreferences: MapPreferences,
    pin: ParkInfo,
    user: User | Empty | null,
    userRoles: UserRoleDTO[],
    isMotorBikeDefault: boolean,
    favParkIds?: number[],
    ): IMarker | null {

    const park = pin as ParkInfo;

    const {
        parkId,
        ownerId,
        organisationId,
        latitude,
        longitude,
        territory,
        pricePerWeek,
        pricePerMonth,
        currentParksAvailable,
        numberOfPublicDynamicBays,
        numberOfLeasesAvailable,
        currentLeasesAvailable,
        numberOfPublicSubscriptionBays,
        currencyCode,
        hideCasualBays,
        hideSubscriptionBays,
        hideEVBays,
        hasCampus
    } = park;

    const favPark = !!favParkIds && favParkIds.includes(parkId);
    const userIsOrgMember = !!organisationId && checkOrgMember(userRoles, organisationId)

    const userIsParkOwner = ownerId === user?.id;

    const hasLongTerm = greaterThan0(numberOfLeasesAvailable) || greaterThan0(numberOfPublicSubscriptionBays);
    const hasShortTerm = greaterThan0(currentParksAvailable) || greaterThan0(numberOfPublicDynamicBays);

    const parkAvailability = getParkAvailability(park);
    const parkIsOpen = parkAvailability.isOpenNow;
    const longTermOnlyText = (currentLeasesAvailable ?? 0).toString();
    const relevantBays = getRelevantBays(park, mapPreferences.parkingType);
    const spotsLeftText = (relevantBays > 0) ? `${relevantBays}` : Strings("full");
    const parkIsFull = relevantBays === 0;
    const hasEVCharger = (park.numberOfEVBays ?? 0) > 0;
    const isOrgOrFav = userIsOrgMember || favPark;
    const pricePeriods = park.parkingPrice?.pricePeriods ?? [];
    const hourlyPricePeriod = !!pricePeriods ? getCurrentHourlyPricePeriod(pricePeriods) : null;
    const dailyPricePeriod = !!pricePeriods ? getCurrentDailyPricePeriod(pricePeriods) : null;

    const marker: IMarker = {
        id: '',
        time: new Date().getTime(),
        location: {
            latitude,
            longitude,
        },
        coordinate: {
            latitude,
            longitude,
        },
        extra: {
            id: parkId,
            parkIsOpen,
            favPark,
            userIsParkOwner,
            userIsOrgMember,
            isOrgOrFav,
            parkIsFull,
            longTermOnlyText,
            perDayText: localizeCurrency(dailyPricePeriod?.price ?? 0, currencyCode, false, true),
            perHourText: localizeCurrency(hourlyPricePeriod?.price ?? 0, currencyCode, false, true),
            perWeekText: localizeCurrency(pricePerWeek??0, currencyCode, false, true),
            perMonthText: localizeCurrency(pricePerMonth??0, currencyCode, false, true),
            spotsLeftText,
            hasEVCharger,
            hasLongTerm,
            hasShortTerm,
            hideCasualBays,
            hideSubscriptionBays,
            hideEVBays,
            territory,
            zIndex: 0,
        },
        isCampus: !!hasCampus
    };

    const {markerText, iconName} = getMarkerTextAndIcon(marker, mapPreferences, isMotorBikeDefault);

    marker.id = `${parkId}_${markerText}_${iconName}`; //updating marker id lets the mapview know it should update visible marker

    return marker;
}

export function buildCampusMarker (pin: CampusDTO,
                                   userRoles: UserRoleDTO[],
                                   isMotorBikeDefault: boolean,
                                   favParkIds?: number[],
                                   mapPreferences?: MapPreferences,
                                   mapParks?: {[key:number]: IMapPark}): IMarker {
    const {latitude, longitude, id, organisation: campusOrgId, parks} = pin;

    const { currencyCode } = parks[0];
    const usersOrganisationId = mapParks && mapParks[parks[0].parkId]?.organisationId;

    const parkIsOpen = parks.filter(park => getParkAvailability(park).isOpenNow).length > 0;
    const favPark = parks.some(p => favParkIds?.includes(p.parkId));
    const hasEVCharger = parks.some(park => greaterThan0(park.numberOfEVBays));
    const userIsOrgMember = usersOrganisationId && checkOrgMember(userRoles, usersOrganisationId);
    const isOrgOrFav = userIsOrgMember || favPark;
    const hasPublicPark = parks.some(park => greaterThan0(park.numberOfPublicDynamicBays) || greaterThan0(park.numberOfPublicSubscriptionBays));
    const relevantBays = parks.reduce((a, b) => a + getRelevantBays(b, mapPreferences?.parkingType), 0);
    // the price to display as a text for campus pin
    const pricePeriods = parks[0].parkingPrice?.pricePeriods ?? [];
    const hourlyPricePeriod = !!pricePeriods ? getCurrentHourlyPricePeriod(pricePeriods) : null;
    const dailyPricePeriod = !!pricePeriods ? getCurrentDailyPricePeriod(pricePeriods) : null;

    const pricePerWeekInPark = parks.map(park => park.pricePerWeek as number)
    const minPricePerWeekInPark = Math.min(...pricePerWeekInPark)
    const pricePerMonthInPark = parks.map(park => park.pricePerMonth as number)
    const minPricePerMonthInPark = Math.min(...pricePerMonthInPark)

    const campusMarker: IMarker = {
        id: '',
        time: new Date().getTime(),
        location: {
            latitude,
            longitude,
        },
        coordinate: {
            latitude,
            longitude,
        },
        extra: {
            id: id,
            zIndex: 0,
            parkIds: pin.parks.map(p => p.parkId),
            parkIsOpen,
            userIsOrgMember: !!userIsOrgMember,
            isOrgOrFav,
            parkIsFull: !relevantBays,
            favPark,
            hasPublicPark,
            hasEVCharger,
            perHourText: localizeCurrency(hourlyPricePeriod?.price ?? 0, currencyCode, false, true),
            perDayText: localizeCurrency(dailyPricePeriod?.price ?? 0, currencyCode, false, true),
            perWeekText: localizeCurrency(minPricePerWeekInPark ?? 0, currencyCode, false, true),
            perMonthText: localizeCurrency(minPricePerMonthInPark ?? 0, currencyCode, false, true),
            spotsLeftText: (relevantBays > 0) ? `${relevantBays}` : Strings("full"),
        },
        isCampus: true
    };

    const {markerText} = getMarkerTextAndIcon(campusMarker, mapPreferences!, isMotorBikeDefault);

    campusMarker.id = `campus_${id}_organisation_${campusOrgId}_markertext_${markerText}`; //updating marker id lets the mapview know it should update visible marker for campuses
    return campusMarker;
}
