import { Nully } from "../../util/nully";
import { useMySWR } from "../http/use-swr";
import { Vehicle2Response, Vehicles2Response, VehiclesDTOResponse } from "./dto/vehicleDTO";
import { del, put } from '../http/rest';

export interface UpdateVehicleRequest {
    registration?: string;
    isEV?: boolean;
    isMotorbike?: boolean;
}

export const useVehicle = (vehicleId: Nully<number>) => {
    return useMySWR<Vehicle2Response>(vehicleId ? `v2/vehicles/${vehicleId}` : null);
}

export const useUserVehicles = () => {
    return useMySWR<Vehicles2Response>(`v2/vehicles`);
}

export const updateDefaultUserVehicle = (userId: number, vehicleId: number) => {
    const data = {
        vehicleId,
    };
    return put<VehiclesDTOResponse>(`v2/users/${userId}`, data);
};

export const updateVehicle = (vehicleId: number, vehicleRequest: UpdateVehicleRequest) =>
    put<Vehicle2Response>(`v1/vehicles/${vehicleId}/update`, vehicleRequest);

export const deleteUserVehicle = (vehicleId: number) =>
    del<VehiclesDTOResponse>(`v1/vehicles/${vehicleId}`);
