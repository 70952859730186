import axios from "axios";
import {allSettled, SettledPromise} from "../../util/allSettled";
import DEFAULT_API_VERSION from "../apiVersion";
import {FirebaseService} from "../../util/services/firebase.service";
import { API } from "../../util/api";

const http = axios.create({});

// Base configuration
http.interceptors.request.use(async (config) => {
    // @ts-ignore
    config.headers = {
        "X-Parkable-API-Version": DEFAULT_API_VERSION,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        ...config.headers, // Allow overriding
    };
    const token = await FirebaseService.getCurrentUser()?.getIdToken();
    if(token && !config.headers.Authorization) {
        config.headers.Authorization = token
    }
    config.baseURL = API.getUrl();
    return config;
});

http.interceptors.response.use(response => {
    return response;
}, error => {
    //put the server error response into the place expected by our old-style (pre-axios) error handlers
    return Promise.reject({
        ...error,
        ...(error?.response?.data??{})
    });
});

export const get = async <T = any>(url: string, params?: any, headers?: any) =>
    (
        await http.get<T>(url, {
            params: params ? { ...params } : undefined,
            headers: { ...headers },
        })
    ).data;

export const getMany = async <T = any>(urls: string[], params?:any, headers?: any): Promise<Array<SettledPromise<T>>>  => {
    const data = await allSettled(urls.map(async url => {
        return await http.get<T>(
            url,
            {
                params: params ? {...params} : undefined,
                headers: {...headers},
            }
        );
    }));

    return data.map((r): SettledPromise<T> => ({
        status: r.status,
        value: r.value?.data,
        reason: r.reason
    }));
}

export const post = async <T = any>(url: string, body?: any, headers?: any) => {
    const result = await http.post<T>(url, body, { headers: headers });
    return result.data;
};

export const patch = async <T = any>(url: string, body?: any, headers?: any) =>
    (await http.patch<T>(url, { ...body }, { headers: { ...headers } })).data;

export const put = async <T = any>(url: string, body?: any, headers?: any) =>
    (await http.put<T>(url, { ...body }, { headers: { ...headers } })).data;

export const del = async <T = any>(url: string, headers?: any) =>
    (await http.delete<T>(url, { headers: { ...headers } })).data;

export const fetcher = async (key: string, paramsStr: string | undefined) => {
    const params = JSON.parse(paramsStr ?? "{}");
    return get(key, params);
};


export const generateQueryString = (params: Record<string, any>) => {
    const query = Object.keys(params).map(key => {
        const value = params[key]
        return value !== null && value !== undefined ? `${key}=${encodeURIComponent(value)}` : ""
    }).join("&")
    return query.length > 1 ? `?${query}` : ""
}
