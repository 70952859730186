import { AxiosError } from "axios";
import useSWR, { Key, SWRConfiguration } from "swr";
import useSWRInfinite, { SWRInfiniteConfiguration, SWRInfiniteKeyLoader } from "swr/infinite";
import { Fetcher } from "swr/dist/types";
import { get } from "./rest";

export type Config = SWRConfiguration;

export type KeyGenerator<A extends any[]> = (...args: A) => string;

export function useMySWR<T = any>(key: Key, fn: Fetcher<T> = get, config?: Config) {
    const {data, ...rest} = useSWR<T, AxiosError>(key, fn, config);

    const isLoading = key != null && data == null && rest.error == null;

    return {
        key,
        isLoading,
        data,
        ...(data ?? ({} as Partial<T>)),
        ...rest,
    } as const;
}

export function useMySWRInfinite<T>(
    key: SWRInfiniteKeyLoader,
    fetcher: Fetcher<T> = get,
    config?: SWRInfiniteConfiguration<T>
) {
    return useSWRInfinite<T>(key, fetcher, config);
}

export const useMyManySWR = <T = any> (
    urls?: string[],
    params?: Record<string, unknown>,
    headers?: Record<string, unknown>,
    customConfig?: SWRInfiniteConfiguration<T>,
) => {

    const getKey = (index: number) => !urls? null : [urls[index], params, headers];

    const config: SWRInfiniteConfiguration = {
        initialSize: urls?.length,
        ...customConfig
    }

    const {data, ...rest} = useSWRInfinite<T>(getKey, get, config);
    const isLoading = urls != null && data == null && rest.error == null;

    return {
        isLoading,
        data,
        ...rest
    };
}
