import {MAP_CAMPUSES} from "../actions/campuses";
import {CampusDTO} from "../../api/campus/dto/CampusDTO";
import {CampusesDataResponse} from "../../api/campus/dto/CampusesDataResponse";

const initialState = {
    mapCampuses: {} as {[key:number]: IMapCampus},
};

export interface IMapCampus extends CampusDTO {
    time: number,
}

type ICampusesReducer = typeof initialState;

export default function campuses(state = initialState, action: any): ICampusesReducer {
    switch (action.type) {
        case MAP_CAMPUSES:
            const {data} = action.result as CampusesDataResponse;
    
            const mapCampuses = {...state.mapCampuses};
            const now = new Date().getTime();
            Object.values(state.mapCampuses)
                .filter(p => (now - p.time) > (action.expirySeconds * 1000))
                .forEach(p => delete mapCampuses[p.id]);
    
            data.map((p: CampusDTO) => ({
                    ...p,
                    time: now
                }))
                .forEach((p: IMapCampus) => mapCampuses[p.id] = p);
    
            return {
                ...state,
                mapCampuses,
            };
        default:
            return state;
    }
}
