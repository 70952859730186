import * as Api from "../../api/ev";
import fetch from "../reducers/fetch";
import {DispatchFunc} from "../../model/Types";
import {OcpiEvse} from "../../model/ocpi/OcpiEvse";
import {OcpiSession} from "../../model/ocpi/OcpiSession";
import {OcpiCommand} from "../../model/ocpi/OcpiCommand";
import {Token} from "../../api/rest";
import {OcpiToken} from "../../model/ocpi/OcpiToken";
import {showAlert} from "../../util/alerts";

export const OCPI_EVSE_RECEIVED = "OCPI_EVSE_RECEIVED";
export const OCPI_EVSESESSIONS_RECEIVED = "OCPI_EVSESESSIONS_RECEIVED";
export const OCPI_EVSESESSION_RECEIVED = "OCPI_EVSESESSION_RECEIVED";


export type EV_ACTION = ReturnType<typeof EVSE_RECEIVED>
export type EVSESSION_ACTION = ReturnType<typeof EVSESSION_RECEIVED | typeof EVSESSIONS_RECEIVED>

function EVSE_RECEIVED(provider: string, location: string, evses: Array<OcpiEvse>) {
    return <const>{
        type: OCPI_EVSE_RECEIVED,
        payload: {
            provider,
            location,
            evses
        }
    }
}

function EVSESSION_RECEIVED(parkSessionId: number, evSession: OcpiSession) {
    return <const>{
        type: OCPI_EVSESESSION_RECEIVED,
        payload: {
            parkSessionId,
            evSession,
        }
    }
}

function EVSESSIONS_RECEIVED(parkSessionId: number, evSessions: Array<OcpiSession>) {
    return <const>{
        type: OCPI_EVSESESSIONS_RECEIVED,
        payload: {
            parkSessionId,
            evSessions,
        }
    }
}

export function GetEVSE(provider: string, location: string, onSuccess?: (evses: Array<OcpiEvse>) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getEvses(api, token, provider, location).then(({evses}) => {
            dispatch(EVSE_RECEIVED(provider, location, evses))
            if (onSuccess) {
                onSuccess(evses)
            }
        }).catch((err) => {
            if (err === 'network_error') {
                throw err;
            } else if (onError) {
                onError(err)
            }
        });
    }, arguments);
}

export function startEvSession(providerId: string, locationId: string, evse_uid: string, parkSessionId: number, onSuccess: (command: OcpiCommand) => void, onError: (err: any) => void) {
    return fetch(async (dispatch: DispatchFunc, api: string, token: Token) => {
        try {
            const { ocpiCommand } = await Api.startEvSession(api, token, providerId, locationId, evse_uid, parkSessionId);
            if (onSuccess) {
                onSuccess(ocpiCommand);
            }
        } catch (err) {
            if (err === "network_error") {
                throw err;
            } else if (onError) {
                onError(err);
            }
        }
    }, arguments);
}

export function getActiveEvSession(parkSessionId: number) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getActiveEvSession(api, token, parkSessionId).then(({session}) => {
            dispatch(EVSESSION_RECEIVED(parkSessionId, session))
            return session;
        }).catch((err) => {
            if (err === 'network_error') {
                throw err;
            }

        });
    }, arguments);
}

export function getEvSessions(parkSessionId: number) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getEvSessions(api, token, parkSessionId).then(({sessions}) => {
            dispatch(EVSESSIONS_RECEIVED(parkSessionId, sessions))
            return sessions;
        }).catch((err) => {
            if (err === 'network_error') {
                throw err;
            }

        });
    }, arguments);
}

export function getOcpiCommand(commandId: number) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getOcpiCommand(api, token, commandId).then((result) => {
            console.log("THE EV RESULT IS ", result);
            return result.ocpiCommand;
        }).catch((err: any) => {
            if (err === 'network_error') {
                throw err;
            }

        });
    }, arguments, true);
}
