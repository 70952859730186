import React, {
    createContext, PropsWithChildren, useContext, useState,
} from "react";
import { EmployeeSubscriptionDTO } from "react/dto/EmployeeSubscriptionDTO";
import { ParkSession } from "react/model/ParkSession";
import { ParkingRequest } from "react/model/ParkingRequest";
import { ParkDTOWithTerritory } from "react/redux/actions/parks";

export interface IUnshareBayContext {
    parkingRequests: ParkingRequest[];
    setParkingRequests: (requests: ParkingRequest[]) => void;
    showUnshareDialog: boolean;
    setShowUnshareDialog: (showDialog: boolean) => void;
    unshareDate: string;
    setUnshareDate: (date: string) => void;
    selectedDateRequest?: ParkingRequest;
    setSelectedDateRequest: (request: ParkingRequest) => void;
    subscription?: EmployeeSubscriptionDTO;
    setSubscription: (subscription: EmployeeSubscriptionDTO) => void;
    activeSession?: ParkSession;
    setActiveSession: (session: ParkSession) => void;
    bayInUseDialog: boolean;
    setBayInUseDialog: (open: boolean) => void;
    currentPark?: ParkDTOWithTerritory;
    setCurrentPark: (park: ParkDTOWithTerritory) => void;
}

export const UnshareBayContext = createContext<IUnshareBayContext | undefined>(undefined);

export const useUnshareBayContext = () => useContext(UnshareBayContext);

export const UnshareBayProvider = ({ children }: PropsWithChildren) => {
    const [parkingRequests, setParkingRequests] = useState<ParkingRequest[]>([])
    const [showUnshareDialog, setShowUnshareDialog] = useState<boolean>(false);
    const [unshareDate, setUnshareDate] = useState<string>('');
    const [selectedDateRequest, setSelectedDateRequest] = useState<ParkingRequest | undefined>()
    const [subscription, setSubscription] = useState<EmployeeSubscriptionDTO | undefined>()
    const [activeSession, setActiveSession] = useState<ParkSession | undefined>()
    const [bayInUseDialog, setBayInUseDialog] = useState<boolean>(false)
    const [currentPark, setCurrentPark] = useState<ParkDTOWithTerritory | undefined>()

    return (
        <UnshareBayContext.Provider
            value={{
                parkingRequests,
                setParkingRequests,
                showUnshareDialog,
                setShowUnshareDialog,
                unshareDate,
                setUnshareDate,
                selectedDateRequest,
                setSelectedDateRequest,
                subscription,
                setSubscription,
                activeSession,
                setActiveSession,
                bayInUseDialog,
                setBayInUseDialog,
                currentPark,
                setCurrentPark
            }}
        >
            {children}
        </UnshareBayContext.Provider>
    );
};
