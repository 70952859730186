import * as React from "react";
import {Image, ScrollView, StyleSheet, TouchableOpacity, View} from "react-native";
import Text from "react/legacy/parkable-components/text/Text";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Strings from "../../util/localization/localization";
import {useSelector} from "react/redux/redux";
import {DrawerContentComponentProps} from "@react-navigation/drawer"
import {Routes} from "../root/root.paths";
import {User} from "react/model/User";
import useConfig from "react/util/hooks/useConfig";
import {useUserRoles} from "../../api/user/user.api";
import {UserRoleDTO} from "../../api/user/dto/user.dto";
import {useEffect, useState} from "react";
const logoBlackHorizontal = require("react/resources/logo-black-horiz.png");

type LinkGroup = {
    title: string,
    verifyShow: (user: User | null, userRoles?: UserRoleDTO[]) => boolean,
    links: Array<{
        title: string,
        path: Routes
    }>
}

export function MapDrawer(props: DrawerContentComponentProps) {
    const { navigation } = props
    const user = useSelector(state => state.user.user)
    const config = useConfig();
    const {userRoles} = useUserRoles();
    const { service } = useSelector(state => state.data);
    const [click, setClick] = useState<number|null>(null)
    const envName = config?.environmentDisplayName
        ? "Server: " + config.environmentDisplayName + (service ? ` (${service})` : "")
        : null;

    const version = "v" + config.version;

    const build = config?.showBuildNumber
        ? "(" + config.build + ")"
        : null;

    const push = (route: Routes) => {
        // @ts-ignore
        navigation.navigate(route);
    };

    const changeServer = () => {
        setClick(prev => ((prev ?? 0) + 1) % 8);
    };

    useEffect(() => {
        if (click === 0) {
            navigation.navigate(Routes.ChangeServerView);
        }
    }, [click]);

    const linkGroups: Array<LinkGroup> = [
        {
            title: Strings("settings"),
            verifyShow: () => true,
            links: [
                { title: Strings("map"), path: Routes.MapSettingsView },
                { title: Strings("account"), path: Routes.AccountView },
            ]
        },
        {
            title: Strings("parking"),
            verifyShow: () => true,
            links: [
                { title: Strings("parking_history"), path: Routes.SessionHistoryView },
                { title: Strings("bookings_and_requests"), path: Routes.UserBookingsAndRequestsView },
                { title: Strings("subscriptions"), path: Routes.SubscriptionListView },
                { title: Strings("vouchers"), path: Routes.VouchersView },
                { title: Strings("tandem_chats"), path: Routes.MessagesView },
                { title: Strings("support"), path: Routes.SupportView },
            ]
        },
    ]

    return (
        <ScrollView scrollsToTop={false} style={styles.mainContainer}>
            <Text h1 style={styles.titleMenu}>
                {Strings("menu")}
            </Text>
            {linkGroups.map((group, i) => {
                const shouldShowGroup = group.verifyShow(user, userRoles)
                return <React.Fragment key={group.title}>
                    {shouldShowGroup && <View key={group.title} style={styles.itemContainer}>
                        <Text bold small style={styles.itemTitle}> {group.title} </Text>
                        {group.links.map((link) => {
                            return <Text key={link.path} suppressHighlighting style={styles.item} onPress={() => push(link.path)} >
                                {link.title}
                            </Text>
                        })}
                    </View>}
                </React.Fragment>
            })}

            <View style={styles.lineBreak} />
            <View style={styles.footer}>
                <Image style={styles.logo} source={logoBlackHorizontal} />
                <TouchableOpacity style={styles.madeInContainer} activeOpacity={1} onPress={changeServer}>
                    <Text small grey style={styles.madeIn}>
                        {Strings("made_in_new_zealand")}
                    </Text>
                </TouchableOpacity>
                {version && <Text small grey>{version} {build && build}</Text>}
                {envName && <Text small grey>{envName}</Text>}
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: Colours.NEUTRALS_WHITE,
        flex: 1,
        paddingLeft: 27,
    },
    titleMenu: {
        paddingBottom: 15,
        marginTop: 47,
        paddingLeft: 5,
    },
    itemTitle: {
        color: Colours.GREY_20,
        textTransform: "uppercase",
        paddingBottom: 14,
    },
    itemContainer: {
        paddingBottom: 27,
    },
    item: {
        paddingBottom: 11,
        paddingLeft: 5,
    },
    lineBreak: {
        height: 1,
        width: "100%",
        backgroundColor: Colours.GREY_BORDER,
    },
    footer: {
        paddingTop: 27,
        paddingBottom: 81,
    },
    logo: {
        height: 40,
        width: 130,
        resizeMode: "contain",
    },
    madeInContainer: {
        lineHeight: 20,
        marginTop: 24,
        marginBottom: 12,
    },
    madeIn: {
        lineHeight: 20,
    }
});
