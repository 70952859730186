import { Nully } from "../../util/nully";
import { useMySWR } from "../http/use-swr";
import EntityResponse from "../../dto/EntityResponse";
import { SecureOrganisationOptionsDTO } from "./dto/SecureOrganisationOptionsDTO";
import { OrganisationContactOptionsDTO } from "./dto/OrganisationContactOptionsDTO";

export const useOrganisationContactOptions = (organisationId: Nully<number>) => {
    return useMySWR<{options: OrganisationContactOptionsDTO}>(organisationId
        ? `/organisations/${organisationId}/options`
        : null);
}

export const useSecureOrganisationOptions = (email?: string) => {
    const key = "v2/organisations/options";
    return useMySWR<EntityResponse<SecureOrganisationOptionsDTO[]>>(
        email ? key + `?email=${encodeURIComponent(email)}` : key);
};
