import React from "react";
import Text from "react/legacy/parkable-components/text/Text";
import { Image, View } from "react-native";
import { alertStyles, contentStyles } from "../../../components/future-booking/how-it-works/styles";
import { ScrollView } from "react-native-gesture-handler";
import Strings from "../../../util/localization/localization";
import { useParkingRequestOptions } from "../../../api/parkingRequestOptions/parkingRequestOptions.api";
import { createRoute, NavigationProps } from "../../../navigation/constants";
import { Routes } from "../../../navigation/root/root.paths";
import ParkableBaseView from "../../../components/common/ParkableBaseView";

const GreenTickIcon = require("../../../resources/tickGreenCircle.png");
const CloseRedIcon = require("../../../resources/closeRed.png");

const behaviours = {
    good: [
        Strings("good_show_up"),
        Strings("good_cancel_booking"),
    ],
    bad: [
        Strings("bad_not_yours"),
        Strings("bad_not_show_up"),
    ],
} as const;

type Props = NavigationProps<Routes.HowItWorksUpcomingWeeksView>

const HowItWorksUpcomingWeeksView = (props: Props) => {

    const {organisationId, parkId, campusId} = props.route.params;
    const { options } = useParkingRequestOptions(organisationId, parkId, campusId);
    const { priorityRequestsPerUser } = options ?? {};

    const upcomingWeeksP2 = options
        ? Strings("upcoming_weeks_p2")(
            Strings("dayOfWeekForIndex")(options.allocationDayOfWeek),
            Strings("formatAllocationHourOfDay")(options.allocationHourOfDay))
        : ['', '', '']

    const upcomingWeeksP3 = priorityRequestsPerUser !== undefined
        ? Strings("upcoming_weeks_p3")(priorityRequestsPerUser)
        : ['', '', '']

    return (
        <ParkableBaseView scrollable={false}>
            <ScrollView style={{flex: 1}} showsVerticalScrollIndicator={false}>
                <View style={contentStyles.heading}>
                    <Text h1>{Strings("future_booking_upcoming_weeks")}</Text>
                </View>

                <Text style={contentStyles.mainText}>
                    {Strings("upcoming_weeks_p1")}
                </Text>

                <Text style={contentStyles.mainText}>
                    {upcomingWeeksP2[0]}
                    <Text bold>
                        {upcomingWeeksP2[1]}
                    </Text>
                    {upcomingWeeksP2[2]}
                </Text>

                <Text style={contentStyles.mainText}>
                    {upcomingWeeksP3[0]}
                    <Text bold>
                        {upcomingWeeksP3[1]}
                    </Text>
                    {upcomingWeeksP3[2]}
                </Text>

                <View style={alertStyles.container}>
                    <Text h4 bold>
                        {Strings("increase_your_chances")}
                    </Text>
                    <Text small>
                        {Strings("follow_guidelines_helper")}
                    </Text>
                    <View style={alertStyles.tableContainer}>
                        <View style={[alertStyles.tableGroup]}>
                            <Text green bold h4>
                                {Strings("do_")}
                            </Text>
                            {behaviours.good.map((t, i) => (
                                <View style={alertStyles.tableRow} key={i}>
                                    <Image source={GreenTickIcon} style={alertStyles.rowIcon}/>
                                    <Text small>{t}</Text>
                                </View>
                            ))}
                        </View>
                        <View style={[alertStyles.tableGroup]}>
                            <Text red bold h4>
                                {Strings("dont_")}
                            </Text>
                            {behaviours.bad.map((t, i) => (
                                <View style={alertStyles.tableRow} key={i}>
                                    <Image source={CloseRedIcon} style={alertStyles.rowIcon}/>
                                    <Text small>{t}</Text>
                                </View>
                            ))}
                        </View>
                    </View>
                </View>
            </ScrollView>
        </ParkableBaseView>
    );
};

export default HowItWorksUpcomingWeeksView;

class HowItWorksUpcomingWeeksViewParams {
    organisationId?: number;
    parkId?: number;
    campusId?: number;
}

export const HowItWorksUpcomingWeeksRoute = createRoute({
    path: Routes.HowItWorksUpcomingWeeksView,
    params: {
        type: HowItWorksUpcomingWeeksViewParams,
        deserialize: d => ({
            organisationId: d.Number(),
            parkId: d.Number(),
            campusId: d.Number(),
        })
    }
});
