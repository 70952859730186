enum Colours {
    GREEN_50 = "#F6FBEC",
    GREEN_100 = "#EDF8DA",
    GREEN_200 = "#BDE474",
    GREEN_300 = "#8CD00E",
    GREEN_400 = "#A2C327",
    GREEN_500 = "#8CA92C",
    GREEN_TINT = "#A3C423",
    GREEN_DARK = "#8DAA2A",

    ORANGE_50 = "#FFF7DF",
    ORANGE_100 = "#FFEEBF",
    ORANGE_200 = "#FFB10C",
    ORANGE_300 = "#FC910F",
    ORANGE_400 = "#8B5008",
    ORANGE = "#FFB200",
    ORANGE_DARK = "#FB9206",

    YELLOW = "#FFEEBF",

    RED_50 = "#FEEFF0",
    RED_100 = "#FDE0E1",
    RED_200 = "#E62C44",
    RED_300 = "#E50238",
    RED_400 = "#7F0220",
    RED_LIGHT = "#FCE5EA",
    RED = "#E42B43",
    RED_DARK = "#E30037",

    SALMON_100 = "#FFE7E5",
    SALMON_300 = "#E99A94",
    SALMON_400 = "#8B514E",
    SALMON = "#F3CDCA",

    BLUE_50 = "#EEF7FF",
    BLUE_100 = "#DDEEFF",
    BLUE_200 = "#88C4FF",
    BLUE_300 = "#3399FF",
    BLUE_400 = "#0082C9",
    BLUE_500 = "#006EB9",

    PINK_100 = "#FFE5F4",
    PINK_200 = "#C22D86",
    PINK_300 = "#B21D72",
    PINK_400 = "#641040",
    PINK = "#C12D87",
    PINK_DARK = "#B11D72",

    PURPLE_100 = "#E6DBFF",
    PURPLE_200 = "#57378B",
    PURPLE_300 = "#4A2D77",
    PURPLE_400 = "#2C1B44",
    PURPLE = "#57378C",

    ROSE = "#E7B8B7",
    ROSE_DARK = "#E89B95",

    GREY_10 = "#DBE3E7",
    GREY_20 = "#B6C6CE",
    GREY_50 = "#8097A3",
    GREY_60 = "#496370",
    GREY_70 = "#394E59",
    GREY_80 = "#2C3E46",
    GREY_BORDER = "#DBE3E7",
    GREY_LIGHT = "#EDF1F3",

    NEUTRALS_50 = "#F9FAFB",
    NEUTRALS_100 = "#F3F6F8",
    NEUTRALS_200 = "#EDF1F3",
    NEUTRALS_300 = "#D9E1E5",
    NEUTRALS_400 = "#B4C5CD",
    NEUTRALS_500 = "#7F96A2",
    NEUTRALS_600 = "#486370",
    NEUTRALS_700 = "#384E59",
    NEUTRALS_800 = "#2B3E46",
    NEUTRALS_900 = "#1F2C33",
    NEUTRALS_BLACK = "#000000",
    NEUTRALS_WHITE = "#FFFFFF",

    BUTTON_DISABLED = "#DBE3E7",
    TRANSPARENT = "transparent",
    SHADOW = "#2F3D45",

}

export default Colours;
