import React, { Fragment, useEffect, useRef, useState } from "react";
import {Platform, ScrollView, StyleSheet, View} from 'react-native';
import {Colours} from 'react/legacy/parkable-components';
import MessageInput from 'react/components/common/MessageInput';
import {ChatMessageDTO} from "react/model/chat/ChatMessageDTO";
import Icons from "react/legacy/parkable-components/icon/Icons";

type ChatProps = {
    messages: ChatMessageDTO[],
    onSendPress: (text: string, onSuccess?: () => void, onError?: () => void) => void,
    renderMessage: (message: ChatMessageDTO, index: number, previousMessageTime?: ChatMessageDTO) => JSX.Element,
    loading: boolean,
    messageInputIcon?: Icons,
    onMessageInputIconPress?: () => void,
    hideInput?: boolean,
}

export const ChatContainer = (props: ChatProps) => {
    const {
        messages,
        loading,
        onSendPress,
        renderMessage,
        hideInput
    } = props;

    const containerView = useRef<any>();
    const [inputHeight, setInputHeight] = useState<number>(0);

    useEffect(() => {
        scrollToEnd();
    }, ['once']);

    const scrollToEnd = () => {
        containerView.current?.scrollToEnd?.();
    };

    useEffect(() => {
        scrollToEnd();
    },[messages]);

    return (
            <View style={{flex: 1}}>
            <ScrollView style={styles.details}
                        ref={containerView}>
                <View style={styles.section}>
                    {messages.length > 0 && messages.map((item, index) => <Fragment key={index}>
                        {renderMessage(item as any, index, index > 0 ? messages[index - 1] as any : undefined)}
                    </Fragment>)}
                </View>
            </ScrollView>
            {!hideInput && <View style={styles.messageInput}>
                <MessageInput
                    onLeftIconPress={props.onMessageInputIconPress}
                    leftIcon={props.messageInputIcon}
                    onActionPress={onSendPress}
                    actionButtonDisabled={loading}
                    onHeightChange={(height: number) => {
                        if (inputHeight === 0) {
                            scrollToEnd();
                        }
                        setInputHeight(height)
                    }}
                    scrollToEnd={scrollToEnd}
                />
            </View>}
            </View>)
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    spacer: {
        width: "24%",
    },
    section: {
        top: Platform.OS === "ios" ? -9 : 9,
        paddingBottom: 4
    },
    details: {
        paddingTop: Platform.OS === "ios" ? 18 : 0,
        paddingHorizontal: 9,
        backgroundColor: Colours.GREY_10
    },
    leftBox: {
        borderRadius: 5,
        marginHorizontal: 9,
        marginBottom: 25,
        marginTop: 25,
        padding: 10,
        backgroundColor: Colours.NEUTRALS_WHITE,
        width: "52%"
    },
    leftBoxArrow: {
        position: 'absolute',
        left: -10,
        top: 9,
        borderTopWidth: 10,
        borderTopColor: 'transparent',
        borderRightWidth: 10,
        borderRightColor: '#fff',
        borderBottomWidth: 10,
        borderBottomColor: 'transparent',
        borderLeftWidth: 0
    },
    messageInput: {
        width: "100%",
    },
});
