import React, { useEffect, useState } from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import Button from "react/legacy/parkable-components/button/Button";
import Text from "react/legacy/parkable-components/text/Text";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Strings from "../../util/localization/localization";
import { Routes } from "../../navigation/root/root.paths";
import { createRoute, NavigationProps } from "../../navigation/constants";
import ParkableBaseView from "../../components/common/ParkableBaseView";
import {useTeamsContext} from "../../components/teams/context";
const MaskGroup = require("react/resources/Mask-Group-15478.png");
const LogoBlack = require("react/resources/logo-black-horiz.png");

export default function LandingView(props: NavigationProps<Routes.LandingView>) {
    const { navigation } = props;

    const [click, setClick] = useState<number|null>(null);
    const [logoClick, setLogoClick] = useState<number|null>(null);

    const {insideTeamsEnvironment, setTeamsLoggingIn} = useTeamsContext();

    const login = () => {
        if(insideTeamsEnvironment) {
            console.log("navigating to teams landing page");
            setTeamsLoggingIn(true);
            navigation.reset({
                routes: [{
                    name: Routes.TeamsLandingView,
                }],
            });
        }
        else {
            navigation.push(Routes.LoginOrSignUpView, {viewMode: "login"});
        }
    }
    const signup = () => navigation.push(Routes.LoginOrSignUpView, {viewMode: "signup"})

    const handleLogoClick = () => {
        setLogoClick(prev => ((prev ?? 0) + 1) % 8);
    };

    const changeServer = () => {
        setClick(prev => ((prev ?? 0) + 1) % 8);
    };

    useEffect(() => {
        if (click === 0) {
            navigation.push(Routes.ChangeServerView);
        }
    }, [click]);

    useEffect(() => {
        if (logoClick === 0) {
            onLogoEasterEgg().catch(console.error);
        }
    }, [logoClick]);

    async function onLogoEasterEgg() {
        // navigation.push(Routes.UpdateCheckView);
    }

    return  (
        <ParkableBaseView
            removeStandardMargins
            showToolbar={false}
            scrollable={false}>
            <TouchableOpacity style={styles.logoContainer} activeOpacity={1} onPress={handleLogoClick}>
                <Image style={styles.logo} source={LogoBlack}/>
            </TouchableOpacity>
            <View style={styles.base}>
                <View style={styles.content}>
                    <View style={styles.headerText}>
                        <Text
                            allowFontScaling={false}
                            style={styles.welcome}
                            suppressHighlighting
                            onPress={changeServer}>
                            {Strings("welcome_to_parkable")}
                        </Text>
                        <Text allowFontScaling={false} style={styles.headerBody}>{Strings("our_mission")}</Text>
                    </View>
                </View>
                <View style={styles.backgroundImageContainer}>
                    <Image style={styles.backgroundImage} source={MaskGroup}/>
                </View>
                <View style={styles.buttons}>
                    <Button blue large center style={{flex: 1}} onPress={login}>{Strings("log_in")}</Button>
                    {!insideTeamsEnvironment && <View style={styles.spacer}/>}
                    {!insideTeamsEnvironment && <Button large center style={{flex: 1}} onPress={signup}>{Strings("sign_up")}</Button>}
                </View>
            </View>
        </ParkableBaseView>
    )

}

export const LandingRoute = createRoute({
    path: Routes.LandingView,
});

const styles = StyleSheet.create({
    logoContainer: {
        paddingHorizontal: 24,
        alignItems: "flex-start",
    },
    logo: {
        height: 40,
        aspectRatio: 130 / 40,
        resizeMode: 'contain',
    },
    base: {
        flex: 1,
    },
    content: {
        flex: 1,
        paddingTop: 15,
        paddingHorizontal: 24,
    },
    welcome:{
        fontSize: 45,
        lineHeight: 49,
        fontFamily: "GTEestiDisplay-Bold",
    },
    headerBody:{
        marginTop:12,
        fontSize: 20,
    },
    headerText: {
        paddingTop: 18,
        paddingBottom: 18,
        zIndex: 10,
    },
    backgroundImageContainer: {
        flexDirection: "row",
    },
    backgroundImage: {
        flex: 1,
        aspectRatio: 375/168,
        zIndex: 1,
    },
    buttons:{
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 28,
        paddingHorizontal: 24,
        borderTopColor: Colours.GREY_10,
        borderTopWidth: 1,
    },
    spacer: {
        width: 18,
    },
    buttonText:{
        fontSize: 24,
    }
});

