import { Ref, useEffect, useRef } from "react";

export default function useForwardRef<T>(forwardRef: Ref<T>) {
    const innerRef = useRef<T>(null);

    useEffect(() => {
        if (!forwardRef) {
            return;
        }

        if (typeof forwardRef === "function") {
            forwardRef(innerRef.current);
        } else {
            // @ts-ignore
            forwardedRef.current = innerRef.current;
        }
    });

    return innerRef;
}
