import React, { useState, useCallback } from 'react';
import moment, { Moment } from "moment";
import { connect } from 'react-redux';
import { IRootReducer } from "../../redux/reducers/main";
import Text from "react/legacy/parkable-components/text/Text";
import Button from "react/legacy/parkable-components/button/Button";
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import Colours from "react/legacy/parkable-components/styles/Colours";
import { StyleSheet, View, Linking } from "react-native";
import Strings from "../../util/localization/localization";
import { Term } from "../../model/Term";
import SupportFooterView from "../../components/common/SupportFooterView";
import localizeCurrency from "../../util/localization/localizeCurrency";
import EditSubscriptionStartDateView from "../../components/subscriptions/EditSubscriptionStartDateView";
import { Routes } from "react/navigation/root/root.paths";
import { Bay } from "../../model/Bay";
import { useBayGroup } from "../../api/bayGroup/bayGroup.api";
import { getLongTermPrices } from "../../util/price.util";
import { usePark } from "../../api/park";
import { useTerritory } from "../../api/territory/territory.api";
import { createRoute, NavigationProps } from "../../navigation/constants";
import ParkableBaseView from "../../components/common/ParkableBaseView";
import { useBay } from "../../api/bay/bay.api";
import { StartSubscriptionSummaryViewParams } from "./StartSubscriptionSummaryView";
import { ScrollView } from 'react-native-gesture-handler';
import { useUserRoles } from "../../api/user/user.api";
import { Role } from "../../model/User";
import { useCalculateProcessingFee } from "../../api/processingFees/processingFees.api";

const SubscriptionDetailsView = (props: ReduxProps & Props) => {
    const { parkId, term, navigation, bayId, feature, baysAvailable } = props;

    const [startDate, setStartDate] = useState<Moment | undefined>(undefined);
    const [showEditStartDate, setShowEditStartDate] = useState(false);

    const { park } = usePark(parkId);
    const { territory } = useTerritory(park?.territory);
    const { bay } = useBay(parkId, bayId);
    const { bayGroup } = useBayGroup(park?.organisation, bay?.group);
    const { userRoles } = useUserRoles();
    const isPublicMember =  !(userRoles??[])
        .filter(ur => !!park?.organisation && ur.organisation === park?.organisation)
        .filter(ur => ur.role.name !== Role.PUBLIC)
        .some(ur => !ur.suspended);
    const isWeeklyTerm = term === Term.Week;
    const { pricePerWeek, pricePerMonth } = getLongTermPrices(park, bayGroup);
    const price = isWeeklyTerm ? pricePerWeek : pricePerMonth;
    const { data: processingFeeAmount } = useCalculateProcessingFee(price, territory?.id, "LongTerm", isPublicMember);

    const onEditStartDate = useCallback(() => {
        setShowEditStartDate(true)
    }, []);

    const onSelectNewDate = useCallback((date: Moment) => {
        if (date) {
            setStartDate(date)
        }
    }, []);

    const onCancellationTermsPress = useCallback(() => {
        (async () => {
            const URL = "https://www.parkable.co.nz/cancellation-policy-subscriptions/";
            if (await Linking.canOpenURL(URL)) {
                Linking.openURL(URL);
            }
        })();
    }, []);

    const onReviewPress = useCallback(() => {
        const params: StartSubscriptionSummaryViewParams = {
            parkId,
            term,
            startDate,
            bayId,
            feature,
            baysAvailable
        }
        navigation.navigate(Routes.StartSubscriptionSummaryView, params);
    }, [park, term, startDate, bay]);

    const canStartInFuture = !!park?.subscriptionMaxDaysInAdvance && park.subscriptionMaxDaysInAdvance > 0;

    return (
        <ParkableBaseView scrollable={false}>
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={styles.pricingTitle}>
                    <Text h2 style={styles.labelPricingTitle}>
                        {isWeeklyTerm ? Strings("weekly_subscription") : Strings("monthly_subscription")}
                    </Text>
                </View>
                <Text small style={{ paddingVertical: 18 }}>
                    {isWeeklyTerm
                        ? Strings("weekly_subscription_payment_details")((startDate || moment()).format("dddd"))
                        : Strings("monthly_subscription_payment_details")((startDate || moment()).format("Do"))}
                </Text>
                {!!price && (
                    <TableRow
                        label={Strings("price")}
                        textProps={{ small: true, numberOfLines: 2 }}
                        iconLeft={"dollarfilled"}
                        labelBottom={
                            processingFeeAmount && processingFeeAmount > 0
                                ? Strings("plus_processing_fee")(
                                      localizeCurrency(processingFeeAmount, territory?.currencyCode, false)
                                  )
                                : ""
                        }
                    >
                        <Text small>
                            {`${localizeCurrency(price, territory?.currencyCode, false)}${
                                isWeeklyTerm ? Strings("per_week") : Strings("per_month")
                            }`}
                        </Text>
                    </TableRow>
                )}
                <TableRow label={Strings("date_of_first_payment")} textProps={{ small: true }} iconLeft={"calendarDollar"}>
                    <Text small>
                        {!startDate ? moment().format("dddd, MMMM Do") : startDate?.format("dddd, MMMM Do")}
                    </Text>
                </TableRow>
                {!showEditStartDate && canStartInFuture && (
                    <View style={styles.editDateBox}>
                        <View style={styles.linkEditDate}>
                            <Text onPress={onEditStartDate} small style={styles.editStartDateLink}>
                                {Strings("edit_start_date")}
                            </Text>
                            <Text small grey>
                                {Strings("up_to_X_days")(park?.subscriptionMaxDaysInAdvance || 0)}
                            </Text>
                        </View>
                    </View>
                )}
                {showEditStartDate && park && canStartInFuture && (
                    <EditSubscriptionStartDateView park={park} onSelectNewDate={onSelectNewDate} />
                )}
                <View style={styles.detailsContainer}>
                    {!!(bayGroup?.termsOfLease || bay?.termsOfLease) && (
                        <View>
                            <Text bold h4 style={styles.labelEpicTitle}>
                                {Strings("subscription_terms")}
                            </Text>
                            <Text small>{bayGroup?.termsOfLease || bay?.termsOfLease}</Text>
                        </View>
                    )}

                    <Text bold h4 style={styles.labelEpicTitle}>
                        {Strings("cancellation")}
                    </Text>
                    <View>
                        <Text small>{Strings("cancellationTermsExplanation")}</Text>
                        <Text small onPress={onCancellationTermsPress} style={styles.cancellationTermsLink}>
                            {Strings("seeCancellationPolicyToLearnMore")}
                        </Text>
                    </View>
                </View>

                <SupportFooterView />
            </ScrollView>

            <View style={styles.outerFooter}>
                <View style={styles.confirmButton}>
                    <Button iconRight={"arrowlightright"} onPress={onReviewPress}>
                        {Strings("review_details")}
                    </Button>
                </View>
            </View>
        </ParkableBaseView>
    );

};

export class SubscriptionDetailsViewPrams {
    parkId: number;
    term: Term;
    bayId?: number;
    feature: string;
    baysAvailable: Bay[];
}

type Props = NavigationProps<Routes.SubscriptionDetailsView>

const getReduxProps = (state: IRootReducer, props: Props) => {
    return {
        parkId: props.route.params?.parkId,
        term: props.route.params?.term,
        bayId: props.route.params?.bayId,
        feature: props.route.params?.feature,
        baysAvailable: props.route.params?.baysAvailable
    }
};

type ReduxProps = ReturnType<typeof getReduxProps>;

export default connect(getReduxProps)(SubscriptionDetailsView) as any as React.FC<Props>;

export const SubscriptionDetailsRoute = createRoute({
    path: Routes.SubscriptionDetailsView,
    params: { type: SubscriptionDetailsViewPrams }
});

const styles = StyleSheet.create({
    pricingTitle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    subscriptionIconContainer: {
        backgroundColor: Colours.ORANGE,
        borderRadius: 50,
    },
    labelPricingTitle: {
        marginBottom: 0
    },
    editDateBox: {
        marginTop: 18
    },
    linkEditDate: {
        flex: 1,
        flexDirection: "row",
    },
    editStartDateLink: {
        color: Colours.BLUE_300,
        paddingRight: 9
    },
    detailsContainer: {
        paddingBottom: 45,
    },
    labelEpicTitle: {
        paddingTop: 45,
        marginBottom: 12
    },
    cancellationTermsLink: {
        color: Colours.BLUE_300,
    },
    outerFooter: {
        paddingTop: 18,
    },
    confirmButton: {
        paddingBottom: 27,
    },
});
