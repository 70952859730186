import React, { useContext, useEffect } from "react";
import { ChatContext, ProblemActionContext, SupportContext } from "../Context";
import Strings from "../../../util/localization/localization";
import { useOrganisationContactOptions } from "../../../api/organisationOptions/organisationOptions.api";
import { useOrganisation } from "../../../api/organisations";
import { AllProblemViewProps } from "../../../views/support/ProblemView";
import { QuestionOptionKey, QuestionTypes } from "./ProblemFlow.types";
import _ from "lodash";
import { usePark } from "../../../api/park";

const flow: QuestionTypes = {
    contactDetails : {
        type:"content",
        title: "",
        isItSupport: true
    }
};

export const MessageOrganisation = (props: AllProblemViewProps) => {
    const {parkId} = props.route.params;

    const { park } = usePark(parkId);

    const {messages, processOptionQuestion} = useContext(ChatContext);
    const {problemActions, updateProblemActions } = useContext(ProblemActionContext);
    const {updateSupportFlow} = useContext(SupportContext);

    const {organisation} = useOrganisation(park?.organisation);
    const { options } = useOrganisationContactOptions(organisation?.id);

    useEffect(()=>{
        problemActions["onMessageOrganisationPress"] = onMessageOrganisationPress;
        updateProblemActions(problemActions);
        updateSupportFlow('messageOrganisation', flow);
    }, [messages]);

    const updateFlow = (preExpectedAns: QuestionOptionKey) => {
        const _flow = _.cloneDeep(flow);
        if (_flow.contactDetails) {
            _flow.contactDetails.title = options?.contactDetails?.length ? options?.contactDetails : Strings("default_contact_message");
            if(options?.contactDetails){
                _flow.contactDetails.allowToCopyToClipboard = true;
            }
        }

        updateSupportFlow('messageOrganisation', _flow, preExpectedAns);
    };

    const onMessageOrganisationPress = () => {
        processOptionQuestion("messageOrganisation");
        updateFlow("contactDetails");
    };

    return <></>
};
