import {get, post} from "./rest";
import {Platform} from "react-native";
import {Host} from "../model/Host";
import {Token} from "./rest";
import { appVersionString } from "react/util/version";
import { UserDTO } from "./user/dto/user.dto";
import { platform } from "./user";
import { Nully } from "../util/nully";

const KEY_LOCATIONS_URL = "v1/keylocations";

export type CreateUser4Response = {
    firebaseCustomToken: string,
    user: UserDTO,
}

export function createUserAPI(api: string, email: string, password: string|undefined, firstName: string, lastName: string, phone: Nully<string>,
                              countryCode: string, pushToken: Nully<string>, samlSignup: boolean, samlToken?:string): Promise<CreateUser4Response> {
    const url = `${api}v4/users`;
    const data = {
        email,
        userName: email,
        password,
        platform: platform(),
        version: appVersionString(),
        firstName,
        lastName,
        phone,
        countryCode,
        deviceToken: pushToken,
        locale: 'en-NZ',
        samlSignup,
        samlToken
    };

    console.log("DATA ", data);
    // @ts-ignore
    return post(null, url, data) as Promise<CreateUser4Response>;
}

export type AuthResponse = {
    firebaseCustomToken: string,
}

export const getHostDataAPI = (url: string, token: Token, hostDataId: number) => get(token, `${url}v1/hosts/${hostDataId}`) as Promise<{host: Host}>;

export function serverLog(token: Token, api: string, log: string, version?: string){
    const url = api + "v1/applicationlog";

    const data = {
        log,
        version,
        platform: Platform.OS,
    };

    return post(token, url, data);
}

export function getKeyLocations(api: string, token: Token, coordinates?: {lat: number, lng: number}) {
    let params = {}
    if(coordinates) {
        params = {
            latitude: coordinates.lat,
            longitude: coordinates.lng
        }
    }
    return get(token, api + KEY_LOCATIONS_URL, params);
}
