export type ParkingCostResponse = {
  cost: number;
  durationInMinutes: number;
  breakdown: ParkingPriceBreakdownItem[];
}

export enum ParkingPriceBreakdownItemType {
  FreePeriod = "FreePeriod",
  Hourly = "Hourly",
  EarlyBird = "EarlyBird",
  Day = "Day",
  Night = "Night",
  ProcessingFee = "ProcessingFee",
}

export type ParkingPriceBreakdownItem = {
  type: ParkingPriceBreakdownItemType;
  durationInMinutes: number | null;
  costInCents: number;
};