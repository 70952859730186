import {DisplayText} from "./DisplayText";

export type OcpiCommand = {
    id: number;
    partyId: string;
    countryCode: string;
    location_id: string;
    evse_uid: string;
    connector_id: string;
    type:  CommandType;
    // async response
    commandResult: CommandResult;
    commandResponse: CommandResponse;
    created: string;
    user: number;
}

export type CommandResponse = {

    /**
     * Response from the CPO on the command request.
     */
    result: CommandResponseType;
    /**
     * Timeout for this command in seconds. When the Result is not received
     * within this timeout, the eMSP can assume that the message might never be
     * send.
     */
    timeout: number;
    message: DisplayText;
}

export type CommandResult = {

    /**
     * Response from the CPO on the command request.
     */
    result: CommandResultType;

    /**
     * Message
     */
    message: DisplayText;
}

export enum CommandType {
    /**
     * Request the Charge Point to reserve a (specific) EVSE for a Token for a certain time, starting now.
     */
    RESERVE_NOW= "RESERVE_NOW",

    /**
     * Request the Charge Point to cancel a specific reservation
     */
    CANCEL_RESERVATION= "CANCEL_RESERVATION",

    /**
     * Request the Charge Point to start a transaction on the given EVSE/Connector.
     */
    START_SESSION= "START_SESSION",

    /**
     * Request the Charge Point to stop an ongoing session.
     */
    STOP_SESSION= "STOP_SESSION",

    /**
     * Request the Charge Point to unlock the connector (if applicable). This functionality is for help desk
     * operators only!
     *
     * The command UNLOCK_CONNECTOR may only be used by an operator or the eMSP. This command SHALL never be allowed
     * to be sent directly by the EV-Driver. The UNLOCK_CONNECTOR is intended to be used in the rare situation that the connector
     * is not unlocked successfully after a transaction is stopped. The mechanical unlock of the lock mechanism might get
     * stuck, for example: fail when there is tension on the charging cable when the Charge Point tries to unlock the connector.
     * In such a situation the EV-Driver can call either the CPO or the eMSP to retry the unlocking.
     *
     */
    UNLOCK_CONNECTOR= "UNLOCK_CONNECTOR"
}

export enum CommandResponseType {

    /**
     * The requested command is not supported by this CPO, Charge Point, EVSE etc.
     */
    NOT_SUPPORTED= "NOT_SUPPORTED",

    /**
     * Command request rejected by the CPO. (Session might not be from a customer of the eMSP that send this request)
     */
    REJECTED= "REJECTED",

    /**
     * Command request accepted by the CPO.
     */
    ACCEPTED= "ACCEPTED",

    /**
     * The Session in the requested command is not known by this CPO
     */
    UNKNOWN_SESSION= "UNKNOWN_SESSION"
}

export enum CommandResultType {

    /**
     * Command request accepted by the Charge Point
     */
    ACCEPTED = "ACCEPTED",

    /**
     * The Reservation has been canceled by the CPO.
     */
    CANCELED_RESERVATION = "CANCELED_RESERVATION",

    /**
     * EVSE is currently occupied, another session is ongoing. Cannot start a new session
     */
    EVSE_OCCUPIED = "EVSE_OCCUPIED",

    /**
     * EVSE is currently inoperative or faulted
     */
    EVSE_INOPERATIVE = "EVSE_INOPERATIVE",

    /**
     * Execution of the command failed at the Charge Point
     */
    FAILED = "FAILED",

    /**
     * The requested command is not supported by this Charge Point, EVSE etc.
     */
    NOT_SUPPORTED = "NOT_SUPPORTED",

    /**
     * Command request rejected by the Charge Point.
     */
    REJECTED = "REJECTED",

    /**
     * Command request timeout, no response received from the Charge Point in a reasonable
     * time.
     */
    TIMEOUT = "TIMEOUT",

    /**
     * The Reservation in the requested command is not known by this Charge Point.
     */
    UNKNOWN_RESERVATION = "UNKNOWN_RESERVATION"


}
