import { createRoute } from "../constants";
import { Routes } from "./root.paths";
import { LandingRoute } from "../../views/landing/LandingView";
import { LoginOrSignupRoute } from "../../views/landing/LoginOrSignupView";
import { ChangeServerRoute } from "../../views/landing/ChangeServerView";
import { ParkableMapRoute } from "../../views/map/ParkableMapRootView";
import { ParkDetailRoute } from "../../views/parking/ParkDetailView";
import { FindParkRoute } from "../../views/parking/FindParkView";
import { SupportRoute } from "../../views/support/SupportView";
import { AddNewCaseViewRoute } from "../../views/support/AddNewCaseView";
import { CaseDetailsRoute } from "../../views/support/CaseDetailsView";
import { ConfirmStartParkingRoute } from "../../views/parking/confirmation/ConfirmStartParkingView";
import { ActiveSessionRoute } from "../../views/parking/ActiveSessionView";
import { AccountRoute } from "../../views/account/AccountView";
import { PreferredBaysRoute } from "../../views/preferred-bays/PreferredBaysView";
import { SelectParkRoute } from "../../views/preferred-bays/SelectParkView";
import { PricingViewRoute } from "../../views/parking/PricingView";
import { ForgotPasswordRoute } from "../../views/landing/ForgotPasswordView";
import { MapSettingsRoute } from "../../views/account/MapSettingsView";
import { MyDetailsRoute } from "../../views/account/MyDetailsView";
import { SessionHistoryRoute } from "../../views/account/session/SessionHistoryView";
import { BookingsAndRequestsRoute } from "../../views/future-booking/BookingAndRequestsView";
import { SubscriptionListRoute } from "../../views/subscriptions/ListSubscriptionView";
import { AddNewVehicleRoute } from "../../views/account/vehicle/AddNewVehicleView";
import { VehiclesRoute } from "../../views/account/vehicle/VehiclesView";
import { SamlLoginRoute } from "../../views/landing/SamlLoginView";
import { MessagesRoute } from "../../views/account/MessagesView";
import { VouchersRoute } from "../../views/account/VouchersView";
import { SessionSummaryRoute } from "../../views/parking/SessionSummaryView";
import { NotificationSettingsRoute } from "../../views/account/NotificationSettingsView";
import { RfidCardsRoute } from "../../views/account/rfid/RfidCardsView";
import { AddEditRfidCardRoute } from "../../views/account/rfid/AddEditRfidCardView";
import { CreditCardRoute } from "../../views/account/payment/CreditCardsView";
import { CreateAccountRoute } from "../../views/landing/CreateAccountView";
import { AddNewCardRoute } from "../../views/account/payment/AddNewCardRootView";
import { SelectBayRoute } from "../../views/parking/SelectBayView";
import { ParkAvailabilityViewRoute } from "../../views/parking/ParkAvailabilityView";
import { VoucherDetailRoute } from "../../views/account/voucher/VoucherDetailView";
import { AddVoucherCodeRoute } from "../../views/account/voucher/AddVoucherCodeView";
import { PreferredBaysSelectionViewRoute } from "../../views/preferred-bays/PreferredBaysSelectionView";
import { SessionHistoryDetailRoute } from "../../views/account/session/SessionHistoryDetailView";
import { SelectSubscriptionPlanRoute } from "../../views/subscriptions/SelectSubscriptionPlanView";
import { ParkTomorrowRoute } from "../../views/future-booking/ParkTomorrowView";
import { ConfirmStartReservationRoute } from "../../views/parking/confirmation/ConfirmStartReservationView";
import { StartSubscriptionSummaryViewRoute } from "../../views/subscriptions/StartSubscriptionSummaryView";
import { PrivacySettingsRoute } from "../../views/account/PrivacySettingsView";
import { QrScannerRoute } from "../../views/qr-scanner/QrScannerView";
import { ChangePasswordViewRoute } from "../../views/account/ChangePasswordView";
import { SubscriptionCancelRoute } from "../../views/subscriptions/SubscriptionCancelView";
import { FutureBookingRoute } from "../../views/future-booking/FutureBookingView";
import { ProblemRoute } from "../../views/support/ProblemView";
import { HowItWorksThisWeekRoute } from "../../views/future-booking/how-it-works/ThisWeekView";
import { HowItWorksUpcomingWeeksRoute } from "../../views/future-booking/how-it-works/UpcomingWeeksView";
import { SubscriptionDetailsRoute } from "../../views/subscriptions/SubscriptionDetailsView";
import { VerifyEmailRoute } from "../../views/landing/VerifyEmailView";
import { SingleSubscriptionRoute } from "../../views/subscriptions/SingleSubscriptionView";
import { ChatDetailsRoute } from "../../views/parking/tandem/ChatDetailsView";
import { StartChargingByLinkRoute } from "../../views/ev/StartChargingByLinkView";
import { ConfirmedBookingRoute } from "../../views/future-booking/ConfirmedBookingView";
import { SubscriptionInvoicesRoute } from "../../views/subscriptions/SubscriptionInvoicesView";
import { SubscriptionSharingCreditRoute } from "../../views/subscriptions/SubscriptionSharingCreditView";
import { CampusScreenRoute } from "../../views/parking/CampusScreenView";
import { ConnectEVNozzleRoute } from "../../views/ev/ConnectEVNozzleView";
import { RetryPaymentRoute } from "../../views/account/payment/RetryPaymentRootView";
import { TandemChatRoute } from "../../views/parking/tandem/TandemChatView";
import { ConfirmStartTandemParkingRoute } from "../../views/parking/confirmation/ConfirmStartTandemParkingView";
import { MagicLinkRoute } from "../../views/landing/MagicLinkView";
import { HowItWorksRewardsRoute } from "../../views/subscriptions/rewards/HowItWorksRewardsView";
import { AdminLoginAsUserRoute } from "../../views/account/admin/AdminLoginAsUserView";
import { WarningOpenSessionRoute } from "../../views/parking/WarningOpenSessionView";
import { AutoEndSessionRoute } from "../../views/parking/AutoEndSessionView";
import { UpdateCheckRoute } from "../../views/landing/UpdateCheckView";
import { TeamsLandingPageRoute } from "../../views/landing/teams/TeamsLandingView";
import { TeamsAuthStartRoute } from "../../views/landing/teams/TeamsAuthStartView";
import { TeamsAuthEndRoute } from "../../views/landing/teams/TeamsAuthEndView";
import { SharingPoolViewRoute } from "react/views/parking/SharingPoolView";
import {VtsLoginRoute} from "../../views/landing/vts/VtsLoginView";
import { EditVehicleRoute } from "../../views/account/vehicle/EditVehicleView";
import { MyRewardsViewRoute } from "react/views/subscriptions/rewards/MyRewardsView";

declare global {
    namespace ReactNavigation {
        interface RootParamList extends RootStackParamList {}
    }
}

export type RoutesMap = typeof routesMap;
type ParamType<T extends ReturnType<typeof createRoute>> = T["params"]["__type"];

export type RootStackParamList = {
    [Key in keyof RoutesMap as RoutesMap[Key]["path"] & Routes]: ParamType<RoutesMap[Key]>;
};

/** Routes that require no special treatment. For more precise routes, manually add types to
 * RootStackParamList and the Navigation component
 */
const routesMap = {
    ParkableMapRoute,
    LandingRoute,
    LoginOrSignupRoute,
    VerifyEmailRoute,
    MagicLinkRoute,
    ChangeServerRoute,
    UpdateCheckRoute,
    ParkDetailRoute,
    CampusScreenRoute,
    ConnectEVNozzleRoute,
    CreateAccountRoute,
    FindParkRoute,
    SupportRoute,
    AddNewCaseViewRoute,
    CaseDetailsRoute,
    SingleSubscriptionRoute,
    MyDetailsRoute,
    SubscriptionCancelRoute,
    ConfirmStartParkingRoute,
    StartSubscriptionSummaryViewRoute,
    PreferredBaysRoute,
    SelectParkRoute,
    PricingViewRoute,
    ActiveSessionRoute,
    ChangePasswordViewRoute,
    RetryPaymentRoute,
    AccountRoute,
    MapSettingsRoute,
    SessionHistoryRoute,
    ProblemRoute,
    BookingsAndRequestsRoute,
    SubscriptionListRoute,
    SubscriptionInvoicesRoute,
    SubscriptionSharingCreditRoute,
    AddNewVehicleRoute,
    EditVehicleRoute,
    PreferredBaysSelectionViewRoute,
    ForgotPasswordRoute,
    VehiclesRoute,
    ConfirmedBookingRoute,
    SamlLoginRoute,
    MessagesRoute,
    SelectSubscriptionPlanRoute,
    SubscriptionDetailsRoute,
    ParkTomorrowRoute,
    ConfirmStartReservationRoute,
    ConfirmStartTandemParkingRoute,
    VouchersRoute,
    SessionSummaryRoute,
    NotificationSettingsRoute,
    FutureBookingRoute,
    HowItWorksThisWeekRoute,
    HowItWorksUpcomingWeeksRoute,
    RfidCardsRoute,
    AddEditRfidCardRoute,
    ChatDetailsRoute,
    TandemChatRoute,
    CreditCardRoute,
    AddNewCardRoute,
    SelectBayRoute,
    ParkAvailabilityViewRoute,
    SharingPoolViewRoute,
    VoucherDetailRoute,
    QrScannerRoute,
    AddVoucherCodeRoute,
    StartChargingByLinkRoute,
    WarningOpenSessionRoute,
    AutoEndSessionRoute,
    PrivacySettingsRoute,
    SessionHistoryDetailRoute,
    MyRewardsViewRoute,
    HowItWorksRewardsRoute,
    AdminLoginAsUserRoute,
    TeamsLandingPageRoute,
    TeamsAuthStartRoute,
    TeamsAuthEndRoute,
    VtsLoginRoute,
};

export const routesList: RoutesMap[keyof RoutesMap][] = Object.keys(routesMap).map((path) => {
    const route = routesMap[path as keyof RoutesMap];
    return route;
});
