import { TypedUseSelectorHook, useDispatch as _useDispatch, useSelector as _useSelector } from "react-redux";
import { store } from "./store/store";
import { IRootReducer } from "./reducers/main";

/**
 * Typed useSelector
 */
export const useSelector: TypedUseSelectorHook<IRootReducer> = _useSelector

export type AppDispatchFunc = typeof store.dispatch;

/**
 * Typed useDispatch
 */
export const useAppDispatch: () => AppDispatchFunc = _useDispatch

export const useReduxUser = () => useSelector(state => state.user)
export const useReduxUserOptions = () => useSelector(state => state.userOptions)
export const useReduxMaps = () => useSelector(state => state.maps)
export const useReduxAuth = () => useSelector(state => state.auth)
export const useReduxCampuses = () => useSelector(state => state.campuses)
export const useReduxParks = () => useSelector(state => state.parks)
export const useReduxSubscriptions = () => useSelector(state => state.subscriptions)
export const useReduxCredentials = () => useSelector(state => state.credential)