// @ts-ignore
import React from 'react';
import {View, StyleSheet} from 'react-native';
import Strings from "../../util/localization/localization";
import {Park} from "../../model/Park";
import ParkInstructions from "./ParkInstructions";
import {wordCount} from "../../util/Util"

export default function InstructionsCard(props: Props) {
    const {park} = props;

    const description = park.description && park.description !== "null" ? park.description : Strings("default_parking_instructions_string");
    return (<View style={styles.container}>
            <ParkInstructions
                    style={{ paddingTop: 9, marginHorizontal: 0, marginVertical: 0}}
                    descriptionTextProps={{h3:true, style:{marginBottom:9}}}
                    text={description}
                    title={Strings("parking_instructions")}
                    showHeading={true}
                    showCollapsed={wordCount(description) > 100}
                    summaryView={true}
            />
        </View>)
}

type Props = {
    park: Pick<Park, 'description'>;
}

const styles = StyleSheet.create({
    container:{
        marginBottom: 100
    },
});
