import React, { useCallback } from "react";
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import Strings from "../../util/localization/localization";
import Colours from "react/legacy/parkable-components/styles/Colours";

import { Routes } from "react/navigation/root/root.paths";
import { ParkingType } from "../../model/Types";
import Constants from "../../util/constants";
import { EmployeeSubscriptionDTO } from "../../dto/EmployeeSubscriptionDTO";

type Props = {
    navigation: any;
    subscription: EmployeeSubscriptionDTO;
    parkingType: ParkingType;
};

export default function SubscriptionTableRow(props: Props) {
    const label: string =
        props.parkingType === ParkingType.LONG_TERM
            ? Strings("view_my_subscription")
            : [Constants.Subscription_Status_Pending, Constants.Subscription_Status_Processing].includes(
                  props.subscription.status
              )
            ? Strings("you_have_pending_subscription")
            : Strings("you_have_active_subscription");

    const openSubscription = useCallback(() => {
        props.navigation.push(Routes.SingleSubscriptionView, { subscriptionId: props.subscription.id });
    }, [props.navigation, props.subscription]);

    return (
        <TableRow
            onPress={openSubscription}
            backgroundColor={Colours.ORANGE_100}
            chevron
            iconLeft={"key"}
        >
            {label}
        </TableRow>
    );
}
