import color from "color";
import React, { PropsWithChildren } from "react";
import {
    StyleSheet,
    TouchableHighlight,
    TouchableHighlightProps,
    TouchableOpacity,
    TouchableOpacityProps,
    ViewStyle,
} from "react-native";
import Colours from "../styles/Colours";

export type TouchableProps = PropsWithChildren<
    {
        disableFeedback?: boolean;
        disabledColor?: string;
    } & (TouchableHighlightProps | TouchableOpacityProps)
>;

export default function Touchable(props: TouchableProps) {
    const { disableFeedback, disabledColor, children, ...otherProps } = props;
    const style = StyleSheet.flatten(props.style);
    const backgroundColor = style?.backgroundColor;

    let actualBackgroundColor = backgroundColor;

    if (props.disabled) {
        actualBackgroundColor = disabledColor || Colours.BUTTON_DISABLED;
    }

    const actualStyle: ViewStyle = {
        ...style,
        backgroundColor: actualBackgroundColor
    };

    if (disableFeedback) {
        return (
            <TouchableOpacity
                {...otherProps}
                activeOpacity={1}
                style={actualStyle}
            >
                <>{children}</>
            </TouchableOpacity>
        );
    }

    if (!backgroundColor || backgroundColor === "transparent") {
        return (
            <TouchableOpacity
                activeOpacity={0.65}
                {...otherProps}
                style={actualStyle}
            >
                <>{children}</>
            </TouchableOpacity>
        );
    }

    const underlayColor = color(backgroundColor).darken(0.09).hex();

    return (
        <TouchableHighlight
            underlayColor={underlayColor}
            {...otherProps}
            style={actualStyle}
        >
            <>{children}</>
        </TouchableHighlight>
    );
}
