import React, { useRef, useEffect } from 'react';
import { View } from 'react-native';
import { DialogRef } from "react/legacy/parkable-components/dialog/Dialog";
import Button from "react/legacy/parkable-components/button/Button";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Dialog from "react/components/dialog/Dialog";
import Strings from "../../../util/localization/localization"
import useSafeArea from "react/util/hooks/useSafeArea";
import moment from "moment";
import {useCurrentParkingSession} from "react/api/parking";
import {usePark} from "react/api/park/park.api";
import {parkReservationTime} from "react/util/Util";

type Props = {
    show: boolean,
    park?: number| null,
    onHide: () => void
}

export default function ReservationTimeOutModal(props: Props) {
    const { show = false,  onHide } = props;

    const dialogRef = useRef<DialogRef | null>(null);

    const { top } = useSafeArea();

    const { parkSession: currentSession } = useCurrentParkingSession();
    const { park } = usePark(currentSession?.park);

    useEffect(() => {
        if (show) {
            dialogRef.current?.show();
        } else {
            dialogRef.current?.hide();
        }
    }, [show]);

    if(!currentSession?.reservationStartedAt){
        return <></>;
    }

    const now = moment();
    const minutesRemaining = parkReservationTime(park?.reservationMinutes) - moment.duration(now.diff(currentSession?.reservationStartedAt)).minutes();

    return <Dialog style={{ position: 'absolute', top, width: '100%' }} onModalHide={onHide}
        ref={dialogRef} iconFABProps={{ style: { backgroundColor: Colours.PINK_DARK } }}
        icon={'reservedfilled'} iconProps={{ white: true }}
        label={Strings("reservations")} labelProps={{ style: { color: Colours.PINK } }}
        title={Strings("reservations_about_to_start")(minutesRemaining)}>
        <View style={{ alignItems: 'center' }}>
            <View style={{ width: '100%' }}>
                <Button center style={{ marginTop: 10 }} onPress={onHide} plain border >{Strings("dismiss")}</Button>
            </View>
        </View>
    </Dialog>
}
