import React, { PropsWithChildren, useState } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import Icon from "../icon/Icon";
import Colours from "../styles/Colours";
import Text, { TextProps } from "../text/Text";
import Touchable from "../touchable/Touchable";
import classnames from "../util/classnames";

const styles = StyleSheet.create({
    base: {
        paddingBottom: 4,
        flexDirection: "row",
    },
    textContainer: {
        flex: 1,
        paddingLeft: 9,
        justifyContent: "center",
    },
    checkBase: {
        height: 27,
        width: 27,
        borderRadius: 3,
        borderWidth: 2,
        borderColor: Colours.GREY_BORDER,
        alignItems: "center",
        justifyContent: "center",
    },
    checked: {
        backgroundColor: Colours.BLUE_300,
        borderColor: Colours.BLUE_300,
    },
    checkedRed: {
        backgroundColor: Colours.RED,
        borderColor: Colours.RED,
    },
    disabled: {
        borderColor: Colours.GREY_BORDER,
    },
    disabledChecked: {
        backgroundColor: Colours.GREY_BORDER,
    },
    tickIcon: {
        padding: 3,
    },
});

const cn = classnames(styles);

export type CheckBoxProps = PropsWithChildren<{
    textProps?: TextProps;
    initialChecked?: boolean;
    checked?: boolean;
    onChange?: (checked: boolean) => void;
    red?: boolean;
    disabled?: boolean;

    checkStyle?: StyleProp<ViewStyle>;
}>;

export default function CheckBox(props: CheckBoxProps) {
    const { children, textProps, initialChecked, onChange, red, disabled } =
        props;

    const [stateChecked, setChecked] = useState<boolean>(
        initialChecked ?? false
    );

    const checked = props.checked ?? stateChecked;

    const checkStyle = [
        cn.styles("checkBase", {
            checked: checked,
            checkedRed: checked && red,
            disabled: disabled,
            disabledChecked: disabled && checked,
        }),
        props.checkStyle,
    ];

    const onCheckedPress = () => {
        setChecked((prev) => {
            const newChecked = !prev;
            onChange?.(newChecked);
            return newChecked;
        });
    };

    return (
        <Touchable
            style={styles.base}
            onPress={onCheckedPress}
            disabled={disabled}
            disabledColor={"transparent"}
        >
            <View style={checkStyle}>
                {checked && (
                    <Icon
                        white
                        name={"checkboxtick"}
                        small
                        style={styles.tickIcon}
                    />
                )}
            </View>
            <View style={styles.textContainer}>
                <Text {...(textProps || { small: true })}>{children}</Text>
            </View>
        </Touchable>
    );
}
