
/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 20/02/2018.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

export default function formatTime(time: any) {

    const hour = time.hour > 12 ? time.hour - 12 : time.hour;
    const minutePrefix = time.minute < 10 ? "0" : "";
    const ampm = (time.hour >= 12 && time.hour < 24) ? "pm" : "am";
    return `${hour}:${minutePrefix}${time.minute}${ampm}`;
}