import {
    NavigationContainer,
    useNavigationContainerRef,
} from "@react-navigation/native";
import React, { PropsWithChildren } from "react";

import { LinkingConfiguration } from "./config";
import { RootStackParamList } from "./root/root.routes";

export const NavigationProvider = ({ children }: PropsWithChildren<{}>) => {
    const navigationRef = useNavigationContainerRef<RootStackParamList>();

    return (
        <NavigationContainer
            linking={LinkingConfiguration}
            ref={navigationRef}
        >
            {children}
        </NavigationContainer>
    );
}
