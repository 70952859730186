import { Platform } from "react-native";
import React from "react";
import { ParkInfo } from "../../../model/ParkInfo";
import { User } from "../../../model/User";
import { Empty } from "../../../redux/reducers/user";
import { CasualDisplayType, ParkingType } from "../../../model/Types";
import { BayDTO } from "../../../model/BayDTO";
import LocationCard from "./LocationCard";
import LocationCardWeb from "./LocationCardWeb";

type Props = {
    parkId: number,
    mapPark?: ParkInfo,
    user?: User | Empty,
    displayType: CasualDisplayType,
    displayText?: string | undefined,
    parkingType: ParkingType,
    bay?: BayDTO | undefined,
    orgName?: string | undefined,
    isMotorBikeDefault: boolean,
    isHideOrgName?: boolean
}

export default function LocationCardRoot(props: Props) {
    return Platform.OS === "web"
        ? <LocationCardWeb {...props}/>
        : <LocationCard {...props}/>;
}
