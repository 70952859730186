export type HourlyAvailability = [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]

export interface DayAvailability {
    available: boolean,
    hourlyAvailability: HourlyAvailability | string,
}

export type DayAvailabilityBools = {
    available: boolean

    /**Array of 48 booleans, showing if the park is available for each half-hour starting from midnight
     * eg [false, false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,true,true,true,true,true,true,true,true,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false] = opening at 8 am, closing at 12pm*/
    hourlyAvailability: HourlyAvailability
}

export const fallbackHourlyAvailability: HourlyAvailability = [false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false,	false];

export const fallbackDayAvailabilityBools: DayAvailabilityBools = {
    available: false,
    hourlyAvailability: fallbackHourlyAvailability,
};


export type DayAvailabilityDTO = {
    available: boolean

    /**String of 48 't's or 'f's
     * eg tttttttttttttttttttttttttttttttttttttttttttttttt
     * */
    hourlyAvailability: string
}

export const convertDayAvailabilityDTO = (dto: DayAvailabilityDTO): DayAvailabilityBools => {
    return {
        available: dto.available,
        hourlyAvailability: dto.hourlyAvailability
            .toLowerCase()
            .split('')
            .map(c => c === 't') as HourlyAvailability
    }
};