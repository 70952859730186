import fetch from "../reducers/fetch";
import * as Api from "../../api/user";
import * as AuthApi from "../../api/auth";
import Strings from "../../util/localization/localization";
import {DispatchFunc, Feature} from "../../model/Types";
import {Token} from "../../api/rest";
import {User} from "../../model/User";
import {Vehicle} from "../../model/Vehicle";
import {Note} from "../../model/Note";
import { UserDTO, UserRoleDTO } from "../../api/user/dto/user.dto";
import moment from "moment";
import { FirebaseService, isFirebaseError } from "../../util/services/firebase.service";
import { getPushNotificationToken } from "../../util/services/pushNotification.service";
import * as StripeAPI from "../../api/stripe";
import { DefaultSettings } from "../../util/constants";
import PhoneNumber from "awesome-phonenumber";
import { getTerritories } from "./territories";
import { ModalType } from "../../components/common/ModalMessageCard";
import {refreshCurrentUser} from "../../api/user/user.api";

export const SET_USER = 'SET_USER';
export const SET_USER_INSTALLATION_ID = 'SET_USER_INSTALLATION_ID';
export const SET_USER_CARDS = 'SET_USER_CARS';
export const SET_USER_VEHICLES = 'SET_USER_VEHICLES';
export const SET_USER_VEHICLE = 'SET_USER_VEHICLE';
export const SET_CURRENT_VEHICLE_ID = "SET_CURRENT_VEHICLE_ID"
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';

export const SET_USER_HOST_DATA = 'SET_USER_HOST_DATA';

export const DATE_FORMAT_RECEIVED = 'DATE_FORMAT_RECEIVED'
export const SET_TOKEN = 'SET_TOKEN'
export const REMOVE_TOKEN = 'REMOVE_TOKEN'


export const SET_COUNTRYCODE = 'SET_COUNTRYCODE'
export const SET_DEFAULT_LOCATION = 'SET_DEFAULT_LOCATION'

export const GPS_REQUESTED = 'GPS_REQUESTED'
export const SET_MESSAGE_MODAL = 'SET_MESSAGE_MODAL';
export const SET_CREATE_USER_DATA = 'SET_CREATE_USER_DATA';
export const SET_SEEN_WELCOME_BACK_MESSAGE = "SET_SEEN_WELCOME_BACK_MESSAGE";
export const SET_SUBSCRIPTION_CANCELLATION_NOTES = "SET_SUBSCRIPTION_CANCELLATION_NOTES";
export const USER_ROLES_RECEIVED = 'USER_ROLES_RECEIVED';
export const USER_PROVIDED_REVIEW = "USER_PROVIDED_REVIEW"


export function setGpsRequested(requested: any) {
    return {
        type: GPS_REQUESTED,
        requested
    }
}

export const setToken = (token: any) => ({
    type: SET_TOKEN,
    token
});

export const removeToken = () => ({
    type: REMOVE_TOKEN,
});

/**
 * No longer used but useful for testing: you can set the old-style token here
 * */
export const setUserToken = (token: any) => (dispatch: DispatchFunc) =>
    dispatch(setToken(token))


export const removeUserToken = () => (dispatch: DispatchFunc) =>
    dispatch(removeToken());


export const userLoggedOut = () => ({
    type: USER_LOGGED_OUT
});

export function setUserInstallationId(installationId: number) {
    return {
        type: SET_USER_INSTALLATION_ID,
        installationId: installationId,
    };
}

export async function loadParkableUser(api: string, authToken: Token)  {
    console.log("loadParkableUser");
    const pushToken = await getPushNotificationToken();
    console.log("pushToken", pushToken);
    const result = await Api.loadParkableUser(authToken, api, pushToken);
    return result.user;
}

export function updateEmail(userId: number, email: string, onSuccess?: (user: User) => void, onError?: (err: any) => void) {
    return updateUser(userId, email, null, null, null, null,null, null, null, onSuccess, onError);
}

export function updateFirstName(userId: number, firstName: string, onSuccess?: (user: User) => void, onError?: (err: any) => void) {
    return updateUser(userId, null, firstName, null, null, null,null, null, null, onSuccess, onError);
}

export function updateLastName(userId: number, lastName: string, onSuccess?: (user: User) => void, onError?: (err: any) => void) {
    return updateUser(userId, null, null, lastName, null, null,null, null, null, onSuccess, onError);
}

export function updatePhoneNumber(userId: number, phone: string, onSuccess?: (user: User) => void, onError?: (err: any) => void) {
    return updateUser(userId, null, null, null, phone, null,null, null, null, onSuccess, onError);
}

export function updateUserVehicle(userId: number, vehicleId: number, onSuccess?: (user: User) => void, onError?: (err: any) => void) {
    return updateUser(userId, null, null, null, null, null,vehicleId, null, null, onSuccess, onError);
}

export function updateDateFormat(userId: number, dateFormat: string, onSuccess?: (user: User) => void, onError?: (err: any) => void) {
    return updateUser(userId, null, null, null, null, null,null, dateFormat, null, onSuccess, onError);
}

export function updateUser(userId: number,
                           email: string | null,
                           firstName: string | null,
                           lastName: string | null,
                           phone: string | null,
                           countryCode: string | null,
                           vehicleId: number | null,
                           dateFormat: string | null,
                           companyDetails: string | null,
                           onSuccess?: (user: User) => void,
                           onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.updateUserAPI(api, token, userId, email, firstName, lastName, phone, countryCode, vehicleId, dateFormat, companyDetails).then((r) => {
            dispatch(setUser(r.user));
            onSuccess?.(r.user);
        }).catch((err) => {
            if(err !== 'network_error') {
                onError?.(err);
            }
            else throw err;
        });
    }, arguments);
}

export function getUserCards() {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getUserCardsAPI(api, token).then((r) => {
            dispatch(userCardsReceived(r));
            return r.cards;
        });
    }, arguments);
}

export function userCardsReceived(response: any) {
    return {
        type: SET_USER_CARDS,
        cards: response.cards,
        currentCardId: response.currentCardId
    };
}

export function addUserVehicle(ownerId: number, registration: string, feature: Feature | undefined | null, isEV: boolean, isMotorbike: boolean, onSuccess?: (vehicle: Vehicle) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.addUserVehicleAPI(api, token, ownerId, registration, feature, isEV, isMotorbike).then(r => {
            refreshCurrentUser();
            dispatch(userVehicleReceived(r))
            onSuccess?.(r.vehicle);
        }).catch(err => {
            if(err !== 'network_error') {
                onError?.(err);
            }
            else throw err;
        })
    }, arguments)
}

export function userVehicleReceived(response: { vehicle: any }) {
    return {
        type: SET_USER_VEHICLE,
        vehicle: response.vehicle
    }
}

export function getUserVehicles() {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getUserVehiclesAPI(api, token).then((r) => {
            //console.log('//getUserVehicles', r)
            dispatch(userVehiclesReceived(r));
            return r.vehicles;
        });
    }, arguments);
}

export function userVehiclesReceived(response: {vehicles: any[]}) {
    return {
        type: SET_USER_VEHICLES,
        vehicles: response.vehicles,
    };
}

export function deleteInstallationId(installationId: number, onSuccess?: (response: any) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.deleteInstallationIdAPI(api, token, installationId).then((r) => {
            onSuccess?.(r);
        }).catch((err) => {
            if(err !== 'network_error') {
                onError?.(err);
            }
            else throw err;
        });
    }, arguments);
}

export async function changeUserPassword(oldPwd: string, newPwd: string) {
    const currentUser = FirebaseService.getCurrentUser();
    if (!currentUser || !currentUser.email) {
        throw new Error(Strings("user_not_found"));
    }
    await reauthenticate(oldPwd);
    await updatePassword(newPwd);
}

async function reauthenticate(password: string) {
    try {
        await FirebaseService.reauthenticate(password);
    } catch (error) {
        if(isFirebaseError(error) && error.code === "auth/wrong-password") {
            throw new Error(Strings("incorrect_password"));
        } else {
            throw new Error(Strings("unknown_error_occurred"));
        }
    }
}

async function updatePassword(newPwd: string) {
    try {
        await FirebaseService.updatePassword(newPwd);
    } catch {
        throw new Error(Strings("error_updating_password"));
    }
}

export function getUserHostData(onSuccess?: (response: any) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getUserHostDataAPI(api, token).then((r) => {
            onSuccess?.(r);
            dispatch(userHostDataReceived(r.host));
        }).catch((err) => {
            if(err !== 'network_error') {
                onError?.(err);
            }
            else throw err;
        });
    }, arguments);
}

export function userHostDataReceived(host: any) {
    return {
        type: SET_USER_HOST_DATA,
        host,
    };
}

export function getInvoiceToken(onSuccess?: (token: string) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getInvoiceToken(api, token).then(({token}) => {
            onSuccess?.(token);
        }).catch(err => {
            if(err !== 'network_error') {
                onError?.(err);
            }
            else throw err;
        })
    }, arguments);
}



export function getSubscriptionCancellationNotes(onSuccess?: (notes: Note[]) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getSubscriptionCancellationNotes(api, token).then(notes => {
            dispatch(setSubscriptionCancellationNotes(notes));
            onSuccess?.(notes);
        }).catch(err => {
            onError?.(err);
        });
    }, arguments);
}

export function setSeenWelcomeBackMessage() {
    return {
        type: SET_SEEN_WELCOME_BACK_MESSAGE
    }
}

export function setSubscriptionCancellationNotes(notes: Note[]) {
    return {
        type: SET_SUBSCRIPTION_CANCELLATION_NOTES,
        notes
    }
}

export function userRolesReceived(userRoles: UserRoleDTO[]) {
    return {
        type: USER_ROLES_RECEIVED,
        userRoles
    };
}

export function retrieveUserRoles() {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.retrieveUserRoles(api, token).then(({userRoles}) => {
            dispatch(userRolesReceived(userRoles));
        }).catch(err => {
            if (err !== 'network_error') {
            } else throw err;
        })
    }, arguments);
}

export function sendEmailVerificationLink(userId: number, continueUrl?: string) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return AuthApi.sendEmailVerificationLink(token, api, userId, continueUrl)
    }, arguments);
}

export function cancelEmailChange(userId: number) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return AuthApi.cancelEmailChange(token, api, userId)
    }, arguments);
}

export function sendPasswordResetLink(email?: string) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return AuthApi.sendPasswordResetLink(token, api, email)
    }, arguments);
}

export function revokeUserToken(){
    return fetch( (dispatch: DispatchFunc, api: string, token: Token) => {
        return AuthApi.revokeToken(token, api);
    }, arguments)
}

export function userProvidedReviewToday() {
    return fetch((dispatch: DispatchFunc) => {
        const today = moment().format('YYYY-MM-DD');
        dispatch(setUserProvidedReviewToday(today));
        return Promise.resolve();
    }, arguments);
}

function setUserProvidedReviewToday(today: any) {
    return {
        type: USER_PROVIDED_REVIEW,
        providedReviewAt: today
    }
}

export function getDefaultPaymentSourceForPaymentIntent(paymentIntent: string) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) =>
        StripeAPI.getDefaultPaymentSourceForPaymentIntent(api, token, paymentIntent));
}

export function setUser(user:UserDTO|null) {
    return (dispatch: DispatchFunc, getState: () => any) => {
        const {settings} = getState()
        const userDf = !!(user??{}).dateFormat ? user?.dateFormat?.toUpperCase() : undefined
        let dateformat
        let needsUpdate: boolean;
        if(!settings.dateformat) {
            //create
            dateformat = !!userDf ? userDf : DefaultSettings.DefaultDateFormat
            needsUpdate = true
        } else if(!!userDf && settings.dateformat !== userDf) {
            //update
            dateformat = userDf
            needsUpdate = true
        } else {
            //do nothing
            needsUpdate = false
        }
        if (needsUpdate) {
            dispatch({
                type: DATE_FORMAT_RECEIVED,
                dateformat
            })
        }
        if(!settings.countryCode) {
            //not been set yet, will update country-code and location
            const phone = (user||{}).phone;
            let countryCode = 'NZ';
            if(!!phone) {
                countryCode = new PhoneNumber(phone).getRegionCode()
            }
            dispatch(getTerritories((territories) => {
                const { latitude, longitude } = territories.find(t => t.countryCode === countryCode) || {latitude: 0, longitude: 0};
                dispatch({
                    type: SET_DEFAULT_LOCATION,
                    defaultLocation: {latitude, longitude}
                })
            }));
            dispatch({
                type: SET_COUNTRYCODE,
                countryCode
            })
        }
        dispatch({
            type: SET_USER,
            user: user,
        })
    }
}

export type CreateUserData = {
    email: string,
    firstName?: string,
    lastName?: string
}

export function handleMessageModal(status: boolean, modalType: ModalType|null, data: any) {
    return {
        type: SET_MESSAGE_MODAL,
        payload: {status, modalType, data}
    }
}

export function setCreateUserData(data : CreateUserData|undefined){
    return {
        type: SET_CREATE_USER_DATA,
        value: data
    }
}


export function setCurrentVehicleId(vehicleId: number | null) {
    return {
        type: SET_CURRENT_VEHICLE_ID,
        vehicleId,
    }
}
