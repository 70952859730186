import { showAlert } from "../../util/alerts";
import { IRootReducer } from "./main";
import { AppDispatchFunc } from "../redux";

const fetch = (func: any, args?: any, handleByAction: boolean = false) : typeof func =>
    (dispatch: AppDispatchFunc, getState: () => IRootReducer) => {

        const {api, token} = getState().data;
        const tokenObject = {
            parkableToken: token,
            firebaseToken: getState().auth.fireBaseToken
        };

        const res = func(dispatch, api, tokenObject, getState, undefined);

        if (__DEV__ && !res) {
            //PARKABLE DEVELOPERS
            const msg = "Parkable developer, make sure you return the promise from your API call so that we can handle error cases here";
            console.log(msg);
            throw msg;
        }
        else if(!!res && !!res.__proto__ &&  !!res.__proto__.catch){
            return res.catch((err: any) => {
                console.log('fetch caught error', err);
                if (err === "network_error") {
                    //dispatch(setServerConnectivityState('disconnected'));
                }
                else if(err?.status === 404) {
                    //swallow - actions that need to handle this specifically should do so in their catch clause
                } else if(!!err?.message && err?.message?.length > 0) {
                    if(handleByAction) {
                        throw err
                    } else {
                        showAlert(err.message);
                    }
                }
                else{
                    throw err;
                }
            });
        }

        return res;
    };

export default fetch;

