import {BlockDTO} from "../api/vehicle/dto/vehicleDTO";

export interface User {
    id: number,
    userName: string,
    userNameLowercase: string,
    email: string,
    createdAt: Date,
    roles: Array<string> | null,
    vehicleId: number | null,
    referralCode: string | null,
    hostData: number | null,
    stripeCustomerId: string | null,
    groups: GroupRole[] | null,
    organisations: OrganisationRole[] | null,
    vouchers: Array<number> | null,
    firstName: string | null,
    lastName: string | null,
    phone: string | null,
    dateFormat: string | null,
    localeIdentifier: string | null,
    block: BlockDTO | null,
    active: boolean | null,
    acceptedTsAndCs?: boolean,
    companyDetails?: string,
    firebaseUid?: boolean;
}

export enum Role {

    /**
     * Parkable developer
     */
    PARKABLE_DEVELOPER = "PARKABLE_DEVELOPER",
    /**
     * Parkable administration
     */
    ADMINISTRATOR = "ADMINISTRATOR",
    /**
     * PfB administrator: has rights to change everything within a PfB Organisation
     */
    ORGANISATION_ADMIN = "ORGANISATION_ADMIN",
    /**
     * PfB team leader: has rights to change things within a UserGroup/BayGroup
     */
    TEAM_LEADER = "TEAM_LEADER",
    /**
     * Can change settings related to Sensors
     */
    SENSOR_ADMIN = "SENSOR_ADMIN",
    /**
     * Public parker who has parked in an organisation or group
     */
    PUBLIC = "PUBLIC",
    /**
     * Role to receive payout emails
     */
    BILLING = "BILLING",
    /**
     * Investor who can see the unrestricted docs from CMS
     */
    INVESTOR = "INVESTOR",
    /**
     * Investor need to accept privacy terms and conditions
     */
    INVESTOR_PENDING = "INVESTOR_PENDING",
    /**
     * Higher priority than common investor who can see restricted docs from cms
     */
    SUPER_INVESTOR = "SUPER_INVESTOR",
    /**
     * SUPER_INVESTOR needs to accept privacy terms and conditions
     */
    SUPER_INVESTOR_PENDING = "SUPER_INVESTOR_PENDING",
    /**
     * Tow truck users who can only check vehicle info in Parks view
     */
    ENFORCEMENT= "ENFORCEMENT"
}

export interface OrganisationRole {
    role?: Role,
    organisation: number,
    suspended?: boolean,
}

export interface GroupRole {
    role: Role | null,
    group: number;
}
