import React, { FC } from "react";
import { Dimensions, Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Text from "react/legacy/parkable-components/text/Text";
import moment from "moment/moment";

interface SiteAvailabilityTileDayProps {
    onDayTilePress: (key: string, day: moment.Moment) => void,
    dayKey: string,
    dayHere: moment.Moment,
    day: number,
    dayGone: boolean,
    available: boolean,
    isSelectedDay: boolean,
    isBookedDay: boolean,
    isActivesession: () => boolean
}

const SCREEN_WIDTH = Dimensions.get("window").width;

const SiteAvailabilityTileDay: FC<SiteAvailabilityTileDayProps> = (props) => {

    const {
        onDayTilePress,
        dayKey,
        dayHere,
        day,
        dayGone,
        available,
        isSelectedDay,
        isBookedDay,
        isActivesession
    } = props;

    const isToday = dayHere.isSame(moment(), 'day');
    const disabled = dayGone || !available;
    const getBackgroundColorStyle = () => {
        if (isToday && isSelectedDay) return Colours.GREEN_300;
        if (isToday) return Colours.BLUE_50;
        if (!isSelectedDay) return Colours.TRANSPARENT;
        if (isSelectedDay && disabled) return Colours.TRANSPARENT;
        return isBookedDay || isActivesession() ? Colours.BLUE_300 : Colours.GREEN_300;
    };
    const getBorderColorStyle = () => {
        if (isToday && isSelectedDay) return Colours.BLUE_500;
        if (isToday) return Colours.BLUE_200;
        if (!isSelectedDay) return Colours.GREY_BORDER;
        if (isSelectedDay && disabled) return Colours.GREY_BORDER;
        return (isBookedDay || isActivesession()) ? Colours.BLUE_300 : Colours.GREEN_300;
    }
    const getTextColorStyle = () => {
        if (isToday && isSelectedDay) return Colours.BLUE_500;
        if (isToday) return Colours.BLUE_300;
        if (isSelectedDay && disabled) return Colours.NEUTRALS_400;
        if (isSelectedDay) return Colours.NEUTRALS_WHITE ;
        return disabled ? Colours.NEUTRALS_400 : Colours.NEUTRALS_900;
    }
    const backgroundColorStyle = getBackgroundColorStyle();
    const borderColorStyle = getBorderColorStyle();
    const textColorStyle = getTextColorStyle();


    return (
        <TouchableOpacity style={styles.dayOfWeekContainer} onPress={() => onDayTilePress(dayKey, dayHere)} disabled={disabled}>
            <View style={styles.circleContainer}>
                <View style={[styles.blueCircle, {
                    backgroundColor: backgroundColorStyle,
                    borderColor: borderColorStyle
                }]} />
            </View>
            {/*When tiles are not disabled the opacity is set to 0.99 rather than 1.*/}
            {/*This is done because opacity is set in Animated.View within the TouchableOpacity component, therefore overrides the style prop.*/}
            <Text style={[styles.dayTilesText, {color: textColorStyle}]} bold>{day}</Text>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    dayOfWeekContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 8,
        marginVertical: 3,
    },
    dayTilesText: {
        fontSize: 18,
    },
    circleContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },
    blueCircle: {
        ...Platform.select({
            native: {
                height: (SCREEN_WIDTH - 21) / 10,
                width: (SCREEN_WIDTH - 21) / 10,
            },
            web: {
                flex: 1,
                maxWidth: "90%",
                aspectRatio: 1,
            }
        }),
        borderRadius: 3,
        opacity: 1,
        margin: 3,
        borderWidth: 1,
    },
});

export default SiteAvailabilityTileDay;
