import { useRef } from "react";
import { LayoutChangeEvent, LayoutRectangle } from "react-native";

export default function useLayoutRef(): [LayoutRectangle | undefined, (event: LayoutChangeEvent) => void] {
    const layout = useRef<LayoutRectangle>();
    const onLayout = (e: LayoutChangeEvent) => {
        layout.current = e.nativeEvent.layout;
    };
    return [layout.current, onLayout];
}
