import {combineReducers} from 'redux';
import subscriptions from './subscriptions';
import data from './data';
import user, {IUserReducer} from './user';
import google from './google';
import parking from './parking';
import vehicles from './vehicles'
import parks from "./parks";
import partialParks from "./partialParks";
import organisations from "./organisations";
import territories from "./territories";
import vouchers from './vouchers';
import maps from './map';
import sensors from './sensors';
import userOptions from "./userOptions";
import settings from './settings'
import ev from './ev'
import geoLocation from "./geoLocation"
import modalReducer from './modal';
import auth from "./auth";
import confirmStartParkingView from"./confirmStartParkingView";
import campuses from "./campuses";
import credential from "./credentials";

const reducers = {
    data,
    user,
    google,
    parking,
    parks,
    partialParks,
    subscriptions,
    organisations,
    vehicles,
    territories,
    vouchers,
    maps,
    sensors,
    userOptions,
    settings,
    campuses,
    ev,
    geoLocation,
    parkableModal: modalReducer,
    auth,
    confirmStartParkingView,
    credential,
};

export type IRootReducer = {
    data: ReturnType<typeof data>,
    user: IUserReducer,
    google: ReturnType<typeof google>,
    parking: ReturnType<typeof parking>,
    parks: ReturnType<typeof parks>,
    partialParks: ReturnType<typeof partialParks>,
    subscriptions: ReturnType<typeof subscriptions>,
    organisations: ReturnType<typeof organisations>,
    vehicles: ReturnType<typeof vehicles>,
    territories: ReturnType<typeof territories>,
    vouchers: ReturnType<typeof vouchers>,
    maps: ReturnType<typeof maps>,
    sensors: ReturnType<typeof sensors>,
    userOptions: ReturnType<typeof userOptions>,
    settings: ReturnType<typeof settings>,
    ev:ReturnType<typeof ev>,
    geoLocation: ReturnType<typeof geoLocation>,
    parkableModal: ReturnType<typeof modalReducer>,
    auth: ReturnType<typeof auth>,
    confirmStartParkingView: ReturnType<typeof confirmStartParkingView>,
    campuses: ReturnType<typeof campuses>,
    credential: ReturnType<typeof credential>,
}

export default combineReducers(reducers);
