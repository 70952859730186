import {useMySWR} from "../http/use-swr";
import {SharingReminderDTO} from "./SharingReminder";
import {post} from "../http/rest";
import {SharingReminderRequest} from "./sharing-reminder-request.request";
import EntityResponse from "../../dto/EntityResponse";

export const useSharingReminderForSubscription = (subscriptionId: number) => {
    return useMySWR<EntityResponse<SharingReminderDTO>>(subscriptionId ? `v1/sharingReminders/${subscriptionId}` : null)
}

export const setSharingReminderForSubscription = (subscriptionId: number, data: SharingReminderRequest) => {
    return post(`v1/sharingReminders/${subscriptionId}/create`,data)
}