import pickBy from "lodash/pickBy";
import React, { PropsWithChildren } from "react";
import { StyleSheet, View, ViewProps } from "react-native";
import Touchable, { TouchableProps } from "../touchable/Touchable";
import classnames from "../util/classnames";

const baseStyles = StyleSheet.create({
    base: {
        borderRadius: 3,
        position: "relative",
    },
    level0: {},
    level1: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.18,
        shadowRadius: 1.0,

        elevation: 1,
    },
    level2: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.2,
        shadowRadius: 1.41,

        elevation: 2,
    },
    level3: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,

        elevation: 3,
    },
    level4: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,

        elevation: 4,
    },
    level5: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    },
    level6: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,

        elevation: 6,
    },
    level7: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.29,
        shadowRadius: 4.65,

        elevation: 7,
    },
    level8: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.3,
        shadowRadius: 4.65,

        elevation: 8,
    },
    level9: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.32,
        shadowRadius: 5.46,

        elevation: 9,
    },
    level10: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.34,
        shadowRadius: 6.27,

        elevation: 10,
    },
    level11: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.36,
        shadowRadius: 6.68,

        elevation: 11,
    },
    level12: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 6,
        },
        shadowOpacity: 0.37,
        shadowRadius: 7.49,

        elevation: 12,
    },
    level13: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 6,
        },
        shadowOpacity: 0.39,
        shadowRadius: 8.3,

        elevation: 13,
    },
    level14: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 7,
        },
        shadowOpacity: 0.41,
        shadowRadius: 9.11,

        elevation: 14,
    },
    level15: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 7,
        },
        shadowOpacity: 0.43,
        shadowRadius: 9.51,

        elevation: 15,
    },
    level16: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.44,
        shadowRadius: 10.32,

        elevation: 16,
    },
    level17: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.46,
        shadowRadius: 11.14,

        elevation: 17,
    },
    level18: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 9,
        },
        shadowOpacity: 0.48,
        shadowRadius: 11.95,

        elevation: 18,
    },
    level19: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 9,
        },
        shadowOpacity: 0.5,
        shadowRadius: 12.35,

        elevation: 19,
    },
    level20: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 10,
        },
        shadowOpacity: 0.51,
        shadowRadius: 13.16,

        elevation: 20,
    },
    level21: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 10,
        },
        shadowOpacity: 0.53,
        shadowRadius: 13.97,

        elevation: 21,
    },
    level22: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 11,
        },
        shadowOpacity: 0.55,
        shadowRadius: 14.78,

        elevation: 22,
    },
    level23: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 11,
        },
        shadowOpacity: 0.57,
        shadowRadius: 15.19,

        elevation: 23,
    },
    level24: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 12,
        },
        shadowOpacity: 0.58,
        shadowRadius: 16.0,

        elevation: 24,
    },
});
const cn = classnames(baseStyles);

export type CardProps = {
    elevation?: number;
    onPress?: () => void;

    shadowColor?: string;

    mt?: number;
    mr?: number;
    mb?: number;
    ml?: number;

    pt?: number;
    pr?: number;
    pb?: number;
    pl?: number;
} & ViewProps &
    TouchableProps;

export default function Card(props: PropsWithChildren<CardProps>) {
    const {
        onPress,
        shadowColor,
        mt,
        mr,
        mb,
        ml,
        pt,
        pr,
        pb,
        pl,
        ...otherProps
    } = props;
    const elevation =
        !props.elevation && props.elevation !== 0 ? 6 : props.elevation;

    const styles = cn.styles("base", {
        level0: elevation === 0,
        level1: elevation === 1,
        level2: elevation === 2,
        level3: elevation === 3,
        level4: elevation === 4,
        level5: elevation === 5,
        level6: elevation === 6,
        level7: elevation === 7,
        level8: elevation === 8,
        level9: elevation === 9,
        level10: elevation === 10,
        level11: elevation === 11,
        level12: elevation === 12,
        level13: elevation === 13,
        level14: elevation === 14,
        level15: elevation === 15,
        level16: elevation === 16,
        level18: elevation === 18,
        level19: elevation === 19,
        level20: elevation === 29,
        level21: elevation === 21,
        level22: elevation === 22,
        level23: elevation === 23,
        level24: elevation === 24,
    });

    const margins = pickBy({
        marginTop: mt,
        marginRight: mr,
        marginBottom: mb,
        marginLeft: ml,
        paddingTop: pt,
        paddingRight: pr,
        paddingBottom: pb,
        paddingLeft: pl,
    });

    const style = [styles, margins, props.style];

    if (onPress) {
        return <Touchable {...otherProps} onPress={onPress} style={style} />;
    }

    return <View {...otherProps} style={style} />;
}
