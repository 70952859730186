/**
 * Google javascript api is quite stupid and is tightly coupled to HTML
 * As such, this file should only be used on the web, as it has references
 * to things like the DOM :(
 *
 * Some of the hooks use a sort of caching to ensure each service is only
 * initialized once
 */
import React from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { FC, useEffect, useState } from "react";
import Map, { Props as MapProps } from "google-map-react";
import { mapStyle } from "./map";

/**
 * IMPORTANT: The google maps API must be loaded with the same configuration
 * otherwise it throws an error.
 * I.e. The GoogleMaps component and the hook-based usage must use the same config
 */
const config: Loader["options"] = {
    libraries: ["places"],
    apiKey: "AIzaSyBsxqKiCgMbXQDFG0FXZ25vgCy03Yx6eNY",
};

const loader = new Loader(config);

// Want to only load google once, storing a global ref
let _google: typeof google | undefined;

export const loadGoogleMaps = async () => {
    if (_google == null) {
        if (window.google != null) {
            _google = window.google;
        } else {
            console.log("Loading Google Places API...");
            _google = await loader.load();
            console.info("Google Places API loaded");
        }
    }
    return _google;
};

export const useGoogleMapsApi = () => {
    const [google, setGoogle] = useState(_google);

    useEffect(() => {
        (async () => {
            setGoogle(await loadGoogleMaps());
        })();
    }, [google]);

    // This code looks superfluous but returning namespaces is weird
    const maps = google?.maps;
    return { ...maps };
};

export const GoogleMap: FC<Omit<MapProps, "bootstrapURLKeys">> = (props) => {
    return (
        // @ts-ignore
        <Map
            options={{ styles: mapStyle }}
            yesIWantToUseGoogleMapApiInternals
            bootstrapURLKeys={{
                key: config.apiKey,
                libraries: config.libraries,
            }}
            {...props}
        />
    );
};
