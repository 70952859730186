import React, { useEffect, useMemo, useState } from "react";
import { RootStackParamList, routesList } from "./root.routes";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useSelector } from "../../redux/redux";
import { IRootReducer } from "../../redux/reducers/main";
import routeComponents from "./root.components";
import { useNavigation } from "../constants";
import { Routes } from "./root.paths";
import { useInitialRoute } from "../useInitialRoute";
import {useTeamsContext} from "../../components/teams/context";

const Stack = createNativeStackNavigator<RootStackParamList>();

const StackNavigator = () => {
	const { user, createUserData } = useSelector((state: IRootReducer) => state.user);
	const { firebaseUser } = useSelector((state: IRootReducer) => state.auth);

    const navigation = useNavigation();
    const {initialRoute, initialRouteLoading} = useInitialRoute();

    const {insideTeamsEnvironment} = useTeamsContext();

    const isEmailVerificationRequired = firebaseUser && !firebaseUser.emailVerified;
    const { hasAcceptedTsAndCs } = user ?? {};
    const loggedOut = !user && !createUserData;

    const topRoute = useMemo(() : {name: Routes, params?: any} => {
        if (!user) {
            //we never show the landing page when inside teams
            if(insideTeamsEnvironment){
                if(initialRoute?.name === Routes.TeamsLandingView){
                    return {
                        name: Routes.TeamsLandingView
                    };
                }
                else if(initialRoute?.name === Routes.TeamsAuthStartView){
                    return {
                        name: Routes.TeamsAuthStartView
                    };
                }
                else if(initialRoute?.name === Routes.TeamsAuthEndView){
                    return {
                        name: Routes.TeamsAuthEndView
                    };
                }
            }
            if(createUserData) {
                return {
                    name: Routes.CreateAccountView,
                    params: {
                        email: createUserData?.email,
                        firstName: createUserData?.firstName,
                        lastName: createUserData?.lastName,
                        usingSSO: true,
                        logoutOnBack: true,
                    }
                };
            }
            return {
                name: Routes.LandingView
                };
        }
        if(isEmailVerificationRequired) {
            return {
                name: Routes.VerifyEmailView
            };
        }
        return {
            name: Routes.ParkableMapView
        };
    }, [loggedOut, isEmailVerificationRequired, hasAcceptedTsAndCs, insideTeamsEnvironment, initialRoute])

    const allowedRouteNames = useMemo(() => {
        if (!user) {
            return [
                Routes.LandingView,
                Routes.ChangeServerView,
                Routes.LoginOrSignUpView,
                Routes.CreateAccountView,
                Routes.SamlLoginView,
                Routes.VtsLoginView,
                Routes.ForgotPasswordView,
                Routes.MagicLinkView,
                Routes.UpdateCheckView,
                Routes.TeamsLandingView,
                Routes.TeamsAuthStartView,
                Routes.TeamsAuthEndView,
            ];
        }
        if (isEmailVerificationRequired) {
            return [
                Routes.VerifyEmailView,
            ];
        }
        return routesList.map(value => value.path).filter(route => ![Routes.VerifyEmailView].some(r => r === route));
    }, [loggedOut, isEmailVerificationRequired, hasAcceptedTsAndCs, user]);

    const navStack = navigation.getState().routes[0].state?.routes.map(value => value.name as Routes) ?? [];

    useEffect(() => {
        const navStackContainsIllegalRoutes = navStack.some(routeName => !allowedRouteNames.includes(routeName));
        if (navStackContainsIllegalRoutes) {
            console.log("Reset to top route", topRoute.name);
            navigation.reset({
                // @ts-ignore
                routes: [topRoute]
            });
        }
    },[JSON.stringify(navStack), allowedRouteNames]);

    useEffect(() => {
        if (initialRouteLoading || (loggedOut && initialRoute.name !== topRoute.name)) {
            return;
        }

        const routes = [];
        if (!loggedOut && initialRoute.name !== Routes.ParkableMapView) {
            routes.push({
                name: Routes.ParkableMapView,
            });
            routes.push({
                name: initialRoute.name,
                params: initialRoute.params,
            });
            navigation.reset({
                // @ts-ignore
                routes,
            });
        }
    }, [initialRouteLoading]);

    useEffect(() => {
        return navigation?.addListener("state", (e) => {
            try {
                console.log("navigation", e.data.state.routes[0]?.state?.routes.map(r => r.name));
            } catch (e) {}
        });
    }, [navigation]);

    // @ts-ignore
	return <Stack.Navigator  screenOptions={{ headerShown: false }} initialRouteName={topRoute.name}>
			{/* More complicated routes can also be placed here */}
			{routesList.map((route) => {
				return (
					<Stack.Screen
						key={route.path}
						name={route.path}
						component={routeComponents[route.path]}
						options={{title: "Parkable", ...route.options}}
					/>
				);
			})}
		</Stack.Navigator>
}

export const RootNavigator = StackNavigator
