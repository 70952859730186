import Text from "react/legacy/parkable-components/text/Text";
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import React, { useCallback } from "react";
import { StyleSheet } from "react-native";
import { usePark } from "../../api/park";
import { Routes } from "react/navigation/root/root.paths";
import { connect } from "react-redux";
import { IRootReducer } from "../../redux/reducers/main";
import { getActiveSubscriptions } from "../../util/getActiveSubscriptions";
import Strings from "../../util/localization/localization";
import { useBaysAvailableToUser } from "../../api/bay/bay.api";
import { ActivityOrigin } from "../../model/ActivityOrigin";
import { useNavigation } from "../../navigation/constants";
import { userIsOrgMember } from "../../util/getUserMember";
import { useUserRoles } from "../../api/user/user.api";
import { useSharingPoolBaysForUser } from "react/api/sharingPool/sharingPool.api";
import { useBayGroupsInPark } from "../../api/bayGroup/bayGroup.api";
import { PUBLIC_BAY_GROUP_TYPES } from "../../model/BayGroupType";

type Props = {parkId: number} & ReturnType<typeof getReduxProps>;

const TableRowParksComponent = (props: Props) => {

    const { parkId, preferences, currentSession, activeSubscriptions } = props;

    const navigation = useNavigation();

    const {park, isLoading} = usePark(parkId);

    const {bays} = useBaysAvailableToUser(parkId, {origin: ActivityOrigin.Application})
    const { bays: sharingPoolBays } = useSharingPoolBaysForUser(park?.organisation, park?.id)

    const {userRoles} = useUserRoles();
    const isUserPrivateOrganisation = !!park?.organisation && userIsOrgMember(userRoles, park.organisation);

    const { bayGroups } = useBayGroupsInPark(park?.organisation, park?.id);
    const hasPublicBayGroups = bayGroups?.some((bg) => PUBLIC_BAY_GROUP_TYPES.includes(bg.groupType));
    const isPublicPark = hasPublicBayGroups || park?.sharingPoolPublic;

    const onPress = useCallback(() => {
        const subInPark = activeSubscriptions.filter(s => s.park === parkId)[0];
            if (!currentSession?.endedAt && currentSession?.park === parkId && !!currentSession.startedAt) {
                navigation.navigate(Routes.ActiveSession, {});
            } else if (!!subInPark) {
                navigation.navigate(Routes.SingleSubscriptionView, {subscriptionId: subInPark.id});
            } else {
                navigation.navigate(Routes.ParkDetailView, {parkId, parkingType: preferences.parkingType});
            }
    }, [currentSession, activeSubscriptions, preferences]);

    const numberOfBays = (bays || []).length + (sharingPoolBays || []).length;

    if (!park?.availability?.globalAvailability) {
        return null;
    }

    if (!isUserPrivateOrganisation && !isPublicPark) {
        return null;
    }

    return (
        <TableRow
            labelProps={{ style: styles.labelStyle }}
            label={isLoading ? "" : park?.displayName!}
            iconLeft={"locationpin"}
            chevron
            onPress={onPress}
            // @ts-expect-error
            labelBottom={<Text red={!numberOfBays}
                               green={!!numberOfBays}>{`${Strings("availableBays")(numberOfBays)}`}</Text>}
            textProps={{ numberOfLines: 2 }}>
            <Text>{isLoading ? Strings("loading") : park?.address}</Text>
        </TableRow>
    );
};

const getReduxProps = (state: IRootReducer) => {
    const user = state.user.user;
    const preferences = state.maps.preferences;
    const activeSubscriptions = getActiveSubscriptions(state.subscriptions.employeeSubscriptions);

    return {
        user,
        preferences,
        currentSession: state.parking.currentSession,
        activeSubscriptions,
        mapPreferences: state.maps?.preferences
    };
};

export default connect(getReduxProps)(TableRowParksComponent)

const styles = StyleSheet.create({
    labelStyle: {fontSize:15}
});
