import * as Type from '../actions/sensors'
import {Sensor} from "../../model/Sensor";
import {Bay} from "../../model/Bay";

const init = {
    sensors: [] as Sensor[],
    scannedSensor: null as Sensor | null,
    parkId: null as number | null,
    bays: {} as {[parkId:number]: Bay[]},
    parkBaysLoading: false,
};

type ISensorReducer = typeof init;

export default function adminSensorsReducer(state = init, action: any): ISensorReducer {
    switch (action.type) {
        case Type.ADMIN_BAY_SENSORS_RECEIVED: {
            return {
                ...state,
                sensors: action.sensors,
            };
        }
        case Type.ADMIN_BAY_SENSOR_RECEIVED: {
            const newArray = [];
            let found = false;
            for (let i = 0; i < state.sensors.length; ++i) {
                const sensor = state.sensors[i];
                if (sensor.id === action.sensor.id) {
                    newArray.push(action.sensor);
                    found = true;
                } else {
                    newArray.push(sensor);
                }
            }

            if (!found) {
                newArray.push(action.sensor);
            }

            return {
                ...state,
                sensors: newArray,
            };
        }
        case Type.CHECK_SENSOR_RECEIVED: {
            return {
                ...state,
                scannedSensor: action.sensor,
            };
        }
        case Type.ADMIN_PARK_BAYS_RECEIVED: {
            const { parkId, bays } = action;
            return {
                ...state,
                parkId,
                bays  :{
                    [parkId]: bays
                }
            }
        }
        case Type.ADMIN_PARK_BAYS_LOADING:{
            return {
                ...state,
                parkBaysLoading: action.parkBaysLoading,
            }
        }
        default:
            return state;
    }
}