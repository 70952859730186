/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 6/10/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import * as ParkApi from "../../api/park";
import fetch from "../reducers/fetch";
import {OrganisationDTO} from "../../model/OrganisationDTO";
import {DispatchFunc} from "../../model/Types";
import {Token} from "../../api/rest";

export const SET_ORGANISATION = 'SET_ORGANISATION';
export const SET_ORGANISATIONS = "SET_ORGANISATIONS";

export function setOrganisation(organisation: OrganisationDTO) {
    return {
        type: SET_ORGANISATION,
        organisation
    }
}

export function setOrganisations(organisations: OrganisationDTO[]) {
    return {
        type: SET_ORGANISATIONS,
        organisations
    }
}

export function getOrganisation(orgId: number) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return ParkApi.getOrganisationDTOAPI(api, token, orgId).then(({organisation}: {organisation: OrganisationDTO}) => {
            dispatch(setOrganisation(organisation));
            return Promise.resolve(organisation);
        }).catch((err: any) => {
            if (err === 'network_error') {
                throw err;
            }
        });
    }, arguments);
}
