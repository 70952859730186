import * as TerritoryApi from "../../api/territory";
import fetch from "../reducers/fetch";
import {Territory} from "../../model/Territory";
import {DispatchFunc} from "../../model/Types";
import {Token} from "../../api/rest";

export const RECEIVED_TERRITORIES = 'RECEIVED_TERRITORIES';

export function receivedTerritories(territories: Territory[]) {
    return {
        type: RECEIVED_TERRITORIES,
        territories
    }
}

export function getTerritory(territoryId: number, onSuccess?: (territory: Territory) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        //console.log('get territory', territoryId)
        return TerritoryApi.getTerritoryAPI(api, token, territoryId).then(({territory}) => {
            dispatch(receivedTerritories([territory]));
            if (onSuccess) {onSuccess(territory)}
        }).catch((err) => {
            if(err === 'network_error'){
                throw err;
            }
            else if (onError) {onError(err)}
        });
    }, arguments);
}

export function getTerritories(onSuccess?: (territories: Territory[]) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        //console.log('get territory', territoryId)
        return TerritoryApi.getAllTerritories(api, token).then(({territories}) => {
            dispatch(receivedTerritories(territories));
            if (onSuccess) {onSuccess(territories)}
        }).catch((err) => {
            if(err === 'network_error'){
                throw err;
            }
            else if (onError) {onError(err)}
        });
    }, arguments);
}
