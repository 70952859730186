import { useMySWR } from "../http/use-swr";
import { generateQueryString } from "../http/rest";
import { Nully } from "../../util/nully";

export enum RequestState {
    ParkTomorrow = "ParkTomorrow",
    FutureBooking = "FutureBooking",
    Disabled = "Disabled",
}

export type ParkingRequestOptionsDTO = {
    state: RequestState,
    allocationDayOfWeek: number,
    allocationHourOfDay: number,
    priorityRequestsPerUser: number,
}

export type ParkingRequestOptionsResponse = { loading: boolean, options?: ParkingRequestOptionsDTO }

export const useParkingRequestOptions = (orgId: Nully<number>, parkId?: number, campusId?: number, campusOrganisationId?: number) => {
    const query = generateQueryString({ parkId, campusId, campusOrganisationId })
    return useMySWR<ParkingRequestOptionsResponse>(orgId ? `organisations/${orgId}/parking-request-options${query}` : null);
};
