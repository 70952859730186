/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 18/10/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import moment from "moment";
import getDayAvailability, {DayAvailabilityBoolsWithToday} from "./getDayAvailability";
import {Availability} from "../../model/Availability";

export default function getTomorrowAvailability(availability: Availability): DayAvailabilityBoolsWithToday | null {
    if (!availability) {
        return null;
    }

    const tomorrow = moment().add(1, 'day');
    const tomorrowDayOfWeek = tomorrow.clone().day();
    return getDayAvailability(tomorrowDayOfWeek, availability, tomorrowDayOfWeek);
}

