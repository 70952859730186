import React, { useEffect, useState } from "react";
import { Alert, StyleSheet, View } from "react-native";
import Button from "react/legacy/parkable-components/button/Button";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Text from "react/legacy/parkable-components/text/Text";
import Strings from "react/util/localization/localization";
import { addUserActionAPI, setDefaultCardAPI } from "react/api/user";
import { Token } from "react/api/rest";
import { logEvent } from "react/util/analytics";
import { Routes } from "../../../navigation/root/root.paths";
import { useNavigation } from "../../../navigation/constants";
import { useAppDispatch, useSelector } from "../../../redux/redux";
import * as StripeApi from "react/api/stripe";
import { CardField, initStripe, useConfirmSetupIntent } from "@stripe/stripe-react-native";
import { userCardsReceived } from "../../../redux/actions/user";
import { AddNewCardProps } from "./AddNewCardRootView";
import ParkableBaseView from "../../../components/common/ParkableBaseView";
import { useUserCards } from "../../../api/stripe/stripe.api";
import {useMasterPublicStripeApiKey} from "react/api/stripe";

const CreateCardTokenSuccess = "CreateCardTokenSuccess";
const CreateCardTokenFailed = "CreateCardTokenFailed";

export default function AddNewCardView(props: AddNewCardProps) {

    const { destination } = props.route.params ?? {};

    const { currentCardId, mutate: mutateCards } = useUserCards()
    const { api } = useSelector(state => state.data);

    const token = useSelector(state => ({
        firebaseToken: state.auth.fireBaseToken
    } as Token));

    const { data: stripeApiKey } = useMasterPublicStripeApiKey();

    const [stripeLoading, setStripeLoading] = useState(false);

    const dispatch = useAppDispatch();

    async function setDefaultCard(paymentMethodId: string) {
        if (currentCardId == paymentMethodId) {
            return;
        }
        try {
            const response = await setDefaultCardAPI(api, token, paymentMethodId);
            dispatch(userCardsReceived(response));
        } catch (err: any) {
            Alert.alert(Strings("could_not_add_card"), err?.message || Strings("internal_error_if_persists"));
        }
    }

    useEffect(() => {
        async function initialize() {
            const publishableKey = stripeApiKey?.masterStripePublicApiKey;
            if (publishableKey) {
                await initStripe({ publishableKey });
                setStripeLoading(false);
            }
        }
        if (stripeApiKey) {
            initialize().catch(console.log);
        }
    }, [stripeApiKey]);

    const { confirmSetupIntent, loading: confirmSetupIntentLoading } = useConfirmSetupIntent();

    const [loading, setLoading] = useState(false);

    const navigation = useNavigation();

    const hasVehicle = useSelector(state =>
        !!state.user.currentVehicleId ||
        !!state.user.user?.vehicleId ||
        (state.user.vehicles?.length ?? 0) > 0);

    const onFinaliseAddCreditCardPress = async () => {

        setLoading(true);

        try {
            const { clientSecret } = await StripeApi.createSetupIntent(api, token);

            // 3. Confirm setup intent
            const { error, setupIntent: setupIntentResult } = await confirmSetupIntent(
                clientSecret,
                {
                    paymentMethodType: "Card"
                }
            );

            if (error) {
                Alert.alert(`Error code: ${error.code}`, error.message);
                console.log("Setup intent confirmation error", error.message);
            } else if (setupIntentResult) {

                //add CreateCardTokenSuccess to UserAction
                await addUserActionAPI(api, token, CreateCardTokenSuccess);

                const facebookParams = {
                    fb_success: 1
                };

                logEvent(undefined, "fb_mobile_add_payment_info", facebookParams);

                if(setupIntentResult.paymentMethodId) {
                    await setDefaultCard(setupIntentResult.paymentMethodId);
                }

                onFinish();
            }

        } catch (err: any) {
            addUserActionAPI(
                api,
                token,
                CreateCardTokenFailed,
                "StripeAPI: " + (err.error || {}).message || ""
            ).catch(console.log);

            switch ((err.error || {}).param) {
            }
        } finally {
            mutateCards();
            setLoading(false);
        }
    };

    const onFinish = () => {
        if (!destination) {
            navigation.goBack();
        } else if (!hasVehicle) {
            navigation.navigate(Routes.AddNewVehicleView, { destination });
        } else {
            // @ts-ignore
            navigation.navigate(destination.route, destination.params);
        }
    };

    return (
        <ParkableBaseView scrollable={false} loading={loading || stripeLoading || confirmSetupIntentLoading}>
            <View style={{flex: 1}}>
                <View style={styles.mainContainer}>
                    <Text h1>{Strings("whats_your_credit_card")}</Text>
                    <CardField
                        postalCodeEnabled={false}
                        cardStyle={{textColor: Colours.NEUTRALS_BLACK}}
                        onCardChange={(cardDetails) => {
                            console.log("card details", cardDetails);
                        }}
                        style={styles.cardField}/>

                    <Button
                        style={{
                            marginTop: 8,
                            justifyContent: "space-between"
                        }}
                        iconRight={"arrowboldright"}
                        onPress={onFinaliseAddCreditCardPress}>
                        {Strings("continue")}
                    </Button>
                </View>
            </View>
        </ParkableBaseView>
    );
}

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        paddingTop: 7,
        paddingBottom: 100,
    },
    cardField: {
        width: "100%",
        height: 50,
        marginVertical: 30,
        color: Colours.NEUTRALS_BLACK,
    }
});
