import React, { FunctionComponent, useState, useCallback, useRef } from "react";
import { View, StyleSheet } from "react-native";
import HelperBlock from "react/legacy/parkable-components/helperBlock/HelperBlock";
import CheckBox from "react/legacy/parkable-components/checkBox/CheckBox";
import Input from "react/legacy/parkable-components/input/Input";
import Button from "react/legacy/parkable-components/button/Button";
import Text from "react/legacy/parkable-components/text/Text";
import Colours from "react/legacy/parkable-components/styles/Colours";
import { connect } from "react-redux";
import { Formik, FormikProps } from "formik";
import * as Yup from 'yup';
import Strings from '../../util/localization/localization';
import { IRootReducer } from "react/redux/reducers/main";
import { DispatchFunc } from "react/model/Types";
import { Routes } from "react/navigation/root/root.paths";
import { changeUserPassword } from "react/redux/actions/user";
import { createRoute, NavigationProps } from "react/navigation/constants";
import ParkableBaseView from "../../components/common/ParkableBaseView";
import { PasswordHelperBlock } from "react/components/landing/passwordHelperBlock/PasswordHelperBlock";
import {showAlert} from "../../util/alerts";

type Props = {
    dispatch: DispatchFunc
} & ReturnType<typeof reduxProps> & NavigationProps<Routes.ChangePasswordView>;

const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(Strings("required")),
});

const ChangePasswordView = (props: Props) => {
    const { navigation, dispatch } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const formRef = useRef<FormikProps<Passwords>|null>(null);
    const [formValid, setFormValid] = useState<boolean>(false);
    const [error, setError] = useState<string|null>(null);
    const [hidePassword, setHidePassword] = useState<boolean>(true);

    const goForgotPassword = useCallback(() => {
        navigation.push(Routes.ForgotPasswordView);
    }, [navigation]);

    const goBack = useCallback(() => {
        navigation.replace(Routes.MyDetailsView, {});
    }, [navigation]);

    const onUpdatePassword = useCallback(() => {
        formRef.current?.submitForm();
    }, [formRef]);

    const handleSubmit = useCallback(async (data: Passwords) => {
        // Check if the password has space at the start or in the end
        if (data.newPassword.startsWith(' ') || data.newPassword.endsWith(' ')) {
            showAlert(Strings("password_no_space_notice"), Strings("password_error"));
            return;
        }
        try {
            setLoading(true);
            setError(null);
            await changeUserPassword(data.oldPassword, data.newPassword);
            navigation.replace(Routes.MyDetailsView, {passwordUpdated: true});
        } catch (e) {
            setError((e as any)?.message??Strings("unknown_error_occurred"));
        } finally {
            setLoading(false);
        }
    }, [navigation, dispatch]);

    const resetError = useCallback(() => setError(null), []);

    const validate = (values: Passwords) => {
        const errors = {} as { [key: string]: any };
        if (values.newPassword?.length < 8) {
            errors.newPasswordError = Strings("minimum_password_length");
        }
        if (values.newPassword && !/^(?=.*[a-z])(?=.*[A-Z])/.test(values.newPassword)) {
            errors.newPasswordComplexityLetterError = Strings("password_complexity_letter");
        }
        if (values.newPassword && !/^(?=.*[0-9])(?=.*[\W_])/.test(values.newPassword)) {
            errors.newPasswordComplexityCharacterError = Strings("password_complexity_character");
        }
        if (values.newPassword !== values.confirmPassword) {
            errors.confirmPasswordError = Strings("passwords_dont_match");
        }
        return errors;
    };

    return <ParkableBaseView loading={loading} backButtonOverride={goBack}>
        <View style={styles.container}>
            <View style={styles.header}>
                <Text h1 bold>{Strings("update_password")}</Text>
            </View>
            <Text>{Strings("password_restrictions")}</Text>
            <Formik<Passwords>
              initialValues={{} as Passwords}
              validationSchema={validationSchema}
              validate={validate}
              onSubmit={handleSubmit}
              innerRef={formRef}
            >
                {({handleChange, handleBlur, values, errors, dirty, isValid}) => {
                    setFormValid(dirty && isValid);
                    return (
                        <View style={styles.editSection}>
                            <Input  label={Strings("current_password").toUpperCase()}
                                    containerStyle={styles.input}
                                    password={hidePassword}
                                    onChange={resetError}
                                    onChangeText={handleChange('oldPassword')}
                                    onBlur={handleBlur('oldPassword')}
                                    value={values.oldPassword}
                                    error={errors.oldPassword} />
                            <Input  label={Strings("password").toUpperCase()}
                                    containerStyle={styles.input}
                                    password={hidePassword}
                                    onChange={resetError}
                                    onChangeText={handleChange('newPassword')}
                                    onBlur={handleBlur('newPassword')}
                                    value={values.newPassword} />
                            {values.newPassword?.length > 0 && <PasswordHelperBlock title={errors.newPasswordError ? errors.newPasswordError : Strings("minimum_password_length")} pass={errors.newPasswordError === undefined} />}
                            {values.newPassword?.length > 0 && <PasswordHelperBlock title={errors.newPasswordComplexityLetterError ? errors.newPasswordComplexityLetterError : Strings("password_complexity_letter")} pass={errors.newPasswordComplexityLetterError === undefined} />}
                            {values.newPassword?.length > 0 && <PasswordHelperBlock title={errors.newPasswordComplexityCharacterError ? errors.newPasswordComplexityCharacterError : Strings("password_complexity_character")} pass={errors.newPasswordComplexityCharacterError === undefined} />}
                            <Input label={Strings("repeat_password").toUpperCase()}
                                    containerStyle={styles.input}
                                    password={hidePassword}
                                    onChange={resetError}
                                    onChangeText={handleChange('confirmPassword')}
                                    onBlur={handleBlur('confirmPassword')}
                                    value={values.confirmPassword} />
                            {values.confirmPassword?.length > 0 && <PasswordHelperBlock title={errors.confirmPasswordError ? errors.confirmPasswordError : Strings("passwords_dont_match")} pass={errors.confirmPasswordError === undefined} />}
                            <CheckBox
                              checkStyle={!hidePassword && {borderColor: Colours.GREY_BORDER}}
                              initialChecked={hidePassword}
                              onChange={hidePassword => setHidePassword(hidePassword)}
                            >
                                    {Strings("hide_password")}
                            </CheckBox>
                            <View style={{top: 18}}>
                                <HelperBlock error={error} />
                            </View>
                        </View>
                )}}
            </Formik>
            <View style={styles.filler}></View>
            <View style={styles.footer}>
                <Button center textProps={{h5:true}}
                        loading={loading} disabled={loading || !formValid} onPress={onUpdatePassword}>{Strings("done")}</Button>
                <Button plain border center textProps={{h5:true}} style={{marginTop: 10}} onPress={goForgotPassword}>
                    {Strings("forgot_password")}
                </Button>
            </View>
        </View>
    </ParkableBaseView>
}

const reduxProps = (state: IRootReducer) => {
    return {
        user: state.user.user
    }
}

type Passwords = {
    oldPassword: string,
    newPassword: string,
    confirmPassword: string,
    newPasswordError?: string,
    newPasswordComplexityLetterError?: string,
    newPasswordComplexityCharacterError?: string,
    confirmPasswordError?: string,
}

const styles = StyleSheet.create({
    header: {
        top: 9,
    },
    editSection: {
    },
    input: {
        marginTop: 9,
        marginBottom: 4
    },
    filler: {
        flex: 1,
    },
    footer: {
        marginTop: 20,
        marginBottom: 100,
    },
    container: {
        position: 'relative',
        width: '100%',
        height: '100%'
    },
});

export default connect(reduxProps)(ChangePasswordView) as FunctionComponent<Props>

export const ChangePasswordViewRoute = createRoute({
    path: Routes.ChangePasswordView,
});
