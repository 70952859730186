import * as Types from "../actions/google";
import * as TypesTS from "../actions/google";

const init = {
    locations: null as any | null,
    driveTimeData: null as any | null,
    loading: false,
    sessiontoken: ""
};

type IGoogleReducer = typeof init;

export default function Google(state = init, action: any): IGoogleReducer {
    //let avail = action.avail;
    switch (action.type) {
        case Types.GOOGLE_GEO_LOCATION_DATA_RECEIVED: {
            return {
                ...state,
                locations: action.locations,
            };
        }
        case Types.GOOGLE_DRIVETIME_DATA_RECEIVED: {
            return {
                ...state,
                driveTimeData: action.driveTimeData,
            };
        }
        case Types.GOOGLE_LOADING: {
            return {
                ...state,
                loading: action.loading,
            };
        }
        case TypesTS.SET_GOOGLE_SESSION_TOKEN: {
            return {
                ...state,
                sessiontoken: action.sessiontoken,
            };
        }
        default:
            return state;
    }
}
