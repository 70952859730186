import {
  NavigationScreenProp,
  NavigationState,
  withNavigation,
} from "react-navigation";


export type NavigationParams<T = any> = {
  navigation: NavigationScreenProp<
  NavigationState & Partial<{ params: Partial<T> }>
  >;
};
export type Navigation = NavigationParams["navigation"]

export const withNavigationProps = <T extends (props: any) => JSX.Element | null>(
  component: T
) =>
  withNavigation(component) as (
    props: T extends (args: infer P) => any ? Omit<P, "navigation"> : any
  ) => JSX.Element;
