import {
    convertDayAvailabilityDTO,
    DayAvailabilityBools,
    DayAvailabilityDTO,
    fallbackDayAvailabilityBools,
} from "./DayAvailability";

export type Availability = {
    globalAvailability: boolean;

    /** If the park is permanentlyAvailable, it is available 24/7 and the DayAvailability settings are ignored */
    permanentlyAvailable: boolean;

    mondayAvailability: DayAvailabilityBools;
    tuesdayAvailability: DayAvailabilityBools;
    wednesdayAvailability: DayAvailabilityBools;
    thursdayAvailability: DayAvailabilityBools;
    fridayAvailability: DayAvailabilityBools;
    saturdayAvailability: DayAvailabilityBools;
    sundayAvailability: DayAvailabilityBools;
};

export const getDayAvailability = (
    param: string,
    availability: Availability
): DayAvailabilityBools => {
    switch (param) {
        case "monday":
            return availability.mondayAvailability;
        case "tuesday":
            return availability.tuesdayAvailability;
        case "wednesday":
            return availability.wednesdayAvailability;
        case "thursday":
            return availability.thursdayAvailability;
        case "friday":
            return availability.fridayAvailability;
        case "saturday":
            return availability.saturdayAvailability;
        case "sunday":
            return availability.sundayAvailability;
        default:
            throw "getDayAvailability: Unknown parameter";
    }
};

export const fallbackAvailability: Availability = {
    globalAvailability: false,
    permanentlyAvailable: false,
    mondayAvailability: fallbackDayAvailabilityBools,
    tuesdayAvailability: fallbackDayAvailabilityBools,
    wednesdayAvailability: fallbackDayAvailabilityBools,
    thursdayAvailability: fallbackDayAvailabilityBools,
    fridayAvailability: fallbackDayAvailabilityBools,
    saturdayAvailability: fallbackDayAvailabilityBools,
    sundayAvailability: fallbackDayAvailabilityBools,
};

export type AvailabilityDTO = {
    globalAvailability: boolean;

    /** If the park is permanentlyAvailable, it is available 24/7 and the DayAvailability settings are ignored */
    permanentlyAvailable: boolean;

    mondayAvailability: DayAvailabilityDTO;
    tuesdayAvailability: DayAvailabilityDTO;
    wednesdayAvailability: DayAvailabilityDTO;
    thursdayAvailability: DayAvailabilityDTO;
    fridayAvailability: DayAvailabilityDTO;
    saturdayAvailability: DayAvailabilityDTO;
    sundayAvailability: DayAvailabilityDTO;
};

export const convertAvailabilityDTO = (dto: AvailabilityDTO): Availability => {
    return {
        globalAvailability: dto.globalAvailability,
        permanentlyAvailable: dto.permanentlyAvailable,
        mondayAvailability: convertDayAvailabilityDTO(dto.mondayAvailability),
        tuesdayAvailability: convertDayAvailabilityDTO(dto.tuesdayAvailability),
        wednesdayAvailability: convertDayAvailabilityDTO(dto.wednesdayAvailability),
        thursdayAvailability: convertDayAvailabilityDTO(dto.thursdayAvailability),
        fridayAvailability: convertDayAvailabilityDTO(dto.fridayAvailability),
        saturdayAvailability: convertDayAvailabilityDTO(dto.saturdayAvailability),
        sundayAvailability: convertDayAvailabilityDTO(dto.sundayAvailability),
    };
};

type WeekAvailability = readonly [boolean, boolean, boolean, boolean, boolean, boolean, boolean];

export const convertAvailabilityToWeekArray = (
    availability: AvailabilityDTO
): WeekAvailability => {
    const weekAvailability = [
        availability.sundayAvailability.available,
        availability.mondayAvailability.available,
        availability.tuesdayAvailability.available,
        availability.wednesdayAvailability.available,
        availability.thursdayAvailability.available,
        availability.fridayAvailability.available,
        availability.saturdayAvailability.available,
    ] as const;
    return weekAvailability;
};

export const convertMultipleParksAvailabilityToWeekArray = (availabilities: AvailabilityDTO[]): WeekAvailability => {
    const weeks = availabilities.map(convertAvailabilityToWeekArray);
    return [...Array(7)].map((_,i) => !!weeks.find(w => w[i])) as any as WeekAvailability;
}

export const generateDefaultWeekArray = (defaultAvailability = true) => [
    defaultAvailability,
    defaultAvailability,
    defaultAvailability,
    defaultAvailability,
    defaultAvailability,
    defaultAvailability,
    defaultAvailability,
] as const;
