import I18n from "i18n-js";
import en from "./translations/en";
import en_US from "./translations/en-US"
import {IStrings} from "./translations/en";

I18n.fallbacks = true;

I18n.defaultLocale = "en";
export const setLocaleForUsersCountry = (countryCode?: string) => {
    switch (countryCode) {
        case "US":
            I18n.locale = "en-US";
            break;
        default:
            I18n.locale = "en";
    }
}

I18n.translations = {
    en,
    "en-US": en_US
};

Object.keys(I18n.translations)
    .forEach(lang => {
        const language: any = I18n.translations[lang];
        Object.keys(en)
            .filter(k => !language[k])
            .forEach(k => {throw `Key ${k} missing from ${lang} translation`;});
});

const Strings = <K extends keyof IStrings>(s: K): IStrings[K] => {
    return I18n.t(s) as IStrings[K];
}
export default Strings;

