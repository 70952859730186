import * as Act from '../actions/vehicles'
import {VehicleRegistration} from "../../model/VehicleRegistration";

const init = {
    registrations: {} as { [key: number]: VehicleRegistration; },
    selectedVehicleIds: undefined as number[] | undefined,
};

export type IVehicles = typeof init;

export default function dataReducer(state = init, action: any): IVehicles {
    switch (action.type) {

        case Act.VEHICLES_SELECTED:
            return {
                ...state,
                selectedVehicleIds: action.vehicleIds,
            }

        case Act.CLEAR_SELECTED_VEHICLES:
            return {
                ...state,
                selectedVehicleIds: undefined,
            }

        default:
            return state;
    }
}
