import {AvailabilityDTO} from "../../model/Availability";
import convertFromAvailabilityString, {DayAvailabilityRules,} from "../parkingViews/history/session-details/operatingHours/convertFromAvailabilityString"

export function getFormattedDayAvailability(availability: AvailabilityDTO): DayAvailabilityRules {
    const d = new Date();
    switch(d.getDay()) {
        case 1:
            return convertFromAvailabilityString(availability?.mondayAvailability, availability);
        case 2:
            return convertFromAvailabilityString(availability?.tuesdayAvailability, availability);
        case 3:
            return convertFromAvailabilityString(availability?.wednesdayAvailability, availability);
        case 4:
            return convertFromAvailabilityString(availability?.thursdayAvailability, availability);
        case 5:
            return convertFromAvailabilityString(availability?.fridayAvailability, availability);
        case 6:
            return convertFromAvailabilityString(availability?.saturdayAvailability, availability);
        case 0:
        case 7:
            return convertFromAvailabilityString(availability?.sundayAvailability, availability);
        default: throw Error("Day not found");

    }
}