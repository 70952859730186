import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    LayoutChangeEvent,
    LayoutRectangle,
    StatusBar,
    StyleSheet,
    View,
} from 'react-native';
import MapView, {PROVIDER_GOOGLE, Region, LatLng} from 'react-native-maps';
import {connect} from "react-redux";
import { ILocation, measureDistanceKms } from "../../util/Util";
import {getClosestParks} from "../../redux/actions/parks";
import {getTerritories} from "../../redux/actions/territories";
import {IRootReducer} from "../../redux/reducers/main";
import CenterMapView from "../../components/map/CenterMapView";
import MapFooterView from "../../components/map/MapFooterView";
import NotificationView from "../../components/map/notifications/NotificationView";
import MapMarkers from "../../components/map/parkableMapView/MapMarkers";
import MenuButton from "../../components/map/parkableMapView/MenuButton";
import {handleMessageModal, setGpsRequested} from "../../redux/actions/user";
import {setLastMapLocation} from "../../redux/actions/map";
import Colours from "react/legacy/parkable-components/styles/Colours";
import { IconName } from "react/legacy/parkable-components/icon/Icons";
import ModalMessageCard, {ModalType} from "../../components/common/ModalMessageCard";
import Strings from "../../util/localization/localization"
import { Routes } from "react/navigation/root/root.paths";
import {getCurrentPosition} from "../../redux/actions/geoLocation";
import {ParkInfo2} from "../../model/ParkInfo2";
import QRScanFab from '../../components/map/QRScanFab';
import { logEvent } from "react/util/analytics";
import {getCampusesToMap} from "../../redux/actions/campuses";
import * as Push from "../../navigation/pushNotifications/constants";
import {addNotificationListener} from "../../navigation/pushNotifications/notificationListener";
import {loadParkableUser, setUser} from "../../redux/actions/user";
import {getFavouriteParks, getUserOptions} from "../../redux/actions/userOptions";
import {CampusDTO} from "../../api/campus/dto/CampusDTO";
import {NavigationProps} from "../../navigation/constants";
import FavouriteLinks from "../../components/map/parkableMapView/FavouriteLinks";
import { API } from "../../util/api";
import { Token } from "../../api/rest";
import { IconProps } from "react/legacy/parkable-components/icon/Icon";
import { TextProps } from "react/legacy/parkable-components/text/Text";
import MapSettingsFab from "../../components/map/MapSettingsFab";

export const MARKER_EXPIRATION_SECONDS = 120;
const DEFAULT_DELTA = 0.012;

interface ILoadPins {
    min: number,
    max: number,
    pinDistanceMetres: number,
    loadPinsCount: number,
    radius: number
}

interface ILoadPinsAndZoom extends ILoadPins {
    zoom: number;
}


const LOAD_PINS: ILoadPins[] = [
    {min: 0, max: 12, pinDistanceMetres: 10000, loadPinsCount: 200, radius: 20000},
    {min: 12, max: 13, pinDistanceMetres: 6000, loadPinsCount: 200, radius: 10000},
    {min: 13, max: 14, pinDistanceMetres: 4000, loadPinsCount: 200, radius: 6000},
    {min: 14, max: 15, pinDistanceMetres: 2000, loadPinsCount: 100, radius: 4000},
    {min: 15, max: 16, pinDistanceMetres: 1000, loadPinsCount: 50, radius: 2500},
    {min: 16, max: 17, pinDistanceMetres: 200, loadPinsCount: 25, radius: 1500},
    {min: 17, max: 100, pinDistanceMetres: 100, loadPinsCount: 20, radius: 1000},
];

type MapViewProps = ReturnType<typeof getReduxProps> & typeof actions;

function ParkableMapView(props: MapViewProps) {

    const {
        defaultLocation,
        lastMapLocation,
        gpsLocation,
        getClosestParks,
        setLastMapLocation,
        setGpsRequested,
        employeeSubscription,
        bay,
        showSubscription,
        messageModalPayload,
        handleMessageModal,
        searchLocation,
        searchPoint,
        getCurrentPosition,
        user,
        getCampusesToMap,
        getFavouriteParks,
        authToken,
        setUser,
        userOptions,
    } = props;

    const getInitialRegion = () =>{
        if(searchLocation){
            return {
                ...searchLocation,
                latitudeDelta: searchLocation.latitudeDelta ?? DEFAULT_DELTA,
                longitudeDelta: searchLocation.longitudeDelta??DEFAULT_DELTA,
            };
        }
        if(lastMapLocation){
            return {
                ...lastMapLocation,
                latitudeDelta: lastMapLocation.latitudeDelta ?? DEFAULT_DELTA,
                longitudeDelta: lastMapLocation.longitudeDelta ?? DEFAULT_DELTA
            };
        }
        if(gpsLocation){
            return {
                latitudeDelta: DEFAULT_DELTA,
                longitudeDelta: DEFAULT_DELTA,
                ...gpsLocation,
            };
        }

        return defaultLocation
    };

    const [region, setRegion] = useState<Region>(getInitialRegion());

    // !!gpsLocation?getRegion(gpsLocation.latitude || 0, gpsLocation.longitude || 0, 1000) : (lastMapLocation || defaultLocation));
    const [showMessage, setShowMessage] = useState(false);
    const [pinsRequest, setPinsRequest] = useState<undefined | number>(undefined);
    const [mapReady, setMapReady] = useState(false);

    const [mapViewContainerLayout, setMapViewContainerLayout] = useState<undefined|LayoutRectangle>(undefined);

    // const [welcomeBackTriangleDimensions, setWelcomeBackTriangleDimensions] = useState<LayoutRectangle | undefined>(undefined);
    const [modalProps, setModalProps] = useState({
        iconName: "checkboxtick" as IconName,
        iconProps: {white:true} as Omit<IconProps, 'name'>,
        label: null as string | null,
        labelProps: undefined as TextProps | undefined,
        title: null as string | null,
        titleProps: undefined as TextProps | undefined,
        signage: null as string | null,
        modalType: null as ModalType | null,
        payloadData: 0 as number
    });

    const mapRef = useRef<MapView>(null);

    const animateToLocation = (location: ILocation) => {
        mapRef.current?.animateToRegion({
            ...location,
            latitudeDelta: DEFAULT_DELTA,
            longitudeDelta: DEFAULT_DELTA,
        });
    };

    useEffect(() => {
        if (!userOptions) {
            props.getUserOptions();
        }
    }, []);

    const getPins = (region: Region, loadPinRadiusMetres: number, cursor: string | null, loadPinsCount: number, thisPinsRequest: number) => {
        getClosestParks(region, loadPinRadiusMetres, loadPinsCount, cursor, MARKER_EXPIRATION_SECONDS, false,
            (parks: ParkInfo2[], newCursor: string | null) => {
                if (newCursor && newCursor !== cursor) {
                    if (pinsRequest === thisPinsRequest) {
                        getPins(region, loadPinRadiusMetres, newCursor, loadPinsCount, thisPinsRequest);
                    }
                }
            });
    };

    const getCampusPins = useCallback((region: Region, loadPinRadiusMetres: number, cursor: string | null, loadPinsCount: number, thisPinsRequest: number) => {
        //Logic to have seamless integration with the old versions TBA
        getCampusesToMap(region, loadPinRadiusMetres, loadPinsCount, cursor, MARKER_EXPIRATION_SECONDS,
            (campuses: CampusDTO[], newCursor: string | null) => {
                if (newCursor && newCursor !== cursor) {
                    if (pinsRequest === thisPinsRequest) {
                        getCampusPins(region, loadPinRadiusMetres, newCursor, loadPinsCount, thisPinsRequest);
                    }
                }
            }
        );

    }, [getCampusesToMap]);

    const getRadius = useCallback((region: Region): ILoadPinsAndZoom => {
        const zoom = Math.floor((Math.log2(360 * (1.4 / region.longitudeDelta)) + 1)* 1000) / 1000;
        return {...(LOAD_PINS.find(({min, max}) => {
            return zoom >= min && zoom < max;
        }) ?? LOAD_PINS[0]), zoom: zoom};
    }, []);

    const onRegionChangeComplete = useCallback(async (newRegion: Region) => {
        // console.log("REGION CHANGED TO : ", newRegion);
        const { zoom: lastRegionZoom } = getRadius(region);
        const { pinDistanceMetres, zoom } = getRadius(newRegion);
        const distance = 1000 * measureDistanceKms(newRegion, region);

        setLastMapLocation(newRegion);
        if (distance >= pinDistanceMetres || zoom !== lastRegionZoom) {
            setRegion(newRegion);
            await setPinsRequest((pinsRequest ?? 0) + 1);
        }
    }, [region, pinsRequest, setPinsRequest]);

    useEffect(() => {
        if(pinsRequest) {
            const {radius, loadPinsCount} = getRadius(region);
            getPins(region, radius, null, loadPinsCount, pinsRequest);
            getCampusPins(region, radius, null, loadPinsCount, pinsRequest);
        }
    },[pinsRequest, region]);

    //On Mount, and on changed user
    useEffect(() => {
     //   console.log("RUNNING ON MOUNT CODE WITH REGION, ", region);
        props.getTerritories();
        setPinsRequest((pinsRequest??0) + 1);
        if(!!showSubscription &&
            !!employeeSubscription &&
            employeeSubscription.status === "Active" &&
            !!bay) {
            const label = Strings("subscription_created");
            const title = Strings("your_bay_number");
            const signage = bay.signage??'ANY';
            setModalProps({...modalProps, label, title: Strings("future_booking_confirmation_title"), signage});
            setShowMessage(true);
        }
    }, [user?.id ?? 'once']);

    const onModalHide = useCallback(() => {
        handleMessageModal(false, null, null); //clean up redux handler
        setShowMessage(false)
    }, []);

    const onPressCenterMapView = async () => {
        const gpsLocation = await getLocation();
        animateToLocation(gpsLocation);
    };

    const getLocation = async () : Promise<ILocation> => {
        const location = await getCurrentPosition();
        setGpsRequested(false);
        return location as any;
    }

    useEffect(() => {
        void getLocation();
    }, []);

    const hasGpsLocation = !!gpsLocation;

    useEffect(() => {
        if (gpsLocation && mapReady) {
            animateToLocation(gpsLocation);
        }
    }, [hasGpsLocation, mapReady]);

    // Temporary workaround for https://github.com/react-native-maps/react-native-maps/issues/4066
    // According to that ticket, the issue will be fixed in react-native-maps@1.0.0
    useEffect(() => {
        if (mapViewContainerLayout) {
            const timeout = setTimeout(() => {
                setMapReady(true);
            }, 999);
        }
    }, [mapViewContainerLayout]);

    const onSearchViewPressed = useCallback(() => {
        const params = { userId: `${user?.id??0}` };
        logEvent(undefined, "map_search", params);
    }, []);

    useEffect(() => {
        if(messageModalPayload?.status) {
            (async () => {
                const {data, modalType} = messageModalPayload;
                if (!data || !modalType) {
                    return;
                }
                const iconName = 'star';
                const label = Strings("private_parking");
                const labelProps = {style: {color: Colours.PINK}};
                setModalProps({...modalProps, iconName, label, labelProps, modalType, payloadData: data});
                setShowMessage(true)
            })()
        }
    }, [messageModalPayload]);

    const onMenuLayout = useCallback((event: LayoutChangeEvent) => {
        // console.log("onMenuLayout", event.nativeEvent.layout);
        // setWelcomeBackTriangleDimensions(event.nativeEvent.layout);
    }, []);

    const onMapViewLayout = useCallback((event: LayoutChangeEvent) => {
        // console.log("Map view dimensions {width: " + event.nativeEvent.layout.width + ", height: " + event.nativeEvent.layout.height + "}", event.nativeEvent.layout);
        setMapViewContainerLayout(event?.nativeEvent?.layout);
    }, []);

    //UPDATE PINS WHEN USER IS INVITED TO ORGANISATION
    useEffect(() => {
        const notifListener = addNotificationListener(onNotificationReceived, 'ParkableMapView');

        return () => { notifListener.remove()};
    }, []);

    const onNotificationReceived = useCallback(async (code: string) => {
        if(code === Push.MemberInvited && lastMapLocation) {
            const user = await loadParkableUser(API.getUrl(), authToken);
            setUser(user);
            await getFavouriteParks();
            setPinsRequest((pinsRequest??0) + 1);
            return true;
        }
        return false;
    }, [getFavouriteParks, pinsRequest, setPinsRequest, lastMapLocation]);

    const getMap = useCallback(() =>{
        if(!mapViewContainerLayout) {
            return null;
        }
        // @ts-ignore
        return <MapView
            key={"map"}
            ref={mapRef}
            // customMapStyle={mapStyle}
            style={{width: mapViewContainerLayout?.width, height: mapViewContainerLayout?.height}}
            provider={PROVIDER_GOOGLE}
            scrollEnabled={props.enableMapScroll}
            initialRegion={region}
            showsUserLocation={true}
            pitchEnabled={false}
            onRegionChangeComplete={onRegionChangeComplete}
            userLocationAnnotationTitle=''
            moveOnMarkerPress={false}
            rotateEnabled={false}
            maxZoomLevel={20}
            minZoomLevel={1}
            onMapReady={ () => setMapReady(true) }
            onMapLoaded={ () => setMapReady(true) }
            showsMyLocationButton={false}>
            <MapMarkers searchPoint={searchPoint} />
        </MapView>
    }, [mapViewContainerLayout, region, props.enableMapScroll, searchPoint]);

    return (
        <View style={styles.main}>
            <StatusBar backgroundColor={"transparent"} translucent={true} barStyle={"dark-content"}/>
            <View style={styles.mapContainer} onLayout={onMapViewLayout}>
                {getMap()}

                <NotificationView/>
                <QRScanFab/>
                <MapSettingsFab />
                <CenterMapView onPress={onPressCenterMapView}/>
                <ModalMessageCard   showMessage={showMessage}
                                    {...modalProps}
                                    onModalHide={onModalHide} />
                <MenuButton onLayout={onMenuLayout}/>
            </View>
            {/* {!!welcomeBackTriangleDimensions && <WelcomeBackComponent style={{marginHorizontal: 18}} anchorLayoutDetails={welcomeBackTriangleDimensions}/>} */}
            <MapFooterView
                showSearchView
                onSearchViewPressed={onSearchViewPressed}
            />
            <FavouriteLinks/>
        </View>
    );
}

const getReduxProps = (state: IRootReducer, props: NavigationProps<Routes.ParkableMapView>) => {

    let defaultLocation: Region = {...state.settings.defaultLocation};

    const navigationParams = props.route.params ?? {};

    let searchLocation:Region|undefined = undefined;
    if(navigationParams?.latitude && navigationParams?.longitude){
        searchLocation = {
            latitude: navigationParams?.latitude,
            longitude: navigationParams?.longitude,
            latitudeDelta: navigationParams?.latitudeDelta ?? DEFAULT_DELTA,
            longitudeDelta: navigationParams?.longitudeDelta ?? DEFAULT_DELTA,
        };
    }

    let searchPoint:LatLng|undefined = undefined;
    if (!!navigationParams?.latitude && !!navigationParams?.longitude && !!navigationParams?.bySearch) {
        searchPoint = {
            latitude: navigationParams.latitude,
            longitude: navigationParams.longitude
        };
    }

    let gpsLocation:ILocation|undefined = undefined;

    if (!!navigationParams.latitude && !!navigationParams.longitude) {
    //    console.log("WE HAVE NAV PARAM LOCATION ", navigationParams);
        defaultLocation.latitude = navigationParams.latitude;
        defaultLocation.longitude = navigationParams.longitude;
    }
    else {
        if (!!state.geoLocation.gpsLocation) {
        //    console.log("WE HAVE GPS LOCATION ", state.geoLocation.gpsLocation);
            //when we have GPS location we zoom up
            gpsLocation = state.geoLocation.gpsLocation;
        }
    }

    const authToken = {
        firebaseToken: state.auth.fireBaseToken
    } as Token;

    return {
        authToken,
        defaultLocation,
        lastMapLocation: state.maps.lastMapLocation,
        gpsLocation,
        searchPoint,
        animatedToSearchLocation: navigationParams?.animatedToSearchLocation,
        employeeSubscription: navigationParams.employeeSubscription,
        bay: navigationParams.bay,
        status: state.geoLocation.status,
        showSubscription: navigationParams.showSubscription,
        messageModalPayload: state.user.messageModalPayload,
        searchLocation,
        preferences: state.maps.preferences,
        user: state.user.user,
        userOptions: state.userOptions.userOptions,
        parkingType: state.maps.preferences.parkingType,
        enableMapScroll: state.maps.enableMapScroll,
    };
};

const actions = {
    getTerritories,
    getClosestParks,
    setLastMapLocation,
    setGpsRequested,
    handleMessageModal,
    getCurrentPosition,
    getFavouriteParks,
    getCampusesToMap,
    setUser,
    getUserOptions
};

export default connect(getReduxProps, actions)(ParkableMapView as React.FunctionComponent);

const styles = StyleSheet.create({
    main: {
        width: "100%",
        height: "100%"
    },
    mapContainer: {
        flex: 1,
        position: "relative",
    },
    darkenMap: {
        backgroundColor: 'black',
        opacity: 0.2,
        position: 'absolute',
        width: '100%',
        height: '100%'
    },
    bayNumberMessage:{
        fontSize: 100,
        lineHeight: 100,
        marginBottom: 18,
        fontWeight: "900",
    }
});
