import React, {useRef} from 'react';
import {FlatList, StyleSheet, View} from 'react-native';
import Text from "react/legacy/parkable-components/text/Text";
import Spinner from "react/legacy/parkable-components/spinner/Spinner";
import Colours from "react/legacy/parkable-components/styles/Colours";
import {connect} from "react-redux";
import Strings from "../../util/localization/localization";
import _ from 'lodash';
import {IRootReducer} from "../../redux/reducers/main";
import {useTandemChatsWithCursor} from "../../api/tandem/tandem.api";
import {ChatDTO} from "../../model/chat/ChatDTO";
import {Message} from "../../components/messages/Message";
import {createRoute} from "../../navigation/constants";
import {Routes} from "../../navigation/root/root.paths";
import ParkableBaseView from "../../components/common/ParkableBaseView";


type MappedMessage =  {
    message: ChatDTO,
    parkSessionId: number | null,
    id: number | string,
    updatedAt: string | undefined,
}

type MessagesViewProps = ReturnType<typeof getReduxProps>;

const messageDateFormat = "D/M/YY";

const MessagesView = (props: MessagesViewProps) => {
    const flatListRef = useRef(null);
    const {data} = useTandemChatsWithCursor();

    const isLoadingTandemChats = data === undefined;

    const tandemChats: { [parkSessionChat: string]: ChatDTO } = data?.reduce((arr, page) => {
        return {...arr, ...page.chats};
    }, {}) ?? {};

    const renderMessageRow = ({ item: message }: { item: MappedMessage }) => {
        return (
            <Message
                message={message.message}
                parkSessionId={message.parkSessionId}
                id={message.id}
                updatedAt={message.updatedAt}
                messageDateFormat={messageDateFormat}
                currentUserId={props.currentUserId}
            />
        );
    };

    const mapMessage = (message: [string, ChatDTO]): MappedMessage => {
        const m = Array.isArray(message) ? message[1] : message;
        // If tandem chat message, the map has a key that is ParkSessionId_ChatId.
        const parkSessionId = Array.isArray(message) ? parseInt(message[0].split("_")[0]) : null;
        return {
            message: m,
            parkSessionId,
            id: m.id,
            // @ts-ignore
            updatedAt: m.lastUpdated ?? _.last(m.messages)?.createdAt,
        };
    };

    const allMessages = _.sortBy([
        ...Object.entries(tandemChats).map(m => mapMessage(m)),
    ], m => m.updatedAt).reverse();

    return (
        <ParkableBaseView scrollable={false}>
            <View style={styles.container}>
                <View>
                    <Text h1 bold>{Strings("messages")}</Text>
                </View>
                <View>
                    {(isLoadingTandemChats) ? <Spinner /> :
                    (allMessages == undefined || allMessages?.length <= 0)
                        ? <Text>{Strings("no_messages_found")}</Text>
                        : <FlatList
                            ref={flatListRef}
                            data={allMessages}
                            renderItem={renderMessageRow}
                            keyExtractor={(item, index) => `${item?.id}_${index}`}
                        />}
                </View>
            </View>
        </ParkableBaseView>
    );
};

const getReduxProps = (state: IRootReducer) => {

    return {
        currentUserId: state.data.userId,
        dateformat: state.settings.dateformat
    }
};

export default connect(getReduxProps)(MessagesView) as React.FunctionComponent;

export const MessagesRoute = createRoute({
    path: Routes.MessagesView,
});

const styles = StyleSheet.create({
    footer: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        bottom: 200
    },
    container: {
        backgroundColor: Colours.NEUTRALS_WHITE,
    },
    row: {
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 10
    }
});
