import * as ParkApi from "../../api/park";
import fetch from "../reducers/fetch";
import { Bay, Feature } from "../../model/Bay";
import {BayGroup} from "../../model/BayGroup";
import { DispatchFunc, ParkingActivity } from "../../model/Types";
import {Token} from "../../api/rest";
import {Park} from "../../model/Park";
import {ParkAvailabilityNotification} from "../../model/ParkAvailabilityNotification";
import {ParkAvailabilityNotificationResponse} from "../../model/ParkAvailabilityNotificationResponse";
import {ParkAvailabilityNotificationsResponse} from "../../model/ParkAvailabilityNotificationsResponse";
import { logEvent } from "react/util/analytics";
import {AccessGateProximity} from "../../model/AccessGate";
import { ParkInfo2 } from "../../model/ParkInfo2";
import { MAP_PARKS_RECEIVED, SET_PARK, SET_USER_BAYS } from "./types";
import { ParkDTO } from "../../model/ParkDTO";
import { Territory } from "../../model/Territory";
import * as TerritoryApi from "../../api/territory";
import { ILocation } from "../../util/Util";
import { ParkSession } from "../../model/ParkSession";
import { setCurrentParkingSession } from "./session";
import { ActivityOrigin } from "../../model/ActivityOrigin";
import { BayDTO } from "../../model/BayDTO";
import * as UserApi from "../../api/user";
import { Vehicle } from "../../model/Vehicle";

//Park
export const SET_HOSTED_PARKS = 'SET_HOSTED_PARKS';
export const SET_HOST_PARK = 'SET_HOST_PARK';
export const SET_BAY = 'SET_BAY';
export const SET_BAYS = 'SET_BAYS';
export const SET_PARK_BAYS = 'SET_PARK_BAYS';
export const SET_HOST_ID = 'SET_HOST_ID';
export const SET_PARK_BAY_GROUPS = 'SET_PARK_BAY_GROUPS';
export const PARK_DELETED = 'PARK_DELETED';
export const RECENTLY_PARKED_IN = 'RECENTLY_PARKED_IN';
export const IS_PUBLIC_PARKER = 'IS_PUBLIC_PARKER';
export const REMOVE_PARK_AVAILABILITY_NOTIFICATION = "REMOVE_PARK_AVAILABILITY_NOTIFICATION";
export const ADD_PARK_AVAILABILITY_NOTIFICATION = "ADD_PARK_AVAILABILITY_NOTIFICATION";
export const ADD_PARK_AVAILABILITY_NOTIFICATIONS = "ADD_PARK_AVAILABILITY_NOTIFICATIONS";

export function getBayGroupsInPark(parkId:number, organisationId: number, onSuccess?:(groups: BayGroup[]) => void, onError?:(err: any) => void) {

    return getBayGroupsInParkIds(organisationId, parkId, onSuccess, onError);
}

export function getBayGroupsInParkIds(organisationId:number, parkId:number, onSuccess?:(groups: BayGroup[]) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return ParkApi.getBayGroupsInPark(api, token, organisationId, parkId).then(({bayGroups}) => {
            dispatch(setParkBayGroups(organisationId, parkId, bayGroups));
            onSuccess?.(bayGroups);
            return new Promise(resolve => resolve(bayGroups));
        }).catch((err) => {
            dispatch(setParkBayGroups(organisationId, parkId, []));
            if(err !== 'network_error') {
                onError?.(err);
            }
            else{
                throw err;
            }

        })
    }, arguments);
}

export function openGate(parkId:number, gateId:string, proximity: Array<AccessGateProximity>, callback?: (result: boolean) => void){
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return ParkApi.openGate(api, token, parkId, gateId, proximity).then(() => {
            if(callback) callback(true);
        }).catch((err) => {
            if(err !== 'network_error') {
                console.log(err)
                if(callback) callback(false);
            }
            else{
                throw err;
            }
        })
    }, arguments);
}

export function getParkBays(parkId:number, onSuccess?:(bays: Bay[]) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return ParkApi.getParkBaysAPI(api, token, parkId).then(({bays}) => {
            dispatch(setParkBays(parkId, bays));
            onSuccess?.(bays);
        }).catch((err) => {
            if(err !== 'network_error') {
                onError?.(err);
            }
            else{
                throw err;
            }
        });
    }, arguments);
}



export function getHostId(parkId:number) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return ParkApi.getHostId(api, token, parkId).then((r) => {
            console.log("Host Id", r);
            dispatch(setHostId(parkId, r.id));
        });
    }, arguments);
}

export function getHostedParks(onSuccess?: ({parks}: {parks: Park[]}) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return ParkApi.getHostedParksAPI(api, token).then((r) => {
            console.log("HOSTED PARKS", r.parks);
            dispatch(setHostedParks(r.parks));
            onSuccess?.(r);
        });
    }, arguments);
}

export function getRecentlyParkedInParks() {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return ParkApi.getRecentlyParkedInParks(api, token).then(({parks}) => {
            //console.log('getRecentlyParkedInParks', parks);
            dispatch(setRecentlyParkedIn(parks));
        });
    }, arguments);
}

const setHostedParks = (parks: Park[]) => ({
    type: SET_HOSTED_PARKS,
    parks
});

export const setHostPark = (park: Park) => ({
    type: SET_HOST_PARK,
    park
});

export function setParkBays(parkId:number, parkBays: Bay[]) {
    return {
        type: SET_PARK_BAYS,
        parkId,
        parkBays
    }
}

export function setParkBayGroups(orgId:number, parkId:number, bayGroups:BayGroup[]) {
    return {
        type: SET_PARK_BAY_GROUPS,
        orgId,
        parkId,
        bayGroups
    }
}

export function setBay(parkId:number, bay:Bay) {
    return {
        type: SET_BAY,
        parkId,
        bay
    }
}

export function setHostId(parkId:number, hostId:number) {
    return {
        type: SET_HOST_ID,
        parkId,
        hostId
    }
}

const setRecentlyParkedIn = (parks: any) => ({
    type: RECENTLY_PARKED_IN,
    parks
});

const setIsPublicParker = (response: any) => ({
    type: IS_PUBLIC_PARKER,
    ...response
})

export function getIsPublicParker (parkId:number, onSuccess?: () => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return ParkApi.isPublicParker(api, token, parkId).then(({publicParker}) => {
            const response = {
                parkId,
                publicParker
            };
            dispatch(setIsPublicParker(response));
            if (onSuccess) onSuccess()
        }).catch((err) => {
            if (err === 'network_error') {
                throw err;
            }
            else if (onError) {
                onError(err);
            }
        })
    }, arguments);
}

export function createUpdateParkAvailabilityNotification(parkId:number, minutes:number, isCreating: boolean) : ParkAvailabilityNotification {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return ParkApi.createUpdateParkAvailabilityNotification(api, token, parkId, minutes).then((response: ParkAvailabilityNotificationResponse) => {
            dispatch(addParkAvailabilityNotification(parkId, response));

            try {
                const params = {
                    parkId: parkId,
                    minutes: minutes
                }
                const eventName = isCreating ? "baynotification_on" : "baynotification_time";
                logEvent(undefined, eventName, params)
            } catch (e) {
                console.log("error on sending analytics", e);
            }

            return Promise.resolve(response.notification);
        }).catch((err) => {
            if (err === 'network_error') {
                throw err;
            }
            return Promise.reject(err);
        })
    }, arguments);
}

const addParkAvailabilityNotification = (parkId: number, response: ParkAvailabilityNotificationResponse) => ({
    type: ADD_PARK_AVAILABILITY_NOTIFICATION,
    parkId,
    ...response
})

export function getParkAvailabilityNotificationForPark (parkId:number) : ParkAvailabilityNotification {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) : Promise<ParkAvailabilityNotification> => {
        return ParkApi.getParkAvailabilityNotificationForPark(api, token, parkId).then((response: ParkAvailabilityNotificationResponse) => {
            if(response.notification){
                dispatch(addParkAvailabilityNotification(parkId, response));
            }
            return Promise.resolve(response.notification);
        }).catch((err) => {
            if (err === 'network_error') {
                throw err;
            }
            return Promise.reject(err);
        })
    }, arguments);
}

const addParkAvailabilityNotifications = (response: ParkAvailabilityNotificationsResponse) => ({
    type: ADD_PARK_AVAILABILITY_NOTIFICATIONS,
    ...response
})

export function getParkAvailabilityNotifications () : ParkAvailabilityNotification {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) : Promise<ParkAvailabilityNotification[]> => {
        return ParkApi.getParkAvailabilityNotifications(api, token).then((response: ParkAvailabilityNotificationsResponse) => {
            dispatch(addParkAvailabilityNotifications(response));
            return Promise.resolve(response.notifications);
        }).catch((err) => {
            if (err === 'network_error') {
                throw err;
            }
            return Promise.reject(err);
        })
    }, arguments);
}

const removeParkAvailabilityNotification = (parkId: number) => ({
    type: REMOVE_PARK_AVAILABILITY_NOTIFICATION,
    parkId
})

export function deleteParkAvailabilityNotification (parkId:number, id: number) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return ParkApi.deleteParkAvailabilityNotification(api, token, parkId, id).then(() => {
            dispatch(removeParkAvailabilityNotification(parkId));

            try {
                const params = {
                    parkId: parkId,
                }
                logEvent(undefined, 'baynotification_off', params)
            } catch (e) {
                console.log("error on sending analytics", e);
            }

            return Promise.resolve();
        }).catch((err) => {
            if (err === 'network_error') {
                throw err;
            }
            return Promise.reject(err);
        })
    }, arguments);
}

export const setParksForMapDisplay = (parks: ParkInfo2[], expirySeconds: number) => ({
    type: MAP_PARKS_RECEIVED,
    parks,
    expirySeconds
});

export type ParkDTOWithTerritory = Omit<ParkDTO, 'territory'> & {
    territory: Territory
}

export const setPark = (park: ParkDTOWithTerritory) => ({
    type: SET_PARK,
    park
});

export function getPark(parkId: number, onSuccess?: (park: ParkDTOWithTerritory) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return ParkApi.getParkAPI(api, token, parkId).then(async ({park}) => {
            const parkWithTerritory = await addTerritoryToParkDTO(park, dispatch, api, token);
            dispatch(setPark(parkWithTerritory));
            onSuccess?.(parkWithTerritory);
            return parkWithTerritory;
        }).catch((err) => {
            if (err?.code !== 'network_error') {
                onError?.(err);
            }
            else {
                throw err;
            }
        })
    }, arguments);
}

const addTerritoryToParkDTO = async (_p: ParkDTO, dispatch: DispatchFunc, api: string, token: Token) => {
    // @ts-ignore
    const p = _p as ParkDTOWithTerritory;
    if(typeof p.territory === 'number') {
        const {territory} = await TerritoryApi.getTerritoryAPI(api, token, p.territory);
        p.territory = territory;
    }
    dispatch(setPark(p));
    return p;
}

export function getParksInOrg(organisationId:number) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return ParkApi.getParksInOrg(api, token, organisationId).then(({parks}) => {
            const pks = parks.map(p => addTerritoryToParkDTO(p, dispatch, api, token))
            return Promise.all(pks)
        }).catch((err) => {
            console.log('Failed to get parks in org: ', err)
            if (err === 'network_error') {
                throw err;
            }
        })
    }, arguments)
}

export function getClosestParks(
    location: ILocation,
    distance: number,
    count: number,
    cursor: string | null,
    expirySeconds: number,
    filterCampus?: boolean,
    onSuccess?: (infos: ParkInfo2[], cursor: string | null) => void) {
    return fetch((dispatch: any, api: string, token: Token) => {
        return ParkApi.getClosestParks(api, token, location.latitude, location.longitude, distance, count, cursor, filterCampus)
            .then((result: any) => {
                // console.log('getClosestParks', result.parkInfo.length);
                dispatch(setParksForMapDisplay(result.parkInfo, expirySeconds));
                onSuccess?.(result.parkInfo, result.cursor);
            }).catch((err) => {
                if (err === 'network_error') {
                    throw err;
                }
            })
    }, arguments);
}

export function searchParkAroundLocation(address: string, { latitude, longitude }: ILocation) {
    return fetch((dispatch: any, api: string, token: Token) => {
        return ParkApi.getClosestParks(api, token, latitude, longitude, 1500, null, null);
    }, arguments);
}

export function searchParkAddress(address:string) {
    return fetch((dispatch: any, api: string, token: Token) => {
        return ParkApi.searchParkAddress(api, token, address);
    }, arguments);
}

export function deletePark (parkId:number, onSuccess?:()=>void) {
    return fetch((dispatch: any, api: string, token: Token) => {
        return ParkApi.deletePark(api, token, parkId).then(() => {
            dispatch(parkDeleted(parkId));
            if (onSuccess) {
                onSuccess()
            }
        });
    }, arguments);
}

const parkDeleted = (parkId:number) => ({
    type: PARK_DELETED,
    parkId: parkId
});

export function changeSessionBay(sessionId:number, bayId:number, bayGroupId:number, organisationId:number | null, successCallback?: (session: ParkSession)=>void, errorCallback?:(err: any)=>void) {
    return fetch((dispatch: any, api: string, token: Token) => {
        return ParkApi.changeSessionBay(api, token, sessionId, bayId, bayGroupId, organisationId).then((r) => {
            if (typeof r.parkSession?.park === "object") {
                r.parkSession.park = r.parkSession.park.id
            }
            // @ts-ignore
            dispatch(setCurrentParkingSession(r.parkSession));
            successCallback?.(r.parkSession!);
        }).catch((err) => {
            if(err  !== 'network_error') {
                if (!!errorCallback && err?.code) {
                    errorCallback(err);
                }
            }
            else{
                throw err;
            }
            console.log('error on changeSessionBay', err)
        })
    }, arguments);
}

export function getBay(parkId:number, bayId:number, onSuccess?: (bay:Bay) => void, onError?: (err:any) => void) {
    return fetch((dispatch: any, api: string, token: Token) => {
        return ParkApi.getBayAPI(api, token, parkId, bayId).then((r) => {
            dispatch(setBay(parkId, r));
            onSuccess?.(r);
            return r;
        }).catch((err) => {
            if(err !== 'network_error') {
                onError?.(err);
            }
        });
    }, arguments);
}

export function getUserBays(parkId:number, parkingType: ParkingActivity, origin?: ActivityOrigin, successCallback?: (bays: BayDTO[]) => void, errorCallback?: (err: any) => void) {
    return fetch((dispatch: any, api: string, token: Token) => {
        return UserApi.getUserVehiclesAPI(api, token).then(({vehicles}: {vehicles: Vehicle[]}) => {
            const features:Feature[] = vehicles.map(v => v.feature??Feature.SEDAN).filter((v, i, arr) => arr.indexOf(v) === i);

            return Promise.all(features.map(feature => ParkApi.getBaysAvailToUserAPI(api, token, parkId, parkingType, feature, origin)))
                .then((value: Array<{bays: BayDTO[]}>) => {
                    const bays = value.map(v => v.bays).reduce((prev, next) => {
                        const prevIds = prev.map(b => b.id);
                        const uniqNext = next.filter(b => !prevIds.includes(b.id));
                        return prev.concat(uniqNext);
                    });
                    successCallback?.(bays)
                    dispatch(setUserBays(parkId, bays));
                    return bays;
                }).catch((err) => {
                    if(err !== 'network_error') {
                        errorCallback?.(err);
                    }
                });
        }).catch((err) => {
            if(err !== 'network_error') {
                errorCallback?.(err);
            }
        });
    }, arguments);
}

//this function was split off of the one above as we dont want to have to get user vehicles when finding bays after QR scan.
export function getEVUserBays(parkId:number, origin?: ActivityOrigin, successCallback?: (bays: BayDTO[]) => void, errorCallback?: (err: any) => void) {
    return fetch((dispatch: any, api: string, token: Token) => {
        return ParkApi.getBaysAvailToUserAPI(api, token, parkId, ParkingActivity.Casual, Feature.ELECTRIC, origin)
            .then(result => {
                const bays = result.bays;
                successCallback?.(bays)
                dispatch(setUserBays(parkId, bays));
                return bays;
            }).catch((err) => {
                if(err !== 'network_error') {
                    errorCallback?.(err);
                }
            });
    }, arguments);
}

export function getUserBaysWithVehicleFeatures(parkId: number, vehicleFetures: Feature[], parkingType: ParkingActivity, origin?: ActivityOrigin) {
    return fetch((dispatch: any, api: string, token: Token) => {
        return Promise.all((vehicleFetures??[]).map(feature => ParkApi.getBaysAvailToUserAPI(api, token, parkId, parkingType, feature, origin)))
            .then((value: Array<{bays: BayDTO[]}>) => {
                const bays = value.map(v => v.bays).reduce((acc, v) => acc.concat(v));
                dispatch(setUserBays(parkId, bays));
                return bays;
            })
          .catch(() => dispatch(setUserBays(parkId, [])));
    }, arguments);
}

function setUserBays(parkId:number, bays:BayDTO[]) {
    return {
        type: SET_USER_BAYS,
        parkId,
        bays
    }
}

export function getBayGroup(organisationId: number, groupId:number) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return ParkApi.getBayGroup(api, token, organisationId, groupId);
    }, arguments);
}
