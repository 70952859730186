import { AccessLevel, UserDTO, UserRoleDTO } from "react/api/user/dto/user.dto";
import { Role } from "react/model/User";

interface Parameters {
    organisationId?: number;
    campusId?: number;
    parkId?: number;
    userGroupId?: number;
    userId?: number;
}

export function hasPermissionToFeature(
    user: UserDTO,
    userRoles: UserRoleDTO[],
    permission: string | null,
    parameters: Parameters = {}
) {
    if (!permission) {
        return true;
    }

    if (userRoles
            .filter((ur) => ur.role.name === Role.PARKABLE_DEVELOPER)
            .filter((ur) => ur.role.accessLevel === AccessLevel.Global).length > 0
    ) {
        return true;
    }

    if (!!parameters.userId && user.id === parameters.userId) {
        return true;
    }

    const matchRoles = userRoles.filter((ur) => ur.role.permissions.includes(permission));

    if (matchRoles.length === 0) {
        return false;
    }

    if (matchRoles.filter((ur) => ur.role.accessLevel === AccessLevel.Global).length > 0) {
        return true;
    }

    return validateRole(matchRoles, parameters);
}

function validateRole(userRoles: UserRoleDTO[], parameters: Parameters) {
    userRoles.sort((r1, r2) =>
        (r1.role.accessLevel === AccessLevel.Park ? -1 : r2.role.accessLevel === AccessLevel.Park ? 1 : 0)
    );
    userRoles.sort((r1, r2) =>
        (r1.role.accessLevel === AccessLevel.Campus ? -1 : r2.role.accessLevel === AccessLevel.Campus ? 1 : 0)
    );
    userRoles.sort((r1, r2) =>
        (r1.role.accessLevel === AccessLevel.Organisation ? -1 : r2.role.accessLevel === AccessLevel.Organisation ? 1 : 0)
    );

    for (let i = 0; i < userRoles.length; i++) {
        const userRole = userRoles[i];
        switch (userRole.role.accessLevel) {
            case AccessLevel.Organisation:
                if (parameters.organisationId == null || parameters.organisationId === userRole.organisation) {
                    return true;
                }
                break;
            case AccessLevel.Campus:
                if (
                    userRole.organisation != null
                    && parameters.organisationId != null
                    && parameters.organisationId === userRole.organisation
                    && userRole.campus != null
                    && parameters.campusId != null
                    && parameters.campusId === userRole.campus
                ) {
                    return true;
                }
                break;
            case AccessLevel.Park:
                if (
                    userRole.organisation != null
                    && parameters.organisationId != null
                    && parameters.organisationId === userRole.organisation
                    && userRole.park != null
                    && parameters.parkId != null
                    && parameters.parkId === userRole.park
                ) {
                    return true;
                }
                break;
            case AccessLevel.UserGroup:
            default:
                if (
                    userRole.organisation != null
                    && parameters.organisationId != null
                    && parameters.organisationId === userRole.organisation
                    && (parameters.userGroupId == null || parameters.userGroupId === (userRole.userGroup ?? 0))
                ) {
                    return true;
                }
                break;
        }
    }
    return false;
}
