import React, { FC } from "react";
import { Image, StatusBar, StyleSheet, TouchableOpacity, View } from "react-native";
import { Colours } from "../../../util/colors";
import { Routes } from "react/navigation/root/root.paths";
import Text from "../Text";
import { connect } from "react-redux";
import useSafeArea from "react/util/hooks/useSafeArea";
import { DispatchFunc } from "../../../model/Types";
import { useNavigation } from "../../../navigation/constants";

const blueArrowLeft = require("../../../resources/blueArrowLeft.png");

interface NavigationHeaderProps {
    transparent?: boolean,
    blue?: boolean,
    white?: boolean,
    solidBlueNav?: boolean,
    overrideOnback?: boolean,
    hideRightIcon?: boolean,
    pulse?: boolean,
    onBackPress?: () => void,
    backgroundColor?: any,
    title?: any,
    rightIcon?: any,
    style?: any,
    iconButtonStyle?: any,
    rightIconButtonStyle?: any,
    titleStyle?: any,
    rightIconButtonContainerStyle?: any,
    onPress?: any,
    onRightIconPress?: any,
}

const NavigationHeader: FC<NavigationHeaderProps & { dispatch: DispatchFunc }> = (props) => {
    const { top } = useSafeArea();
    const navigation = useNavigation();

    const onBackClick = () => {

        if (!!props.onBackPress) {
            props.onBackPress();
        }

        if (props.overrideOnback) {
            return;
        }

        if (navigation.canGoBack()) {
            navigation.pop();
        } else {
            navigation.reset({ routes: [{ name: Routes.ParkableMapView }] });
        }
    }

    let iconLeft = blueArrowLeft;

    //let navBgTop = !!props.backgroundColor ? props.backgroundColor : "#fff";
    let navBgBottom = !!props.backgroundColor ? props.backgroundColor : "#fff";
    let textColor = Colours.ParkableDarkGrey;

    if (!!props.blue) {
        //navBgTop = "#103B5D";
        navBgBottom = "#103B5D00";
        textColor = Colours.White;
    }

    if (!!props.transparent) {
        //navBgTop = "transparent";
        navBgBottom = "transparent";
    }

    if (!!props.white) {
        //navBgTop = "#fff";
        navBgBottom = "#fff";
        textColor = Colours.ParkableWeirdBlue;
    }

    if (!!props.solidBlueNav) {
        //navBgTop = Colours.ParkableWeirdBlue;
        navBgBottom = Colours.ParkableWeirdBlue;
        textColor = Colours.White;
    }

    let titleLength = 0;
    if (!!props.title) {
        titleLength = props.title.length;
    }

    if(props.pulse){
        console.log("HEADER SET TO PULSE");
    }

    return (

        <View style={[{top}, styles.container, {backgroundColor: 'transparent'}, props.style, { backgroundColor: navBgBottom}]}>
            <StatusBar backgroundColor={"white"} translucent={false} barStyle="dark-content"/>
            <TouchableOpacity activeOpacity={0.65} style={[styles.iconButton, {marginLeft: 15}, props.iconButtonStyle]} onPress={onBackClick} testID={'ReturnBackImage'}>
                <Image source={iconLeft} resizeMode={"contain"} style={styles.backButton}/>
            </TouchableOpacity>
            {!!props.title ? <Text center h4 h5={titleLength > 35} h6={titleLength > 39} h7={titleLength > 45} style={[{flex: 1, color: textColor}, props.titleStyle]}>{props.title}</Text> :
                <View style={{flex: 1, backgroundColor: Colours.Transparent}}/>}

            {!!props.rightIcon && !props.hideRightIcon ? <TouchableOpacity activeOpacity={0.65} style={[styles.iconButton, {marginRight: 15}, props.rightIconButtonContainerStyle]} onPress={props.onRightIconPress}>
                <Image source={props.rightIcon} resizeMode={"contain"} style={[styles.backButton, props.rightIconButtonStyle]}/>
            </TouchableOpacity> : <View style={[styles.iconButton, {marginRight: 15,}]}/> }
        </View>

    )
}

export default connect()(NavigationHeader);

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        height: 85,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
    },
    iconButton: {
        backgroundColor: Colours.Transparent,
        justifyContent: 'center',
        alignItems: 'center',
        width: 50,
        height: '100%',
        maxHeight: '100%',
        // backgroundColor: 'pink'
    },
    backButtonContainer: {
        height: 35,
        width: 35,
        borderRadius: 40,
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: 'pink'
    },
    backButton: {
        height: 40,
        width: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        backgroundColor: Colours.Transparent,
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        color: Colours.White,
        flex: 1,
    },
    backgroundOverLay: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0.,
        left: 0,
        backgroundColor: Colours.BORDER_GREY,
        opacity: 0.2
    },
});

