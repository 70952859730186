
export const SET_CREDENTIALS = 'SET_CREDENTIALS';
export const CLEAR_CREDENTIALS = 'CLEAR_CREDENTIALS';

interface SetCredentialsAction {
    type: typeof SET_CREDENTIALS;
    payload: {
        email: string | undefined;
        password: string;
    };
}

interface ClearCredentialsAction {
    type: typeof CLEAR_CREDENTIALS;
}

export const setCredentials = (email: string | undefined, password: string): SetCredentialsAction => ({
    type: SET_CREDENTIALS,
    payload: { email, password }
});

export const clearCredentials = (): ClearCredentialsAction => ({
    type: CLEAR_CREDENTIALS
});

export type CredentialActionTypes = SetCredentialsAction | ClearCredentialsAction; // Include other actions as needed
