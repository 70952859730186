import React, { PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";
import Colours from "../styles/Colours";
import Text from "../text/Text";
import classnames from "../util/classnames";

const styles = StyleSheet.create({
    base: {
        display: undefined,
        backgroundColor: Colours.GREY_10,
        paddingHorizontal: 9,
        borderRadius: 25,
        height: 27,
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        alignSelf: "baseline",
    },
    red: {
        backgroundColor: Colours.RED_LIGHT,
    },
});

const cn = classnames(styles);

export type ChipProps = PropsWithChildren<{
    red?: boolean;
}>;

export default function Chip(props: ChipProps) {
    const { children, red } = props;

    const base = cn.styles("base", {
        red: red,
    });

    return (
        <View style={base}>
            <Text small red={red}>
                {children}
            </Text>
        </View>
    );
}
