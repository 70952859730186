import {get} from "./rest";
import {ActivityType, Voucher} from "../model/Voucher";
import {Moment} from "moment";
import {Token} from "./rest";
export const getVouchers = (url: string, token: Token) => get(token, `${url}v2/vouchers`);

export const checkForVoucherWithCode = (url: string, token: Token, code: string) => get(token, `${url}v2/vouchers/check?code=${encodeURIComponent(code)}`);

export const retrieveLargestDiscountVoucher = (url: string, token: Token, parkId: number, activity: ActivityType, date?: Moment) =>
    get(token, `${url}v2/vouchers/retrieveLargestDiscountVoucher`,
        {
            parkId,
            activity,
            date: !!date ? date.format('YYYY-MM-DD') : null
        }) as Promise<{voucher: Voucher}>;