import React, { useState } from "react";
import Text from "react/legacy/parkable-components/text/Text";
import Button from "react/legacy/parkable-components/button/Button";
import { ScrollView, StyleSheet } from "react-native";
import { createRoute } from "../../navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import ParkableBaseView from "../../components/common/ParkableBaseView";
import * as Updates from "expo-updates";
import { UpdateCheckResult, UpdateFetchResult } from "expo-updates";

const STUB_MANIFEST = {
    name: "a name",
    slug: "a slug",
    bundleUrl: "foo.bar.com",
    metadata: Updates
};

export default function UpdateCheckView() {

    const [updateCheckResult, setUpdateCheckResult] = useState<UpdateCheckResult>();
    const [updateFetchResult, setUpdateFetchResult] = useState<UpdateFetchResult>();

    async function onCheckPress() {
        try {
            const result = await Updates.checkForUpdateAsync();
            // const result = { isAvailable: false };
            // const result: UpdateCheckResult = { isAvailable: true, manifest: STUB_MANIFEST };
            setUpdateCheckResult(result);
        } catch (e) {
            console.error(e);
            alert(e);
        }
    }

    async function onFetchPress() {
        try {
            const result = await Updates.fetchUpdateAsync();
            // const result = { isNew: false };
            // const result = { isNew: true, manifest: STUB_MANIFEST };
            setUpdateFetchResult(result);
        } catch (e) {
            console.error(e);
            alert(e);
        }
    }

    async function onReloadPress() {
        try {
            await Updates.reloadAsync();
        } catch (e) {
            console.error(e);
            alert(e);
        }
    }

    function renderUpdateCheckResult({ isAvailable, manifest }: UpdateCheckResult) {
        return <>
            <Text>Available: [{isAvailable ? "true" : "false"}]</Text>
            {isAvailable && <>
                <Text>Manifest:</Text>
                <ScrollView style={styles.scrollView}><Text>{JSON.stringify(manifest, null, 2)}</Text></ScrollView>
                <Button style={styles.button} onPress={onFetchPress}>Fetch</Button>
                {updateFetchResult && renderUpdateFetchResult(updateFetchResult)}
            </>}
        </>;
    }

    function renderUpdateFetchResult({ isNew }: UpdateFetchResult) {
        return <>
            <Text>Is New: [{isNew ? "true" : "false"}]</Text>
            {isNew && <Button style={styles.button} onPress={onReloadPress}>Reload</Button>}
        </>;
    }

    return (
        <ParkableBaseView scrollable={false}>
            <ScrollView style={styles.main}>
                <Text>Channel: [{Updates.channel ?? "NULL"}]</Text>
                <Button style={styles.button} onPress={onCheckPress}>Check</Button>
                {updateCheckResult && renderUpdateCheckResult(updateCheckResult)}
            </ScrollView>
        </ParkableBaseView>
    );
};

export const UpdateCheckRoute = createRoute({
    path: Routes.UpdateCheckView
});

const styles = StyleSheet.create({
    main: {
        flex: 1
    },
    input: {
        borderWidth: 1,
        padding: 8,
        marginBottom: 5
    },
    scrollView: {
        borderWidth: 1,
        maxHeight: 300
    },
    button: {
        marginVertical: 10
    }
});
