import main from "../reducers/main";
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";
import { configureStore } from "@reduxjs/toolkit";

const persistConfig = {
    key: "root",
    // Storage Method (React Native)
    storage: AsyncStorage,
    // Whitelist (Save Specific Reducers)
    whitelist: ["settings", "data", "maps"],
    // Blacklist (Don't Save Specific Reducers)
    blacklist: [],
    // auto merge 1 level deep
    stateReconciler: autoMergeLevel1
};

// @ts-ignore
const persistedReducer = persistReducer(persistConfig, main);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export const persistor = persistStore(store);
