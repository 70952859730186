import {post, put, del} from './http/rest';
import {GenericResponse} from "./generic/GenericResponse";
import {RfidTokenDTO} from "../model/rfid/RfidTokenDTO";
import {useMySWR} from "./http/use-swr";
import {Nully} from "../util/nully";
import {RfidType} from "../model/rfid/RfidType";

export const createParkingRfid = async (rfidNumber: string, userId: number) => {
    return post<GenericResponse<RfidTokenDTO>>(`v1/rfid`, {rfidNumber, userId, type: RfidType.Parking});
}

export const deleteParkingRfid = async (id: string) => {
    return del<GenericResponse<RfidTokenDTO>>(`v1/rfid/${id}`);
}

export const useRfidTokens = (userId: Nully<number>) => {
    return useMySWR<GenericResponse<RfidTokenDTO[]>>(userId ? `v1/rfid/user/${userId}` : null);
}
