import React from "react"
import { createDrawerNavigator } from "@react-navigation/drawer"
import { MapDrawer } from "react/navigation/drawer/drawer";
import { RootNavigator } from "../root/root.navigator";


const Drawer = createDrawerNavigator()

/**
 * We need a dummy base route to nest a stack navigator inside
 * the drawer navigator. Consequently our app has the Drawer UI
 * while adhering to a web-like route organisation scheme  */
export const BASE_ROUTE = "app"

/** To provide the drawer menu on the map view we need to wrap it in
 * this drawer navigator
 */
export const DrawerNavigator = () => {
	return <Drawer.Navigator
		initialRouteName={BASE_ROUTE}
		drawerContent={(props) => <MapDrawer {...props} />}
		screenOptions={{ headerShown: false }}>
		<Drawer.Screen name={BASE_ROUTE} component={RootNavigator} />
	</Drawer.Navigator>
}
