import React from "react"
import moment from "moment";
import { useEffect } from "react";
import * as StoreReview from "expo-store-review";
import {UpdateUserOptionsRequest} from "../../dto/UpdateUserOptionsRequest";
import {updateUserOptions} from "../../api/userOptions";
import {UserOptions} from "../../model/UserOptions";
import {MIN_DAYS_TO_WAIT_TO_ASK_FOR_REVIEW} from "../../util/constants";

type AppReviewProps = {
    userOptions: UserOptions
}

const AppReview = (props: AppReviewProps) => {

    const { userOptions } = props;

    useEffect(() => {
        const askForReview = async () => {
            console.log(`Asking user ${!!userOptions?.hasReviewedApp ? "again" : "first time"} for review!`);
            await StoreReview.requestReview();
            const updateRequest: UpdateUserOptionsRequest = {
                hasReviewedApp: true,
            };
            await updateUserOptions(updateRequest);
        };

        (async () => {
            const isReviewAvailable = await StoreReview.isAvailableAsync();
            console.log("App review is available >> ", isReviewAvailable)
            if (isReviewAvailable) {
                if(!userOptions?.hasReviewedApp // user has not reviewed app yet
                    || (!!userOptions?.lastReviewedAt && moment(userOptions.lastReviewedAt).add({ day: MIN_DAYS_TO_WAIT_TO_ASK_FOR_REVIEW }).isBefore(moment())) // last review was asked MIN_DAYS_TO_WAIT_TO_ASK_FOR_REVIEW days before today
                )
                    await askForReview();
            }
        })();

    }, []);

    return <></>;
}

export default (AppReview) as React.FunctionComponent<AppReviewProps>
