class Api {
    private url : string;

    setUrl(url: string) {
        this.url = url;
    }

    getUrl() {
        return this.url;
    }
}

export const API = new Api()