import React, { useMemo } from "react";
import { TouchableOpacity, View } from "react-native";
import { useReduxMaps } from "react/redux/redux";
import { ParkInfo } from "react/model/ParkInfo";
import { ParkingType } from "react/model/Types";
import { useParkPinMeta, useParkPinPressHandler } from "./park.constants";
import { generateCasualIcon, generateCasualText, getShowCasualPin } from "./casual.constants";
import { getCurrentDailyPricePeriod, getCurrentHourlyPricePeriod } from "react/util/services/parkingPrice.service";
import { MarkerContent } from "react/components/map/MarkerContent";
import { IconName } from "react/legacy/parkable-components";
import { generateLongTermIcon, generateLongTermText, getShowLongTermPin } from "./long-term.constants";
import { generateEvIcon, generateEvText, getShowEvPin } from "./ev.constants";

export type ParkPinWebProps = {
    info: ParkInfo;
};

export const ParkPinWeb = ({ info }: ParkPinWebProps) => {
    const { preferences } = useReduxMaps();
    const { parkingType } = preferences;
    const {
        parkId,
        hideCasualBays,
        hideSubscriptionBays,
        hideEVBays,
        parkingPrice,
        pricePerMonth,
        pricePerWeek,
        currencyCode,
    } = info;
    const pricePeriods = parkingPrice?.pricePeriods ?? [];
    const hourlyPricePeriod = !!pricePeriods ? getCurrentHourlyPricePeriod(pricePeriods) : null;
    const dailyPricePeriod = !!pricePeriods ? getCurrentDailyPricePeriod(pricePeriods) : null;
    const {
        isFavouritePark,
        isMotorbikeDefault,
        isOrgMember,
        isParkFull,
        isParkOpen,
        casualDisplayType,
        longTermDisplayType,
        hasShortTerm,
        hasLongTerm,
        numSpotsLeft,
        isParkUnavailable,
        isParkOwner,
        hasEvCharger,
        zIndex,
    } = useParkPinMeta(info, parkingType);

    const shouldShow: { [key in ParkingType]: boolean } = {
        [ParkingType.CASUAL]: !!getShowCasualPin({
            hideCasualBays,
            isParkOwner,
            hasShortTerm,
            isParkFull,
            isParkOpen,
            isOrgMember,
            isFavouritePark,
            preferences,
        }),
        [ParkingType.LONG_TERM]: !!getShowLongTermPin({
            hasLongTerm,
            isOrgMember,
            isFavouritePark,
            isParkFull,
            isParkOpen,
            preferences,
            hideSubscriptionBays,
            isParkOwner,
        }),
        [ParkingType.ELECTRIC]: !!getShowEvPin({
            hideEVBays,
            isParkOwner,
            hasEvCharger,
            isFavouritePark,
            isOrgMember,
            preferences,
        }),
    };
    const icon: { [key in ParkingType]: IconName } = {
        [ParkingType.CASUAL]: generateCasualIcon({ isFavouritePark, isMotorbikeDefault, isOrgMember }),
        [ParkingType.LONG_TERM]: generateLongTermIcon({
            isFavouritePark,
            isOrgMember,
        }),
        [ParkingType.ELECTRIC]: generateEvIcon({ isFavouritePark, isOrgMember, isMotorbikeDefault }),
    };
    const text: { [key in ParkingType]: string } = {
        [ParkingType.CASUAL]: generateCasualText({
            currencyCode,
            displayType: casualDisplayType,
            isFavouritePark,
            isParkFull,
            numSpotsLeft,
            pricePerDay: dailyPricePeriod?.price,
            pricePerHour: hourlyPricePeriod?.price,
            isOrgMember,
        }),
        [ParkingType.LONG_TERM]: generateLongTermText({
            currencyCode,
            displayType: longTermDisplayType,
            isFavouritePark,
            isParkFull,
            numSpotsLeft,
            pricePerMonth,
            pricePerWeek,
            isOrgMember,
        }),
        [ParkingType.ELECTRIC]: generateEvText({ isOrgMember }),
    };

    const handlePress = useParkPinPressHandler({ parkId });

    return useMemo(
        () => (
            <View style={{ zIndex }}>
                {shouldShow[parkingType] && (
                    <TouchableOpacity onPress={handlePress} activeOpacity={1}>
                        <MarkerContent
                            iconName={icon[parkingType]}
                            markerText={text[parkingType]}
                            parkIsOpen={!isParkUnavailable}
                            userIsOrgMember={isOrgMember}
                            parkIsFull={isParkFull}
                            favPark={isFavouritePark}
                            hasEVCharger={hasEvCharger}
                        />
                    </TouchableOpacity>
                )}
            </View>
        ),
        [text, parkId, shouldShow, icon]
    );
};
