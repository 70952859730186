import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { IRootReducer } from "../../redux/reducers/main";
import { Moment } from "moment";
import Text from "react/legacy/parkable-components/text/Text";
import Button from "react/legacy/parkable-components/button/Button";
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import { ScrollView, StyleSheet, View } from "react-native";
import Strings from "../../util/localization/localization";
import { Term } from "../../model/Term";
import { Park } from "../../model/Park";
import localizeCurrency from "../../util/localization/localizeCurrency";
import Colours from "react/legacy/parkable-components/styles/Colours";
import UserDetailsCard from "../../components/common/UserDetailsCard";
import { Bay } from "../../model/Bay";
import LocationCard from "../../components/common/LocationCard";
import InstructionsCard from "../../components/common/InstructionsCard";
import { acceptEmployeeSubscription, createPublicSubscription } from "../../redux/actions/subscriptions";
import { Routes } from "react/navigation/root/root.paths";
import LoadingView from "../../components/common/LoadingView";
import { ActivityType, Voucher } from "../../model/Voucher";
import SelectedVoucherCard from "../../components/common/voucher/SelectedVoucherCard";
import { useTerritory } from "../../api/territory/territory.api";
import { Territory } from "../../model/Territory";
import { useBayGroup } from "../../api/bayGroup/bayGroup.api";
import { getLongTermPrices } from "../../util/price.util";
import { ParkingType } from "../../model/Types";
import { usePark } from "../../api/park";
import { createRoute, NavigationProps, useNavigation } from "../../navigation/constants";
import ParkableBaseView from "../../components/common/ParkableBaseView";
import { CarParkNameRow } from "../../components/widgets/table-rows/car-park-row";
import { useBay } from "../../api/bay/bay.api";
import { useAppDispatch, useSelector } from "react/redux/redux";
import { clearSelectedVehicles } from "../../redux/actions/vehicles";
import { useUserVehicles } from "react/api/vehicle/vehicle.api";
import { handleFailedTransaction } from "react/util/ExceptionHandler";
import { showAlert } from "react/util/alerts";
import { useUserRoles } from "../../api/user/user.api";
import { Role } from "../../model/User";
import { useCalculateProcessingFee } from "../../api/processingFees/processingFees.api";
import { BayRow } from "../../components/bay/BayRow";
import { mapBayToBayWithSharingPool } from "../../components/bay/util";

const StartSubscriptionSummaryView = (props: ReduxProps & typeof actions) => {
    const {
        parkId,
        term,
        startDate,
        bayId,
        createPublicSubscription,
        baysAvailable,
        isInvitationConfirmation,
        employeeSubscriptionId,
        acceptEmployeeSubscription,
        voucher,
    } = props;

    const navigation = useNavigation();
    const dispatch = useAppDispatch();

    const { park } = usePark(parkId);
    const { territory } = useTerritory(park?.territory);
    const { bay } = useBay(parkId, bayId);

    const [loading, setLoading] = useState(false);
    const [baySelected, setBaySelected] = useState(bay);
    const { bayGroup } = useBayGroup(park?.organisation, bay?.group);
    const { vehicles: userVehicles } = useUserVehicles();
    const { selectedVehicleIds } = useSelector((state: IRootReducer) => state.vehicles);
    const { userRoles } = useUserRoles();
    const isPublicMember = !(userRoles ?? [])
        .filter((ur) => !!park?.organisation && ur.organisation === park?.organisation)
        .filter((ur) => ur.role.name !== Role.PUBLIC)
        .some((ur) => !ur.suspended);
    const { pricePerWeek, pricePerMonth } = getLongTermPrices(park, bayGroup);
    const isWeeklyTerm = !!term && term === Term.Week;
    const price = isWeeklyTerm ? pricePerWeek! : pricePerMonth!;
    let discount = null;
    let total = price;
    if (!!voucher) {
        discount = price * (voucher.percentDiscount! / 100.0) * -1;
        total = price + discount;
    }
    const { data: processingFeeAmount } = useCalculateProcessingFee(price, territory?.id, "LongTerm", isPublicMember);
    const { data: processingFeeCharged } = useCalculateProcessingFee(total, territory?.id, "LongTerm", isPublicMember);

    const blockedVehicle = useMemo(() => {
        return userVehicles?.some((vehicle) => vehicle.block !== null && vehicle.block.active);
    }, [userVehicles]);

    const onConfirmSubscriptionPress = async () => {
        if (userVehicles != null && (userVehicles.length ?? 0) == 0) {
            const destination = {
                route: Routes.StartSubscriptionSummaryView,
                params: {
                    employeeSubscriptionId,
                    parkId,
                    isInvitationConfirmation,
                },
            };
            // @ts-ignore
            navigation.replace(Routes.VehiclesView, { destination });
            return;
        }

        try {
            setLoading(true);
            if (isInvitationConfirmation && employeeSubscriptionId) {
                const employeeSubscription = await acceptEmployeeSubscription(employeeSubscriptionId, territory);

                setLoading(false);
                dispatch(clearSelectedVehicles());
                navigation.reset({
                    routes: [
                        {
                            name: Routes.ParkableMapView,
                            params: { employeeSubscription, bay: baySelected, showSubscription: true },
                        },
                    ],
                });
            } else if (baySelected) {
                //EmployeeSubscription for public parker
                const employeeSubscription = await createPublicSubscription(
                    parkId,
                    baySelected.id,
                    term,
                    price,
                    startDate,
                    territory,
                    selectedVehicleIds ?? []
                );
                setLoading(false);
                dispatch(clearSelectedVehicles());
                navigation.reset({
                    routes: [
                        {
                            name: Routes.ParkableMapView,
                            params: { employeeSubscription, bay: baySelected, showSubscription: true },
                        },
                    ],
                });
            }
        } catch (err: any) {
            if (err && !handleFailedTransaction(navigation, err)) {
                showAlert(err.message ?? Strings("internal_error_if_persists"), Strings("error"));
            }
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <LoadingView title={Strings("creating_your_subscription")} />;
    }

    total += processingFeeCharged ?? 0;

    return (
        <ParkableBaseView scrollable={false}>
            <ScrollView showsVerticalScrollIndicator={true}>
                <View style={styles.base}>
                    <Text h1 style={{ marginBottom: 10 }}>
                        {Strings("confirm_details")}
                    </Text>

                    {park && <CarParkNameRow displayName={park.displayName} />}

                    {park && <LocationCard park={park} />}

                    <TableRow
                        iconLeft={"key"}
                        label={isWeeklyTerm ? Strings("weekly_subscription") : Strings("monthly_subscription")}
                        labelBottom={
                            processingFeeAmount
                                ? Strings("plus_processing_fee")(
                                      localizeCurrency(processingFeeAmount, territory?.currencyCode, false)
                                  )
                                : ""
                        }
                    >
                        {`${localizeCurrency(price, territory?.currencyCode, false)}${
                            isWeeklyTerm ? Strings("per_week") : Strings("per_month")
                        }`}
                    </TableRow>

                    <BayRow
                        // Need this because on first render, 'bay' might be undefined.
                        bay={baySelected ?? bay}
                        parkId={parkId}
                        onChange={setBaySelected}
                        // @ts-ignore
                        baysAvailable={mapBayToBayWithSharingPool(baysAvailable, [])}
                        parkingType={ParkingType.LONG_TERM}
                    />

                    <UserDetailsCard
                        parkId={parkId}
                        selectSubscriptionVehicles={!!park?.maxVehiclesPerSubscription}
                    />

                    <TableRow iconLeft={"dollarfilled"} label={Strings("total_to_be_charged")}>
                        {`${localizeCurrency(total, territory?.currencyCode, false)} ${
                            processingFeeCharged
                                ? `(${Strings("incl_processing_fee")(
                                      localizeCurrency(processingFeeCharged, territory?.currencyCode, false)
                                  )})`
                                : ""
                        }`}
                    </TableRow>

                    {park && (
                        <SelectedVoucherCard
                            territory={territory}
                            voucher={voucher}
                            park={park}
                            activity={ActivityType.LongTerm}
                            discount={discount}
                        />
                    )}

                    {park && <InstructionsCard park={park} />}
                </View>
            </ScrollView>
            <View style={styles.buttonContainer}>
                <Button
                    iconRight={"arrowboldright"}
                    style={styles.base}
                    disabled={blockedVehicle}
                    onPress={onConfirmSubscriptionPress}
                >
                    {Strings("confirm_and_start")}
                </Button>
            </View>
        </ParkableBaseView>
    );
};

export type StartSubscriptionPark = Pick<
    Park,
    "id" | "pricePerWeek" | "pricePerMonth" | "address" | "description" | "organisation"
> & {
    territory: number | Territory;
};

export class StartSubscriptionSummaryViewParams {
    parkId: number;
    term: Term;
    startDate?: Moment;
    bayId?: number;
    feature?: string;
    baysAvailable: Bay[];
    isInvitationConfirmation?: boolean;
    employeeSubscriptionId?: number;
}

type Props = NavigationProps<Routes.StartSubscriptionSummaryView>;

const actions = {
    createPublicSubscription,
    acceptEmployeeSubscription,
};

const getReduxProps = (state: IRootReducer, props: Props) => {
    const { employeeSubscriptionId, parkId, isInvitationConfirmation } = props.route.params;
    const voucher = state.user?.longTermVoucher?.[employeeSubscriptionId ?? parkId] ?? null;

    return {
        parkId,
        bayId: props.route.params.bayId,
        term: props.route.params.term,
        startDate: props.route.params.startDate,
        feature: props.route.params.feature,
        baysAvailable: props.route.params.baysAvailable,
        isInvitationConfirmation,
        employeeSubscriptionId,
        userId: state.data.userId,
        voucher: voucher as Voucher | null,
    };
};

type ReduxProps = ReturnType<typeof getReduxProps>;

export default connect(getReduxProps, actions)(StartSubscriptionSummaryView) as any as React.FC<Props>;

export const StartSubscriptionSummaryViewRoute = createRoute({
    path: Routes.StartSubscriptionSummaryView,
    params: { type: StartSubscriptionSummaryViewParams },
});

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
    },
    outerFooter: {
        height: 100,
        backgroundColor: Colours.ORANGE_DARK,
        justifyContent: "flex-end",
    },
    buttonContainer: {
        height: 54,
        borderColor: Colours.GREY_BORDER,
        borderRadius: 5,
        borderWidth: 1,
        paddingVertical: 10,
        marginVertical: 5,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
    },
    base: {
        flex: 1,
    },
});
