import { classnames, Colours, Icon, IconName, Icons } from "react/legacy/parkable-components";
import React from "react";
import { Platform, StyleSheet, Text, View } from "react-native";
import { spacing } from "react/util/theme/spacing";

const styles = StyleSheet.create({
    // Outer container styling
    // Padding is for positioning the EV icon on iOS and Android
    outer: {
        alignItems: "center",
        justifyContent: "center",
        ...Platform.select({
            ios: {
                paddingRight: 5,
                paddingTop: 6,
            },
            android: {
                paddingRight: 3,
                paddingTop: 6,
            },
        }),
    },
    // Main container for the marker
    container: {
        zIndex: 1,
        width: 48,
        borderRadius: 3,
        alignItems: "stretch",
        // Background colour required to effectively calculate shadow.
        backgroundColor: Colours.NEUTRALS_WHITE,
        shadowColor: Colours.NEUTRALS_900,
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.3,
        shadowRadius: 2,
        ...Platform.select({
            android: {
                elevation: 5,
            },
            web: {
                shadowRadius: 3,
            },
        }),
    },

    // Text panel styling
    textPanel: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
        width: "100%",
        height: spacing(2),
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Colours.NEUTRALS_WHITE,
    },
    inactiveTextPanel: {
        backgroundColor: Colours.NEUTRALS_300,
    },
    userIsOrgMember: {
        height: 0,
    },

    // Icon styling
    icon: {
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        alignItems: "center",
        justifyContent: "center",
    },
    activeIconView: {
        height: 42,
        backgroundColor: Colours.GREEN_300,
    },
    activeIconOrgMemberView: {
        height: 60,
        backgroundColor: Colours.PINK,
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
    },
    favoritePark: {
        height: 42,
        backgroundColor: Colours.PURPLE,
    },
    hidePriceIconView: {
        height: 42,
    },
    inactiveIcon: {
        backgroundColor: Colours.NEUTRALS_300,
    },

    // Arrow and shadow box for the arrow styling
    arrowDown: {
        zIndex: 2,
        width: 0,
        height: 0,
        top: 0,
        alignSelf: "center",
        backgroundColor: "transparent",
        borderStyle: "solid",
        borderLeftWidth: 5,
        borderRightWidth: 5,
        borderBottomWidth: 5,
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
        borderBottomColor: Colours.NEUTRALS_WHITE,
        ...Platform.select({
            android: {
                elevation: 3,
            },
        }),
        transform: [{ rotate: "180deg" }],
    },
    arrowDownShadowBox: {
        width: 10,
        height: 10,
        top: -13,
        // Background colour required to effectively calculate shadow.
        backgroundColor: Colours.NEUTRALS_WHITE,
        shadowColor: Colours.NEUTRALS_900,
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.3,
        shadowRadius: 2,
        ...Platform.select({
            ios: {
                top: -12,
            },
            android: {
                elevation: 2,
            },
            web: {
                shadowRadius: 3,
            },
        }),
        transform: [{ rotate: "45deg" }],
    },
    pinkArrowDown: {
        borderBottomColor: Colours.PINK,
    },
    inactiveArrowDown: {
        borderBottomColor: Colours.NEUTRALS_300,
    },
    orgHidePriceArrowDown: {
        borderBottomColor: Colours.PINK,
    },
    casualHidePriceArrowDown: {
        borderBottomColor: Colours.GREEN_300,
    },

    // Marker text styling
    markerText: {
        color: Colours.NEUTRALS_BLACK,
        fontWeight: "700",
        textTransform: "uppercase",
        fontSize: 12,
        letterSpacing: -0.7,
    },
    inactiveMarkerText: {
        color: Colours.NEUTRALS_500,
    },

    // Electric plug icon styling
    electricPlugIconContainer: {
        zIndex: 5,
        position: "absolute",
        top: 0,
        right: 0,
        width: spacing(2),
        height: spacing(2),
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 3,
        ...Platform.select({
            android: {
                elevation: 6,
            },
            web: {
                top: -6,
                left: spacing(1),
            },
        }),
    },
    electricPlugIcon: {
        width: 12,
        height: 12,
        alignItems: "center",
        justifyContent: "center",
        ...Platform.select({
            ios: {
                // BAC-12198 For some reason, iOS requires this to make the ev label icon look centred.
                marginLeft: 1,
            },
        }),
    },
});

const cn = classnames(styles);

export type MarkerContentProps = {
    isHidePrice?: boolean;
    markerText?: string;
    iconName: IconName;
    parkIsOpen?: boolean;
    favPark?: boolean;
    userIsOrgMember?: boolean;
    hasEVCharger?: boolean;
    iconBackgroundColour?: string;
    userIsParkOwner?: boolean;
    parkIsFull?: boolean;
};

export const MarkerContent = (props: MarkerContentProps) => {
    const { isHidePrice, markerText, iconName, parkIsOpen, favPark, userIsOrgMember, hasEVCharger, parkIsFull } = props;

    const parkUnavailable = (!userIsOrgMember && parkIsFull) || !parkIsOpen;

    //icon colour for Campus and Org Pins
    const iconStyle = [
        styles.icon,
        cn.styles(userIsOrgMember ? "activeIconOrgMemberView" : favPark ? "favoritePark" : "activeIconView", {
            inactiveIcon: parkUnavailable,
            hidePriceIconView: isHidePrice && !userIsOrgMember,
        }),
    ];

    const arrowStyle = cn.styles("arrowDown", {
        casualHidePriceArrowDown: isHidePrice,
        orgHidePriceArrowDown: isHidePrice && userIsOrgMember,
        inactiveArrowDown: parkUnavailable,
        pinkArrowDown: isHidePrice || parkUnavailable ? false : userIsOrgMember,
    });

    const textPanelStyle = cn.styles("textPanel", {
        userIsOrgMember: userIsOrgMember,
        inactiveTextPanel: parkUnavailable,
    });

    const textStyle = cn.styles("markerText", {
        inactiveMarkerText: parkUnavailable,
    });

    return (
        <View style={styles.outer}>
            <View style={styles.container}>
                <View style={iconStyle}>
                    <Icon
                        iconProps={{ allowFontScaling: false }}
                        name={iconName}
                        style={{
                            width: 24,
                            height: 24,
                        }}
                        iconStyle={{
                            fontSize: 24,
                        }}
                        color={parkUnavailable ? Colours.NEUTRALS_500 : Colours.NEUTRALS_WHITE}
                    />
                </View>
                {!isHidePrice && (
                    <View style={textPanelStyle}>
                        <Text allowFontScaling={false} style={textStyle}>
                            {markerText}
                        </Text>
                    </View>
                )}
            </View>
            <View style={arrowStyle} />
            <View style={styles.arrowDownShadowBox} />
            {hasEVCharger && (
                <View
                    style={[
                        styles.electricPlugIconContainer,
                        { backgroundColor: parkUnavailable ? Colours.NEUTRALS_500 : Colours.NEUTRALS_BLACK },
                    ]}
                >
                    <Icon
                        iconStyle={{
                            fontSize: 12
                        }}
                        style={styles.electricPlugIcon}
                        color={parkUnavailable ? Colours.NEUTRALS_300 : Colours.NEUTRALS_WHITE}
                        name={Icons.electricvehicleplug}
                    />
                </View>
            )}
        </View>
    );
};
