import {Park} from "./Park";

export enum ActivityType {
    Casual = 'Casual',
    LongTerm = 'LongTerm',
}

export enum ValidForParking {
    ClassicCasual = 'ClassicCasual',
    PfBCasual = 'PfBCasual',
    PfBSubscription = 'PfBSubscription',
    PfBSaaSSubscription = 'PfBSaaSSubscription'
}

export enum VoucherType {
    FREE_SESSION = 100,
    FREE_CREDIT = 200,
    PERCENT_DISCOUNT = 300,
    FREE_TRIAL_SAAS_PLAN = 400,
    PERCENT_DISCOUNT_SAAS_PLAN = 500,
    FREE_TRIAL_UPGRADE_SAAS_PLAN = 600,
    PERCENT_DISCOUNT_UPGRADE_SAAS_PLAN = 700,
}

export interface Voucher {
    id: number,
    type: number,
    validForParking: ValidForParking[] | null,
    territory: number,
    deleted: boolean | null,
    userMessage: string,
    park: Park | number | null,
    originalCreditAmount: number | null,
    remainingCreditAmount: number | null,
    fullyRedeemed: boolean | null,
    originalSessionCount: number | null,
    remainingSessionCount: number | null,
    percentDiscount: number | null,
    validTo: string | null,
    campaign: any | number | null;
    code: string | null;
    validFrom: string | null;
    createdAt: string;
    bulkCampaign: any | number | null;
    parkSessionIds: number[] | null;
    organisation: number | null;
    lengthDaysFreeTrial: number | null;
}