import React from "react";
import { Platform, StyleSheet, TextInput, View } from "react-native";
import classnames from "react/legacy/parkable-components/util/classnames";
import Colours from "react/legacy/parkable-components/styles/Colours";
import { spacing } from "react/util/theme/spacing";
import Strings from "react/util/localization/localization";

type RegoInputBlockParams = {
    value: string | undefined;
    onChange: (text: string) => void;
};

export const RegoInputBlock = ({ value, onChange }: RegoInputBlockParams) => {
    const inputStyle = cn.styles("regoInput", {
        notPlaceholder: !!value?.length,
    });

    return (
        <View style={styles.rego}>
            <View style={[styles.pin, styles.pinTL]} />
            <View style={[styles.pin, styles.pinTR]} />
            <View style={[styles.pin, styles.pinBL]} />
            <View style={[styles.pin, styles.pinBR]} />
            <TextInput
                style={inputStyle}
                maxLength={15}
                autoFocus={false}
                value={value}
                autoCapitalize="characters"
                placeholder={Strings("start_typing")}
                // https://github.com/facebook/react-native/issues/27449
                // Using `toUpperCase` or `textTransform: 'uppercase'` breaks on an Android controlled TextInput
                onChangeText={(text) => onChange(Platform.OS === "android" ? text : text.toUpperCase())}
                placeholderTextColor={Colours.NEUTRALS_500}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    rego: {
        position: "relative",
        borderColor: Colours.NEUTRALS_BLACK,
        borderWidth: 5,
        borderRadius: 9,
        marginTop: spacing(2),
        marginBottom: spacing(3),
        height: 120,
    },
    pin: {
        position: "absolute",
        height: 6,
        width: 6,
        backgroundColor: Colours.NEUTRALS_BLACK,
        borderRadius: 3.5,
    },
    pinTL: {
        top: 5,
        left: 5,
    },
    pinTR: {
        top: 5,
        right: 5,
    },
    pinBL: {
        bottom: 5,
        left: 5,
    },
    pinBR: {
        bottom: 5,
        right: 5,
    },
    regoInput: {
        position: "absolute",
        height: "100%",
        width: "100%",
        fontSize: 24,
        letterSpacing: 1,
        alignItems: "center",
        textAlign: "center",
        fontFamily: "GTEestiDisplay-Regular-Italic",
    },
    notPlaceholder: {
        fontFamily: "GTEestiDisplay-Bold",
    },
});

const cn = classnames(styles);
