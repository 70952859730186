import { IconName } from "react/legacy/parkable-components/icon/Icons";
import Strings from "react/util/localization/localization";
import localizeCurrency from "react/util/localization/localizeCurrency";
import { LTDisplayType, MapPreferences, ParkingType } from "react/model/Types";

export type GenerateLongTermTextArgs = {
	displayType?: LTDisplayType;
	isParkFull?: boolean;
	isFavouritePark?: boolean;
	currencyCode?: string;
	pricePerWeek?: number;
	pricePerMonth?: number;
	numSpotsLeft?: number;
	isOrgMember?: boolean;
};
export const generateLongTermText = (args: GenerateLongTermTextArgs) => {
	if (args.isOrgMember) {
		return ""
	}
	if (args.isParkFull) {
		return args.isFavouritePark ? "0" : Strings("full")
	}
	switch (args.displayType) {
		case LTDisplayType.PRICE_PER_MONTH:
			return localizeCurrency(
				args.pricePerMonth ?? 0,
				args.currencyCode,
				false,
				true
			);
		case LTDisplayType.PRICE_PER_WEEK:
			return localizeCurrency(
				args.pricePerWeek ?? 0,
				args.currencyCode,
				false,
				true
			);
		case LTDisplayType.SPOTS_LEFT:
			return `${args.numSpotsLeft ?? "0"}`;
		default:
			return ""
	}
};


export const generateLongTermIcon = (args: {
	isFavouritePark?: boolean
	isOrgMember?: boolean
}): IconName => {
	if (args.isOrgMember) {
		return "buildingwork"
	}

	if (args.isFavouritePark) {
		return "star"
	}

	return "key"
}


export type ShowLongTermPinProps = {
	hasLongTerm?: boolean
	isParkFull?: boolean
	isParkOpen?: boolean
	isFavouritePark?: boolean | null
	isOrgMember?: boolean | null
	preferences?: Partial<Pick<MapPreferences, "hideClosedParks" | "hideFullParks" | "parkingType">>
	hideSubscriptionBays?: boolean | null
	isParkOwner?: boolean
};
export const getShowLongTermPin = (
	{ hasLongTerm, isParkFull, isParkOpen, preferences, hideSubscriptionBays, isParkOwner, isOrgMember, isFavouritePark }: ShowLongTermPinProps
) => {
	if (hideSubscriptionBays) {
		return isParkOwner;
	}
	if (isOrgMember || isFavouritePark) {
		// if bayType is Leasable. hasLongTerm return false.
		return true;
	}
	if (preferences?.parkingType !== ParkingType.LONG_TERM) {
		return false
	}
	if (!hasLongTerm) {
		return false
	}
	if (preferences?.hideFullParks && isParkFull) {
		return false
	}
	if (preferences?.hideClosedParks && !isParkOpen) {
		return false
	}
	return true;
};
