import {ParkableError} from "react/model/ParkableError";
import {showConfirmation} from "react/util/alerts";
import Strings from "react/util/localization/localization";
import {Routes} from "react/navigation/root/root.paths";

export const isErrorFailedTransaction = (parkableError?: ParkableError) => {
    if(!parkableError){
        return false;
    }

    const failedPaymentErrorCodes = [
        "UNPAID_DEPOSIT",
        "UNPAID_INFRINGEMENT",
        "UNPAID_USER_CHARGE",
        "UNPAID_SUBSCRIPTION_INVOICE",
    ];

    return (parkableError.code??0) === 275 || (!!parkableError.errorCode && failedPaymentErrorCodes.includes(parkableError.errorCode));

}

export const handleFailedTransaction = (navigation: any, parkableError?: ParkableError) => {
    if (!isErrorFailedTransaction(parkableError)) {
        return false;
    }
    showConfirmation(
        Strings("unfortunately_your_last_payment_failed"),
        Strings("uh_oh_"),
        true,
        (confirmed: boolean) =>
            confirmed &&
            navigation.push(
                Routes.RetryPaymentRequest,
                parkableError!.code === 275 ? { sessionId: parkableError!.data } : {}
            ),
        Strings("continue"),
        Strings("cancel")
    );
    return true;
};
