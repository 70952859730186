import React, { useRef, useEffect } from 'react';
import {View, Image} from 'react-native';
import Button from "react/legacy/parkable-components/button/Button";
import Text from "react/legacy/parkable-components/text/Text";
import { DialogRef } from "react/legacy/parkable-components/dialog/Dialog";
import Spinner from "react/legacy/parkable-components/spinner/Spinner";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Dialog from "react/components/dialog/Dialog";
import Strings from "../../util/localization/localization"
import { Park } from '../../model/Park';
import localizeCurrency from '../../util/localization/localizeCurrency';
import { CasualDisplayType, ParkingType } from '../../model/Types';
import { OrganisationDTO } from '../../model/OrganisationDTO';
import useSafeArea from "react/util/hooks/useSafeArea";
import {getCurrentHourlyPricePeriod} from "react/util/services/parkingPrice.service";
import {useParkingPrice} from "react/api/parkingPrice/parkingPrice.api";
import LocationCard from "./maps/LocationCard_root";

const ThreeStar = require('../../resources/threestar.png');

type Props = {
    show: boolean,
    organisation: OrganisationDTO,
    parksInOrg: Park[],
    onHide: () => void,
    isMotorBikeDefault: boolean
}

export default function MemberInvitationModal(props: Props) {
    const { show = false, organisation = null, parksInOrg = [], onHide, isMotorBikeDefault } = props;

    const { pricePeriods } = useParkingPrice(parksInOrg[0]?.parkingPrice);
    const hourlyPricePeriod = !!pricePeriods ? getCurrentHourlyPricePeriod(pricePeriods) : null;

    const dialogRef = useRef<DialogRef | null>(null);

    const { top } = useSafeArea();

    useEffect(() => {
        if (show) {
            dialogRef.current?.show();
        } else {
            dialogRef.current?.hide();
        }
    }, [show]);

    let content = <View><Spinner large /></View>;
    if(parksInOrg.length === 1) {
        const park = parksInOrg[0];
        const displayText = localizeCurrency(hourlyPricePeriod?.price??0, park.territory?.currencyCode, false);
        content =   <View style={{width: '100%'}}>
                        <LocationCard   parkId={park.id}
                                        displayType={CasualDisplayType.PRICE_PER_HOUR}
                                        parkingType={ParkingType.CASUAL}
                                        orgName={organisation?.name}
                                        displayText={displayText}
                                        isMotorBikeDefault={isMotorBikeDefault}
                        />
                        <Button center style={{marginTop: 10}} onPress={onHide}>{Strings("ok")}</Button>
                    </View>
    } else if (parksInOrg.length > 1) {
        content =   <View style={{width: '100%'}}>
                        <Text h5 mb={10} >{Strings("member_invite_description")}</Text>
                        <Image style={{width: '100%'}} source={ThreeStar} resizeMode='contain' />
                        <Button center style={{marginTop: 10}} onPress={onHide}>{Strings("ok")}</Button>
                    </View>
    } else {
        content = <View style={{width: '100%'}}><Button center style={{marginTop: 10}} onPress={onHide}>{Strings("ok")}</Button></View>
    }

    return  <Dialog style={{position:'absolute', top, width:'100%'}} onModalHide={onHide}
                    ref={dialogRef} iconFABProps={{style: {backgroundColor: Colours.PINK_DARK}}}
                    icon={'star'} iconProps={{white:true}}
                    label={Strings("private_parking")} labelProps={{style: {color: Colours.PINK}}}
                    title={parksInOrg.length > 1 ? `${Strings("org_invite_you")(organisation?.name??'')}s` : `${Strings("org_invite_you")(organisation?.name??'')}`}>
                <View style={{alignItems: 'center'}}>
                    {content}
                </View>
            </Dialog>
}
