import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import {useMasterPublicStripeApiKey, usePublicStripeApiKey} from "../../api/stripe";

type StripeElementsContainerProps = {
	organisationId?: number;
	setStripeLoading: (loading: boolean) => void;
	children: React.ReactNode;
}

export default function StripeElementsContainer(props: StripeElementsContainerProps) {
	const { organisationId, setStripeLoading, children } = props;

	const { data: stripeApiKey } = usePublicStripeApiKey(organisationId);
	const { data: masterStripeApiKey } = useMasterPublicStripeApiKey();
	const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | undefined>(undefined);
	const publishableKey = organisationId ? stripeApiKey?.stripePublicApiKey : masterStripeApiKey?.masterStripePublicApiKey;
	
	useEffect(() => {
		if (publishableKey) {
			try{
				setStripePromise(loadStripe(publishableKey));
				setStripeLoading(false);
			} catch (e) {
				console.log("Error loading stripe", e)
			}
		}
	}, [publishableKey]);

	if(!stripePromise){
		return null;
	}

	return <Elements stripe={stripePromise}>
		{children}
	</Elements>
}
